import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import ReactModal from 'react-modal'
import { BrowserRouter as Router } from 'react-router-dom'
import ReactGA from 'react-ga'
import { analytics } from 'Utilities/config'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'react-datepicker/dist/react-datepicker.css' // FIXME is this necessary?

ReactModal.setAppElement('#root')
ReactGA.initialize(analytics())

ReactDOM.render(<Router basename={process.env.REACT_APP_BASENAME}>
  <App />
</Router>, document.getElementById('root'))
