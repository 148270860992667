/* eslint-disable camelcase */
export type UUID = string

export interface Contact {
  id?: string
  email?: string
  name?: string
  phone?: string
}

export interface Group {
  id: string
  name: string
  todo: GroupToDos
  dates: {
    effective?: Date
    contacted?: Date
    renewal?: Date
  }
  status?: PipelineStatus
  address: Address
  contacts: Contact[]
  flagged: boolean
  members: {
    total: number
    enrolled: number
    inProgress: number
    noActionTaken: number
    waived: number
    averageAge?: number
  }
  identifiers: {
    ein?: string
    sic?: number
  }
  associations?: Association[]
  contributions: {
    medical: {value: string, isEquitable: boolean}
    dental: {value: string, isEquitable: boolean}
    vision: {value: string, isEquitable: boolean}
  }
  waitingPeriod: number
  hasPaymentInfo: boolean
}

export interface PartialGroup extends Partial<Omit<Group, 'contributions'>> {
  contributions?: {
    medical?: {value: string, isEquitable: boolean}
    dental?: {value: string, isEquitable: boolean}
    vision?: {value: string, isEquitable: boolean}
  }
}

export interface GroupToDos {
  manager: boolean
  billing: boolean
  invites: boolean
  census: boolean
  plans: boolean
}

export enum PipelineStatus {
  suspect = 'suspect',
  prospect = 'prospect',
  proposed = 'proposed',
  lead = 'lead',
  processing = 'processing',
  enrolled = 'enrolled',
  archived = 'archived'
}

export interface Address {
  street1?: string
  street2?: string
  state?: string
  city?: string
  zip?: string
  county?: {id: string, name?: string}
}

export interface Member {
  id: string
  dob?: Date
  ssn?: string
  tier: Tier
  dates: { hire?: Date, effective?: Date }
  group: {id: string, name: string}
  gender: Gender
  status: EnrollmentStatus
  contact: Contact
  address: Address
  isWaived: boolean
  privilege: Privilege
  dependents?: Dependent[]
  signUpLink?: string
  isMedicallyUnderwritten: boolean
  isFlagged: boolean
  groups?: {id: string, name: string, privilege: Privilege}[]
}

export enum Privilege {
  standard = 'standard',
  manager = 'manager',
  invitedToManage = 'invitedToManage'
}

export enum EnrollmentStatus {
  notInvited = 'notInvited',
  notStarted = 'notStarted',
  awaitingMedicalUnderwriting = 'awaitingMedicalUnderwriting',
  awaitingElections = 'awaitingElections',
  waived = 'waived',
  complete = 'complete'
}

export interface Association {
  id: string
  name: string
}

export interface Ticket {
  mode: TicketMode
  name?: string
  email: string
  venue: Venue
  resetToken?: string
}

export interface TicketPayload {
  name: string
  email: string
  venue: Venue
  venueID: string
}

export enum TicketMode {
  redirect = 'redirect',
  signIn = 'signIn',
  signUp = 'signUp',
  resetPassword = 'resetPassword',
}

export interface ToastCollectorPayload {
  error: string
  localStorage: string
  browserIdentity: string
  url: string
}

export enum Venue {
  group = 0,
  association = 1,
  employee = 2,
  agency = 3,
  eeDashboard = 4,
  erDashboard = 5
}

export enum Tier {
  individual = 'individual',
  singleParent = 'singleParent',
  couple = 'couple',
  family = 'family'
}

export type TieredRates = { [key in Tier]: string }

export interface Dependent {
  id: string
  dob: Date
  ssn?: string
  name?: string
  gender: Gender
  relationship: Relationship
}

export enum Gender {
  male = 'male',
  female = 'female'
}

export enum Relationship {
  spouse = 'spouse',
  child = 'child',
  lifePartner = 'lifePartner'
}

export interface Form {
  id?: UUID
  name: string
  type: FormType
  carrier: string
  carrierFormID: string
  carrierFormDate: Date

  created_at?: Date
  updated_at?: Date
  deleted_at?: Date
}

export enum FormType {
  carrierApplication = 'carrierApplication',
  underwriting = 'underwriting'
}

export interface Question {
  id?: UUID
  formID: string
  text: string
  type: QuestionType
  tagName: string
  required: boolean
  answers?: string[]
  redflagged: boolean
  parentID?: UUID
  answerIndex?: number
  sortOrder?: number

  created_at?: Date
  updated_at?: Date
  deleted_at?: Date
}

export enum QuestionType {
  text = 'text',
  checkbox = 'checkbox',
  radio = 'radio',
  date = 'date',
  array = 'array'
}

export interface QuestionAnswer {
  id?: UUID
  value: string
  formID: UUID
  questionID: UUID
  groupID: UUID
  memberID: UUID

  created_at?: Date
  updated_at?: Date
  deleted_at?: Date
}

interface BasePlan {
  id: string
  groupPlanID?: string
  name: string
  carrier: string
}

export interface MedicalPlan extends BasePlan {
  type: PlanType
  premium: Premium
  memberPremiums: MemberPremium[]
  deductible: string
  oopMax: string
  coinsurance: string
  copay: Copay
  prescription: Rx
  isLevelFunded: boolean
  oonCoverage: boolean
  sbc?: string // It's a url but we don't initialize it as a url object anywhere so leaving it as a string
  preexistingConditionsCovered: boolean
}

export interface DentalPlan extends BasePlan {
  type: string
  data: {
    dentalAllocationOfServicesInNetwork?: string
    dentalAllocationOfServicesOutOfNetwork?: string
    dentalBasicInNetwork?: string
    dentalBasicOutOfNetwork?: string
    dentalChildOrthodontics?: string // Not sure if this is necessary, but just incase
    dentalChildOrthodonticsInNetwork?: string
    dentalChildOrthodonticsOutOfNetwork?: string
    dentalInNetworkReimbusement?: string
    dentalMajorInNetwork?: string
    dentalMajorOutOfNetwork?: string
    dentalOrthodonticsLifetimeMaximumInNetwork?: string
    dentalOrthodonticsLifetimeMaximumOutOfNetwork?: string
    dentalOutOfNetworkReimbusement?: string
    dentalPreventativeInNetwork?: string
    dentalPreventativeOutOfNetwork?: string
    familyInNetworkCarrierPayOutMaximum?: string
    familyInNetworkDeductible?: string
    familyOutOfNetworkCarrierPayOutMaximum?: string
    familyOutOfNetworkDeductible?: string
    individualInNetworkCarrierPayOutMaximum?: string
    individualOutOfNetworkCarrierPayOutMaximum?: string
    individualInNetworkDeductible?: string
    individualOutOfNetworkDeductible?: string
  }
}

export interface VisionPlan extends BasePlan {
  type: string
  data: {
    comprehensiveVisionExamFrequencyInMonths?: string
    comprehensiveVisionExamInNetwork?: string
    comprehensiveVisionExamOutOfNetwork?: string
    contactLensesFrequencyInMonths?: string
    contactLensesInNetwork?: string
    contactLensesOutOfNetwork?: string
    framesAllowanceFrequencyInMonths?: string
    framesAllowanceInNetwork?: string
    framesAllowanceOutOfNetwork?: string
    materialsLensesFramesAndContactsFrequencyInMonths?: string
    materialsLensesFramesAndContactsInNetwork?: string
    materialsLensesFramesAndContactsOutOfNetwork?: string
    standardCorrectiveLensesFrequencyInMonths?: string
    standardCorrectiveLensesInNetwork?: string
    standardCorrectiveLensesOutOfNetwork?: string
  }
}

export interface LifePlan extends BasePlan {
  type: string
  data: {
    benefitsSpouse?: string
    benefitsPerChild?: string
    benefitsIndividual?: string
    disabilityProvision?: string
    'supplementalAD&DIncluded?': string
    willPreparationAndEstateResolutionServices?: string
  }
}

export enum PlanType {
  PPO = 'PPO',
  HMO = 'HMO',
  EPO = 'EPO',
// Indemnity = 'Indemnity',  // unloved
  POS = 'POS',
}

interface Premium {
  employer: string
  employee: { [key in Tier]: string }
}

interface MemberPremium {
  id: string
  planID: string
  quoteID: string
  memberID: string
  total_premium: string
  insured_premium: string
  spouse_premium: string
  children_premium: string
  created_at: Date
  updated_at: Date
}
export interface Copay {
  primaryCarePhysician: string
  urgentCare: string
  specialist: string
  conditional?: string
}

export interface Rx {
  generic: string
  preferredBrand: string
  specialty: string
  conditional?: string
}

export enum DocumentType {
  carrierApplication = 'carrierApplication',
  wageAndTaxReport = 'wageAndTaxReport',
  businessLicense = 'businessLicense',
  priorCarrierBill = 'priorCarrierBill',
  articlesOfIncorporation = 'articlesOfIncorporation',
  census = 'census',
  unclassified = 'unclassified'
}

export interface Note {
  id: string
  content: string
  targetID: string
  ownerID: string
  created_at: Date
  updated_at: Date
}
