import React, { useState } from 'react'
import styles from './ProfileInfoSection.module.scss'
import TodoList from 'Components/Anubis/TodoList'
import EnrollmentStatus from './EnrollmentStatus'
import { Route } from 'Utilities/Route'
import ContactSnapshot from 'Components/Anubis/ContactSnapshot'
import { stages as statuses } from 'Routes/dashboard/agency/pipeline/PipelineSettings'
import { put } from 'Utilities/fetch++'
import useToast from 'Utilities/Hooks/useToast'
import { Group, PipelineStatus, Contact } from 'Utilities/pharaoh.types'
import { ValueType } from 'react-select'
import PipelineStatusSelect, { StatusType } from 'Components/Rudimentary/PipelineStatusSelect'
import FlagGroup from 'Components/Rudimentary/FlagGroup'
import _ from 'lodash'
import { useForm } from 'react-hook-form'
import CandorForm, { CandorFormName, CandorFormAddress, CandorFormDatePicker } from 'Components/Anubis/CandorForm'
import { v3 } from 'Utilities/pharaoh'
import useUser, { PowerLevel } from 'Utilities/Hooks/useUser'
import BackTo from 'Components/Anubis/BackTo'
import { useLocation } from 'react-router-dom'
import NewContactModal from 'Components/Anubis/NewContactModal'
import { useToggle } from 'react-use'
import ContactsModal from 'Components/Anubis/ContactsModal'
import effectiveDateFilter from 'Utilities/Plans/effectiveDateFilter()'
import ACHPayment from 'Routes/shop/employee/checkout/ACHPayment'

type Props = {
  id: string
  group: Group
  targetType: string
}

const ProfileInfoSection: React.SFC<Props> = ({ group, id }) => {
  const [status, setStatus] = useState(group.status)
  const [flagged, setFlagged] = useState(group.flagged)
  const addToast = useToast()
  const defaultContact = !_.isEmpty(group.contacts) ? group.contacts[0] : undefined
  const defaultFormValues = { ...group, contact: defaultContact }
  const form = useForm({ defaultValues: defaultFormValues })
  const { register, setValue } = form
  const user = useUser()
  const location = useLocation()
  const [showNewContactModal, toggleShowNewContactModal] = useToggle(false)
  const [showSwitchContactModal, toggleShowSwitchContactModal] = useToggle(false)
  const [currentContact, setCurrentContact] = useState<Contact | undefined>(defaultContact)
  const [showNotesForCurrentContact, toggleShowNotesForCurrentContact] = useToggle(false)
  const [showPlaidForm, toggleShowPlaidForm] = useToggle(false)
  const isAgencyDash = useLocation().pathname.startsWith(Route.agencyDashboard)
  const cleanUp = () => {
    group.hasPaymentInfo = true
    toggleShowPlaidForm()
  }

  function currentStatus() {
    let selectedStatus = statuses.find(({ key }) => key === status)
    if (!selectedStatus) { selectedStatus = statuses[0] }
    const value: StatusType = { value: selectedStatus.key, label: selectedStatus.label }
    return value
  }

  const profileSnapshotContent: any[] = [{
    before: <h2>{group.members.total || '–'}</h2>,
    after: <span># Employees</span>
  }]
  if (user.value && user.value.powerLevel >= PowerLevel.broker) {
    profileSnapshotContent.push({
      after: <FlagGroup checked={flagged} onChange={onToggleFlagged}/>
    })
    profileSnapshotContent.push({
      after: <div>
        <span>Status</span>
        <PipelineStatusSelect
          value={currentStatus()}
          onChange={onStatusChanged}
          options={ statuses.map(status => ({ value: status.key, label: status.label }))}
        />
      </div>
    })
  }

  const stats: any = _.pick(group.members, 'complete', 'waived', 'incomplete')

  const back = location.pathname.startsWith(Route.agencyDashboard)
    ? <BackTo route={Route.agencyDashboardClients}>Back to Clients</BackTo>
    : null

  return <>
    { back }
    <div className={styles.mainContainer}>
      <NewContactModal isOpen={showNewContactModal} onRequestClose={onAddContactRequestClose} groupID={group.id}/>
      <ContactsModal isOpen={showSwitchContactModal} onRequestClose={onSwitchContactRequestClose} groupID={group.id} currentContact={currentContact} showNotesForCurrentContact={showNotesForCurrentContact}/>
      <ContactSnapshot name={group?.name} content={profileSnapshotContent}/>
      <CandorForm {...form} save={onSave}>
        <CandorFormName name='name' flagged={flagged} />
        <p className={styles.contactDetails}>
          <span>
            { isAgencyDash && <i
              className='material-icons'
              role='button'
              tabIndex={0}
              aria-label='Add New Contact'
              onClick={toggleShowNewContactModal}>
                add_circle
            </i> }
            Contact Details
          </span>
          { isAgencyDash && <div role='button' tabIndex={0} onClick={toggleShowSwitchContactModal}>Switch Contact</div> }
        </p>
        <label>
          Name
          <input name='contact.name' ref={register} placeholder='Contact Name' />
        </label>
        <label>
          Email
          <input name='contact.email' ref={register} placeholder='Contact Email' />
        </label>
        <label>
          Phone
          <input name='contact.phone' ref={register} placeholder='Contact Phone' />
        </label>
        { isAgencyDash && <label>Notes
          <div role='button' tabIndex={0} onClick={showNotesForCurrentcontact}>
            See Notes
          </div>
        </label> }
        <p>Business Details</p>
        <CandorFormAddress showCountyPicker />
        <CandorFormDatePicker
          name="dates.effective"
          placeholder='Effective Date'
          openToDate={defaultMinimumDate()}
          filterDate={effectiveDateFilter}
        >
          Effective Date
        </CandorFormDatePicker>
        <label>
          Wait Per. (Days)
          <input name='waitingPeriod' placeholder='Wait Period' ref={register} />
        </label>
        <label>
          EIN
          <input name='identifiers.ein' placeholder='Employer ID Number' ref={register} />
        </label>
      </CandorForm>
      <div className={styles.todoEnrollment}>
        <TodoList id={id} />
        <EnrollmentStatus stats={stats} />
        <h4>{ group.hasPaymentInfo ? 'Update' : 'Link' } Bank Account Info</h4>
        { showPlaidForm
          ? <ACHPayment groupID={group.id} cleanUp={cleanUp} />
          : <button onClick={toggleShowPlaidForm}>{ group.hasPaymentInfo ? 'Update' : 'Link' }</button>
        }
      </div>
    </div>
  </>

  function onAddContactRequestClose(contact?: Contact) {
    toggleShowNewContactModal()
    if (contact) {
      setCurrentContact(contact)
      setValue('contact', contact, { shouldValidate: true })
    }
  }

  function onSwitchContactRequestClose(contact?: Contact) {
    toggleShowSwitchContactModal()
    if (contact) {
      setCurrentContact(contact)
      setValue('contact', contact, { shouldValidate: true })
    }
  }

  function showNotesForCurrentcontact() {
    toggleShowNotesForCurrentContact()
    toggleShowSwitchContactModal()
  }

  async function onSave(data: any) {
    await Promise.all([
      v3.groups(group.id).PUT(data),
      v3.groups(group.id).contacts.POST({ id: currentContact?.id, ...data.contact })
    ])
  }

  async function onStatusChanged(pipelineStatus: ValueType<StatusType>) {
    const prevStatus = status
    try {
      const status = (pipelineStatus as StatusType).value as PipelineStatus
      setStatus(status)
      await put('/v3/groups', { id, status })
    } catch (error) {
      addToast(error)
      setStatus(prevStatus)
    }
  }

  async function onToggleFlagged(event: React.ChangeEvent<HTMLInputElement>) {
    const prevFlagged = flagged
    try {
      const flagged = event.currentTarget.checked
      setFlagged(flagged)
      await put('/v3/groups', { id, flagged })
    } catch (error) {
      addToast(error)
      setFlagged(prevFlagged)
    }
  }
}

export function minEffectiveDate() {
  const date = new Date()
  date.setDate(date.getDate() + 45)
  return date
}

export function defaultMinimumDate() {
  let date = minEffectiveDate()
  if (!effectiveDateFilter(date)) {
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    if (day > 15) {
      date = new Date(year, month + 1, 1)
    } else {
      date = new Date(year, month, 15)
    }
  }
  return date
}

export default ProfileInfoSection
