import React from 'react'
import styles from './ReturningModal.module.scss'
import StargateModal from 'Components/Stargate/StargateModal'
import { Mode as StargateMode } from 'Utilities/Hooks/useStargate'

type Props = {
  isOpen: boolean
  onRequestClose: () => void
  mode?: StargateMode
  handleButton: (button: 'employee' | 'logout' | 'employer') => void
}

const ReturningModal: React.SFC<Props> = ({ isOpen, onRequestClose, mode, handleButton }) => {
  return <StargateModal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    styles={{ width: '483px', height: 'fit-content', padding: 30 }}
  >
    <h1 className={styles.welcome}>Welcome!</h1>
    <p className={styles.desc}>I am new or returning…</p>
    <div className={styles.modalContainer}>
      {(mode === StargateMode.both || mode === StargateMode.employer) && (
        <button className={styles.employer} onClick={() => handleButton('employer')}>
          EmployER / HR Representative, selecting plans for my group
        </button>
      )}
      {(mode === StargateMode.both || mode === StargateMode.employee) && (
        <button onClick={() => handleButton('employee')} className={styles.employee} >
          EmployEE / Electing benefits
        </button>
      )}
      <button onClick={() => handleButton('logout')} className={styles.logout}>
        Sign Out
      </button>
    </div>
  </StargateModal>
}

export default ReturningModal
