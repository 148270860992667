import React from 'react'
import styles from './index.module.css'

const WaiveConfirmation: React.FC = () =>
  <div className={styles.container}>
    <span><i className={`material-icons ${styles.confirmCheck}`}>check_circle</i></span>
    <h1>Sorry to see you go</h1>
    <p className={styles.confirmation}>Your healthcare coverage has been waived successfully</p>
  </div>

export default WaiveConfirmation
