import React from 'react'
import styles from './index.module.scss'
import StargateModal from 'Components/Stargate/StargateModal'
import TermsAndConditionsText from './TermsAndConditionsText'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const TermsModal: React.FC<Props> = (props) =>
  <StargateModal
    isOpen={props.isOpen}
    onRequestClose={props.onClose}
    styles={{ height: '70vh', padding: 40 }}
  >
    <div className={styles.container}>
      <h1>Terms and Conditions</h1>
      <TermsAndConditionsText className={styles.terms}/>
    </div>
  </StargateModal>

export default TermsModal
