import { useSet } from 'react-use'
import ReactDOM from 'react-dom'

export enum Side {
  left, right
}

function usePivot<T>(): [[Set<T>, (t: T) => void], [Set<T>, (t: T) => void], { side: (t: T) => Side | undefined, size: number, slot: (t: T, side: Side) => void }] {
  const [left, { add: addToLeft, remove: removeFromLeft }] = useSet<T>()
  const [right, { add: addToRight, remove: removeFromRight }] = useSet<T>()

  return [
    [
      left,
      (t: T) => slot(t, Side.left)
    ],
    [
      right,
      (t: T) => slot(t, Side.right)
    ],
    {
      side: (t: T) => {
        if (left.has(t)) { return Side.left }
        if (right.has(t)) { return Side.right }
        return undefined
      },
      size: left.size + right.size,
      slot
    }
  ]

  function slot(t: T, side: Side | undefined) {
    ReactDOM.unstable_batchedUpdates(() => {
      switch (side) {
      case Side.left:
        addToLeft(t)
        removeFromRight(t)
        break
      case Side.right:
        addToRight(t)
        removeFromLeft(t)
        break
      case undefined:
        removeFromLeft(t)
        removeFromRight(t)
      }
    })
  }
}

export default usePivot
