import { centsFormat, dollarsFormat, isDollar, isPercentage } from './PlanCostUtils'
import numeral from 'numeral'
import * as config from 'Utilities/config'
import { Tier, TieredRates, MedicalPlan, DentalPlan, VisionPlan, LifePlan } from 'Utilities/pharaoh.types'
import { Contribution } from 'Components/Plans/plan-subcomponents/PlanHelpers'
import { Member, ContributionSplit } from 'Utilities/Hooks/useStargate'

/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */

/**
 Each calculator takes a single plan, and applies our complicated contribution structure to get the math you want.
 */

function applyContribution(contribution: string | undefined, isEquitable: boolean | undefined, individualPremium_: string | number, tieredPremium_: string | number): number {
  const individualPremium = moneyNumber(individualPremium_, 2)
  const tieredPremium = moneyNumber(tieredPremium_, 2)
  // no contribution
  if (!contribution) return 0

  if (isDollar(contribution)) {
    const rv = numeral(contribution).value()
    if (rv < 0) return 0
    // ensure contribution does not exceed the member’s premium
    if (tieredPremium < rv) return tieredPremium
    return rv
  } else if (isPercentage(contribution)) {
    const basis = isEquitable ? tieredPremium : individualPremium
    const rv = numeral(contribution).multiply(basis).value()
    // shouldn't happen unless user managed to hack > 100% contribution
    if (tieredPremium < rv) return tieredPremium
    return rv
  } else {
    throw new Error('Invalid contribution string')
  }
}

/// returns values for the sum of premiums for the whole group
/// excludes waived members. Tiers are taken into account as necessary.
class MedicalPlanGroupCalculator {
  plan: MedicalPlan
  baseContribution: Contribution
  splits: ContributionSplit[]
  members: Member[]

  constructor(plan: MedicalPlan, baseContribution: Contribution, splits: ContributionSplit[], members: Member[]) {
    this.plan = plan
    this.baseContribution = baseContribution
    this.splits = splits
    this.members = members

    if (!Array.isArray(this.splits)) this.splits = []
    if (!Array.isArray(this.members)) this.members = []
  }

  erForMembers = (members: Member[]) => {
    if (members.length === 0) return 0
    const premiums = members.map(member => {
      if (member.is_waived) return 0
      let premium
      this.splits.forEach(split => {
        if (!split.members.includes(member.id)) return

        premium = applyContribution(
          split.contribution,
          split.isEquitable,
          this.plan.premium.employee.individual,
          this.plan.premium.employee[member.tier])
      })
      if (premium === undefined) {
        premium = applyContribution(
          this.baseContribution.value,
          this.baseContribution.isEquitable,
          this.plan.premium.employee.individual,
          this.plan.premium.employee[member.tier])
      }
      return premium
    })
    return premiums.reduce((a, b) => a + b, 0)
  }

  er = () => this.erForMembers(this.members)
  ee = () => this.total() - this.er()

  erForTier = (tier: Tier) => this.erForMembers(this.members.filter(member => member.tier === tier))
  eeForTier = (tier: Tier) => this.totalForTier(tier) - this.erForTier(tier)

  totalForTier = (tier: Tier) => {
    const premiums = this.members.map(member => {
      if (member.tier !== tier) return 0
      if (member.is_waived) return 0
      return moneyNumber(this.plan.premium.employee[member.tier], 2)
    })
    return premiums.reduce((a, b) => a + b, 0)
  }

  erString = () => moneyString(this.er());
  eeString = () => moneyString(this.ee());

  total = () => {
    if (this.members.length === 0) return 0
    const premiums = this.members.map(member => {
      if (member.is_waived) return 0
      return moneyNumber(this.plan.premium.employee[member.tier], 2)
    })
    return premiums.reduce((a, b) => a + b, 0)
  }

  totalString = () => moneyString(this.total());

  eeCostForTier = (tier: Tier) => {
    return moneyNumber(this.plan.premium.employee[tier], 2) - this.erCostForTier(tier)
  }

  erCostForTier = (tier: Tier) => {
    const premium = applyContribution(
      this.baseContribution.value,
      this.baseContribution.isEquitable,
      this.plan.premium.employee.individual,
      this.plan.premium.employee[tier])

    return moneyNumber(premium, 2)
  }
}

export type AncillaryPlanUnion =
  { rate: TieredRates, plan: DentalPlan | VisionPlan | LifePlan } |
  { rates: TieredRates, plan: DentalPlan | VisionPlan | LifePlan } |
  { rate: string, plan: DentalPlan | VisionPlan | LifePlan }

export function ratesFor(plan: AncillaryPlanUnion): TieredRates | undefined {
  if (typeof (plan as any).rate === 'string') return undefined
  if ((plan as any).rate) return (plan as any).rate
  return (plan as any).rates
}

/// returns values for the sum of premiums for the whole group
/// excludes waived members. Tiers are taken into account as necessary.
class AncillaryPlanGroupCalculator {
  plan: AncillaryPlanUnion
  contribution: string
  members: Member[]

  constructor(plan: AncillaryPlanUnion, contribution: string, members: Member[]) {
    this.plan = plan
    this.contribution = contribution
    this.members = members

    if (!Array.isArray(this.members)) this.members = []
  }

  er = () => {
    if (this.contribution === undefined || this.contribution === null) this.contribution = '$0'
    if (this.members.length === 0) return 0

    const rates = ratesFor(this.plan)
    if (!rates) return 0

    // NOTE currently waiving means waiving from EVERYTHING, yes prosper and ancillary too

    return this.members.map(member => {
      if (member.is_waived) return 0
      const individualPremium = rates.individual
      const tieredPremium = rates[member.tier]
      return applyContribution(this.contribution, false, individualPremium, tieredPremium)
    }).reduce((a, b) => a + b, 0)
  }

  avgEr = () => {
    const nonWaivedMembers = this.members.filter(m => !m.is_waived)
    return nonWaivedMembers.length ? this.er() / nonWaivedMembers.length : 0
  }

  total = () => {
    const rates = ratesFor(this.plan)
    if (!rates || this.members.length === 0) return 0
    return this.members.map(member => {
      if (member.is_waived) return 0
      const tieredPremium = rates[member.tier]
      return moneyNumber(tieredPremium, 2)
    }).reduce((a, b) => a + b, 0)
  }

  ee = () => this.total() - this.er()

  eeForTier = (tier: Tier) => {
    const rates = ratesFor(this.plan)
    if (!rates) return 0
    return moneyNumber(rates[tier], 2) - moneyNumber(applyContribution(this.contribution, false, rates.individual, rates[tier]), 2)
  }

  erString = () => moneyString(this.er());
  eeString = () => moneyString(this.ee());
  totalString = () => moneyString(this.total());
}

class AncillaryPlanEmployeeCalculator {
  plan: AncillaryPlanUnion
  contribution: string
  tier: Tier

  constructor(plan: AncillaryPlanUnion, contribution: string, tier: Tier) {
    this.plan = plan
    this.contribution = contribution
    this.tier = tier
  }

  er = () => {
    const rates = ratesFor(this.plan)
    if (rates) {
      return applyContribution(this.contribution, false, rates.individual, rates[this.tier])
    } else {
      const rate = (this.plan as any).rate as string
      return applyContribution(this.contribution, false, rate, rate)
    }
  }

  ee = () => {
    const rates = ratesFor(this.plan)
    if (rates) {
      return moneyNumber(rates[this.tier], 2) - this.er()
    } else {
      const rate = (this.plan as any).rate as string
      return moneyNumber(rate as unknown as string, 2) - this.er()
    }
  }
}

class MedicalPlanEmployeeCalculator {
  erValue: number
  eeValue: number

  constructor(plan: MedicalPlan, baseContribution: string | undefined, baseContributionIsEquitable: boolean | undefined, tier: Tier, splits: ContributionSplit[], memberID: string) {
    const individualBasis = moneyNumber(plan.premium.employee.individual, 2)
    const basis = moneyNumber(plan.premium.employee[tier], 2)

    let gotERValue: number | undefined

    (splits || []).forEach(split => {
      if (!split.members.includes(memberID)) {
        return
      }
      gotERValue = applyContribution(split.contribution, split.isEquitable, individualBasis, basis)
    })
    if (gotERValue === undefined) {
      gotERValue = applyContribution(baseContribution, baseContributionIsEquitable, individualBasis, basis)
    }
    this.erValue = gotERValue
    this.eeValue = Math.max(0, basis - this.erValue)
  }

  er = () => this.erValue
  ee = () => this.eeValue
}

function moneyString(rawInput: string | number | undefined, precision = config.moneyDecimals()) {
  if (rawInput === 0) {
    return '$0'
  }
  const value = moneyNumber(rawInput, precision)
  const format = precision === 0 ? dollarsFormat : centsFormat
  const outputStr = numeral(value).format(format)

  return outputStr
}

function moneyNumber(rawInput: string | number | undefined, precision = config.moneyDecimals()): number {
  if (rawInput === undefined || rawInput === null || rawInput === '') return 0
  let value
  const tenPower = Math.pow(10, precision)
  value = numeral(rawInput).multiply(tenPower).value()
  value = Math.ceil(value) / tenPower
  return value
}

export {
  MedicalPlanGroupCalculator,
  MedicalPlanEmployeeCalculator,
  AncillaryPlanGroupCalculator,
  AncillaryPlanEmployeeCalculator,
  moneyString,
  moneyNumber
}
