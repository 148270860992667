import React, { ReactElement } from 'react'
import styles from './FilterItem.module.scss'
import Tooltip from '../../ToolTip/Tooltip'
import { FundingType } from 'Components/Plans/plan-subcomponents/PlanHelpers'
import { useToggle } from 'react-use'
import { FilterType, FilterItem } from './PlansFilter'
import { LevelFundedExplanationModal } from 'Components/Plans/plan-subcomponents/PlanTitle'

interface Props {
  filters: FilterItem[]
  active: any[]
  callback: (value: any) => void
  type?: string
}

const OptionFilter: React.FC<Props> = ({ filters, active, callback, type }) => {
  const [isOpen, toggleModal] = useToggle(false)

  return <div>
    <LevelFundedExplanationModal isOpen={isOpen} onRequestClose={toggleModal}/>
    {filters.map(go)}
  </div>

  function go(filter: FilterItem, index: number): ReactElement {
    const isActive = active.find(([, value]) => value === filter.value) !== undefined
    return <div key={index}>
      {type === FilterType.fundingType && tooltip()}
      <label data-tip data-for={filter.label} className={styles.radioContainer}>
        <input value={filter.value} onClick={() => callback(filter.value)} checked={isActive} type="radio" readOnly />
        {filter.label}
        <span className={styles.radio}></span>
      </label>
    </div>

    function tooltip() {
      return <Tooltip
        id={filter.label}
        place='right'
        offset={{ top: 0, left: -20 }}
        type='info'
        delayHide={100}
      >
        {inner()}
      </Tooltip>

      function inner() {
        switch (filter.label) {
        case FundingType.mec:
          return <span>
            {FundingType.mec} plans cover everyday health care needs
          </span>
        case FundingType.levelFunded:
          return <span>{FundingType.levelFunded} plans are a new innovative plan type,{' '}
            <button className='link-button' onClick={toggleModal}>
              click here
            </button> for more info.
          </span>
        case FundingType.fullyFunded:
          return <span>
            {FundingType.fullyFunded} plans are standard health plans.
          </span>
        }
      }
    }
  }
}

export default OptionFilter
