
import React, { useState } from 'react'
import styles from './EmployerPlansBreakdown.module.scss'
import { Table, Alignment, SortDirection } from '../Rudimentary/Table'
import { carrierForPlan, massagedPlanName, Contribution, Premiums } from '../Plans/plan-subcomponents/PlanHelpers'
import { ContributionSplitType, ContributionSplitTypeText } from '../Stargate/Contribution/ContributionAmount'
import { PlanDetailsModal } from '../Plans/plan-subcomponents/PlanTitle'
import { useToggle } from 'react-use'
import { moneyString, moneyNumber } from 'Utilities/Plans/PremiumCalculators'
import { MedicalPlan, DentalPlan, VisionPlan } from 'Utilities/pharaoh.types'

export interface PlansBreakdown {
  id: string
  carrier: string
  plan: string
  employerCost: number
  employeeCost: number
  enrollees: number
  sbc?: string
}

interface Props {
  planBreakdowns: PlansBreakdown[]
  contribution?: Contribution
  reportFileBasename?: string
}

const EmployerPlansBreakdown: React.FC<Props> = ({ planBreakdowns, contribution, reportFileBasename }) => {
  const [showSBC, toggleShowSBC] = useToggle(false)
  const [selectedSBC, setSelectedSBC] = useState<string>()
  const contributionType = contribution?.value.startsWith('$')
    ? ContributionSplitType.flatContribution
    : contribution?.isEquitable
      ? ContributionSplitType.allTiers
      : ContributionSplitType.perEmployee

  const contributionCopy = `Employer Contribution: ${contribution?.value || '0%'} ${ContributionSplitTypeText(contributionType).substring(2)}`

  return <>
    <h3 style={{ textAlign: 'left' }}>{contributionCopy}</h3>
    { selectedSBC && <PlanDetailsModal sbc={selectedSBC} showSBC={showSBC} toggleShowSBC={toggleShowSBC} /> }
    <Table
      data={planBreakdowns}
      order={['plan', 'enrollees', 'employerCost', 'employeeCost', 'sbc']}
      content={(key, value, row) => {
        switch (key) {
        case 'plan':
          return <><b>{carrierForPlan(row.carrier, value)}</b> {massagedPlanName(value, row.carrier)}</>
        case 'employerCost':
        case 'employeeCost':
          return value ? moneyString(value) : '—'
        case 'sbc':
          return value
            ? <button className={styles.details} onClick={() => planSelectAction(value)}>Details</button>
            : '—'
        }
      }}
      width={key => {
        switch (key) {
        case 'enrollees':
          return '120px'
        case 'sbc':
          return '100px'
        case 'employerCost':
        case 'employeeCost':
          return '160px'
        }
      }}
      reportFileBasename={reportFileBasename}
      selectAction={(row) => planSelectAction(row.sbc)}
      selectable={(row) => !!row.sbc}
      sortable={['plan', 'enrollees', 'employerCost', 'employeeCost']}
      defaultSortDirection={key => {
        switch (key) {
        case 'plan':
        case 'employerCost':
        case 'employeeCost':
          return SortDirection.ascending
        default:
          return SortDirection.descending
        }
      }}
      defaultSort='employerCost'
      alignment={key => {
        switch (key) {
        case 'sbc':
          return Alignment.center
        default:
          return Alignment.left
        }
      }}
    />
  </>

  function planSelectAction(sbc?: string) {
    if (!sbc) { return }
    setSelectedSBC(sbc)
    toggleShowSBC(true)
  }
}

interface AncillaryPlan {
  plan: DentalPlan | VisionPlan
}

export function getPlansBreakdown(type: keyof Premiums, plans: MedicalPlan[] | AncillaryPlan[], members: any[]): PlansBreakdown[] {
  // Need to do (plans as any[]) or it won't compile since Each member of the union type has signatures, but none of those signatures are compatible with each other.
  return (plans as any[]).map((plan: MedicalPlan | AncillaryPlan) => {
    const { id, carrier, name } = type === 'medical' ? (plan as MedicalPlan) : (plan as AncillaryPlan).plan
    const membersWithPlan = members.filter((member: any) => {
      if (type === 'medical') { return member.plan?.id === id }
      return member[`enrolled_${type}_plan_id`] === id
    })
    const erCosts: number[] = membersWithPlan.map((m: any) => moneyNumber(m.premiums?.[type]?.split?.er))
    const eeCosts: number[] = membersWithPlan.map((m: any) => moneyNumber(m.premiums?.[type]?.split?.ee))

    return {
      id,
      carrier,
      plan: name,
      employerCost: erCosts.reduce((a, b) => a + b, 0),
      employeeCost: eeCosts.reduce((a, b) => a + b, 0),
      enrollees: membersWithPlan.length,
      sbc: type === 'medical' ? (plan as MedicalPlan).sbc : `https://s3.amazonaws.com/documents-pub.candor.insurance/sbc/${id.toLowerCase()}.pdf`
    }
  })
}

export default EmployerPlansBreakdown
