import React from 'react'
import baseStyles from './Plan.module.scss'
import styles from './EEPremiumBreakdown.module.scss'
import { tierMarketingCopy } from './PlanHelpers'
import { Tier } from 'Utilities/pharaoh.types'
import { moneyNumber, moneyString } from 'Utilities/Plans/PremiumCalculators'

interface Props {
  erPremium: number
  eePremium: number
  tier: Tier
}

const EEPremiumBreakdown: React.FC<Props> = ({ tier, eePremium, erPremium }) => {
  const classes = [baseStyles.section, baseStyles.box, styles.container]

  return <div className={classes.join(' ')}>
    <div className={styles.tier}>{tierMarketingCopy(tier)} Tier</div>
    <div className={styles.premiums}>
      <div className={styles.ee}>
        <span>$</span>{moneyNumber(eePremium)}<span>/mo</span>
      </div>
      <div className={styles.afterContribution}>After Employer Contribution</div>
      <div className={styles.er}>Your employer contributes<br/><em>{moneyString(erPremium)}/mo</em> to this plan</div>
    </div>
  </div>
}

export default EEPremiumBreakdown
