import React from 'react'
import ReactModal from 'react-modal'
import style from './CandorModal.module.css'

const CandorModal: React.SFC<ReactModal.Props> = props =>
  <ReactModal
    overlayClassName={{
      base: style.base,
      beforeClose: style.beforeClose,
      afterOpen: style.afterOpen
    }}
    closeTimeoutMS={150}
    bodyOpenClassName='ReactModal__Body--open'
    {...props}
  >
    {props.children}
  </ReactModal>

export default CandorModal
