import React from 'react'
import useStargate from 'Utilities/Hooks/useStargate'
import Loader from 'Components/Rudimentary/Loader'
import { Redirect, useLocation } from 'react-router-dom'
import Instructions from './Instructions'
import FourOhFour from 'Components/Rudimentary/404'
import { Route } from 'Utilities/Route'
import { useTableOfContents } from 'Components/Stargate/TableOfContents'
import * as config from 'Utilities/config'

/// /shop and /shop/:slug? are complex to determine, so here we go…

const ShopHome: React.FC = () => {
  const { furthestChapter } = useTableOfContents()
  const async = useStargate()
  const location = useLocation()
  if (async.loading) return <Loader />
  const stargate = async.value

  // FUCK ME. COMPLEXITY.
  const match = location.pathname.match(/\/shop\/([^/]+)/) || []

  switch (match[1]) {
  case undefined:
    if (config.obeliskMode()) {
      const slug = stargate?.obelisk.slug
      if (slug) {
        // this will load this component again, but URL will be correct
        return <Redirect to={`/shop/${slug}`} />
      } else {
        // we need a slug 😕
        return <Redirect to='/' />
      }
    } else if (config.individualsMode()) {
      return <Redirect to='/shop/employee' />
    } else {
      return <Instructions {...async} />
    }
  case 'employer':
  case 'employee':
    return <Redirect to={standardRedirect()} />
  default:
    if (config.obeliskMode()) {
      if (config.individualsMode()) {
        const path = Route.eeStargate_info.replace(/employee/, match[1])
        return <Redirect to={path} />
      } else {
        return <Instructions {...async} />
      }
    } else {
      return <FourOhFour />
    }
  }

  function standardRedirect(): string {
    if (furthestChapter) {
      return furthestChapter
    } else if (!config.obeliskMode()) {
      return '/shop'
    } else if (stargate) {
      return `/shop/${stargate.obelisk.slug}`
    } else {
      return '/'
    }
  }
}

export default ShopHome
