import React, { ReactElement } from 'react'
import { $enum } from 'ts-enum-util'
import { useToggle } from 'react-use'
import CandorModal from 'Components/Rudimentary/CandorModal'
import { Label, Mode, switchLabel } from 'Utilities/config'

const LabelSwitcherModal: React.FC = () => {
  const [open, toggle] = useToggle(true)

  return <CandorModal isOpen={open} onRequestClose={toggle}>
    <h1>Standard Labels</h1>
    <ul style={{ columns: 3, listStyleType: 'none' }}>{standards()}</ul>
    <h1>Demo Labels</h1>
    <ul style={{ columns: 3, listStyleType: 'none' }}>{demos()}</ul>
  </CandorModal>

  function standards() {
    return $enum(Label).map(label => render(label, onClick(label), title(label)))

    function onClick(label: Label) {
      return () => {
        switchLabel(label, Mode.standard)
        window.location.reload()
      }
    }

    function title(label: Label) {
      switch (label) {
      case Label.get:
      case Label.brokers:
      case Label.blacksmith:
        return <><br />{label}</>
      case Label.simulacra:
        return <><br />Simulacra</>
      }
    }
  }

  function demos() {
    return [Label.brokers, Label.myhealthily].map(label => render(label, onClick(label), title(label)))

    function onClick(label: Label) {
      return () => {
        switchLabel(label, Mode.demo)
        window.location.reload()
      }
    }

    function title(label: Label) {
      if (label === Label.brokers) {
        return <><br />demo.candor.insurance</>
      }
    }
  }

  function render(label: Label, onClick: () => void, title: ReactElement | undefined) {
    const host = label
    return <li key={label}>
      <button onClick={onClick}>
        <img src={`https://${host}/logo.svg`} alt={host} style={{ width: 250 }} />
        {title}
      </button>
    </li>
  }
}

export default LabelSwitcherModal
