import React, { useEffect } from 'react'
import styles from 'Routes/shop/employer/confirm/Confirmation.module.css'
import DDR4 from 'Assets/ddr4.png'
import { ReactComponent as Confetti } from 'Assets/confetti.svg'
import { Link } from 'react-router-dom'
import { post } from 'Utilities/fetch++'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import { Venue } from 'Utilities/pharaoh.types'
import { individualsMode, supportPhone, supportEmail, title } from 'Utilities/config'

const EEShopConfirm: React.FC<PrivateWizardPageProps> = ({ stargate }) => {
  useEffect(() => {
    const el = document.getElementById('content')!
    el.style.padding = '0'
    el.style.maxWidth = 'unset'
    return () => {
      el.style.removeProperty('padding')
      el.style.removeProperty('max-width')
    }
  })

  useEffect(() => {
    post('/v3/souvenir', { context: stargate.group?.id, venue: Venue.employee }).catch(console.error)
  }, [stargate.group])

  return <div className={styles.mainContainer}>
    <div className={styles.header}>
      <img className={styles.ddr4} src={DDR4} alt="People celebrating"/>
      <div>
        <div>
          <div className={styles.confirmCheck}>
            <i className="material-icons">check</i>
          </div>
          <h1>All done! You made it!</h1>
        </div>
        <p>We will be in contact as soon as possible letting you know about your new benefits.</p>
      </div>
      <div className={styles.confetti}><Confetti/></div>
    </div>
    <div className={styles.linksContainer}>
      {individualsMode() && <div>
        <p style={{ width: '100%' }}>
          Thank you for enrolling healthcare coverage through <b>{title()}</b>!
          Be sure to check your inbox for an email confirmation and receipt.
          Your coverage will take effect 11 business days from today, and you can expect
          to receive your coverage card at the home address you listed earlier.
          <br/><br/>
          If you have any questions, comments, or concerns, please feel free to
          call <a href={`tel:${supportPhone()}`}>{supportPhone()}</a>&nbsp;
          or email <a href={`mailto:${supportEmail()}`}>{supportEmail()}</a>.
          Our representatives are available Monday through Friday, from 8am to 6pm.
        </p>
      </div>}
      <div>
        <p>Check out your plans, profile, and billing at your dashboard.</p>
        <Link to="/dashboard/employee" className={styles.exploreDashboard}><button>Explore my Dashboard</button></Link>
      </div>
    </div>
  </div>
}

export default EEShopConfirm
