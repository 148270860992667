import React from 'react'
import baseStyles from './plan-subcomponents/Plan.module.scss'
import styles from './DentalPlan.module.scss'
import Plan, { PlanBody } from './plan-subcomponents/Plan'
import { AncillaryPlanEmployeeCalculator, moneyNumber } from 'Utilities/Plans/PremiumCalculators'
import { Tier, DentalPlan, TieredRates } from 'Utilities/pharaoh.types'
import { Contribution, PremiumSplits } from './plan-subcomponents/PlanHelpers'
import PlanTitle from './plan-subcomponents/PlanTitle'
import EEPremiumBreakdown from './plan-subcomponents/EEPremiumBreakdown'
import PremiumBreakdown from './plan-subcomponents/PremiumBreakdown'
import { startCase } from 'lodash'
import { Member } from 'Utilities/Hooks/useStargate'

/* eslint-disable camelcase */
type EmployerProps = Props & {
  members: Member[]
}
/* eslint-enable camelcase */

type EmployeeProps = Props & {
  employeeTier: Tier
  premium?: PremiumSplits
}

interface Props {
  plan: DentalPlan
  rates: TieredRates
  contribution: Contribution
  selectHandler?(plan: DentalPlan): void
  selected?: boolean
}

enum DentalDataKeys {
  preventive = 'preventive',
  basic = 'basic',
  major = 'major',
  childOrthodontia = 'childOrthodontia',
  orthodontia = 'orthodontia',
  annualMax = 'annualMax'
}

const DentalPlanComponent: React.FC<EmployeeProps | EmployerProps> = (props) => {
  const { plan, rates, contribution, selectHandler, selected } = props
  const data = massagedData()

  return <Plan selected={!!selected}>
    <PlanTitle
      planName={plan.name}
      carrier={plan.carrier}
      selectHandler={selectHandler && (() => selectHandler(plan))}
      selected={selected}
      sbc={`https://s3.amazonaws.com/documents-pub.candor.insurance/sbc/${plan.id.toLowerCase()}.pdf`}
      includesProsperBenefits={false}
    />
    <PlanBody>
      {breakdown()}
      <div className={`${baseStyles.section} ${styles.specs}`}>
        {Object.keys(DentalDataKeys).map(key => {
          const { inNetwork, outOfNetwork } = data[key as DentalDataKeys]
          return <div className={`${baseStyles.box} ${styles.spec}`} key={key}>
            <div>{ startCase(key as DentalDataKeys) }</div>
            { !inNetwork && !outOfNetwork
              ? <><div>Not Covered</div><span>in &amp; out of network</span></>
              : inNetwork === outOfNetwork
                ? <><div>{inNetwork}</div><span>in &amp; out of network</span></>
                : <div className={styles.separateSpecs}>
                  { inNetwork && <div>{inNetwork}<span>in-network</span></div> }
                  { outOfNetwork && <div>{outOfNetwork}<span>out of network</span></div> }
                </div>
            }
          </div>
        })}
      </div>
    </PlanBody>
  </Plan>

  function breakdown() {
    if ((props as EmployerProps).members !== undefined) {
      const _props = props as EmployerProps
      return <PremiumBreakdown contribution={contribution} rates={rates} members={_props.members}/>
    }

    const _props = props as EmployeeProps
    let er, ee
    if (_props.premium) {
      er = moneyNumber(_props.premium.er)
      ee = moneyNumber(_props.premium.ee)
    } else {
      const calc = new AncillaryPlanEmployeeCalculator({ plan, rates }, contribution.value, _props.employeeTier)
      er = calc.er()
      ee = calc.ee()
    }
    return <EEPremiumBreakdown erPremium={er} eePremium={ee} tier={_props.employeeTier}/>
  }

  function massagedData(): {[key in DentalDataKeys]: { inNetwork?: string, outOfNetwork?: string }} {
    const { data } = plan
    return {
      preventive: {
        inNetwork: data.dentalPreventativeInNetwork,
        outOfNetwork: data.dentalPreventativeOutOfNetwork
      },
      basic: {
        inNetwork: data.dentalBasicInNetwork,
        outOfNetwork: data.dentalBasicOutOfNetwork
      },
      major: {
        inNetwork: data.dentalMajorInNetwork,
        outOfNetwork: data.dentalMajorOutOfNetwork
      },
      childOrthodontia: {
        inNetwork: data.dentalChildOrthodonticsInNetwork || data.dentalChildOrthodontics,
        outOfNetwork: data.dentalChildOrthodonticsOutOfNetwork
      },
      orthodontia: {
        inNetwork: data.dentalOrthodonticsLifetimeMaximumInNetwork,
        outOfNetwork: data.dentalOrthodonticsLifetimeMaximumOutOfNetwork
      },
      annualMax: {
        inNetwork: data.individualInNetworkCarrierPayOutMaximum,
        outOfNetwork: data.individualOutOfNetworkCarrierPayOutMaximum
      }
    }
  }
}

export default DentalPlanComponent
