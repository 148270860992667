/* eslint-disable camelcase */
import React from 'react'
import styles from './index.module.scss'
import { PrivateWizardPageProps, WizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import EmployeeInfoWaiveModal from 'Components/Stargate/Waiving/EmployeeInfoWaiveModal'
import CandorInput from 'Components/Rudimentary/CandorInput'
import CandorSelect from 'Components/Rudimentary/CandorSelect'
import { compact, startCase } from 'lodash'
import { ssnNormalizer, cleanPhoneNumber } from 'Utilities/etc'
import StatePicker from 'Components/Rudimentary/StatePicker'
import CountyPicker from 'Components/Rudimentary/CountyPicker'
import { isAuthenticated, localMidnightToPharaohFormat, clearLocalStorage, setToken } from 'Utilities/pharaoh'
import { useToggle } from 'react-use'
import { Response as Stargate, MaritalStatus } from 'Utilities/Hooks/useStargate'
import { AsyncState } from 'react-use/lib/useAsync'
import { useHistory } from 'react-router-dom'
import { Route } from 'Utilities/Route'
import numeral from 'numeral'
import api from 'Utilities/Deprecated/api'
import { $enum } from 'ts-enum-util'
import { Gender } from 'Utilities/pharaoh.types'
import * as config from 'Utilities/config'
import { CandorDatePicker } from 'Components/Anubis/CandorForm'
import { post } from 'Utilities/fetch++'
import useContentAdjustment from 'Utilities/Hooks/useContentAdjustment'
import TermsAndConditions from 'Components/Modals/TnC/TermsAndConditions'

const EEShopCensus: React.FC<WizardPageProps | PrivateWizardPageProps> = ({ onwards, ...props }) => {
  const stargate: Partial<Stargate> = (props.stargate as AsyncState<Stargate>).value || props.stargate as Stargate | undefined || {}
  const { group } = stargate
  const title = `Healthcare coverage, offered through ${group?.name || config.title()}`
  const form = useForm({ defaultValues: defaultValues() })
  const { handleSubmit, watch } = form
  const zip = watch('zipCode') as string | undefined
  const [showingWaiveModal, toggleWaiveModal] = useToggle(false)
  const history = useHistory()
  const showWaiveButton = config.label() !== config.Label.blacksmith && !config.individualsMode()
  useContentAdjustment({ paddingTop: '50px' })

  return <div className={styles.mainContainer}>
    <h1 className={`shop-h1-periwinkle ${styles.title}`}>{title}</h1>
    <h2 className='shop-h2-navy'>Making healthcare coverage super simple.</h2>
    <div className={styles.formContainer}>
      <form autoComplete="on" onSubmit={handleSubmit(onSubmit)} noValidate={false}>
        <FormProvider {...form}>
          <fieldset>
            <div> {/* Need to do this because of a chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=262679 */}
              <CandorInput
                autoComplete="name"
                placeholder="Name"
                name="name"
                required />
              <CandorDatePicker
                autoComplete="bday"
                placeholder='Birthdate'
                className="glance_masked"
                maxDate={new Date()}
                required
                dropdownMode="select"
                name='birthday'
              />
              <Controller
                render={props => <CandorInput
                  {...props}
                  onChange={event => props.onChange(ssnNormalizer(event.currentTarget.value))}
                  minLength={11} maxLength={11}
                  pattern='[0-9-]{11}'
                  className='glance_masked'
                  placeholder={config.individualsMode() ? 'SSN (Optional)' : 'SSN'}
                  required={!config.individualsMode()}
                />}
                name="socialSecurityNumber"
              />
              <CandorSelect
                placeholder="Gender"
                options={$enum(Gender).map(makeOptions)}
                name="gender"
              />
              <span className={styles.inputLabel}>Residential Address</span>
              <CandorInput
                autoComplete="street-address"
                placeholder="Address 1"
                name="address1"
                required
              />
              <CandorInput
                autoComplete="address-line2"
                placeholder="Address 2"
                name="address2"
              />
              <div className={styles.cityGrid}>
                <CandorInput
                  autoComplete="address-level2"
                  placeholder="City"
                  name="city"
                  required
                />
                <CandorInput
                  autoComplete="postal-code"
                  maxLength={5}
                  placeholder="ZIP Code"
                  name="zipCode"
                  required
                />
              </div>
              <div className={styles.stateGrid}>
                <StatePicker
                  zip={zip}
                  name='state'
                  required
                />
                <CountyPicker
                  name='countyId'
                  zip={zip}
                  required
                />
              </div>
              <div className={styles.spacer}/> {/* To align with the next button */}
              <input
                onClick={toggleWaiveModal}
                type='button'
                value='Waive Coverage'
                style={{ visibility: showWaiveButton ? 'visible' : 'hidden' }}
              />
            </div>
          </fieldset>
          <fieldset>
            <div>
              <Controller
                render={props => <CandorInput
                  {...props}
                  onChange={event => props.onChange(cleanPhoneNumber(event.currentTarget.value))}
                  autoComplete="tel-national"
                  placeholder="Phone"
                />}
                name="phoneNumber" required />
              <CandorSelect
                placeholder="Marital Status"
                options={$enum(MaritalStatus).map(makeOptions)}
                name='maritalStatus'
                required
              />
              <CandorInput
                autoComplete="email"
                placeholder="Email"
                name="email"
                required
                disabled={isAuthenticated()}
              />
              <div className={styles.measurementsGrid} style={{ visibility: config.showHeightWeight() ? 'visible' : 'hidden' }}>
                <CandorInput
                  className={styles.numericInput}
                  placeholder="Height (feet)"
                  name="feet"
                  type="number"
                  step="1" min={0} max={99}
                  required={config.showHeightWeight()}
                />
                <CandorInput
                  className={styles.numericInput}
                  placeholder="inches"
                  name="inches"
                  type="number"
                  step="1" min={0} max={11}
                  required={config.showHeightWeight()}
                />
                <CandorInput
                  className={styles.numericInput}
                  placeholder="Weight (lbs)"
                  name="weight"
                  type="number"
                  step="1" min={0} max={999}
                  required={config.showHeightWeight()}
                />
              </div>
              <span className={styles.inputLabel}>Mailing Address <span className={styles.labelAside}>(if different from residential)</span></span>
              <CandorInput
                autoComplete="street-address"
                placeholder="Address 1"
                name="mailingAddress1"
              />
              <CandorInput
                autoComplete="address-line2"
                placeholder="Address 2"
                name="mailingAddress2"
              />
              <div className={styles.cityGrid}>
                <CandorInput
                  autoComplete="address-level2"
                  placeholder="City"
                  name="mailingCity"
                />
                <CandorInput
                  autoComplete="postal-code"
                  maxLength={5}
                  placeholder="ZIP Code"
                  name="mailingZIP"
                />
              </div>
              <div className={styles.stateGrid}>
                <StatePicker
                  name='mailingState'
                  zip={watch('mailingZIP') as string | undefined}
                />
              </div>
              <div className={styles.spacer}>
                <Controller
                  name='acceptedTermsAndConditions'
                  render={props => <TermsAndConditions {...props} onChange={props.onChange} />}
                />
              </div>
              <input type="submit" value='Next'/>
            </div>
          </fieldset>
        </FormProvider>
      </form>
    </div>
    <EmployeeInfoWaiveModal isOpen={showingWaiveModal} onCancel={toggleWaiveModal} callback={waiveCallback} />
  </div>

  function defaultValues() {
    const { user, groupMember, consumerProfile, userMetadata } = stargate
    return {
      email: user?.email,
      name: compact([user?.first_name, user?.last_name]).join(' ') || groupMember?.name,
      birthday: groupMember?.date_of_birth,
      socialSecurityNumber: consumerProfile?.ssn,
      gender: groupMember ? groupMember.gender === 0 ? 'male' : 'female' : undefined,
      address1: consumerProfile?.address1,
      address2: consumerProfile?.address2,
      city: userMetadata?.city || consumerProfile?.city,
      state: consumerProfile?.state_code,
      zipCode: groupMember?.zip_code,
      countyId: groupMember?.countyId,
      maritalStatus: user?.marital_status,
      feet: Math.trunc((groupMember?.height || 0) / 12) || undefined,
      inches: Math.trunc((groupMember?.height || 0) % 12) || undefined,
      weight: groupMember?.weight,
      mailingAddress1: userMetadata?.mailingAddress1,
      mailingAddress2: userMetadata?.mailingAddress2,
      mailingCity: userMetadata?.mailingCity,
      mailingState: userMetadata?.mailingState,
      mailingZIP: userMetadata?.mailingZIP,
      phoneNumber: consumerProfile?.phone_number,
      acceptedTermsAndConditions: user?.acceptedTermsAndConditions
    }
  }

  async function onSubmit(data: any) {
    return onwards(save(data))
  }

  async function save(data: any) {
    if (!data.acceptedTermsAndConditions) { throw Error('You must accept the terms and conditions to continue') }
    if (config.individualsMode() && !isAuthenticated()) {
      try {
        const rsp = await post('/v2/users/gatekeeper', data)
        clearLocalStorage()
        setToken(rsp.token)
      } catch (error) {
        switch (error.response?.status) {
        case 401:
        case 403:
        case 409:
        case 406:
          alert('Welcome back! We need you to sign-in')
          history.push(Route.signIn)
          return
        default:
          throw error
        }
      }
    }

    const height = data.feet && data.inches
      ? numeral(data.feet).multiply(12).add(data.inches).value()
      : data.feet
        ? numeral(data.feet).multiply(12).value()
        : undefined

    await api.updateUser({
      ...data,
      birthday: localMidnightToPharaohFormat(data.birthday),
      height: height,
      weight: numeral(data.weight).value()
    })
  }

  function waiveCallback(waiveReason: string) {
    const payload = {
      waiveReason,
      isWaiving: true
    }
    onwards(api.waiveUserForGroup(group?.id, payload).then(() => ({ waived: true })))
  }
}

function makeOptions(value: string) {
  return { value, label: startCase(value) }
}

export default EEShopCensus
