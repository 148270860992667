import React, { HTMLProps } from 'react'
import styles from './index.module.scss'
import CandorInput, { CandorInputProps } from 'Components/Rudimentary/CandorInput'
import IndustryPicker from './IndustryPicker'
import CountyPicker from 'Components/Rudimentary/CountyPicker'
import DateSelector from 'Components/Stargate/DateSelector'
import TermsAndConditions from 'Components/Modals/TnC/TermsAndConditions'
import { compact } from 'lodash'
import moment from 'moment'
import { useAsync, useSet } from 'react-use'
import { get, post } from 'Utilities/fetch++'
import Loader from 'Components/Rudimentary/Loader'
import Error from 'Components/Primitives/Error'
import { AssociationCheckbox } from 'Components/Plans/plan-subcomponents/Checkboxes'
import { Controller, FormProvider, useFormContext, useForm } from 'react-hook-form'
import { localMidnightToPharaohFormat } from 'Utilities/pharaoh'
import { WizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import useToast from 'Utilities/Hooks/useToast'
import { associationsMode } from 'Utilities/config'
import { defaultMinimumDate } from 'Routes/dashboard/agency/clients/ID/ProfileInfoSection'
import effectiveDateFilter from 'Utilities/Plans/effectiveDateFilter()'
import { PowerLevel } from 'Utilities/Hooks/useUser'

/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */

const ERShopGetStarted: React.FC<WizardPageProps> = ({ onwards, stargate: async }) => {
  const { group, user, consumerProfile, userMetadata, associationIds } = async.value || {}

  const defaults: Payload = {
    group: {
      zip: group?.zipCode,
      fips: group?.countyID,
      ein: group?.ein,
      phone: consumerProfile?.phone_number,
      industry: group?.industrySICCode,
      name: group?.name
    },
    user: {
      jobTitle: userMetadata?.jobTitle,
      fullName: compact([user?.first_name, user?.last_name]).join(' '),
      acceptedTermsAndConditions: user?.acceptedTermsAndConditions || false
    },
    effectiveDate: group?.effectiveDate,
    associations: associationIds,
    existingCoverage: {
      renewalDate: group?.existingCoverageRenewalDate,
      carrier: group?.existingCoverageCarrier
    }
  }

  return <div className={styles.mainContainer}>
    <h1 className='shop-h1-periwinkle'>You’re on your way to getting your personalized quote!</h1>
    <Form defaultValues={defaults} callback={save} isBroker={user && user.power_level >= PowerLevel.broker} />
  </div>

  function save(data: Payload): Promise<void> {
    return onwards((async() => {
      const rsp = await post(`/v2/groups/${group?.id || ''}`, data)
      localStorage.overrideGroupID = rsp.id
    })())
  }
}

interface FormProps {
  isBroker: boolean | undefined
  defaultValues: Payload
  callback: (payload: Payload) => Promise<void>
}

interface Payload {
  user: {
    acceptedTermsAndConditions: boolean
    fullName?: string
    jobTitle?: string
  }
  group: {
    ein?: string
    zip?: string
    fips?: string
    name?: string
    phone?: string
    industry?: string
  }
  effectiveDate?: Date
  associations?: string[]
  existingCoverage: {
    renewalDate?: Date
    carrier?: string
  }
}

const Form: React.FC<FormProps> = ({ callback, defaultValues, isBroker }) => {
  const form = useForm({ defaultValues })
  const [associationIDs, { toggle: toggleAssociationID }] = useSet(new Set(defaultValues.associations || []))
  const maxEffectiveDate = moment().add(3, 'quarter').endOf('quarter').toDate()
  const zip = form.watch('group.zip', defaultValues.group.zip)
  const addToast = useToast()

  return <FormProvider {...form} >
    <form className={styles.formContainer} onSubmit={form.handleSubmit(save)}>
      <SubHeader>First a little about your company</SubHeader>
      <Row>
        <Input name='group.name' placeholder='Company Name' required flex={2} />
        <Input name='group.ein' placeholder='Employer Identification Number (EIN)' flex={2} />
      </Row>
      <div className={styles.industryContainer}>
        <Controller render={props => <IndustryPicker {...props} />} name='group.industry' />
      </div>
      <Row>
        <Input key='foo' name='group.zip' placeholder='ZIP Code' flex={2} required />
        <div className={styles.countyContainer}>
          <CountyPicker name='group.fips' zip={zip} required />
        </div>
      </Row>

      {isBroker || <>
        <SubHeader>Now a little about you</SubHeader>
        <Input name='user.fullName' placeholder='Full Name' />
        <Row>
          <Input name='user.jobTitle' placeholder='Job Title' flex={2} />
          <Input name='group.phone' placeholder='Phone' />
        </Row>
      </>}

      <div className={styles.addtlContainer}>
        <SubHeader style={{ margin: '30px 0 20px 0' }}>When would you like your coverage to start?</SubHeader>
        <Controller
          name='effectiveDate'
          render={props => <DateSelector
            {...props}
            filterDate={effectiveDateFilter}
            minDate={defaultMinimumDate()}
            maxDate={maxEffectiveDate}
            openToDate={defaultMinimumDate()}
          />}
          defaultValue={defaultValues.effectiveDate} // required due to bug in react-hook-form
        />
        <p className={styles.caveats}>The earliest start date is the 1st or 15th of the month, 45 days from now.</p>
      </div>

      <div className={styles.addtlContainer} style={{ marginBottom: 30 }}>
        <SubHeader>Do you have existing healthcare coverage?</SubHeader>
        <p style={{ margin: '-5px 0 20px 0' }}>If so, when would it renew if you kept it?</p>
        <Controller
          name='existingCoverage.renewalDate'
          render={props => <DateSelector
            {...props}
            filterDate={effectiveDateFilter}
            minDate={new Date()}
          />}
          defaultValue={defaultValues.existingCoverage.renewalDate} // required due to bug in react-hook-form
        />
        <Input name='existingCoverage.carrier' placeholder='Carrier' style={{ marginTop: -10 }}/>
        <p className={styles.caveats} style={{ margin: 6 }}>eg. UnitedHealthcare, Humana, etc.</p>
      </div>

      <div className={styles.addtlContainer}>
        <Associations selected={associationIDs} onSelected={toggleAssociationID} />
      </div>

      <div className={styles.termsContainer}>
        <Controller render={props => <TermsAndConditions {...props} required />} name='user.acceptedTermsAndConditions' />
      </div>

      <input type='submit' value='Next' />
    </form>
  </FormProvider>

  async function save(data: any) {
    try {
      if (data.effectiveDate && (data.effectiveDate < defaultMinimumDate() || data.effectiveDate > maxEffectiveDate || !effectiveDateFilter(data.effectiveDate))) throw new window.Error('Invalid effective date')
      if (data.existingCoverage.renewalDate && data.existingCoverage.renewalDate < new Date()) throw new window.Error('Your renewal date cannot prior to today')
      if (associationsMode() && associationIDs.size <= 0) throw new window.Error('Please select your association memberships')
      if (!data.group.fips) throw new window.Error('Please select your county')
      data.group.zip = zip
      data.effectiveDate = localMidnightToPharaohFormat(data.effectiveDate)
      data.existingCoverage.renewalDate = localMidnightToPharaohFormat(data.existingCoverage?.renewalDate)
      data.associations = Array.from(associationIDs)
      await callback(data)
      // form.unpersist()
    } catch (error) {
      addToast(error)
    }
  }
}

const Row: React.FC = ({ children }) =>
  <div className={styles.formRow}>{children}</div>

const SubHeader: React.FC<HTMLProps<HTMLParagraphElement>> = ({ children, style }) =>
  <p className={styles.subHeader} style={style || { margin: '30px 0 20px 0' }}>{children}</p>

const Input: React.FC<{flex?: number} & CandorInputProps> = ({ flex, ...props }) => {
  const { register } = useFormContext()
  flex = flex || 1
  return <CandorInput
    style={{ flex }}
    ref={register}
    {...props} />
}

interface AssociationsProps {
  selected: Set<string>
  onSelected: (id: string) => void
}

const Associations: React.FC<AssociationsProps> = ({ selected, onSelected }) => {
  const async = useAsync(async() => {
    if (associationsMode()) {
      return get('/associations/in/foo')
    } else {
      return []
    }
  })

  if (!associationsMode()) return <></>

  if (async.loading) return <Loader />
  if (async.error) return <Error error={async.error} />

  const asses = async.value as any[]

  const items = asses.map(ass => <AssociationCheckbox
    key={ass.id}
    name={ass.name}
    label={ass.name}
    onChange={() => onSelected(ass.id)}
    value={selected.has(ass.id)}
  />)

  return <>
    <SubHeader style={{ marginBottom: '-5px', marginTop: '20px' }}>To which associations do you belong?</SubHeader>
    <div className={`${styles.form2col} ${styles.associations} ${styles.halfWidth}`}>
      {items}
    </div>
  </>
}

export default ERShopGetStarted
