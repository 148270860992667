import React from 'react'
import { kebabCase } from 'lodash'
import { post } from 'Utilities/fetch++'
import { Img } from 'react-image'
import { MedicalPlan, Tier } from 'Utilities/pharaoh.types'
import { isPlanProsper, IndividualModeProsperName } from '../EERightNowPlan'
import { title, individualsMode } from 'Utilities/config'

/*
  IMPORTANT
  If you are adding new cases make sure that
   a) They remain in alphatical order
   b) Make sure the carrier logo image is formatted as such `${kebabCase(carrier)}-logo` and placed in public/carrier-logos
   c) Don't forget to add the height for the logo
*/

enum Carriers {
  'Aetna' = 'Aetna',
  'AllWays Health Partners' = 'AllWays Health Partners',
  'AmeriHealth' = 'AmeriHealth',
  'Anthem' = 'Anthem',
  'Apex Management Group' = 'Apex Management Group',
  'Blue Cross Blue Shield' = 'Blue Cross Blue Shield',
  'BEST Life and Health Insurance Company' = 'BEST Life and Health Insurance Company',
  'CalChoice' = 'CalChoice',
  'Cigna' = 'Cigna',
  'Fallon Health' = 'Fallon Health',
  'FirstCarolinaCare Insurance Company' = 'FirstCarolinaCare Insurance Company',
  'Group Health Cooperative' = 'Group Health Cooperative',
  'Guardian' = 'Guardian',
  'HealthFirst' = 'HealthFirst',
  'Humana' = 'Humana',
  'Kaiser Permanente' = 'Kaiser Permanente',
  'Medica' = 'Medica',
  'MetLife' = 'MetLife',
  'MetroPlus Health Plan' = 'MetroPlus Health Plan',
  'Minuteman Health' = 'Minuteman Health',
  'National General' = 'National General',
  'NationalGeneral' = 'NationalGeneral',
  'Optima Health' = 'Optima Health',
  'Oscar' = 'Oscar',
  'Oxford' = 'Oxford',
  'Principal' = 'Principal',
  'Prosper' = 'Prosper',
  'Renaissance Life & Health Insurance Company of America' = 'Renaissance Life & Health Insurance Company of America',
  'Sutter Health Plus' = 'Sutter Health Plus',
  'Tufts Health Plan' = 'Tufts Health Plan',
  'United Healthcare' = 'United Healthcare',
  'UnitedHealthcare' = 'UnitedHealthcare',
  'Unum' = 'Unum'
}

export function carrierForPlan(carrier: string, planName: string) {
  if (carrier === Carriers['Apex Management Group']) { return title() }
  if (carrier === Carriers.NationalGeneral || carrier === Carriers['National General']) {
    // Our fake plan only shown in testing environments
    if (planName === 'Candor Fake Staging Plan') { return Carriers['National General'] }
    for (const carrier in Carriers) {
      if (planName.includes(carrier)) {
        return carrier
      }
    }
    post('/v3/panic', { msg: `No NatGen network case for plan: ${planName}` }).catch(console.error)
    return Carriers['National General']
  }
  return carrier
}

export function massagedPlanName(planName: string, carrier: string) {
  if (individualsMode()) {
    return <><b>{isPlanProsper(carrier) ? IndividualModeProsperName : 'Right Now'}</b> {planName.replace('Apex', '')}</>
  }

  if (planName.includes('Apex') && title()) {
    return planName.replace('Apex', title())
  }

  if (planName.includes('National General')) {
    let p = planName
    // Separating this incase plan names change for some reason in the future
    p = p.replace('National General ', '')
    p = p.replace('Benefits Solutions ', '')
    return p
  }

  return planName
}

export function logoFor(carrier: string, planName: string) {
  const carrierToShow = carrierForPlan(carrier, planName)

  if (individualsMode() || carrierToShow === title()) {
    const src = `${process.env.PUBLIC_URL}/logo.svg`
    const height = 35
    const alt = `${title()}'s logo`
    return <Img src={src} height={height} alt={alt} />
  }

  // Assuming that a logo has been added for every entry in Carriers and is named correctly
  if (carrierToShow in Carriers) {
    const src = `${process.env.PUBLIC_URL}/carrier-logos/${kebabCase(carrierToShow)}-logo`
    const height = logoHeightFor(carrierToShow as Carriers)
    const alt = `${carrierToShow}'s logo`
    return <Img src={[src + '.svg', src + '.png']} height={height} alt={alt}/>
  }
  post('/v3/panic', { msg: `No logo for carrier: ${carrier}` }).catch(console.error)
  return undefined
}

function logoHeightFor(carrier: Carriers): number {
  switch (carrier) {
  case Carriers['BEST Life and Health Insurance Company']:
  case Carriers.Oscar:
    return 18
  case Carriers.Aetna:
  case Carriers.Anthem:
  case Carriers['Kaiser Permanente']:
  case Carriers.Medica:
  case Carriers.MetLife:
    return 20
  case Carriers['Blue Cross Blue Shield']:
  case Carriers.Guardian:
  case Carriers.HealthFirst:
  case Carriers.Humana:
  case Carriers.NationalGeneral:
  case Carriers['National General']:
  case Carriers.Unum:
    return 25
  case Carriers['AllWays Health Partners']:
  case Carriers.CalChoice:
  case Carriers.Cigna:
  case Carriers['FirstCarolinaCare Insurance Company']:
  case Carriers['Group Health Cooperative']:
  case Carriers['MetroPlus Health Plan']:
  case Carriers['Minuteman Health']:
  case Carriers['Optima Health']:
  case Carriers.Oxford:
  case Carriers['Renaissance Life & Health Insurance Company of America']:
  case Carriers['Sutter Health Plus']:
  case Carriers['Tufts Health Plan']:
  case Carriers.UnitedHealthcare:
  case Carriers['United Healthcare']:
    return 30
  case Carriers['Fallon Health']:
    return 45
  case Carriers['Apex Management Group']:
  case Carriers.Principal:
  case Carriers.Prosper:
  default:
    return 35
  }
}

// TODO Separate these to a separate file with typed plan types

export enum FundingType {
  mec = 'Association Select', // Minimum Essential Coverage Plans such as Apex plans
  levelFunded = 'Personalized', // Level Funded Plans such as NatGen plans
  fullyFunded = 'Traditional', // Fully Funded Plans, your usual plans
}

export function fundingTypeFor(plan: MedicalPlan) {
  if (plan.isLevelFunded) {
    return FundingType.levelFunded
  } else if (plan.carrier === Carriers['Apex Management Group']) {
    return FundingType.mec
  } else {
    return FundingType.fullyFunded
  }
}

export interface Contribution {
  value: string
  isEquitable: boolean
}

export interface Premiums {
  medical?: Premium
  dental?: Premium
  vision?: Premium
  life?: Premium
}

interface Premium {
  contribution: string
  contributionIsEquitable: boolean
  premium: string
  split: PremiumSplits
}

export interface PremiumSplits {
  er: string
  ee: string
}

export function tierMarketingCopy(tier: Tier) {
  switch (tier) {
  case Tier.individual:
    return 'Individual'
  case Tier.singleParent:
    return 'Employee/Child'
  case Tier.couple:
    return 'Couple'
  case Tier.family:
    return 'Family'
  }
}
