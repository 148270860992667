import React, { useState } from 'react'
import styles from './NewClientModal.module.scss'
import AnubisModal from './AnubisModal'
import CandorSelect from 'Components/Rudimentary/CandorSelect'
import CandorInput from 'Components/Rudimentary/CandorInput'
import { stages } from 'Routes/dashboard/agency/pipeline/PipelineSettings'
import CountyPicker from 'Components/Rudimentary/CountyPicker'
import * as api from 'Utilities/fetch++'
import useToast from 'Utilities/Hooks/useToast'
import { v4 as uuid } from 'uuid'
import { CandorDatePicker } from './CandorForm'
import moment from 'moment'
import usePersistableForm from 'Utilities/Hooks/usePersistableForm'
import { minEffectiveDate, defaultMinimumDate } from 'Routes/dashboard/agency/clients/ID/ProfileInfoSection'
import effectiveDateFilter from 'Utilities/Plans/effectiveDateFilter()'

interface Props {
  isOpen: boolean
  onRequestClose: (id: string | null) => void
}

const NewClientModal: React.FC<Props> = ({ isOpen, onRequestClose }) => {
  const { register, handleSubmit, watch, control, unpersist } = usePersistableForm('add-new-group')
  const zip = watch('address.zip')
  const addToast = useToast()
  const [disabled, setDisabled] = useState(false)

  return <AnubisModal isOpen={isOpen} onRequestClose={() => onRequestClose(null)} showClose={true} styles={{ width: '80%', maxWidth: 1300, height: 500, overflow: 'visible' }}>
    <h1>Add New Contact/Client</h1>
    <fieldset disabled={disabled}>
      <form onSubmit={handleSubmit(submit)} className={styles.addContactForm}>
        <div>
          <CandorInput placeholder='Contact Name' name='contact.name' ref={register} />
          <CandorInput
            placeholder='Phone'
            name='contact.phone'
            ref={register}
            maxLength={10}
            minLength={10}
          />
          <CandorInput placeholder='Email' name='contact.email' ref={register} type='email'/>
          <div className={styles.twoCol}>
            <CandorInput placeholder='# of Employees' type='number' name='employees' ref={register} />
            <CandorSelect placeholder='Average Age' options={ageOptions} name='age' control={control} backgroundColor='#fff'/>
          </div>
          <div className={styles.twoCol}>
            <CandorSelect placeholder='Pipeline Status' options={pipelineStages} name='status' control={control} backgroundColor='#fff'/>
            <CandorDatePicker
              placeholder='Effective Date'
              name='dates.effective'
              control={control}
              className={styles.whiteBackground}
              minDate={minEffectiveDate()}
              openToDate={defaultMinimumDate()}
              filterDate={effectiveDateFilter} />
          </div>
        </div>
        <div>
          <CandorInput placeholder='Company / Group Name' name='name' ref={register} />
          <CandorInput placeholder='Address 1' name='address.street1' ref={register} />
          <CandorInput placeholder='Address 2' name='address.street2' ref={register} />
          <CandorInput placeholder='City' name='address.city' ref={register} />
          <div className={styles.twoCol}>
            <CandorInput required placeholder='ZIP Code' name='address.zip' ref={register} maxLength={5} minLength={5}/>
            <CountyPicker required zip={zip} control={control} backgroundColor='#fff' name='address.county.id' />
          </div>
          <input type='submit' className={styles.save} value='Submit'/>
        </div>
      </form>
    </fieldset>
  </AnubisModal>

  async function submit(data: any) {
    try {
      setDisabled(true)

      data.id = uuid()
      data.dates.effective = data.dates.effective && moment.utc(data.dates.effective).format()
      data.address.state = data.address.state || undefined
      await api.post('/v3/groups', data)

      unpersist()
      onRequestClose(data.id)
    } catch (error) {
      addToast(error)
      setDisabled(false)
    }
  }
}

const ageOptions = [
  { value: '18-29', label: '18-29' },
  { value: '130-39', label: '30-39' },
  { value: '40-49', label: '40-49' },
  { value: '50-59', label: '50-59' },
  { value: '60-64', label: '60-64' }
]

const pipelineStages = stages.map(stage => ({
  value: stage.key,
  label: stage.label
}))

export default NewClientModal
