import React from 'react'
import AnubisModal, { Props as AnubisModalProps } from 'Components/Anubis/AnubisModal'
import CandorInput from 'Components/Rudimentary/CandorInput'
import { v4 as uuid } from 'uuid'
import { post, delete_ } from 'Utilities/fetch++'
import { useToggle } from 'react-use'
import useToast from 'Utilities/Hooks/useToast'
import { $enum } from 'ts-enum-util'
import usePersistableForm from 'Utilities/Hooks/usePersistableForm'
import _ from 'lodash'
import { Dependent, Gender, Relationship } from 'Utilities/pharaoh.types'
import { localMidnightToPharaohFormat } from 'Utilities/pharaoh'
import { CandorDatePicker } from './CandorForm'
import styles from './DependentModal.module.scss'
import CandorSelect from 'Components/Rudimentary/CandorSelect'
import { Controller } from 'react-hook-form'
import { ssnNormalizer } from 'Utilities/etc'

interface Props extends AnubisModalProps {
  id: string
  onSuccess: (dependent: Dependent) => void
  onDelete: () => void
  dependent?: Dependent
}

const genderOptions = $enum(Gender).map((key: string) => { return { value: key, label: _.startCase(key) } })
const relationshipOptions = $enum(Relationship).map((key: string) => {
  return {
    value: key,
    label: key === Relationship.lifePartner
      ? 'Domestic Partner'
      : _.startCase(key)
  }
})

const DependentModal: React.FC<Props> = props => {
  const key = props.dependent?.id || props.id
  const { register, handleSubmit, unpersist, canReset, reset, control } = usePersistableForm(`add-dependent-${key}`, props.dependent)
  const [disabled, setDisabled] = useToggle(false)
  const addToast = useToast()

  return <AnubisModal {...props} styles={{ display: 'inline-table' }}>
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <h1>Add Dependent</h1>
      <fieldset disabled={disabled}>
        <CandorInput name='name' ref={register} placeholder='Name' autoFocus required />
        <CandorSelect placeholder='Biological Sex' options={genderOptions} control={control} name='gender' backgroundColor='#fff' required />
        <CandorDatePicker
          name='dob'
          control={control}
          placeholder='Date of Birth'
          className={styles.white}
          maxDate={new Date()}
          required
        />
        <CandorSelect placeholder='Relationship' control={control} options={relationshipOptions} name='relationship' backgroundColor='#fff' required />
        <Controller
          render={({ onChange, ...props }) => <CandorInput
            onChange={event => onChange(ssnNormalizer(event.currentTarget.value))}
            {...props}
            minLength={11} maxLength={11}
            pattern='[0-9-]{11}'
            className='glance_masked'
            placeholder='Social Security Number'
            required
          />}
          control={control}
          name="ssn"
        />
        <input type='submit'/>
        <div className={styles.resetDelete}>
          <input type='button' onClick={reset} value='Reset' style={{ display: canReset ? 'block' : 'none' }}/>
          <input type='button' onClick={onDelete} value='Delete' style={{ display: props.dependent ? 'block' : 'none' }}/>
        </div>
      </fieldset>
    </form>

  </AnubisModal>

  async function onSubmit(data: any) {
    try {
      setDisabled(true)
      data.id = props.dependent?.id || uuid()
      data.dob = localMidnightToPharaohFormat(data.dob)
      await post(`/v3/users/${props.id}/dependents`, data)
      unpersist()
      props.onSuccess(data)
    } catch (error) {
      addToast(error)
      setDisabled(false)
    }
  }

  async function onDelete() {
    try {
      setDisabled(true)
      await delete_(`/v3/users/${props.id}/dependents/${props.dependent!.id}`)
      props.onDelete()
    } catch (error) {
      addToast(error)
      setDisabled(false)
    }
  }
}

export default DependentModal
