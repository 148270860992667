import * as api from './fetch++'
import * as types from './pharaoh.types'
import moment, { Moment } from 'moment'
import { mode, Mode } from './config'

/* eslint-disable @typescript-eslint/no-explicit-any */

/// used when signed in user account
export function clearLocalStorage() {
  for (const key of Object.keys(localStorage)) {
    // keep any scoping/label/mode settings
    if (!key.includes('/') && key !== 'mode' && key !== 'label') localStorage.removeItem(key)
  }
  tokenStorage().removeItem('token')
}

function tokenStorage() {
  if (mode()[0] === Mode.demo) {
    return sessionStorage
  } else {
    return localStorage
  }
}

export function getToken() {
  return tokenStorage().token
}

const v1 = {
  associations: (id: string) => ({
    GET: () => api.get(`/associations/${id}`),
    groups: () => api.get(`/associations/${id}/groups`)
  }),
  groups: (id: string) => ({
    users: {
      GET: () => api.get(`/groups/${id}/users`),
      POST: (data: any) => api.post(`/groups/${id}/users`, data)
    },
    splits: () => api.get(`/groups/${id}/split`),
    plans: {
      options: (type: string) => api.get(`/groups/${id}/plans/options/${type}`)
    }
  }),
  support: {
    mail: (payload: {subject: string, body: string, referrerURL: string, email?: string, isPlanApplication?: boolean}) => {
      return api.post('/support/mail', payload)
    }
  },
  users: {
    resetPassword: async(data: any, token = '') => {
      const rsp = await api.post(`/users/reset-password/${token}`, data)
      if (token) setToken(rsp.token)
      return rsp
    },
    sessions: {
      POST: async(email: string, password: string) => {
        const rsp = await api.post('/users/sessions', { email, password }, false)
        clearLocalStorage()
        setToken(rsp.token)
        return rsp
      },
      DELETE: async() => {
        try {
          if (getToken()) {
            await api.delete_('/users/sessions')
          }
        } finally {
          clearLocalStorage()
        }
      }
    }
  }
}

const v2 = {
  brokers: {
    groups: (id: string | null = null) => ({
      GET: () => api.get('/v2/brokers/groups'),
      documents: (docID = '') => ({
        GET: () => api.get(`/v2/brokers/groups/${id}/documents`),
        DELETE: () => api.delete_(`/v2/brokers/groups/${id}/documents/${docID}`),
        PUT: (data: any) => api.put(`/v2/brokers/groups/${id}/documents/${docID}`, data),
        POST: (data: any) => api.post(`/v2/brokers/groups/${id}/documents`, data)
      }),
      users: () => api.get(`/v2/brokers/groups/${id}/users`)
    }),
    pipeline: () => api.get('/v2/brokers/pipeline'),
    associations: (id: string | null = null) => ({
      GET: () => api.get('/v2/brokers/associations'),
      groups: () => api.get(`/v2/brokers/associations/${id}/groups`)
    }),
    reports: () => api.get('/v2/brokers/reports'),
    producers: (id: string | null = null) => ({
      GET: () => api.get(`/v2/brokers/producers/${id || ''}`),
      groups: ({
        GET: () => api.get(`/v2/brokers/producers/${id}/groups`)
      }),
      assign: {
        to: (groupID: string) => api.post(`/v2/brokers/producers/${id}/assign/to/${groupID}`)
      }
    })
  },
  forms: (id: string) => ({
    GET: () => api.get(`/v2/forms/${id}`),
    questions: () => ({
      GET: () => api.get(`/v2/forms/${id}/questions`)
    })
  }),
  groups: (id?: string) => ({
    plans: {
      GET: () => api.get(`/v2/groups/${id}/plans`),
      options: {
        recommended: (query = '') => api.get(`/v2/groups/${id}/plans/options/recommended${query}`)
      }
    },
    xlsx: (file: File) => api.upload('/v2/groups/xlsx', file),
    users: {
      GET: () => api.get(`/v2/groups/${id}/users`),
      redflags: {
        GET: () => api.get(`/v2/groups/${id}/users/redflags`),
        POST: (data: any) => api.post(`/v2/groups/${id}/users/redflags`, data)
      },
      uhone: () => api.get(`/v2/groups/${id}/users/uhone`),
      underwriting: {
        GET: () => api.get(`/v2/groups/${id}/users/underwriting`),
        POST: (data: any) => api.post(`/v2/groups/${id}/users/underwriting`, data)
      }
    }
  }),
  notes: {
    GET: (id = '') => api.get(`/v2/notes/${id}`),
    POST: async function(targetID: string, content: string) {
      return api.post('/v2/notes', { targetID, content })
    },
    PUT: async(id: string, content: string) => api.put(`/v2/notes/${id}`, { content }),
    DELETE: async(id: string) => api.delete_(`/v2/notes/${id}`)
  },
  todos: (id = '') => ({
    GET: () => api.get(`/v2/todos/${id}`),
    POST: (data: any) => api.post(`/v2/todos/${id}`, data),
    PUT: (data: any) => api.put(`/v2/todos/${id}`, data),
    DELETE: () => api.delete_(`/v2/todos/${id}`)
  })
}

const v3 = {
  tickets: (token: string | null = null) => ({
    GET: () => api.get(`/v3/tickets/${token}`) as Promise<types.Ticket>,
    POST: (data: types.TicketPayload) => api.post('/v3/tickets', data)
  }),
  toastCollector: () => ({
    POST: (data: types.ToastCollectorPayload) => api.post('/v3/toastCollector', data)
  }),
  users: (id?: string) => ({
    POST: async(data: any) => {
      const rsp = await api.post('/v3/users', data, false)
      setToken(rsp.token)
      return rsp
    },
    GET: async() => {
      const rsp = await api.get(`/v3/users/${id}`) as types.Member
      rsp.dob = utcMidnightToLocalMidnight(rsp.dob)
      rsp.dependents = rsp.dependents?.map(dep => {
        dep.dob = utcMidnightToLocalMidnight(dep.dob)!
        return dep
      })
      rsp.dates.effective = utcMidnightToLocalMidnight(rsp.dates.effective)
      rsp.dates.hire = utcMidnightToLocalMidnight(rsp.dates.hire)
      return rsp
    },
    venues: () => api.get('/v3/users/venues'),
    qle: {
      GET: () => api.get(`/v3/users/${id}/qle`),
      POST: (data: any) => api.post(`/v3/users/${id}/qle`, data)
    },
    payments: {
      GET: () => api.get('/v3/users/payments'),
      POST: (data: any) => api.post('/v3/users/payments', data),
      premium: (data: any) => api.post('/v3/users/payments/premium', data),
      ach: (data: any) => api.put('/v3/users/payments/ach', data),
      promocodes: (data: any) => api.post('/v3/users/payments/promocodes', data)
    }
  }),
  groups: (groupID?: string) => ({
    GET: async() => {
      const rsp = await api.get(`/v3/groups/${groupID || ''}`) as types.Group
      for (const key in rsp.dates) {
        (rsp.dates as any)[key] = utcMidnightToLocalMidnight((rsp.dates as any)[key])
      }
      return rsp
    },
    PUT: async(data: types.PartialGroup) => {
      for (const key in data.dates) {
        (data.dates as any)[key] = utcMidnightToLocalMidnight((data.dates as any)[key])
      }
      data.waitingPeriod = numberify(data.waitingPeriod)
      if (data.address) {
        data.address.zip = data.address.zip || undefined
        data.address.county = data.address.county || undefined
        data.address.state = data.address.state || undefined
      }
      await api.put('/v3/groups', { ...data, id: groupID })
    },
    application: {
      GET: () => api.get(`/v3/groups/${groupID}/application`),
      POST: (data: any) => api.post(`/v3/groups/${groupID}/application`, data)
    },
    users: (memberId?: string) => ({
      plans: {
        GET: () => api.get(`/v3/groups/${groupID}/users/${memberId}/plans`)
      },
      underwriting: {
        POST: (data: any) => api.post(`/v3/groups/${groupID}/users/${memberId}/underwriting`, data),
        GET: () => api.get(`/v3/groups/${groupID}/users/${memberId}/underwriting`)
      }
    }),
    plans: {
      GET: () => api.get(`/v3/groups/${groupID || ''}/plans`)
    },
    contacts: {
      GET: () => api.get(`/v3/groups/${groupID}/contacts`),
      POST: (data: any) => api.post(`/v3/groups/${groupID}/contacts`, data)
    },
    payments: {
      ach: {
        PUT: (data: any) => api.put(`/v3/groups/${groupID}/payments/ach`, data)
      }
    }
  }),
  qualifyingevents: () => api.get('/v3/qualifyingevents')
}

function numberify(input: string | number | undefined): number | undefined {
  if (typeof input === 'string') {
    return parseInt(input)
  } else {
    return input
  }
}

export function setToken(newValue: string): void {
  const str = newValue?.trim()
  if (str) {
    tokenStorage().token = newValue
  } else {
    throw new Error('Invalid or empty token returned from server')
  }
}

function isAuthenticated(): boolean {
  return !!getToken()
}

function utcMidnightToLocalMidnight(input: any): Date | undefined {
  if (!input) return undefined
  if (typeof input !== 'string') return undefined
  const mk = moment(input).local()
  const offset = mk.utcOffset()
  return mk.minutes(-offset).toDate()
}

function localMidnightToUTCMidnight(input: Date | Moment | undefined): Moment | undefined {
  if (!input) return input
  if (!moment.isMoment(input)) input = moment(input).local()
  if (!input.isLocal()) input = input.local()
  const offset = input.utcOffset()
  return input.minutes(offset)
}

function localMidnightToPharaohFormat(input: Date | Moment | undefined): string | undefined {
  return localMidnightToUTCMidnight(input)?.format()
}

export {
  v1, v2, v3,
  isAuthenticated,
  utcMidnightToLocalMidnight,
  localMidnightToUTCMidnight,
  localMidnightToPharaohFormat
}
