import React from 'react'
import styles from './PointsTracker.module.css'
import progressIcon from './lf_progress_icon.svg'
import { FundingType } from 'Components/Plans/plan-subcomponents/PlanHelpers'

interface Props {
  points: number
}

const PointsTracker: React.FC<Props> = ({ points }) => {
  const pos = 50 + points
  const gradientSoftness = 0
  let fiPos = pos - gradientSoftness
  let lfPos = pos + gradientSoftness

  if (fiPos < 0) { lfPos = 0 }
  if (lfPos > 100) { fiPos = 100 }

  const altPrefix = 'Quiz progress currently favoring '
  const altSuffix = lfPos > 50 ? `${FundingType.levelFunded} Plans` : `${FundingType.fullyFunded} Plans`

  return (
    <div className={styles.container}>
      <h4 style={{ opacity: pos <= 50 ? 1 : 0.5 }}>{FundingType.fullyFunded}<br/>Plans</h4>
      <div>
        <div style={{ paddingLeft: `calc(${pos}% - 10px)`, textAlign: 'left', width: '100%' }}>
          <img width='20px' height='auto' src={progressIcon} alt={altPrefix + altSuffix}/>
        </div>
        <div className={styles.bar} style={{ backgroundImage: `linear-gradient(to right, rgba(72,124,224,1) ${fiPos}%, rgba(186,200,229,1) ${lfPos}%)` }}></div>
      </div>
      <h4 style={{ opacity: pos >= 50 ? 1 : 0.5 }}>{FundingType.levelFunded}<br/>Plans</h4>
    </div>
  )
}

export default PointsTracker
