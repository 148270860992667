import React from 'react'
import ContributionBar from './ContributionBar'
import styles from './ContributionGraph.module.css'
import { moneyNumber } from 'Utilities/Plans/PremiumCalculators'
import { tierMarketingCopy } from 'Components/Plans/plan-subcomponents/PlanHelpers'
import { ContributionSplitType } from 'Components/Stargate/Contribution/ContributionAmount'
import { Tier } from 'Utilities/pharaoh.types'
import { $enum } from 'ts-enum-util'

interface Props {
  height: any
  displayMode: ContributionSplitType
  contributionValue: number
  premiums: {[key in Tier]: string | number}
}

const ContributionGraph: React.FC<Props> = ({ height, displayMode, contributionValue, premiums }) => {
  const individualPremium = moneyNumber(premiums.individual, 2) // default value used for height
  const baseHeight = height / premiumsByRatio(Tier.family)

  return <div className={styles.contributionGraph}>
    {$enum(Tier).map((premium, index) => {
      const barHeight = baseHeight * premiumsByRatio(premium)
      const tierPrem = moneyNumber(premiums[premium])
      return (
        <ContributionBar
          key={index}
          barHeight={barHeight}
          backgroundColor='#2f6ca5'
          fillColor='#48a3db'
          label={tierMarketingCopy(premium)}
          premium={tierPrem}
          fillHeight={getFillHeight(barHeight, individualPremium)}
        >
          {premium === Tier.family &&
            <>
              <span className={styles.eeBarLabel}>Paid by Employee</span>
              <span className={styles.erBarLabel}>Paid by Employer</span>
            </>}
        </ContributionBar>
      )
    })}
  </div>

  function premiumsByRatio(premType: Tier): number {
    return moneyNumber(premiums[premType], 2) / moneyNumber(premiums.individual, 2)
  }

  function getFillHeight(barHeight: number, individualPremium: number): number {
    const individualHeight = baseHeight * premiumsByRatio(Tier.individual)
    let ratio, pixels
    switch (displayMode) {
    case ContributionSplitType.perEmployee:
      ratio = (contributionValue / 100)
      pixels = ratio * individualHeight
      return barHeight < pixels ? barHeight : pixels
    case ContributionSplitType.allTiers:
      ratio = (contributionValue / 100)
      pixels = ratio * barHeight
      return barHeight < pixels ? barHeight : pixels
    case ContributionSplitType.flatContribution:
      ratio = contributionValue / individualPremium
      pixels = ratio * individualHeight
      return barHeight < pixels ? barHeight : pixels
    }
  }
}

export default ContributionGraph
