import React from 'react'
import baseStyles from './plan-subcomponents/Plan.module.scss'
import styles from './LifePlan.module.scss'
import Plan, { PlanBody } from './plan-subcomponents/Plan'
import { AncillaryPlanEmployeeCalculator, moneyNumber, moneyString } from 'Utilities/Plans/PremiumCalculators'
import { Tier, LifePlan } from 'Utilities/pharaoh.types'
import { Contribution, PremiumSplits } from './plan-subcomponents/PlanHelpers'
import PlanTitle from './plan-subcomponents/PlanTitle'
import EEPremiumBreakdown from './plan-subcomponents/EEPremiumBreakdown'

interface Props {
  plan: LifePlan
  rates: { [key in Tier]: string }
  contribution: Contribution
  selectHandler?(plan: LifePlan): void
  selected?: boolean
  premium?: PremiumSplits
}

const LifePlanComponent: React.FC<Props> = ({ plan, rates, contribution, selectHandler, selected, premium }) => {
  const { data } = plan
  const { willPreparationAndEstateResolutionServices: will, 'supplementalAD&DIncluded?': add } = data

  let ee, er
  if (premium) {
    er = moneyNumber(premium.er)
    ee = moneyNumber(premium.ee)
  } else {
    const calc = new AncillaryPlanEmployeeCalculator({ plan, rates }, contribution.value, Tier.individual)
    er = calc.er()
    ee = calc.ee()
  }

  return <Plan selected={!!selected}>
    <PlanTitle
      planName={plan.name}
      carrier={plan.carrier}
      selectHandler={selectHandler && (() => selectHandler(plan))}
      selected={selected}
      sbc={`https://s3.amazonaws.com/documents-pub.candor.insurance/sbc/${plan.id.toLowerCase()}.pdf`}
      includesProsperBenefits={false}
    />
    <PlanBody>
      <EEPremiumBreakdown
        erPremium={er}
        eePremium={ee}
        tier={Tier.individual}
      />
      <div className={`${baseStyles.section} ${styles.specs}`}>
        <div className={baseStyles.box}>
          <div className={baseStyles.label}>For Individual</div>
          <div className={styles.largeFigure}>{moneyString(data.benefitsIndividual)}</div>
          <span>Minimum Benefit </span>
        </div>
        <div className={baseStyles.box}>
          <div className={baseStyles.label}>For Spouse</div>
          <div className={styles.largeFigure}>{moneyString(data.benefitsSpouse)}</div>
          <span>Minimum Benefit</span>
        </div>
        <div className={baseStyles.box}>
          <div className={baseStyles.label}>Per Child</div>
          <div className={styles.largeFigure}>{moneyString(data.benefitsPerChild)}</div>
          <span>Minimum Benefit</span>
        </div>
        <div className={baseStyles.box}>
          <div className={styles.label}>Will Preparation &amp; Estate Resolution Services</div>
          <span style={{ color: will === 'true' ? '#29B573' : '#16346f' }}>{will === 'true' ? 'Included with your coverage' : 'Not Covered'}</span>
        </div>
        <div className={baseStyles.box}>
          <div className={styles.label}>Supplemental AD&amp;D Included?</div>
          <span style={{ color: add === 'true' ? '#29B573' : '#97B1D9' }}>{add === 'true' ? 'Included with your coverage' : 'Not Covered'}</span>
        </div>
        <div className={baseStyles.box}>
          <div className={styles.label}>Disability Provision</div>
          <span>{data.disabilityProvision}</span>
        </div>
      </div>
    </PlanBody>
  </Plan>
}

export default LifePlanComponent
