import React from 'react'
import styles from './index.module.scss'
import ProfileInfoSection from 'Routes/dashboard/agency/clients/ID/ProfileInfoSection'
import NotesSection from 'Components/Anubis/NotesSection'
import GroupsProfileMedicalPlansSection from './GroupsProfileMedicalPlansSection'
import GroupsProfileAncillaryPlansSection from './GroupsProfileAncillaryPlansSection'
import GroupsProfileDocumentsSection from './GroupsProfileDocumentsSection'
import GroupsProfileEmployeesSection, { Response as EEResponse } from './GroupsProfileEmployeesSection'
import * as api from 'Utilities/pharaoh'
import Error from 'Components/Primitives/Error'
import Loader from 'Components/Rudimentary/Loader'
import { useToggle, useAsyncRetry } from 'react-use'
import { Route } from 'Utilities/Route'
import { Link, useLocation } from 'react-router-dom'
import { Identifiable } from 'Components/Rudimentary/Table'
import useUser, { PowerLevel } from 'Utilities/Hooks/useUser'
import UploadFileModal from 'Components/Anubis/UploadFileModal'
import { upload, FetchError } from 'Utilities/fetch++'
import { v4 as uuid } from 'uuid'
import * as config from 'Utilities/config'

const DashboardAgencyGroup: React.FC<Identifiable> = ({ id }) => {
  const async = useAsyncRetry(api.v3.groups(id).GET, [id])
  const eeAsync = useAsyncRetry<EEResponse>(async() => (await api.v2.brokers.groups(id).users()), [id])

  if (async.loading) return <Loader />
  if (async.error) return <Error error={async.error} />

  const group = async.value!

  return (
    <div className={styles.mainContainer}>
      <div>
        <ProfileInfoSection id={id} targetType='group' group={group} />
      </div>
      <div className={styles.miscDetails}>
        <Associations associations={group.associations} />
        <GroupIncompleteSection
          todo={group.todo}
          groupID={group.id}
          callback={() => {
            async.retry()
            eeAsync.retry()
          }}>
          <Link className={styles.links} to={`${Route.stargate}/employer`} onClick={setGroupID}>Employer Shop</Link>
        </GroupIncompleteSection>
        <div>
          <HRDashboardButton setGroupID={setGroupID} />
          <CairoButton id={id} />
        </div>
      </div>
      <NotesSection targetID={id} />
      <GroupsProfileMedicalPlansSection id={id} group={group} />
      <GroupsProfileAncillaryPlansSection id={id} group={group} />
      <GroupsProfileDocumentsSection id={id} />
      <GroupsProfileEmployeesSection ees={eeAsync} id={id} />
    </div>
  )

  function setGroupID() {
    localStorage.overrideGroupID = id
  }
}

interface HRDashboardButtonProps {
  setGroupID: () => void
}

const HRDashboardButton: React.FC<HRDashboardButtonProps> = ({ setGroupID }) => {
  const isAgencyDash = useLocation().pathname.startsWith(Route.agencyDashboard)

  if (isAgencyDash) {
    return <>
      <h3>Dashboard</h3>
      <p className={styles.explainerCopy}>Access client information such as statuses, plans, and other pertinent information about their coverage.</p>
      <Link className={styles.links} to={Route.dashboardEmployer} onClick={setGroupID}>HR Dashboard</Link>
    </>
  } else {
    return <></>
  }
}

interface CairoButtonProps {
  id: string
}

const CairoButton: React.FC<CairoButtonProps> = ({ id }) => {
  const user = useUser()
  const isSuperUser = (user.value?.powerLevel || PowerLevel.individual) >= PowerLevel.candorEmployee

  if (isSuperUser) {
    const cairo = `${config.cairo()}?id=${id}`
    return <a className={styles.links} href={cairo}>Cairo</a>
  } else {
    return <></>
  }
}

interface GISProps {
  todo: {census: boolean, manager: boolean}
  groupID: string
  callback: () => void
}

const GroupIncompleteSection: React.FC<GISProps> = ({ todo, groupID, callback, children }) => {
  const [isModalOpen, setIsModalOpen] = useToggle(false)
  const user = useUser()

  if (user.loading) return <></>
  if (user.error) {
    if (user.error instanceof FetchError && user.error.response.status === 401) {
      return <></> // not signed in
    } else {
      return <Error error={user.error} />
    }
  }

  const isBroker = user.value!.powerLevel > PowerLevel.groupManager
  const header = `${isBroker ? 'Broker' : 'Your'} action links`
  const uploadModalCopy = `
    Please use the downloadable .xlsx template below to upload
    employee information for those eligible to receive healthcare
    coverage through your ${isBroker ? 'client' : 'business'}.
    Employees waiving coverage must also be entered into the
    census to ensure accuracy
  `
  let copy: JSX.Element

  switch (true) {
  case isBroker && todo.census && todo.manager:
    copy = <>This area contains important links for your client’s healthcare coverage. You can upload your client’s census, and shop healthcare coverage options.</>
    break
  case isBroker:
    copy = <>Enter your client’s shopping experience to browse or select their healthcare coverage options.</>
    break
  case !isBroker && todo.census && todo.manager:
    copy = <>This area contains important links for your healthcare coverage. You can upload your census, and shop healthcare coverage options.</>
    break
  default:
    copy = <>Enter your shopping experience to browse or select healthcare coverage options.</>
  }

  return <div className={styles.actionLinksContainer}>
    <h3>{header}</h3>
    <p className={styles.explainerCopy}>{copy}</p>
    {todo.census && todo.manager &&
      <UploadFileModal
        title='Census Upload'
        copy={uploadModalCopy}
        template={{
          href: config.censusURL(),
          name: config.censusFilename()
        }}
        isOpen={isModalOpen}
        onSubmit={handleSubmit}
        onRequestClose={setIsModalOpen}
        successMessage='Census successfully uploaded'
      />
    }
    <div className={styles.actionLinks}>
      {todo.census && todo.manager &&
        <button className={`${styles.links} ${styles.upload}`} onClick={setIsModalOpen}>Upload Census</button>
      }
      {children}
    </div>
  </div>

  async function handleSubmit(data: any) {
    // Catches in UploadFileModal
    return upload('/groups/xlsx', data.file[0]).then(rsp => {
      /* eslint-disable */
      const emps = rsp.map((emp: any) => {
        return {
          id: uuid(),
          email: emp.email,
          name: `${emp.firstName} ${emp.lastName}`,
          gender: emp.gender,
          date_of_birth: api.localMidnightToPharaohFormat(emp.dateOfBirth),
          zip_code: emp.zipCode,
          last_tobacco_use_date: emp.tobbacoUse ? api.localMidnightToPharaohFormat(new Date()) : undefined,
          dependents : emp.dependents.map((dep: any) => {
            return {
              id: uuid(),
              firstName: dep.firstName,
              lastName: dep.lastName,
              zipCode: dep.zipCode,
              dateOfBirth: api.localMidnightToPharaohFormat(dep.dateOfBirth),
              gender: dep.gender,
              lastUsedTobacco: dep.tobaccoUse ? api.localMidnightToPharaohFormat(new Date()) : undefined,
              relationship: dep.type === 'domesticPartner' ? 'life_partner' : dep.type
            }
          })
        }
      })
      /* eslint-enable */
      return api.v1.groups(groupID).users.POST(emps).then(callback)
    })
  }
}

const Associations: React.FC<any> = ({ associations }) => {
  // Must return something or else the grid gets broken
  if (!config.associationsMode()) return <div/>

  const lili = associations.map((ass: any, ii: number) =>
    <li key={ii}>
      <Link to={`${Route.agencyDashboardAssociations}/${ass.id}`}>
        <span>{ass.name}</span>
      </Link>
    </li>)

  return <div className={styles.ass}>
    <h3>Associations</h3>
    {associations.length === 0
      ? 'None'
      : <ul className={styles.asses}>{lili}</ul>
    }
  </div>
}

export default DashboardAgencyGroup
