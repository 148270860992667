import React from 'react'
import ReactSelect, { Props as ReactSelectProps, Styles } from 'react-select'
import cssStyles from './CandorSelect.module.css'
import { classNames } from 'Utilities/etc'
import { useFormContext, Controller, Control } from 'react-hook-form'
import { omit } from 'lodash'

type Foo = Pick<ReactSelectProps, 'placeholder' | 'options' | 'name' | 'styles' | 'className'>

/// we pick rather than omit as ReactSelectProps seems to include “any”
interface Props extends Foo {
  onChange?: (value: string) => void
  value?: string
  control?: Control<any>

  color?: string
  backgroundColor?: string
  width?: string
  height?: string
  required?: boolean
  isDisabled?: boolean

  stop?: boolean // internal do not use
}

const CandorSelect: React.FC<Props> = ({ value, styles: propsStyles, stop, ...props }) => {
  /// attempt to make us easily used with react-hook-form
  const control = useFormContext()?.control || props.control
  if (control && props.name && !stop) {
    const fwdprops = omit(props, ['onChange', 'value', 'stop']) as any // FIXME: I DUNNO WHY WON'T COMPILE
    return <Controller as={CandorSelect} {...fwdprops} name={props.name} styles={propsStyles} stop />
  }

  const choice = (props.options as any[])?.find((opt: any) => opt.value === value)
  const maybeOnChange = props.onChange ? (o: any) => props.onChange!(o.value) : undefined
  const hasValue = !!value

  return <div className={classNames(cssStyles.selectContainer, props.className)}>
    <span className={classNames(cssStyles.label, hasValue && cssStyles.labelVisible)}>{props.placeholder}</span>
    <ReactSelect {...props} onChange={maybeOnChange} styles={styles()} value={choice} />
  </div>

  function styles() {
    if (propsStyles) return propsStyles

    const styles: Partial<Styles> = { ...selectStyle }
    styles.singleValue = styles => ({
      ...styles,
      padding: props.placeholder ? '10px 30px 0 0px' : '0px 30px 0 0px',
      color: props.color || '#4EA6DC',
      fontSize: 16,
      margin: 0
    })
    styles.control = (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? 'rgb(230, 230, 230)' : props.backgroundColor || '#f4f4f4',
      cursor: isDisabled && 'not-allowed',
      borderRadius: '7px',
      border: 0,
      height: props.height || '55px'
    })
    if (props.width) {
      styles.container = styles => ({
        ...styles,
        width: props.width
      })
    }
    return styles
  }
}

const selectStyle: Partial<Styles> = {
  option: (provided, state) => ({
    ...provided,
    padding: '17px 23px',
    color: state.isSelected ? 'white' : state.isFocused ? 'hsl(0, 0%, 35%)' : 'inherit'
  }),
  control: styles => ({
    ...styles,
    backgroundColor: '#f4f4f4',
    borderRadius: '6px',
    border: 0,
    height: '55px',
    width: '100%'
  }),
  placeholder: styles => ({
    ...styles,
    color: 'hsla(0, 0%, 67%, 1)',
    fontSize: 16
  }),
  valueContainer: styles => ({
    ...styles,
    paddingLeft: '21px'
  }),
  singleValue: styles => ({
    ...styles,
    color: 'hsla(0, 0%, 50%, 1)'
  }),
  indicatorsContainer: styles => ({
    ...styles,
    position: 'absolute',
    right: '0',
    top: '12px'
  }),
  menu: styles => ({
    ...styles,
    zIndex: 5,
    overflow: 'hidden'
  }),
  menuList: styles => ({
    ...styles,
    maxHeight: 200
  })
}

export default CandorSelect
