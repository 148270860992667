import React, { useState } from 'react'
import styles from './LevelFundedModal.module.css'
import caution from './lf_caution_icon.svg'
import proceed from './lf_proceed_icon.svg'
import CheckEligibility from './CheckEligibility'
import StargateModal from 'Components/Stargate/StargateModal'
import { FundingType } from 'Components/Plans/plan-subcomponents/PlanHelpers'
import { Group } from 'Utilities/Hooks/useStargate'

enum Mode {
  INITIAL = 'initial',
  QUIZ = 'quiz',
  DECLINED = 'declined',
  TRADITIONAL = 'traditional',
  LEVELFUNDED = 'levelFunded'
}

export enum Decision {
  continue, reconsider
}

interface Props {
  show: boolean
  onClose: () => void
  group: Group
  setDecision: (decision: Decision) => void
}

const LevelFundedModal: React.FC<Props> = ({ show, onClose, setDecision: handleLFDecision, group }) => {
  const [lfMode, setLFMode] = useState(Mode.INITIAL)

  return <StargateModal
    isOpen={show}
    onRequestClose={onClose}
    styles={{ height: 500, padding: 20 }}
  >
    {renderMode()}
  </StargateModal>

  function renderMode() {
    switch (lfMode) {
    case Mode.INITIAL:
      return renderInitial()
    case Mode.QUIZ:
      return <CheckEligibility callback={setLFMode} group={group}/>
    case Mode.DECLINED:
      return renderDeclined()
    case Mode.TRADITIONAL:
      return renderResultTraditional()
    case Mode.LEVELFUNDED:
      return renderResultLevelFunded()
    }
  }

  function renderInitial() {
    return <div className={styles.initial}>
      <h2>You selected a {FundingType.levelFunded} Plan</h2>
      <p>Our estimates for {FundingType.levelFunded.toLowerCase()} plans can have a significant increase if your company doesn’t meet health standards</p>
      <div className={styles.flexContainer}>
        <h4 className={styles.modeSelectCopy}>Check if {FundingType.levelFunded} Plans are the right option for your business</h4>
        <div className={styles.checkContainer}>
          <button className={styles.check} onClick={() => setLFMode(Mode.QUIZ)}>Check my Eligibility</button>
          <div>2 Minutes</div>
        </div>
        <button className={styles.thankYouNext} onClick={() => setLFMode(Mode.DECLINED)}>No Thanks</button>
      </div>
      <hr></hr>
      <h3>Advantages of {FundingType.levelFunded} Plans</h3>
      <div className={styles.flexContainer}>
        <div className={styles.advantages}>
          <div className={styles.bullet}></div>
          <p>Up to 30% savings in Healthcare costs</p>
        </div>
        <div className={styles.advantages}>
          <div className={styles.bullet}></div>
          <p>Protection against Extraordinary Costs</p>
        </div>
        <div className={styles.advantages}>
          <div className={styles.bullet}></div>
          <p>Ability to fall back on an ACA plan</p>
        </div>
      </div>
    </div>
  }

  function renderDeclined() {
    return <div className={styles.declined}>
      <img src={caution} height='90px' width= '90px' alt=''/>
      <h1>Take the following considerations:</h1>
      <p>{FundingType.levelFunded} Plans are good for companies whose employees:</p>
      <div className={styles.flexContainer}>
        <div className={styles.reasons}>Don’t use a lot of prescriptions</div>
        <div className={styles.reasons}>Don’t have major health issues like Diabetes or Cancer</div>
        <div className={styles.reasons}>Visit the doctor less than once a month </div>
      </div>
      <div className={styles.flexContainer}>
        <button className={styles.check} onClick={() => handleLFDecision(Decision.continue)}>Continue, my employees are healthy</button>
        <button className={styles.thankYouNext} onClick={() => handleLFDecision(Decision.reconsider)}>Change, my plan selection</button>
      </div>
    </div>
  }

  function renderResultTraditional() {
    return <div className={styles.result}>
      <img src={caution} height='90px' width= '90px' alt=''/>
      <h2>{FundingType.fullyFunded} are a better option for you!</h2>
      <p>{FundingType.levelFunded} plan rates would be too high for your company. Let us help you find the best {FundingType.fullyFunded} plan for your team</p>
      <div className={styles.flexContainer}>
        <button className={styles.check} onClick={() => handleLFDecision(Decision.reconsider)}>Remove {FundingType.levelFunded} Plans</button>
        <button className={styles.thankYouNext} onClick={() => handleLFDecision(Decision.continue)}>Continue, I’ll take the risk</button>
      </div>
    </div>
  }

  function renderResultLevelFunded() {
    return <div className={styles.result}>
      <img src={proceed} height='90px' width= '90px' alt=''/>
      <h2>{FundingType.levelFunded} Plans are the right option for you!</h2>
      <p>Based on your information, there is a high chance that your application will be accepted. You may continue to checkout</p>
      <button className={styles.check} onClick={() => handleLFDecision(Decision.continue)}>Continue to Checkout</button>
    </div>
  }
}

export default LevelFundedModal
export { Mode }
