import React from 'react'
import ProfileSection from 'Components/Anubis/ProfileSection'
import * as api from 'Utilities/pharaoh'
import Error from 'Components/Primitives/Error'
import Loader from 'Components/Rudimentary/Loader'
import { useAsync } from 'react-use'
import EmployerPlansBreakdown, { PlansBreakdown, getPlansBreakdown } from 'Components/Anubis/EmployerPlansBreakdown'

type Props = {
  id: string
  group: any
}

const GroupsProfileAncillaryPlansSection: React.FC<Props> = ({ id, group }) => {
  const async = useAsync(() =>
    Promise.all([
      api.v1.groups(id).plans.options('dental'),
      api.v1.groups(id).plans.options('vision'),
      api.v1.groups(id).users.GET()
    ]), [id])

  return (
    <ProfileSection name="Ancillary Plans" expanded={false}>
      {content()}
    </ProfileSection>
  )

  function content() {
    if (async.loading) return <Loader />
    if (async.error) return <Error error={async.error}/>

    const [dentalPlans, visionPlans, members] = async.value!
    const nonWaivedCount = group.members.total - (group.members.waived || 0)

    if (!nonWaivedCount) {
      return <p>Please add an employee to see available ancillary plans </p>
    }

    const dentalPlansBreakdown: PlansBreakdown[] = getPlansBreakdown('dental', dentalPlans, members)
    const visionPlansBreakdown: PlansBreakdown[] = getPlansBreakdown('vision', visionPlans, members)

    return <>
      <h3 style={{ textAlign: 'left', fontWeight: 800 }}>Dental Plans</h3>
      <EmployerPlansBreakdown planBreakdowns={dentalPlansBreakdown} contribution={group.contributions?.dental} reportFileBasename='dental-benefits-breakdown' />
      <h3 style={{ textAlign: 'left', fontWeight: 800, marginTop: '2rem' }}>Vision Plans</h3>
      <EmployerPlansBreakdown planBreakdowns={visionPlansBreakdown} contribution={group.contributions?.vision} reportFileBasename='vision-benefits-breakdown' />
    </>
  }
}

export default GroupsProfileAncillaryPlansSection
