import { moneyNumber } from './PremiumCalculators'
import numeral from 'numeral'

export const centsFormat = '$0,0.00'
export const dollarsFormat = '$0,0'

export const isDollar = (amount: string) => {
  return amount.startsWith('$')
}

export const isPercentage = (amount: string) => {
  return amount.endsWith('%')
}

export const cleanContributionString = (amount: any): number => {
  if (amount === undefined || amount === null) return 0
  if (typeof (amount) !== 'string') throw new Error(`Bad input ${amount}`)

  if (isPercentage(amount)) {
    return numeral(amount).multiply(100).value()
  } else {
    return moneyNumber(amount)
  }
}
