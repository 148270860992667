import React, { useState } from 'react'
import styles from './CheckEligibility.module.css'
import api from 'Utilities/Deprecated/api'
import QuizAnswerItem from './QuizAnswerItem'
import PointsTracker from './PointsTracker'
import { questionFor, optionsFor, numberOfSteps, pointsFor, bodyFor, Answer } from './QuizHelper'
import { Mode } from './LevelFundedModal'
import { FundingType } from 'Components/Plans/plan-subcomponents/PlanHelpers'
import { Group } from 'Utilities/Hooks/useStargate'
import ReactDOM from 'react-dom'

interface Props {
  group: Group
  callback: (mode: Mode) => void
}

const CheckEligibility: React.FC<Props> = ({ group, callback }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [answers, setAnswers] = useState<Answer[]>([]) // TODO:mxcl useList
  const [points, setPoints] = useState(0)

  const options = optionsFor(currentStep)

  return <div className={styles.container}>
    <h2>Assessing {FundingType.levelFunded} Eligibility</h2>
    <p className={styles.question}>{questionFor(currentStep)}</p>
    <div className={styles.flexContainer}>
      {options.map((option, index) => (
        <QuizAnswerItem key={index} option={option} callback={handleAnswerSelected}/>
      ))}
    </div>
    <ul className={styles.bulleted}>{renderStepNav().map((circle, index) =>
      <li key={index} className={styles.listItem}>{circle}</li>
    )}</ul>
    <hr></hr>
    <h2>Match</h2>
    <PointsTracker points={points}/>
  </div>

  function handleAnswerSelected(answer: Answer) {
    const nAnswers = [...answers]
    nAnswers[currentStep] = answer

    if (currentStep === numberOfSteps() - 1) {
      const mode = points > 0 ? Mode.LEVELFUNDED : Mode.TRADITIONAL

      const subject = `${group.name}: ${FundingType.levelFunded} Quiz`
      const body = bodyFor(nAnswers, group)
      const referrer = window.location.href

      api.sendSupportEmail(subject, body, referrer).catch(console.error)

      callback(mode)
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setAnswers(nAnswers)
      setCurrentStep(currentStep + 1)
      setPoints(points + pointsFor(answer))
    })
  }

  function handleStepNavClick(step: any) {
    return () => {
      const newAnswers = answers
      newAnswers.splice(step)
      let newPoints = 0
      for (const ans of newAnswers) {
        newPoints += pointsFor(ans)
      }

      ReactDOM.unstable_batchedUpdates(() => {
        setAnswers(newAnswers)
        setCurrentStep(step)
        setPoints(newPoints)
      })
    }
  }

  function renderStepNav() {
    const circles = []
    let step
    for (step = 0; step < numberOfSteps(); step++) {
      circles.push(<button key={step} className={styles.stepButton} onClick={handleStepNavClick(step)} disabled={step > currentStep} />)
    }
    return circles
  }
}

export default CheckEligibility
