import React from 'react'
import { useAppMode, AppMode } from 'Components/Stargate/TableOfContents'
import styles from './CartSummary.module.css'
import { individualsMode } from 'Utilities/config'
import { moneyString, moneyNumber } from 'Utilities/Plans/PremiumCalculators'
import * as api from 'Utilities/pharaoh'
import useToast from 'Utilities/Hooks/useToast'
import { PremiumSplits } from 'Components/Plans/plan-subcomponents/PlanHelpers'

interface Props {
  callback: () => void
  premiums: {
    medical: { split: PremiumSplits }
    dental: { split: PremiumSplits }
    vision: { split: PremiumSplits }
    life: { split: PremiumSplits }
  }
}

const CartSummary: React.FC<Props> = ({ callback, premiums }) => {
  const mode = useAppMode()
  const addToast = useToast()
  const buttonCopy = 'Finalize Plan Selections'
  const copy = 'All pricing details are illustrative and are subject to change when carriers give final pricing.'

  return <div className={styles.container}>
    <div className={styles.flexContainer}>
      <div className={styles.summary}>
        <p className={styles.summaryHeader}>Cart Summary</p>
        <p className={styles.summaryDescription}>{copy}</p>
      </div>
      <div className={styles.costArea}>
        <p className={styles.estCostHeader} style={{ marginBottom: 20 }}>Estimated Costs</p>
        {estimatedCost('Medical', medicalPremium())}
        {estimatedCost('Ancillary', ancillaryPremium())}
        <div className={styles.separator}></div>
        <p className={styles.summaryTotalCost}>
          {totalPremiumString()}
          <span className={styles.summaryMo}>/mo</span>
        </p>
      </div>
    </div>
    <button className={styles.nextButton} onClick={callback}>{buttonCopy}</button>
  </div>

  function totalPremiumString() {
    const premium = moneyString(medicalPremium() + ancillaryPremium())
    if (mode === AppMode.employee && individualsMode()) {
      api.v3.users().payments.premium({ amount: premium }).catch(addToast)
    }
    return premium
  }

  function estimatedCost(planType: 'Medical' | 'Prosper' | 'Ancillary', cost: string | number) {
    let mo
    // show no contribution to incentivize scrolling down to look
    if (planType === 'Prosper' && mode === AppMode.employee) {
      cost = 'Included'
      mo = ''
      // We have data for this planType
    } else {
      cost = moneyString(cost)
      mo = '/mo'
    }

    const estimatedCopy = planType === 'Prosper' ? '' : 'Estimated '

    return <div className={styles.flexContainer}>
      <p className={styles.summaryPlanCostHeader}>{`${estimatedCopy}${planType} Plan:`}</p>
      <div>
        <p className={styles.summaryPlanCost}>
          {cost}
          <span className={styles.summaryMo}>{mo}</span>
        </p>
      </div>
    </div>
  }

  function medicalPremium() {
    if (!premiums || !premiums.medical) return 0 // prosper only probs
    return moneyNumber(premiums.medical.split.ee)
  }

  function ancillaryPremium() {
    let rv = 0
    if (premiums) {
      if (premiums.dental) rv += moneyNumber(premiums.dental.split.ee)
      if (premiums.vision) rv += moneyNumber(premiums.vision.split.ee)
      if (premiums.life) rv += moneyNumber(premiums.life.split.ee)
    }
    return moneyNumber(rv)
  }
}

export default CartSummary
