import React from 'react'
import Component from 'Routes/dashboard/agency/employees/ID'
import useUser from 'Utilities/Hooks/useUser'
import Error from 'Components/Primitives/Error'
import Loader from 'Components/Rudimentary/Loader'

const DashboardEmployee: React.SFC = () => {
  const user = useUser()

  if (user.loading) return <Loader />
  if (user.error) return <Error error={user.error} />

  return <Component id={user.value!.id} />
}

export default DashboardEmployee
