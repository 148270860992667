import React from 'react'
import ProfileSection from 'Components/Anubis/ProfileSection'
import * as api from 'Utilities/pharaoh'
import Error from 'Components/Primitives/Error'
import Loader from 'Components/Rudimentary/Loader'
import { useAsync } from 'react-use'
import { useHistory } from 'react-router-dom'
import { get } from 'Utilities/fetch++'
import { MedicalPlan } from 'Utilities/pharaoh.types'
import EmployerPlansBreakdown, { PlansBreakdown, getPlansBreakdown } from 'Components/Anubis/EmployerPlansBreakdown'
import { Route } from 'Utilities/Route'

type Props = {
  id: string
  group: any
}

export const healthcareDetailsTitle = 'Healthcare Plans'

const GroupsProfileMedicalPlansSection: React.FC<Props> = ({ id, group }) => {
  const async = useAsync(() =>
    Promise.all([
      get(`/v3/groups/${id}/plans`),
      api.v1.groups(id).users.GET()
    ]), [id])
  const history = useHistory()

  const children = () => {
    if (async.loading) return <Loader />
    if (async.error) return <Error error={async.error} />
    const [plans, members] = async.value!
    if (plans.length === 0) return <p>No plans selected.</p>
    const data: PlansBreakdown[] = getPlansBreakdown('medical', plans as MedicalPlan[], members)
    return <EmployerPlansBreakdown planBreakdowns={data} contribution={group.contributions.medical} reportFileBasename='health-benefits-breakdown' />
  }

  return <ProfileSection
    name={ healthcareDetailsTitle }
    addButtonName="Add Healthcare Plans"
    expanded={false}
    onAddButton={() => {
      localStorage.overrideGroupID = id
      history.push(Route.erStargatePlans)
    }}
  >
    { children() }
  </ProfileSection>
}

export default GroupsProfileMedicalPlansSection
