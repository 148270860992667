/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable camelcase */
import React, { useState } from 'react'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import { useAsync, useSetState, useToggle } from 'react-use'
import { get, put, delete_ } from 'Utilities/fetch++'
import Error from 'Components/Primitives/Error'
import Loader from 'Components/Rudimentary/Loader'
import styles from './index.module.scss'
import { useAppMode, AppMode } from 'Components/Stargate/TableOfContents'
import ContributionAmount from 'Components/Stargate/Contribution/ContributionAmount'
import { capitalize, startCase } from 'lodash'
import Plan from 'Components/Plans/plan-subcomponents/Plan'
import { label, Label, showLifePlans } from 'Utilities/config'
import DentalPlan from 'Components/Plans/DentalPlan'
import VisionPlan from 'Components/Plans/VisionPlan'
import LifePlan from 'Components/Plans/LifePlan'
import useToast from 'Utilities/Hooks/useToast'
import { $enum } from 'ts-enum-util'
import useFullContentArea from 'Utilities/Hooks/useFullContentArea'
import { v3 } from 'Utilities/pharaoh'

enum PlanTypes {
  DENTAL = 'dental',
  VISION = 'vision',
  LIFE = 'life',
  LTD = 'disability'
}

interface Plan {
  // TODO no any
  plan: any & {type: PlanTypes}
  rate: any
}

const EEShopPlansAncillary: React.FC<PrivateWizardPageProps> = ({ stargate, onwards }) => {
  const { group, user } = stargate
  const mode = useAppMode()
  const { value: plans, loading, error } = useAsync(async() => await get(mode === AppMode.employee
    ? `/v3/groups/${group?.id}/users/${user?.id}/plans/options/ancillary`
    : `/v3/groups/${group?.id}/plans/options/ancillary`) as Plan[]
  )

  useFullContentArea()

  if (loading) return <Loader />
  if (error) return <Error error={error} />
  if (!plans || plans.length <= 0) {
    // sometimes we can offer nothing, so skip
    onwards(Promise.resolve())
    return <Loader />
  }

  const visibleTypes = new Set((plans || []).map(({ plan }) => plan.type))
  if (!showLifePlans() || mode === AppMode.employer) visibleTypes.delete(PlanTypes.LIFE)

  const visibleOrderedTypes = $enum(PlanTypes).getValues().filter(type => visibleTypes.has(type))
  const visiblePlans = plans.filter(({ plan }) => visibleTypes.has(plan.type))

  return <Content stargate={stargate} onwards={onwards} plans={visiblePlans} tabs={visibleOrderedTypes} />
}

interface Props extends PrivateWizardPageProps {
  plans: Plan[]
  tabs: PlanTypes[]
}

const Content: React.FC<Props> = ({ stargate, onwards, plans, tabs }) => {
  const { user, userTier, members, group } = stargate
  const [visiblePlanType, setVisiblePlanType] = useState(tabs[0]) // NOTE safe bang because we don’t use this if no value
  const [contribution, setContribution] = useSetState(defaultContributions())
  const [selections, setSelections] = useSetState(defaultSelections())
  const mode = useAppMode()
  const addToast = useToast()
  const [disabled, setDisabled] = useToggle(false)

  return <>
    <div className={styles.header}>
      <div className={styles.mainContainer}>
        <span className={styles.plansTitle}>{titleCopy()}</span>
        <div className={styles.bottomRow}>
          <div className={styles.aboutAndSelectors}>
            <span className={styles.aboutText}>
              {aboutCopy()}
            </span>
            <div className={styles.planType}>
              {renderTabs()}
            </div>
          </div>
          <div className={styles.contribution}>
            {contributionForm()}
          </div>
          <div className={styles.checkoutArea}>
            <button style={{ margin: 0, float: 'right' }} className={styles.nextButton} onClick={go} disabled={disabled}>Next</button>
          </div>
        </div>
      </div>
    </div>
    <div className={styles.mainContainer}>
      <h2 style={{ margin: '25px 0 0' }}>{h2()} Plans</h2>
      <p className={styles.numberOfPlans}>{plansSummary()}</p>
      {plans!.map(render)}
    </div>
  </>

  function plansSummary() {
    const N = plans.filter(({ plan }) => plan.type === visiblePlanType).length
    return `Showing ${N} plan${N === 1 ? '' : 's'}`
  }

  function render(plan: Plan) {
    if (plan.plan.type !== visiblePlanType) return null

    const isSelected = mode === AppMode.employee && selections[visiblePlanType] === plan.plan.id

    // TODO no any
    const props: any = {
      selected: isSelected,
      contribution: { value: contribution[visiblePlanType] || '0%', isEquitable: false },
      key: plan.plan.id,
      plan: plan.plan,
      rates: plan.rate,
      selectHandler: mode === AppMode.employee ? toggle : undefined
    }

    switch (mode) {
    case AppMode.employee:
      props.employeeTier = userTier
      break
    case AppMode.employer:
      props.members = members
    }

    switch (plan.plan.type) {
    case PlanTypes.DENTAL:
      return <DentalPlan {...props} />
    case PlanTypes.VISION:
      return <VisionPlan {...props} />
    case PlanTypes.LIFE:
      return <LifePlan {...props} />
    case PlanTypes.LTD:
      return null // TODO
    }

    async function toggle() {
      try {
        if (!isSelected) {
          await put(`/users/plans/${plan.plan.type}/${plan.plan.id}`)
        } else {
          await delete_(`/users/plans/${plan.plan.type}`)
        }
        setSelections({ [plan.plan.type]: isSelected ? undefined : plan.plan.id })
      } catch (error) {
        addToast(error)
      }
    }
  }

  function h2() {
    switch (visiblePlanType) {
    case PlanTypes.LIFE:
      if (label() === Label.blacksmith) {
        return 'Life, Accidental Death and Dismemberment'
      }
      // eslint-disable-next-line no-fallthrough
    case PlanTypes.DENTAL:
    case PlanTypes.VISION:
      return startCase(visiblePlanType)
    case PlanTypes.LTD:
      return 'LTD'
    }
  }

  function go() {
    const index = tabs.indexOf(visiblePlanType)
    if (index !== -1) {
      const nextType = tabs[index + 1]
      if (nextType) {
        return setVisiblePlanType(nextType)
      }
    }

    // ONWARDS!!

    switch (mode) {
    case AppMode.employer: {
      setDisabled(true)
      const contributions = {
        dental: { value: contribution[PlanTypes.DENTAL] || '0%', isEquitable: false },
        vision: { value: contribution[PlanTypes.VISION] || '0%', isEquitable: false },
        life: contribution[PlanTypes.LIFE],
        disability: contribution[PlanTypes.LTD]
      }
      onwards((async() => {
        try {
          await v3.groups(group?.id).PUT({ contributions })
        } finally {
          setDisabled(false)
        }
      })())
      break
    }
    case AppMode.employee:
      onwards(Promise.resolve())
    }
  }

  function contributionForm() {
    if (mode !== AppMode.employer) return null

    return <div className={styles.contributionForm}>
      <span className={styles.copy}>
        How much do you want to<br />
        contribute to {capitalize(visiblePlanType)} Plans?
      </span>
      <div className={styles.contributionInput}>
        <ContributionAmount
          value={contribution[visiblePlanType]}
          onChange={contribution => setContribution({ [visiblePlanType]: contribution })}
        />
      </div>
    </div>
  }

  function renderTabs() {
    return tabs.map(type => {
      const className = visiblePlanType === type ? styles.planTypeButtonSelected : styles.planTypeButton
      return <button onClick={() => setVisiblePlanType(type)} className={className} key={type}>
        {titleForType(type)}<br/>
        { mode !== AppMode.employer &&
          <span className={styles.smallContrib}>
            {cont()}
          </span>
        }
      </button>

      function cont() {
        const value = contribution[type]
        if (!value || value === '0%' || value === '$0') {
          return '\u00a0'
        } else {
          return `${value} Contribution`
        }
      }
    })
  }

  function titleForType(type: PlanTypes): string {
    if (label() === Label.blacksmith) {
      switch (type) {
      case PlanTypes.LIFE:
        return 'Life AD&D'
      case PlanTypes.LTD:
        return 'LTD'
      }
    }
    return type
  }

  function titleCopy() {
    switch (mode) {
    case AppMode.employer:
      return 'Enhance your Offer'
    case AppMode.employee:
      return 'Enhance your Coverage'
    }
  }

  function aboutCopy() {
    switch (mode) {
    case AppMode.employer:
      return <>
        Employees can choose from the ancillary plans below.<br />
        Be sure to add your contribution for both Vision and Dental by using the tabs below.
      </>
    case AppMode.employee:
      return <>
        Select from the following plans to round out your health coverage.<br />
        Click next when you’re done.
        {blacksmithExtra()}
      </>
    }

    function blacksmithExtra() {
      if (label() !== Label.blacksmith) return null
      return <p style={{ color: 'yellow' }}>
        To complete your enrollment, you must either enroll (check) or
        waive (uncheck) the dental and long‐term disability (LTD) plans.
      </p>
    }
  }

  function defaultContributions() {
    const rv: { [key in PlanTypes]?: string } = {}
    rv[PlanTypes.DENTAL] = group?.dental_contribution
    rv[PlanTypes.VISION] = group?.vision_contribution
    rv[PlanTypes.LIFE] = group?.life_contribution
    rv[PlanTypes.LTD] = group?.disability_contribution
    return rv
  }

  function defaultSelections() {
    const selected: {[key in PlanTypes]?: string } = {}
    selected[PlanTypes.DENTAL] = user?.enrolled_dental_plan_id
    selected[PlanTypes.VISION] = user?.enrolled_vision_plan_id
    selected[PlanTypes.LIFE] = user?.enrolled_life_plan_id
    selected[PlanTypes.LTD] = user?.enrolled_disability_plan_id
    return selected
  }
}

export default EEShopPlansAncillary
