import React, { useState } from 'react'
import styles from 'Components/Stargate/ShowPlans/Components/ShowPlansHeader.module.scss'
import scStyles from './SavingsCalculatorModal.module.scss'
import CandorInput from 'Components/Rudimentary/CandorInput'
import { useForm } from 'react-hook-form'
import { moneyString } from 'Utilities/Plans/PremiumCalculators'
import numeral from 'numeral'
import StargateModal from 'Components/Stargate/StargateModal'

const SavingsCalculator: React.FC = () => {
  const [showModal, toggleModal] = useState(false)

  return <div className={styles.savingsCalculator}>
    <SavingsCalculatorModal isOpen={showModal} onClose={() => toggleModal(false)}/>
    <h2>Savings Calculator</h2>
    <button onClick={() => toggleModal(true)}>Click Here</button>
  </div>
}

export default SavingsCalculator

interface Props {
  isOpen: boolean
  onClose: () => void
}

const SavingsCalculatorModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { register, setValue, watch } = useForm()
  const currentPremium = watch('currentPremium')
  const currentMOOP = watch('currentMOOP')
  const desiredPremium = watch('desiredPremium')
  const desiredMOOP = watch('desiredMOOP')

  function strip(value: string) { return numeral(value).value() }
  function pretty(value: string | number | undefined) { return value ? moneyString(value) : '—' }

  function minSpend(premium: any) { return strip(premium) * 12 }
  function maxSpend(premium: any, moop: any) {
    if (!premium || !moop) { return 0 }
    return minSpend(premium) + strip(moop)
  }
  function avgYearlyCost(premium: any, moop: any) {
    if (!premium || !moop) { return 0 }
    return (minSpend(premium) + maxSpend(premium, moop)) / 2
  }
  function compare(outsideCost: number, candorCost: number) {
    if (!outsideCost || !candorCost) { return undefined }
    const savings = numeral(outsideCost).subtract(candorCost).divide(outsideCost)
    return savings.value() > 0 ? savings.format('0.[00]%') : undefined
  }

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault()
    const { name, value } = e.target
    /*
      Limit input to 6 figures to avoid layout issues when numbers get to large,
      and no one should have premiums/moop that large
     */
    const value2 = strip(value.substring(0, 8))
    setValue(name, value2 ? moneyString(value2) : '')
  }

  // TODO Replace with TrueCostOfCareCalculator
  const currentMin = minSpend(currentPremium)
  const currentMax = maxSpend(currentPremium, currentMOOP)
  const desiredMin = minSpend(desiredPremium)
  const desiredMax = maxSpend(desiredPremium, desiredMOOP)
  const withoutCandor = avgYearlyCost(currentPremium, currentMOOP)
  const candorSavingsPercentage = 0.65
  const withCandor = avgYearlyCost(desiredPremium, desiredMOOP) * candorSavingsPercentage
  const savings = compare(withoutCandor, withCandor)

  return <StargateModal
    isOpen={isOpen}
    styles={{ width: 840, padding: 20 }}
    onRequestClose={onClose}
  >
    <header className={scStyles.header}>
      <h1>Calculate your average savings</h1>
      {/* TODO when we have time <button>Share Savings!</button> */}
      <p>Update your amounts to see your possible savings.</p>
    </header>
    <div className={scStyles.calculatorContainer}>
      <div>
        <h2>Your Current Plan</h2>
        <CandorInput name='currentPremium' ref={register} placeholder='Monthly Premium' onChange={onInputChange}/>
        <CandorInput name='currentMOOP' ref={register} placeholder='Max Out-of-Pocket' onChange={onInputChange}/>
        <h2>Minimum spend</h2>
        <h3>{pretty(currentMin)}</h3>
        <h2>Maximum spend</h2>
        <h3>{pretty(currentMax)}</h3>
      </div>
      <div>
        <h2>Your Desired Plan</h2>
        <CandorInput name='desiredPremium' ref={register} placeholder='Monthly Premium' onChange={onInputChange}/>
        <CandorInput name='desiredMOOP' ref={register} placeholder='Max Out-of-Pocket' onChange={onInputChange}/>
        <h2>Minimum spend</h2>
        <h3>{pretty(desiredMin)}</h3>
        <h2>Maximum spend</h2>
        <h3>{pretty(desiredMax)}</h3>
      </div>
      <div>
        <h2>Average cost for use / year</h2>
        <div>
          <p>Without our platform</p>
          <h1>{pretty(withoutCandor)}</h1>
        </div>
        <div>
          <p>With our platform</p>
          <h1>{pretty(withCandor)}</h1>
          { savings && <p>{savings} savings</p> }
        </div>
      </div>
    </div>

  </StargateModal>
}
