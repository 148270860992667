import active from './lf_active_icon_white.svg'
import activeLittle from './lf_active_little_icon_white.svg'
import activeVery from './lf_active_very_icon_white.svg'
import doctorLess from './lf_doctor_lessVisits_icon_white.svg'
import doctorMore from './lf_doctor_moreVisits_icon_white.svg'
import heart from './lf_heart_icon_white.svg'
import heartHappy from './lf_heart_happy_icon_white.svg'
import heartSad from './lf_heart_sad_icon_white.svg'
import no from './lf_no_icon_white.svg'
import { Group } from 'Utilities/Hooks/useStargate'

export interface Answer {
  copy: string
  icon: string
  weight: number
}

const quiz = [
  {
    question: 'What would you consider the overall health of your company?',
    options: [
      {
        copy: 'Not as healthy',
        icon: heartSad,
        weight: -1
      },
      {
        copy: 'Relatively healthy',
        icon: heart,
        weight: 0
      },
      {
        copy: 'Extremely Healthy',
        icon: heartHappy,
        weight: 1
      }
    ]
  },
  {
    question: 'Do any of your employees have major health concerns?',
    options: [
      {
        copy: 'Yes',
        icon: heartSad,
        weight: -1
      },
      {
        copy: 'No',
        icon: heartHappy,
        weight: 1
      }
    ]
  },
  {
    question: 'How active are your employees?',
    options: [
      {
        copy: 'Not active',
        icon: no,
        weight: -1
      },
      {
        copy: 'Not very active',
        icon: activeLittle,
        weight: -0.5
      },
      {
        copy: 'Quite active',
        icon: active,
        weight: 0.5
      },
      {
        copy: 'Constantly moving',
        icon: activeVery,
        weight: 1
      }
    ]
  },
  {
    question: 'How often are your employees visiting the doctor?',
    options: [
      {
        copy: 'More than once a month',
        icon: doctorMore,
        weight: -1
      },
      {
        copy: 'Once a month or less',
        icon: doctorLess,
        weight: 1
      }
    ]
  },
  {
    question: 'Does your company promote employee wellness or promote wellness programs',
    options: [
      {
        copy: 'No',
        icon: no,
        weight: -1
      },
      {
        copy: 'Yes',
        icon: heart,
        weight: 1
      }
    ]
  }
]

function pointsFor(answer: Answer) {
  // Max points you can earn in the quiz are 50
  const pointsPerQ = 50 / quiz.length
  return answer.weight * pointsPerQ
}

function numberOfSteps() {
  return quiz.length
}

function questionFor(step: number) {
  return quiz[step].question
}

function optionsFor(step: number) {
  return quiz[step].options
}

function bodyFor(answers: Answer[], group: Group) {
  let rv = `
  Group Name: ${group.name}
  Group ID: ${group.id}
  `
  answers.forEach((ans, index) => {
    rv += `

    Question: ${questionFor(index)}
    Answer: ${ans.copy}`
  })
  return rv
}

export { pointsFor, numberOfSteps, questionFor, optionsFor, bodyFor }
