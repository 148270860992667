import React from 'react'
import PlanTitle from './plan-subcomponents/PlanTitle'
import PlanSnapshotSection from './plan-subcomponents/PlanSnapshotSection'
import PlanBox from './plan-subcomponents/PlanBox'
import styles from './ProsperPlan.module.css'
import { moneyString } from 'Utilities/Plans/PremiumCalculators'
import { IndividualModeProsperName } from './EERightNowPlan'
import Plan, { PlanBody } from './plan-subcomponents/Plan'
import { individualsMode } from 'Utilities/config'
import { useAppMode, AppMode } from 'Components/Stargate/TableOfContents'

interface Props {
  isIncluded: boolean
  nonWaivedMemberCount: number
}

const ProsperPlan: React.FC<Props> = ({ isIncluded, nonWaivedMemberCount }) => {
  return <Plan>
    <PlanTitle
      carrier={'Prosper'}
      planName={individualsMode() ? IndividualModeProsperName : 'Prosper Benefits'}
      includesProsperBenefits={false}
    />
    <PlanBody>
      {prosperSnapshot()}
    </PlanBody>
  </Plan>

  function prosperSnapshot() {
    return <>
      <PlanSnapshotSection sectionWidth={0.33}>
        <PlanBox className={styles.prosperBox}>
          <PlanBoxInner isIncluded={isIncluded} nonWaivedMemberCount={nonWaivedMemberCount} />
        </PlanBox>
      </PlanSnapshotSection>
      <PlanSnapshotSection sectionWidth={0.67}>
        <PlanBox className={styles.prosperBox}>
          <p className={styles.benefit}><span>24/7/365</span> access to board certified physician via phone or web.</p>
          <p className={styles.benefit}>Help in negotiating expensive medical bills through dedicated <span>Claims Concierge Service.</span></p>
          <p className={styles.benefit}>Medical services shopping tool for <span>complete pricing transparency.</span></p>
          <p className={styles.benefit}>Support navigating the medical process with <span>health advocacy service.</span></p>
          <p className={styles.benefit}>Personalized enrollment support and customer service. <span>We put your needs first!</span></p>
        </PlanBox>
      </PlanSnapshotSection>
    </>
  }
}

const PlanBoxInner: React.FC<Props> = (props) => {
  const mode = useAppMode()
  const prosperIncludedCost = 34.95
  const prosperOnlyCost = 34.95

  if (mode === AppMode.employer) {
    let employeesString = props.nonWaivedMemberCount > 1 ? 'employees' : 'employee'
    employeesString = `${props.nonWaivedMemberCount} ${employeesString}`

    if (props.isIncluded) {
      const cost = moneyString(prosperIncludedCost * props.nonWaivedMemberCount)
      return <>
        <p className={styles.prosperLabel}>This plan is</p>
        <p className={styles.prosperLargeFigure}>Included</p>
        <p className={styles.prosperLabel}>with healthcare coverage</p>
        <p className={styles.prosperSavingsLabel}>You are saving <span>{cost}/mo</span><br/>for {employeesString}</p>
      </>
    } else {
      const cost = moneyString(prosperOnlyCost * props.nonWaivedMemberCount)
      return <>
        <p className={styles.prosperLabel}>Employer Pays</p>
        <p className={styles.prosperLargeFigure}>{cost}<span className={styles.prosperMo}>/mo</span></p>
        <p className={styles.prosperLabel}>For {employeesString}</p>
      </>
    }
  } else {
    if (props.isIncluded) {
      return <>
        <p className={styles.prosperLabel}>This plan is</p>
        <p className={styles.prosperLargeFigure}>Included</p>
        <p className={styles.prosperLabel}>with healthcare coverage</p>
        <p className={styles.prosperSavingsLabel}>You are saving <span>{moneyString(prosperIncludedCost, 2)}/mo</span></p>
      </>
    } else {
      return <>
        <p className={styles.prosperEmployerPaysLabel}>Your Employer is<br/>covering the cost<br/>of this plan</p>
        <p className={styles.prosperSavingsLabel}>You are saving <span>{moneyString(prosperIncludedCost, 2)}/mo</span></p>
      </>
    }
  }
}

export default ProsperPlan
