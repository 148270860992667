import React, { useState } from 'react'
import styles from './index.module.css'
import * as api from 'Utilities/pharaoh'
import AddButton from 'Components/Anubis/AddButton'
import AddNewClient from 'Components/Anubis/AddNewClient'
import ProfileSection, { ShowAll } from 'Components/Anubis/ProfileSection'
import { useHistory, Link } from 'react-router-dom'
import { Route } from 'Utilities/Route'
import { useAsyncRetry, useToggle } from 'react-use'
import { AsyncTable, SortDirection } from 'Components/Rudimentary/Table'
import _ from 'lodash'
import SearchBar from 'Components/Anubis/SearchBar'
import UploadFileModal from 'Components/Anubis/UploadFileModal'
import useUser from 'Utilities/Hooks/useUser'
import { Contact } from 'Utilities/pharaoh.types'
import * as config from 'Utilities/config'
import { Census } from 'Utilities/config'

interface Props {
  flagged: boolean
}

const DashboardAgencyGroups: React.FC<Props> = ({ flagged }) => {
  const groups = useAsyncRetry(async() => (await api.v2.brokers.groups().GET()).groups as Group[])
  const [query, setQuery] = useState('')
  const [isBookModalOpen, toggleBookModal] = useToggle(false)
  const history = useHistory()
  const copy = "Please use the downloadable .xlsx template below to upload your existing client information. You will need information about your client's business, as well as the employees qualified to receive health insurance through your client."
  const user = useUser()

  const count = groups.value ? `(${filter(groups.value).length})` : ''

  return <div className={styles.mainContainer}>
    <UploadFileModal
      title='Existing Client Upload'
      isOpen={isBookModalOpen}
      copy={copy}
      onRequestClose={toggleBookModal}
      onSubmit={handleExistingClientUpload}
      successMessage='Your book of business has been uploaded'
      template={{
        href: config.censusURL(Census.super),
        name: config.censusFilename(Census.super)
      }}
    />
    <div className={styles.head}>
      <div>
        <h1>Enrolled Clients</h1>
        <AddButton onClick={toggleBookModal}>Add Existing Client</AddButton>
        <AddNewClient/>
      </div>
      <SearchBar
        placeholder='Search Clients'
        query={query}
        setQuery={setQuery}
      />
    </div>
    <ProfileSection name={`Clients ${count}`} expanded style={{ marginTop: 0 }}>
      <ShowAll onChange={() => { setQuery('') }} checked={!query}/>
      <AsyncTable
        async={groups}
        mangle={filter}
        content={(key, value) => {
          switch (key) {
          case 'renewal':
            return api.utcMidnightToLocalMidnight(value)
          case 'associations':
            return value.map((ass: any) => <Link key={ass.id} to={`${Route.agencyDashboardAssociations}/${ass.id}`}>{ass.acronym || ass.name}</Link>)
          case 'pipelineStatus':
          case 'applicationStatus':
            return _.startCase(value)
          case 'name':
            return value || <i>Unnamed</i>
          }
        }}
        selectAction={row => push(row.id)}
        selectable={(row) => !!row.id}
        order={order()}
        heading={key => {
          switch (key) {
          case 'contact':
            return 'Client / Lead Name'
          case 'name':
            return 'Group'
          case 'pipelineStatus':
            return 'Status'
          case 'applicationStatus':
            return 'App. Status'
          case 'flagged':
            return 'Flagged'
          case 'eeCount':
            return '# EEs'
          }
        }}
        truncate={key => {
          switch (key) {
          case 'contact':
          case 'name':
            return true
          default:
            return false
          }
        }}
        width={key => {
          switch (key) {
          case 'contact':
          case 'name':
          case 'associations':
            return '20%'
          case 'renewal':
          case 'pipelineStatus':
            return '10%'
          case 'applicationStatus':
            return '13%'
          case 'flagged':
            return '7%'
          case 'eeCount':
            return '5%'
          }
        }}
        headerToolTip={key => {
          switch (key) {
          case 'applicationStatus':
            return 'Carrier Application Status'
          case 'pipelineStatus':
            return _.startCase(key)
          }
        }}
        sortable={['contact', 'name', 'pipelineStatus', 'applicationStatus', 'renewal', 'eeCount', 'flagged']}
        defaultSort='name'
        defaultSortDirection={key => {
          switch (key) {
          case 'renewal':
          case 'contacted':
          case 'eeCount':
            return SortDirection.descending
          default:
            return SortDirection.ascending
          }
        }}
        reportFileBasename='clients'
        reportContent={(key, value) => {
          switch (key) {
          case 'associations':
            return value.map((ass: any) => ass.codename || ass.name).join(', ')
          case 'flagged':
            return value
          }
        }}
      />
    </ProfileSection>
    {(user.value || !config.obeliskMode()) && <div style={{ marginTop: '3rem' }}>
      <h1>Agency Shop</h1>
      <p>New clients can sign‑up independently at the following link, new sign‑ups will appear above.</p>
      <p>{shopLink()}</p>
    </div>}
  </div>

  function shopLink() {
    const path = !config.obeliskMode()
      ? '/shop'
      : `/shop/${user.value!.slug}`
    const host = window.location.host
    const prefix = process.env.REACT_APP_BASENAME || ''
    const display = `${window.location.protocol}//${host}${prefix}${path}`
    return <Link to={path}>{display}</Link>
  }

  async function handleExistingClientUpload(data: any) {
    // Catches in UploadFileModal
    await api.v2.groups().xlsx(data.file[0])
    groups.retry()
  }

  function filter(input: Group[]) {
    return input.filter((group: Group) => {
      if (flagged && !group.flagged) return false
      if (!query.trim()) return true
      const name = group.name.toLowerCase()
      const regex = RegExp(`.*${query}.*`)
      return regex.test(name)
    })
  }

  function push(id: string) {
    history.push({
      pathname: `${Route.agencyDashboardClients}/${id}`,
      state: { group: groups.value!.find((g: any) => g.id === id) }
    })
  }

  function order() {
    const keys: Array<keyof Group> = ['contact', 'name', 'applicationStatus', 'renewal']
    if (config.associationsMode()) keys.push('associations')
    return keys.concat(['eeCount', 'flagged'])
  }
}

interface Group {
  id: string
  name: string
  contact?: Contact
  associations: [{id: string, name: string, acronym?: string}]
  contacted: Date | null
  renewal: Date | null
  pipelineStatus: string
  applicationStatus: string
  flagged: boolean
  eeCount: number
}

export default DashboardAgencyGroups
