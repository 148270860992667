import React from 'react'
import styles from './QuizAnswerItem.module.css'
import { Answer } from './QuizHelper'

interface Props {
  option: Answer
  callback: (option: Answer) => void
}

const QuizAnswerItem: React.FC<Props> = props => {
  return (
    <div className={styles.option}>
      <button onClick={onClick}>
        <img src={props.option.icon} alt=''/>
      </button>
      <div>{props.option.copy}</div>
    </div>
  )
  function onClick() {
    props.callback(props.option)
  }
}

export default QuizAnswerItem
