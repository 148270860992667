import React from 'react'
import styles from './index.module.scss'
import ContactSnapshot from 'Components/Anubis/ContactSnapshot'
import ContactSection from 'Components/Anubis/ContactSection'
import TodoList from 'Components/Anubis/TodoList'
import NotesSection from 'Components/Anubis/NotesSection'
import AssociationProfileGroupsSection from './AssociationProfileGroupsSection'
import { useAsync } from 'react-use'
import * as api from 'Utilities/pharaoh'
import Error from 'Components/Primitives/Error'
import Loader from 'Components/Rudimentary/Loader'
import { Route } from 'Utilities/Route'
import BackTo from 'Components/Anubis/BackTo'

type Props = {
  id: string
}

const AssociationProfile: React.FC<Props> = ({ id }) => {
  const async = useAsync(api.v1.associations(id).GET, [id])
  if (async.loading) return <Loader />
  if (async.error) return <Error error={async.error} />

  const contactSnapshotContent = [
    {
      before: <h2>{async.value.enrolledCount}</h2>,
      after: <span>Total # Enrolled</span>
    },
    {
      before: <h2>{async.value!.memberGroupCount}</h2>,
      after: <span># Member Groups</span>
    }
  ]

  const association = async.value!.association

  const data = {
    name: association.name,
    address: {
      street1: association.address1,
      street2: association.address2,
      city: association.city,
      zip: association.zipCode
    },
    contact: {
      name: association.contactName,
      type: 'Association',
      email: association.contactEmail,
      phone: association.phoneNumber
    }
  }

  return <>
    <BackTo route={Route.agencyDashboardAssociations}>Back to Associations</BackTo>
    <div className={styles.association}>
      <ContactSnapshot content={contactSnapshotContent} name={association.name} />
      <ContactSection data={data} />
      <TodoList id={id} />
    </div>
    <div>
      <hr className={styles.separator}/>
      <AssociationProfileGroupsSection id={id} />
      <hr className={styles.separator}/>
      <NotesSection targetID={id} />
    </div>
  </>
}

export default AssociationProfile
