import React, { useState } from 'react'
import styles from './SendPlansModal.module.css'
import { uniq, sortBy } from 'lodash'
import NextButton, { ButtonStates } from 'Components/Stargate/NextButton/NextButton'
import StargateModal from 'Components/Stargate/StargateModal'
import { Member } from 'Utilities/Hooks/useStargate'
import ReactDOM from 'react-dom'
import useToast from 'Utilities/Hooks/useToast'

interface Props {
  members: Member[]
  isOpen: boolean
  onClose: () => void
  callback: (email: string) => Promise<any>
}

const SendPlansModal: React.FC<Props> = ({ members, isOpen, onClose, callback }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedMember, setSelectedMember] = useState<Member>()
  const [buttonState, setButtonState] = useState<ButtonStates>('disabled')
  const orderedMembers = getOrderedMembers()
  const addToast = useToast()

  return <StargateModal isOpen={isOpen} styles={style} onRequestClose={onClose}>
    <form className={styles.container} onSubmit={handleSendPlans}>
      <h2>Request a Review</h2>
      <p>Give access to the lead contact from the company to review these plans, by selecting a contact from the census:</p>
      <input
        className={styles.searchBar}
        value={searchTerm}
        onChange={handleSearchChange}
        name='searchTerm'
        placeholder='Type name to search'
      />
      <div className={styles.membersListContainer}>
        <div className={styles.memberList}>
          { orderedMembers.map((member, index) => {
            const isUnique = uniq(orderedMembers).length === 1
            const isSelected = selectedMember ? member.id === selectedMember.id : false
            return <MemberItem member={member} isUnique={isUnique} isSelected={isSelected} onClick={handleMemberSelect} key={index}/>
          }) }
        </div>
        <div className={styles.greyGradient}></div>
      </div>
      <NextButton
        type='submit'
        value={'Send Invitation'}
        className={styles.sendButton}
        controlled={true}
        state={buttonState}
      />
    </form>
  </StargateModal>

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.target.value)
  }

  function getOrderedMembers() {
    let hasMemberWithSearchTerm = false
    let orderedMembers
    if (!searchTerm || searchTerm === '') {
      orderedMembers = members
    } else {
      orderedMembers = sortBy(members, member => {
        const nameMatchesSearchTerm = member.name.toLowerCase().includes(searchTerm.toLowerCase())
        if (nameMatchesSearchTerm) { hasMemberWithSearchTerm = true }
        return nameMatchesSearchTerm
      })
    }

    if (hasMemberWithSearchTerm) {
      orderedMembers.reverse()
    }
    return orderedMembers
  }

  function handleMemberSelect(member: Member) {
    ReactDOM.unstable_batchedUpdates(() => {
      if (selectedMember?.id === member.id) {
        setSelectedMember(undefined)
        setSearchTerm('')
        setButtonState('disabled')
      } else {
        setSelectedMember(member)
        setSearchTerm(member.name)
        setButtonState('')
      }
    })
  }

  async function handleSendPlans(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault() // don't refresh the page
    setButtonState('loading')
    if (!selectedMember || !selectedMember.email) {
      setButtonState('error')
      return Promise.reject(new Error("No member selected or email doesn't exist"))
    }
    try {
      await callback(selectedMember.email)
      addToast('Email sent successfully!', 'info')
      setSelectedMember(undefined)
      setSearchTerm('')
      setButtonState('disabled')
    } catch (error) {
      addToast(error)
    }
  }
}

interface Props2 {
  isSelected: boolean
  member: Member
  onClick: (member: Member) => void
  isUnique: boolean
}

const MemberItem: React.FC<Props2> = props => {
  return <button
    type='button'
    className={`${styles.memberItem} ${props.isSelected ? styles.memberItemSelected : styles.memberItemDeselected}`}
    onClick={() => props.onClick(props.member)}
  >
    {props.member.name}<br/>
    {!props.isUnique && <span>{props.member.email}</span>}
  </button>
}

const style = {
  background: '#F8F8F8',
  height: '580px',
  overflow: 'hidden'
}

export default SendPlansModal
