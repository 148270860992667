import React, { useState } from 'react'
import mjmLogo from 'Assets/MJMLogo.png'
import { Link, useLocation } from 'react-router-dom'
import styles from './Header.module.scss'
import { Route } from 'Utilities/Route'
import HeaderUserProfile from 'Components/Anubis/HeaderUserProfile'
import AskAQuestion from 'Components/Stargate/AskAQuestion'
import { useAsync } from 'react-use'
import { get } from 'Utilities/fetch++'
import useStargate from 'Utilities/Hooks/useStargate'
import * as config from 'Utilities/config'
import { Mode, Host } from 'Utilities/config'
import { Img } from 'react-image'
import { compact } from 'lodash'
import { classNames } from 'Utilities/etc'

export const Logo: React.FC = () => {
  const location = useLocation()

  const mode = config.mode()
  switch (mode[0]) {
  case Mode.standard: {
    const { individualsMode, obeliskMode } = mode[1]
    if (obeliskMode && !individualsMode && location.pathname.startsWith('/shop')) {
      const slug = getSlug()
      if (slug) {
        return <RemoteLogo slug={slug} />
      } else {
        return <AgencyLogo />
      }
    }
  }
  // eslint-disable-next-line no-fallthrough
  case Mode.demo:
    return <img
      className={styles.logo}
      alt={`${config.title()} Logo`}
      src={src()} />
  }

  function getSlug(): string | undefined {
    const match = location.pathname.match(/\/shop\/([^/]+)/) || []
    // FIXME determine from Route enum rather than hardcode
    switch (match[1]) {
    case ':slug': // IF THIS HAPPENS IT’S AN ERROR!
    case 'undefined': // IF THIS HAPPENS IT’S PROBS AN ERROR!
    case 'null': // IF THIS HAPPENS IT’S PROBS AN ERROR!
    case 'employer':
    case 'employee':
    case 'agency':
    case undefined:
    case null:
      return undefined
    default:
      return match[1]
    }
  }

  function src() {
    if (config.isProduction()) {
      return `${process.env.PUBLIC_URL}/logo.svg`
    } else {
      // requires the label be deployed, so initially may confuse you
      return `https://${config.label()}/logo.svg`
    }
  }
}

const AgencyLogo: React.FC = () => {
  const { name, logo } = useStargate().value?.obelisk || {}
  return <Img
    className={styles.logo}
    alt={`${name} Logo`}
    src={compact([
      logo,
      `https://${config.label()}/logo.svg`
    ])} />
}

const RemoteLogo: React.FC<{slug: string}> = ({ slug }) => {
  const { logo, name } = useAsync(() => get(`/v2/small-brokers/${slug}`)).value || {}
  return <Img
    className={styles.logo}
    alt={`${name} Logo`}
    src={compact([
      logo,
      `https://${config.label()}/logo.svg`
    ])} />
}

const MJMLogo = () => <img
  className={styles.logo}
  style={{ height: '4rem' }}
  alt="MJM Logo"
  src={mjmLogo}
  id='mjm' />

const Masthead: React.FC = ({ children }) => <div className={styles.header}>
  <Link to='/' className={styles.logos}>
    <Logo />
    {config.showMJMFlair() && MJMLogo()}
  </Link>
  {children}
</div>

function InnerMasthead() {
  const pathname = useLocation().pathname

  function showHelp() {
    return (pathname.startsWith(Route.stargate) && !pathname.startsWith(`${Route.stargate}/employee`))
  }
  function showAskQuestion() {
    return pathname.startsWith('/shop')
  }

  const [isOpen, setIsOpen] = useState(false)

  const rv = []
  addDevelopMetadata()
  if (pathname.startsWith('/shop') && (config.showMJMFlair() || config.individualsMode())) {
    if (showHelp()) {
      rv.push(<Help key='1' />)
    }
    if (showAskQuestion()) {
      rv.push(<button key='2' className={styles.question} onClick={() => setIsOpen(true)}>Ask a Question</button>)
      rv.push(<AskAQuestion key='3' isOpen={isOpen} onRequestClose={() => setIsOpen(false)} />)
    }
  } else if (!pathname.startsWith('/account')) {
    rv.push(<HeaderUserProfile key='1' />)
  }
  return rv

  function addDevelopMetadata() {
    switch (config.host()) {
    case Host.develop:
    case Host.staging:
      if (process.env.REACT_APP_GIT_SHA) {
        rv.push(<span key='4'>{process.env.REACT_APP_GIT_SHA}</span>)
      }
    // eslint-disable-next-line no-fallthrough
    case Host.localhost:
      if (config.mode()[0] === Mode.demo) {
        rv.push(<span key='5'>DEMO</span>)
      }
      if (config.isNonDifferentiableCandor()) {
        rv.push(<span key='6'>{config.labelShortCode()}</span>)
      }
    }
  }
}

const IEBanner: React.FC = () => {
  const isIE = navigator.userAgent.indexOf('Trident/') > 0
  if (!isIE) return null
  const securityRisks = <a href='https://www.forbes.com/sites/jasonevangelho/2019/04/15/warning-internet-explorer-just-became-a-silent-but-serious-threat-to-every-windows-user/#1b2c604486d8' target='_blank' rel="noopener noreferrer">security risks</a>
  const chrome = <a href='https://www.google.com/chrome/' target='_blank' rel="noopener noreferrer">Chrome</a>
  return <div className={styles.ieBanner}>
    We determined there are {securityRisks} with your browser: Please use a modern browser like {chrome}.
  </div>
}

const Header: React.FC = ({ children }) => {
  const location = useLocation()
  const showMastHead = config.showMJMFlair() || !location.pathname.startsWith(Route.stargate)
  const classes = classNames(
    styles.static,
    config.showMJMFlair() ? styles.mjm : styles.candor
  )
  let banner
  if (config.showMJMFlair()) {
    banner = <div className={styles.banner}>
      <span>{config.title()} a division of MJM Global</span>
    </div>
  }
  return <div className={classes}>
    <IEBanner/>
    { banner }
    { showMastHead && <Masthead>
      {InnerMasthead()}
    </Masthead> }
    { children }
  </div>
}

const Help = () => {
  const supportPhone = config.supportPhone().replace(/ /g, '\xa0') // Non Breaking space
  return <div className={styles.help}>
    <div className={styles.inner}>
      <p>If&nbsp;you&nbsp;have&nbsp;questions,&nbsp;or&nbsp;need&nbsp;help,&nbsp;call:</p>
      <p className={styles.bigger}>&#32;{supportPhone}</p>
      <p className={styles.smaller}>Mon–Fri, 8:00AM–9:00PM EST</p>
    </div>
  </div>
}

export default Header
