import React from 'react'
import NewClientModal from './NewClientModal'
import AddButton from './AddButton'
import { useHistory } from 'react-router-dom'
import { Route } from 'Utilities/Route'
import { useToggle } from 'react-use'

const AddNewClient: React.FC = () => {
  const history = useHistory()
  const [open, toggle] = useToggle(false)

  return <>
    <NewClientModal isOpen={open} onRequestClose={onClose} />
    <AddButton onClick={toggle}>
      Add New Client
    </AddButton>
  </>

  function onClose(id: string | null = null) {
    toggle(false)
    if (id) {
      history.push(`${Route.agencyDashboardClients}/${id}`)
    }
  }
}

export default AddNewClient
