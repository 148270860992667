import React, { useState, useEffect } from 'react'
import styles from './EmployeeInfoWaiveModal.module.css'
import Select from 'Components/Rudimentary/CandorSelect'
import StargateModal from 'Components/Stargate/StargateModal'
import { useAppMode, AppMode } from '../TableOfContents'

interface EE {
  id: string
  name: string
}

interface Props {
  waivingEmployee?: EE
  isOpen: boolean
  onCancel: () => void
  callback: (waiveReason: string, ee?: EE) => void
}

const EmployeeInfoWaiveModal: React.FC<Props> = ({ waivingEmployee, isOpen, onCancel, callback }) => {
  const appMode = useAppMode()
  const [waiveReason, setWaiveReason] = useState<string | undefined>()
  const [isWaiving, setIsWaiving] = useState(appMode === AppMode.employer)

  useEffect(() => {
    setIsWaiving(appMode === AppMode.employer)
    setWaiveReason(undefined)
  }, [waivingEmployee, appMode])

  return <StargateModal
    isOpen={isOpen}
    onRequestClose={onCancel}
    styles={{ overflow: 'visible', padding: 40, height: 375, width: 500 }}
  >
    <div className={styles.container}>
      <h1 className={styles.header}>Waive Healthcare Coverage</h1>
      {isWaiving ? renderIsWaiving() : renderOptions()}
    </div>
  </StargateModal>

  function renderIsWaiving() {
    return <div className={styles.waivingContainer}>
      <p className={styles.text}>Please select {appMode === AppMode.employer ? 'a' : 'your'} reason for waiving coverage.</p>
      <div className={styles.reason}>
        <Select placeholder="Reason for waiving" options={waiveReasons} onChange={setWaiveReason} value={waiveReason}/>
      </div>
      {
        waiveReason &&
          <button className={`${styles.button} ${styles.waiveButton}`} onClick={handleSubmit}>Waive Coverage</button>
      }
      <button className={`${styles.button} ${styles.cancel}`} onClick={onCancel}>
        {appMode === AppMode.employee && 'I changed my mind. '}Take me back!
      </button>
    </div>
  }

  function handleSubmit() {
    if (waiveReason) {
      callback(waiveReason, waivingEmployee)
    }
  }

  function renderOptions() {
    return <>
      <p className={styles.text}>
        Are you sure you want to waive healthcare coverage{appMode === AppMode.employer && waivingEmployee && ` for ${waivingEmployee.name}`}?
      </p>
      <div className={styles.buttonContainer}>
        <button className={styles.button} onClick={onCancel}>
          {appMode === AppMode.employee && 'I do not want to waive. '}Take me back!
        </button>
        <button className={styles.button} onClick={() => setIsWaiving(true)}>
          {appMode === AppMode.employee && 'I want to '}waive healthcare coverage.
        </button>
      </div>
    </>
  }
}

const waiveReasons = [
  {
    value: 'spouseInsured',
    label: 'Spousal Coverage.'
  },
  {
    value: 'govInsured',
    label: 'Government Insured'
  },
  {
    value: 'medicare',
    label: 'Medicare'
  },
  {
    value: 'other',
    label: 'Other'
  }
]

export default EmployeeInfoWaiveModal
