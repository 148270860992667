import React, { useState, useEffect } from 'react'
import styles from './RightNowShowPlans.module.scss'
import headerStyles from 'Components/Stargate/ShowPlans/Components/ShowPlansHeader.module.scss'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import { useAsync, useAsyncRetry } from 'react-use'
import { v3 } from 'Utilities/pharaoh'
import Loader from 'Components/Rudimentary/Loader'
import Error from 'Components/Primitives/Error'
import { MedicalPlan, Tier } from 'Utilities/pharaoh.types'
import EERightNowPlan from 'Components/Plans/EERightNowPlan'
import { get, post, delete_ } from 'Utilities/fetch++'
import useToast from 'Utilities/Hooks/useToast'
import { AsyncStateRetry } from 'react-use/lib/useAsyncRetry'

const EEShopRightNow: React.FC<PrivateWizardPageProps> = ({ onwards, stargate }) => {
  const { group, user, userTier } = stargate
  const [disabled, setDisabled] = useState(false)
  const selectedPlanAsync = useAsyncRetry(() => get('/v2/users/plans'))
  const async = useAsync(v3.groups(group!.id).users(user.id).plans.GET)
  const addToast = useToast()

  useEffect(() => {
    const el = document.getElementById('content')
    if (!el) return
    el.style.maxWidth = 'unset'
    el.style.paddingTop = '0'
    el.style.paddingRight = '0'
    el.style.paddingLeft = '0'
    return () => {
      el.style.maxWidth = ''
      el.style.paddingTop = ''
      el.style.paddingRight = ''
      el.style.paddingLeft = ''
    }
  })

  if (async.error || selectedPlanAsync.error) return <Error error={async.error} />

  const plans = async.value! as MedicalPlan[]

  return <>
    <Header onwards={onwards} disabled={disabled} async={selectedPlanAsync}/>
    { async.loading
      ? <Loader/>
      : <PlansList
        plans={plans}
        userID={user.id}
        disabled={disabled}
        async={selectedPlanAsync}
        handleSelect={handlePlanSelection}
        tier={userTier || Tier.individual}
      />
    }
  </>

  async function handlePlanSelection(planID: string) {
    try {
      setDisabled(true)
      if (selectedPlanAsync.value?.healthPlan?.id === planID) {
        await delete_(`/v2/groups/${group!.id}/users/plans/`)
      } else {
        await post(`/v2/groups/${group!.id}/users/plans/${planID}`)
      }
      selectedPlanAsync.retry()
    } catch (error) {
      addToast(error)
    } finally {
      setDisabled(false)
    }
  }
}

interface HeaderProps {
  disabled: boolean
  async: AsyncStateRetry<any>
  onwards(api: Promise<any>): Promise<void>
}

const Header: React.FC<HeaderProps> = ({ disabled, onwards, async }) => {
  function handleClick() {
    onwards(Promise.resolve())
  }

  const hasHealthplan = !!async.value?.healthPlan
  const buttonCopy = hasHealthplan ? 'Next' : <>Select a plan<br/>to continue</>
  const disabled_ = disabled || !hasHealthplan || async.loading

  return <div className={headerStyles.header}>
    <div>
      <div/> {/* Placeholder for Grid */}
      <h1 className='shop-h1-periwinkle'>Select Your Plan</h1>
      <button onClick={handleClick} disabled={disabled_}>{buttonCopy}</button>
    </div>
  </div>
}

interface PlansProps {
  plans: MedicalPlan[]
  userID: string
  disabled: boolean
  async: AsyncStateRetry<any>
  handleSelect(planID: string): Promise<void>
  tier: Tier
}

const PlansList: React.FC<PlansProps> = ({ plans, userID, disabled, async, handleSelect, tier }) => {
  const selectedPlan = async.value?.healthPlan?.id

  return <div className={styles.plansContainer}>
    {plans.map(plan =>
      <EERightNowPlan
        key={plan.id}
        groupMemberID={userID}
        plan={plan}
        selected={selectedPlan === plan.id}
        selectHandler={handleSelect}
        isHandlingCheckmark={disabled || async?.loading}
        tier={tier}
      />
    )}
    <p className={styles.disclaimer}>Note all healthcare coverage will take effect 11 business days from today. This coverage is not active until confirmed<br/>by the carrier. Do not cancel any active health insurance until this coverage is confirmed by the carrier.</p>
  </div>
}

export default EEShopRightNow
