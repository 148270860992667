/* eslint-disable camelcase */
import React, { useState } from 'react'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import styles from 'Routes/shop/employer/plans/ShowPlans.module.scss'
import useFullContentArea from 'Utilities/Hooks/useFullContentArea'
import { useAsync, useToggle } from 'react-use'
import { get, post, delete_ } from 'Utilities/fetch++'
import Error from 'Components/Primitives/Error'
import { MedicalPlan } from 'Utilities/pharaoh.types'
import Loader from 'Components/Rudimentary/Loader'
import useToast from 'Utilities/Hooks/useToast'
import { supportEmail, individualsMode } from 'Utilities/config'
import EEMedicalPlan from 'Components/Plans/EEMedicalPlan'
import headerStyles from 'Components/Stargate/ShowPlans/Components/ShowPlansHeader.module.scss'
import EmployeeInfoWaiveModal from 'Components/Stargate/Waiving/EmployeeInfoWaiveModal'

const EEShopPlansHealth: React.FC<PrivateWizardPageProps> = ({ stargate, ...props }) => {
  const { group, userTier, splits, groupMember, user } = stargate
  const isRedFlagged = groupMember?.is_redflagged
  const [isWaiveModalOpen, toggleWaiveCoverageModal] = useToggle(false)
  // TODO: this is super broken. selectedPlanID is a MedicalPlanModel.id and the user IDs are GroupPlan.ids.
  // We can't translate between them that I can see here. So this page will never show the user's existing
  // plan on load, but will be right thereafter.
  const [selectedPlanID, setSelectedPlanID] = useState(user.enrolled_group_plan_id || user.enrolled_individual_plan_id)
  const [disabled, setDisabled] = useToggle(false)
  const contribution = group?.medical_contribution || '0%'
  const addToast = useToast()
  useFullContentArea()

  const { value: plans, loading, error } = useAsync(async() =>
    await get(`/v3/groups/${group?.id}/users/${groupMember?.id}/plans`) as MedicalPlan[]
  )

  if (!group || !userTier || !groupMember) return <Error error='Please complete earlier steps'/>
  if (error) return <Error error={error} />
  if (loading) return <Loader />

  const selectedPlan = plans!.find(({ id, groupPlanID }) => id === selectedPlanID || groupPlanID === selectedPlanID)
  const isNextButtonDisabled = selectedPlan === undefined || disabled

  return <>
    <header className={headerStyles.header}>
      <div>
        {!individualsMode() &&
          <button className={headerStyles.waiveButton} onClick={toggleWaiveCoverageModal}>
            I don’t want Coverage!
          </button>
        }
        <h1>Select Plans</h1>
        <button className={headerStyles.nextButton} onClick={onwards} disabled={isNextButtonDisabled}>
          {isNextButtonDisabled ? 'Select a plan to continue' : 'Next Step'}
        </button>
      </div>
    </header>
    <div className={styles.mainContainer}>
      <div className={styles.plansAndFilter} style={{ display: 'block' }}>
        <div className={styles.plansListContainer}>
          {isRedFlagged && <Appeal/>}
          {plans!.map(plan => <EEMedicalPlan
            plan={plan}
            tier={userTier}
            contribution={{
              value: contribution,
              isEquitable: group.medical_contribution_equitable
            }}
            splits={splits}
            groupMemberID={groupMember.id}
            selected={selectedPlan === plan}
            selectHandler={onPlanSelectToggled}
            key={plan.id}
          />)}
        </div>
      </div>
    </div>
    <EmployeeInfoWaiveModal
      isOpen={isWaiveModalOpen}
      onCancel={toggleWaiveCoverageModal}
      callback={waiveCallback}
    />
  </>

  async function onPlanSelectToggled(plan: MedicalPlan) {
    if (disabled) return
    const oldSelectedPlanId = selectedPlanID
    try {
      setDisabled(true)
      setSelectedPlanID(plan.id)
      if (plan.id !== oldSelectedPlanId) {
        await delete_(`/v2/groups/${group?.id}/users/plans`)
        await post(`/v2/groups/${group?.id}/users/plans/${plan.id}`)
      }
    } catch (error) {
      addToast(error)
      setSelectedPlanID(oldSelectedPlanId)
    } finally {
      setDisabled(false)
    }
  }

  function onwards() {
    props.onwards(Promise.resolve())
  }

  function waiveCallback(waiveReason: string) {
    post(`/groups/${group?.id}/users/waive`, {
      waiveReason,
      isWaiving: true
    }).then(onwards).catch(addToast)
  }
}

const Appeal = () => {
  const contactHref = `mailto:${supportEmail()}?Subject=[Request]%20Appeal%20Individual%20Optimization`

  return <section className={styles.eeSection}>
    <h3>
      <b>Based on medical information you have provided. We believe we can save you more, with a plan that best fits your individual needs. </b><br/>
      If you feel this specialized customization will not fit your needs or you want more information please contact us</h3>
    <a href={contactHref}>Contact Us</a>
  </section>
}

export default EEShopPlansHealth
