import React, { useState } from 'react'
import styles from './SupportSection.module.scss'
import api from 'Utilities/Deprecated/api'
import { useToggle } from 'react-use'
import useUser, { PowerLevel } from 'Utilities/Hooks/useUser'
import useToast from 'Utilities/Hooks/useToast'
import useStargate from 'Utilities/Hooks/useStargate'
import DebugModal from 'Components/Modals/DebugModal'
import LabelSwitcherModal from 'Components/Modals/LabelSwitcherModal'
import { isProduction } from 'Utilities/config'

enum Mode {
  off,
  open,
  debug
}

const SupportSection: React.FC = () => {
  const [mode, setMode] = useState(Mode.off)

  return <div className={styles.supportContainer}>
    {mode !== Mode.off
      ? <Content debug={mode === Mode.debug} onClick={onClick}/>
      : <button name="support" onClick={onClick}>Support<i className='material-icons'>help_outline</i></button>
    }
  </div>

  function onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (event.shiftKey) {
      setMode(Mode.debug)
    } else {
      if (mode === Mode.open) {
        setMode(Mode.off)
      } else {
        setMode(Mode.open)
      }
    }
  }
}

interface Props {
  debug: boolean
  onClick(event: any): void
}

const Content: React.FC<Props> = ({ debug, onClick }) => {
  const { value: user } = useUser()
  const { value: stargate } = useStargate()
  const [email, setEmail] = useState('')
  const [bugReporterOpen, toggleBugReporter] = useToggle(false)
  const [labelSwitcherOpen, toggleLabelSwitcher] = useToggle(false)
  const addToast = useToast()
  const isAdmin = user && user.powerLevel >= PowerLevel.candorEmployee
  const isNotProduction = !isProduction()
  const children = []

  if (isAdmin || isNotProduction) {
    children.push(<>
      <input
        onChange={event => setEmail(event.target.value)}
        placeholder="Email (Switch User)"
      />
      <button onClick={switchToUser}>Submit</button>
    </>)
  }

  if (isAdmin || isNotProduction || debug) {
    children.push(<button onClick={toggleBugReporter}>Report Bug</button>)
    children.push(<button onClick={toggleLabelSwitcher}>Switch Label</button>)
  }

  children.push(
    <button onClick={startGlanceSession}>Glance Cobrowse</button>
  )

  children.push(
    <button onClick={onClick}>Collapse Menu<i className='material-icons'>expand_more</i></button>
  )

  const els = children.flatMap((el, index) => [
    React.cloneElement(el, { key: `${index}_` }),
    <br key={index} />
  ])
  els.pop() // pop last <br/>

  return <>
    {els}
    {bugReporterOpen && <DebugModal />}
    {labelSwitcherOpen && <LabelSwitcherModal />}
  </>

  function startGlanceSession(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault()

    const glance = (window as any).GLANCE?.Cobrowse?.Visitor

    if (!glance) {
      return addToast('Could not start Glance Cobrowse')
    }

    glance.startSession()
    glance.addEventListener('sessionstart', mailSupportDebugInfo)
  }

  function mailSupportDebugInfo() {
    const state = JSON.stringify(stargate || { error: 'Tell Tech Dept.' }, null, 2)
    const subject = 'Glance Support'
    const body = `${state}`
    const url = window.location.href
    api.sendSupportEmail(subject, body, url).catch(console.error)
  }

  function switchToUser() {
    api.switchToUser(email)
      .then(rsp => {
        localStorage.setItem('token', rsp.token)
        window.location.reload()
      })
      .catch(addToast)
  }
}

export default SupportSection
