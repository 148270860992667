import React from 'react'
import styles from './Plan.module.scss'
import headerStyles from './PlanTitle.module.scss'
import SlashesLoader from 'Components/Stargate/Wizard/SlashesLoader'

interface Props {
  selected?: boolean
  selectedForRemoval?: boolean
}

const Plan: React.FC<Props> = ({ selected, children }) => {
  const classes = [styles.container]
  if (selected) { classes.push(styles.selected) }

  return <div className={classes.join(' ')}>
    {children}
  </div>
}

interface BodyProps {
  selectedForRemoval?: boolean
}

export const PlanBody: React.FC<BodyProps> = ({ selectedForRemoval, children }) => {
  return <div className={styles.planBodycontainer}>
    {children}
    { selectedForRemoval && <div className={headerStyles.remove}/> }
  </div>
}

export const PlanPlaceholder: React.FC<any> = () => {
  return <>
    <div className={headerStyles.fundingTypeContainer} style={{ backgroundColor: '#d0d0d0', margin: '0 0 0 auto' }}/>
    <div className={styles.placeholderContainer}>
      <SlashesLoader/>
    </div>
  </>
}

export default Plan
