/* eslint-disable @typescript-eslint/camelcase */
import React, { useMemo, useEffect } from 'react'
import styles from './index.module.scss'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import UploadCensus from './UploadCensus'
import eeStyles from './CensusEmployee.module.scss'
import { Dependent } from 'Utilities/Hooks/useStargate'
import { compact } from 'lodash'
import CandorInput from 'Components/Rudimentary/CandorInput'
import { useForm, Controller, FormProvider, useFormContext, useFieldArray } from 'react-hook-form'
import { CandorDatePicker } from 'Components/Anubis/CandorForm'
import CountyPicker from 'Components/Rudimentary/CountyPicker'
import { createStateContext, useToggle } from 'react-use'
import { v4 as uuid } from 'uuid'
import { Relationship } from 'Utilities/pharaoh.types'
import useToast from 'Utilities/Hooks/useToast'
import { FormData, CensusMember, submit, mangle, isSpouse, GenderSelect, mangleXLSX, relationshipCopy } from './index.helpers'
import { ReactComponent as InfoIcon } from 'Assets/info_icon_empty.svg'
import InformationalModal from 'Components/Modals/InformationalModal'

// FIXME if we keep this then we need to prevent simultaneous POST of submit if user is fast
// NOTE however this whole thing sucks, instead be granular with endpoints

const [useSharedEditID, SharedEditIDProvider] = createStateContext<string | undefined>(undefined)

const ERShopCensus: React.FC<PrivateWizardPageProps> = ({ onwards, stargate }) => {
  const defaultValues = useMemo(() => ({ ees: mangle(stargate) }), [stargate])
  const [showModal, setShowModal] = useToggle(false)

  useEffect(() => {
    const el = document.getElementById('content')
    if (!el) return
    el.style.backgroundColor = '#F1FAF8'
    el.style.maxWidth = 'unset'
    return () => {
      el.style.backgroundColor = ''
      el.style.maxWidth = ''
    }
  })

  return <div className={styles.container}>
    <CensusInformationalModal isOpen={showModal} onRequestClose={setShowModal}/>
    <h1 className='shop-h1-periwinkle'>What we need for your quote<i onClick={setShowModal}><InfoIcon/></i></h1>
    <h2 className='shop-h2-navy'>
      This information provided will help us get you the most precise
      pricing, and the information will not be used for any other means.
      <span> All employees must be entered.</span>
    </h2>
    <SharedEditIDProvider>
      <Census defaultValues={defaultValues} onwards={onwards} id={stargate.group!.id}/>
    </SharedEditIDProvider>
  </div>
}

interface CensusProps {
  id: string
  onwards(api: Promise<any>): Promise<void>
  defaultValues: FormData
}

const Census: React.FC<CensusProps> = ({ defaultValues, id, onwards }) => {
  const form = useForm<FormData>({ defaultValues })
  const { append, fields: ees, remove } = useFieldArray<CensusMember, 'rhfID'>({ control: form.control, name: 'ees' })
  const [, setEditID] = useSharedEditID()
  const addToast = useToast()

  // HACK but works since re-render won’t occur
  let submitButtonPressed = false

  return <>
    <UploadCensus callback={handleCensusUpload} />

    <h4>Or</h4>
    <h2 className={styles.manually}>Manual Complete</h2>
    <h3>Best for 5 or less people. Employees waiving coverage must also be entered for accuracy:</h3>

    <FormProvider {...form}>
      {/* as any below because of some bug in TS or react-hook-form that decomposes `Date` into its contents then thinks they are different types */}
      <form onSubmit={form.handleSubmit(onSubmit as any)}>
        {ees.map((ee, index) => <Record ee={ee} key={(ee as any).rhfID || ee.id} index={index} remove={remove} />)}
        <button className={styles.addEmployee} onClick={onAppend}><span>+</span>Add Employee</button>
        <input type="submit" value='Next' onClick={() => { submitButtonPressed = true }} />
      </form>
    </FormProvider>
  </>

  function handleCensusUpload(input: any) {
    form.reset({ ees: mangleXLSX(input) })
  }

  async function onSubmit(data: FormData) {
    submit(data, submitButtonPressed, onwards, addToast, id)
  }

  function onAppend() {
    append({ id: uuid(), email: '', dependents: [] })
    setEditID(`ees[${ees.length}]`)
  }
}

interface RecordProps {
  ee: Partial<CensusMember>
  index: number
  remove: (index: number) => void
}

const Record: React.FC<RecordProps> = ({ ee, index, remove: eeRemove }) => {
  const prefix = `ees[${index}].dependents`
  const { append, fields: deps, remove } = useFieldArray<Dependent, 'rhfID'>({ name: prefix })
  const hasSpouse = deps.some(isSpouse)
  const [, setEditID] = useSharedEditID()

  return <div className={eeStyles.container}>
    <h4>Employee {index + 1}</h4>
    <Line
      person={ee}
      placeholder='Employee'
      formComponent={EmployeeForm}
      prefix='ees'
      index={index}
      remove={eeRemove} />
    {deps.map((dep, index) =>
      <React.Fragment key={`fragment[${index}]`}>
        <hr/>
        { depLine(dep, index) }
      </React.Fragment>
    )}
    <div className={eeStyles.dependentButtonContainer}>
      <button onClick={onAppend(Relationship.spouse)} disabled={hasSpouse}>Add Spouse</button>
      <button onClick={onAppend(Relationship.lifePartner)} disabled={hasSpouse}>Add Domestic Partner</button>
      <button onClick={onAppend(Relationship.child)}>Add Child</button>
    </div>
  </div>

  function onAppend(relationship: Relationship): () => void {
    return () => {
      append({ id: uuid(), relationship })
      setEditID(`${prefix}[${deps.length}]`)
    }
  }
  function depLine(dep_: any, index: number) {
    const dep = dep_ as Dependent
    return <Line
      key={(dep as any).rhfID || dep.id}
      person={dep}
      placeholder={relationshipCopy(dep)}
      formComponent={DependentForm}
      prefix={prefix}
      index={index}
      remove={remove}
    />
  }
}

interface LineProps {
  person: any
  placeholder: string
  formComponent: React.ComponentType<any>
  prefix: any
  index: number

  remove: (index: number) => void
}

const Line: React.FC<LineProps> = ({ person, placeholder, formComponent: Form, prefix, index, remove }) => {
  const [editID, setEditID] = useSharedEditID()
  const key = `${prefix}[${index}]`
  const name = person.name || compact([person.firstName, person.lastName]).join(' ')
  const editing = editID ? editID === key : key === 'ees[0]'

  let title: any = placeholder
  if (!editing && name) {
    title = name
    if (person.relationship) title = <>{title}&ensp;<i>({relationshipCopy(person)})</i></>
  }

  return <>
    <div className={eeStyles.nameRow}>
      <div className={eeStyles.name}>{title}</div>
      <button className={eeStyles.edit} onClick={onEdit} style={{ display: editing ? 'none' : '' }}>Edit</button>
      <button className={eeStyles.delete} onClick={onDelete}>Delete</button>
    </div>
    <Form prefix={key} initialZip={person.zip} hidden={!editing} person={person} />
  </>

  function onDelete() {
    remove(index)
  }
  function onEdit() {
    setEditID(key)
  }
}

interface EmployeeFormProps extends DependentFormProps<CensusMember> {
  initialZip?: string
}

const EmployeeForm: React.FC<EmployeeFormProps> = ({ prefix, initialZip, hidden, person }) => {
  const zip = useFormContext().watch(`${prefix}.zip`, initialZip)
  const style = { display: hidden ? 'none' : '' }
  const { register } = useFormContext()

  return <div className={eeStyles.form} style={style} key={(person as any).rhfID}>
    <input type='hidden' name={`${prefix}.id`} ref={register()} defaultValue={person.id} />
    <CandorInput autoComplete="given-name" placeholder="First Name" name={`${prefix}.firstName`} defaultValue={person.firstName}/>
    <CandorInput autoComplete="family-name" placeholder="Last Name" name={`${prefix}.lastName`} defaultValue={person.lastName}/>
    <CandorInput ref={register()} autoComplete="postal" name={`${prefix}.zip`} maxLength={5} placeholder='ZIP Code' defaultValue={zip}/>
    <CountyPicker name={`${prefix}.countyId`} zip={zip} />
    <CandorDatePicker
      placeholder='Birthdate'
      showYearDropdown
      autoComplete="bday"
      dropdownMode="select"
      name={`${prefix}.dateOfBirth`}
    />
    <CandorInput autoComplete="email" name={`${prefix}.email`} placeholder="Email" defaultValue={person.email}/>
    <Controller as={GenderSelect} name={`${prefix}.gender`}/>
  </div>
}

interface DependentFormProps<T = Dependent> {
  prefix: string
  hidden: boolean
  person: T
}

const DependentForm: React.FC<DependentFormProps> = ({ prefix, hidden, person }) => {
  const style = { display: hidden ? 'none' : '' }
  const { register } = useFormContext()

  return <div className={eeStyles.form} style={style}>
    <input type='hidden' name={`${prefix}.id`} ref={register()} defaultValue={person.id} />
    <input type='hidden' name={`${prefix}.relationship`} ref={register()} defaultValue={person.relationship} />
    <CandorInput autoComplete="given-name" placeholder="First Name" name={`${prefix}.firstName`} defaultValue={person.firstName}/>
    <CandorInput autoComplete="family-name" placeholder="Last Name" name={`${prefix}.lastName`} defaultValue={person.lastName}/>
    <CandorDatePicker
      placeholder='Birthdate'
      showYearDropdown
      autoComplete="bday"
      dropdownMode="select"
      name={`${prefix}.dateOfBirth`}
    />
    <Controller as={GenderSelect} name={`${prefix}.gender`}/>
  </div>
}

const CensusInformationalModal: React.FC<ReactModal.Props> = props =>
  <InformationalModal { ...props }>
    <h1>Census Upload</h1>
    <p className={styles.paragraph}>Your Company’s Census must be completely filled out for every employee prior to submitting. We will need the following information for each employee: </p>
    <p className={styles.detail}>(Providing this information will ensure you receive precise pricing for the healthcare coverage options for your team. All information is needed for every employee even those waiving coverage)</p>
    <ul className={styles.censusInformationList}>
      <li>Full Name</li>
      <li>Employee Type</li>
      <li>Email Address</li>
      <li>Date of Birth</li>
      <li>Gender</li>
      <li>Zip Code</li>
      <li>Knowledge of Tobacco use</li>
      <li>Enrollment Type <span>(Employee, Employee+Spouse, Employee+Child(ren) or Family)</span></li>
    </ul>
  </InformationalModal>

export default ERShopCensus
