/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
/// HEY YOU! LISTEN! These must be in an order so iterating them with a `startsWith` type pattern will return longer paths first!
/// NOTE handle `:id` params etc. in App.tsx, DO NOT ADD HERE
enum Route {
  agencyDashboardReports = '/dashboard/agency/reports',
  agencyDashboardPipeline = '/dashboard/agency/pipeline',
  agencyDashboardClients = '/dashboard/agency/clients',
  agencyDashboardAssociations = '/dashboard/agency/associations',
  agencyDashboardProducers = '/dashboard/agency/producers',
  agencyDashboardEmployees = '/dashboard/agency/employees',
  agencyDashboardAgencies = '/dashboard/agency/agencies',

  dashboardEmployerEmployees = '/dashboard/employer/employees',
  dashboardEmployerProfile = '/dashboard/employer/profile',
  dashboardEmployerBilling = '/dashboard/employer/billing',
  dashboardEmployer = '/dashboard/employer',

  dashboardEmployee = '/dashboard/employee',
  dashboardEmployeeProfile = '/dashboard/employee/profile',

  agencyDashboard = '/dashboard/agency',

  dashboard = '/dashboard',

  stargate = '/shop',

  erStargateGetStarted = '/shop/employer/get-started',
  erStargateCensus = '/shop/employer/census',
  erStargateCensusWaive = '/shop/employer/census/waive',
  erStargateContribution = '/shop/employer/plans/contribution',
  erStargateCarvedContributions = '/shop/employer/plans/contribution/carved',
  erStargatePlans = '/shop/employer/plans',
  erStargateAncillaryPlans = '/shop/employer/plans/ancillary',
  erStargateReview = '/shop/employer/plans/review',
  erStargateFinalize = '/shop/employer/confirm',

  // DEPRECATED but MJM insist
  erStargateApplication = '/shop/employer/plans/application',

  eeStargate_info = '/shop/employee/census',
  eeStargate_work = '/shop/employee/work',
  eeStargate_family = '/shop/employee/census/family',
  eeStargate_redflags = '/shop/employee/medical',
  eeStargate_have_plan = '/shop/employee/plans/already-covered',
  eeStargate_select = '/shop/employee/plans/health',
  eeStargate_waive_confirm = '/shop/employee/plans/health/waive',
  eeStargate_ancillary = '/shop/employee/plans/ancillary',
  eeStargate_underwriting = '/shop/employee/plans/underwriting',
  eeStargate_enroll = '/shop/employee/plans/enroll',
  eeStargate_pay = '/shop/employee/checkout',
  eeStargate_confirm = '/shop/employee/confirm',

  signUp = '/account/sign-up',
  signIn = '/account/sign-in',
  signOut = '/account/sign-out',
  forgotPassword = '/account/recover',
  resetPassword = '/account/reset-password',

  landing = '/' // MUST BE LAST!
}
/// HEY YOU! I HOPE YOU LISTENED!

function getTitle(route: Route): string {
  switch (route) {
  case Route.agencyDashboardProducers:
    return 'Producers'
  case Route.agencyDashboardClients:
    return 'Clients'
  case Route.agencyDashboardAssociations:
    return 'Associations'
  case Route.agencyDashboardPipeline:
    return 'Pipeline'
  case Route.agencyDashboardReports:
    return 'Reports'
  case Route.agencyDashboardAgencies:
    return 'Agencies'
  case Route.dashboardEmployerEmployees:
  case Route.agencyDashboardEmployees:
    return 'Employees'
  case Route.dashboardEmployerProfile:
    return 'Company Profile'
  case Route.dashboardEmployerBilling:
    return 'Billing'
  case Route.dashboardEmployer:
    return 'Home'
  case Route.dashboardEmployeeProfile:
    return 'Your Profile'
  case Route.dashboard:
  case Route.agencyDashboard:
  case Route.dashboardEmployee:
    return 'Dashboard'
  case Route.stargate:
    return 'Shop'
  case Route.erStargateGetStarted:
    return 'Get Started'
  case Route.erStargateCensus:
    return 'Census'
  case Route.erStargateCensusWaive:
    return 'Census·Waive'
  case Route.erStargateContribution:
    return 'Plans·Contributions'
  case Route.erStargateCarvedContributions:
    return 'Plans·Contributions·Carved'
  case Route.erStargatePlans:
    return 'Plans'
  case Route.erStargateAncillaryPlans:
    return 'Plans·Ancillary'
  case Route.erStargateReview:
    return 'Review'
  case Route.erStargateFinalize:
    return 'Finalize'

  case Route.erStargateApplication:
    return 'Carrier Application'

  case Route.eeStargate_info:
  case Route.eeStargate_waive_confirm:
  case Route.eeStargate_work:
  case Route.eeStargate_family:
  case Route.eeStargate_redflags:
  case Route.eeStargate_have_plan:
  case Route.eeStargate_select:
  case Route.eeStargate_ancillary:
  case Route.eeStargate_underwriting:
  case Route.eeStargate_enroll:
  case Route.eeStargate_pay:
  case Route.eeStargate_confirm:
    return 'TODO'

  case Route.signIn:
    return 'Sign‑In'
  case Route.signOut:
    return 'Sign‑Out'
  case Route.forgotPassword:
    return 'Recover Account'
  case Route.resetPassword:
    return 'Reset Password'
  case Route.landing:
    return 'Landing Page'
  case Route.signUp:
    return 'Sign Up'
  }
}

export { Route, getTitle }
