import React from 'react'
import styles from './MedicalPlanAdditionalSpecs.module.css'
import PlanSnapshotSection from './PlanSnapshotSection'
import PlanBox from './PlanBox'
import { moneyString } from 'Utilities/Plans/PremiumCalculators'
import { individualsMode } from 'Utilities/config'
import { MedicalPlan } from 'Utilities/pharaoh.types'
import { classNames } from 'Utilities/etc'

interface Props {
  plan: MedicalPlan
  isCollapsed: boolean
  sectionWidth?: number
  refCallback?: any
  isProsper?: boolean
}

const MedicalPlanAdditionalSpecs: React.FC<Props> = ({ plan, isCollapsed, sectionWidth, refCallback, isProsper }) => {
  const copay = plan.copay
  const prescription = plan.prescription

  const isZeroAmt = (amt: string) => amt === '0' || amt === '$0' || amt === '0%'
  const isDollarAmt = (amt: string) => amt.startsWith('$') && amt.length < 5
  const isPercentageAmt = (amt: string) => amt.endsWith('%') && amt.length < 5
  const isLongText = (val: string) => val.length > 20
  const hasOONCoverage = () => plan.oonCoverage

  return <PlanSnapshotSection sectionWidth={sectionWidth} flexDirection={isCollapsed ? 'column' : 'row'} refCallback={refCallback}>
    <div className={isCollapsed ? styles.collapsedSpecs : styles.expandedSpecs}>
      <div>
        <div className={styles.medicalProsperContainer}>
          <PlanBox className={styles.medicalProsperPlanBox}>
            <p className={styles.medicalLabel}>Medical Bill Advocacy</p>
            {prosperCovered()}
          </PlanBox>
          <PlanBox className={styles.medicalProsperPlanBox}>
            <p className={styles.medicalLabel}>HealthCare Advisor</p>
            {prosperCovered()}
          </PlanBox>
          <PlanBox className={styles.medicalProsperPlanBox}>
            <p className={styles.medicalLabel}>Telemedicine</p>
            {prosperCovered()}
          </PlanBox>
          <PlanBox className={styles.medicalProsperPlanBox}>
            <p className={styles.medicalLabel}>Telemental</p>
            {prosperCovered()}
          </PlanBox>
        </div>
      </div>
      <div>
        <div className={`${styles.medicalSpecsContainer} ${styles.medicalSpecsLeftAlign}`}>
          { prosperOnlyBox() }
          <PlanBox className={classNames(styles.medicalSpecsPlanBox, styles.visitAndPrescContainer)}>
            <p className={styles.medicalLabel}>
              Copay <span>{copay.conditional && `(${copay.conditional})`}</span>{asterisk()}
            </p>
            <div className={styles.medicalVisitAndPrescLabel}>
              <p>Primary:</p>
              {visitAndPrescCopy(copay.primaryCarePhysician)}
            </div>
            <div className={styles.medicalVisitAndPrescLabel}>
              <p>Specialist:</p>
              {visitAndPrescCopy(copay.specialist)}
            </div>
            <div className={styles.medicalVisitAndPrescLabel}>
              <p>Urgent Care:</p>
              {visitAndPrescCopy(copay.urgentCare)}
            </div>
          </PlanBox>
          <PlanBox className={classNames(styles.medicalSpecsPlanBox, styles.visitAndPrescContainer)}>
            <p className={styles.medicalLabel}>
                  Rx Cost <span>{prescription.conditional && `(${prescription.conditional})`}</span>{asterisk()}
            </p>
            <div className={styles.medicalVisitAndPrescLabel}>
              <p>Generic:</p>
              {visitAndPrescCopy(prescription.generic)}
            </div>
            <div className={styles.medicalVisitAndPrescLabel}>
              <p>Preferred:</p>
              {visitAndPrescCopy(prescription.preferredBrand)}
            </div>
            <div className={styles.medicalVisitAndPrescLabel}>
              <p>Specialty:</p>
              {visitAndPrescCopy(prescription.specialty)}
            </div>
          </PlanBox>
        </div>
      </div>
    </div>
    <div className={isCollapsed ? styles.collapsedSpecs : styles.expandedSpecs}>
      <div className={styles.medicalSpecsContainer}>
        { prosperOnlyBox() }
        <PlanBox className={styles.medicalSpecsPlanBox}>
          <p className={styles.medicalLabel}>Deductible{asterisk()}</p>
          {planSpecsCopy(plan.deductible)}
        </PlanBox>
        <PlanBox className={`${oonCoverageStyle()} ${styles.medicalSpecsPlanBox}`}>
          <p className={styles.medicalLabel}>OOP Max</p>
          {planSpecsCopy(plan.oopMax)}
        </PlanBox>
        <PlanBox className={`${oonCoverageStyle()} ${styles.medicalSpecsPlanBox}`}>
          <p className={styles.medicalLabel}>Co-insurance</p>
          {planSpecsCopy(plan.coinsurance)}
        </PlanBox>
        { !hasOONCoverage() &&
              <PlanBox className={styles.medicalSpecsAsteriskPlanBox}>
                {asterisk()}
                <p>NO / limited out of network coverage or co-pay contribution. {plan.sbc && 'Please see SBC for more details'}</p>
              </PlanBox>
        }

      </div>
    </div>
  </PlanSnapshotSection>

  function prosperCovered() {
    return <div>
      <p className={styles.prosper100}>100%</p>
      <p className={styles.prosperCovered}>Covered</p>
    </div>
  }

  // For when we remove the asterisk box
  function oonCoverageStyle() {
    if (hasOONCoverage() && !isCollapsed) {
      return styles.oonSpecsPlanBox
    }
  }

  function asterisk() {
    return !hasOONCoverage() && <span className={styles.asterisk}>*</span>
  }

  function visitAndPrescCopy(copay: string) {
    copay = copay.split('.')[0]
    switch (true) {
    case isLongText(copay) && !!plan.sbc:
      return <p>See SBC for more details</p>
    case isZeroAmt(copay):
      return <p>100% covered</p>
    case isPercentageAmt(copay):
      return <p>{`${copay}`}</p>
    case isDollarAmt(copay):
      return <p>{`${moneyString(copay)}`}</p>
    default:
      return <p>{`${copay}`}</p>
    }
  }

  function planSpecsCopy(amt: string) {
    amt = amt.split('.')[0]
    switch (true) {
    case isPercentageAmt(amt): {
      const youPayPercentage = parseInt(amt.substring(0, amt.length - 1))
      const coveredPercentage = 100 - youPayPercentage
      return <div className={styles.medicalPlanSpecsCopy}>
        <p className={styles.coveredFigure}>{`${coveredPercentage}%`}</p>
        <p className={styles.coveredText}>covered</p>
        { !isZeroAmt(amt) &&
          <>
            <p className={styles.youPayText}>you pay</p>
            <p className={styles.youPayFigure}>{`${youPayPercentage}%`}</p>
          </>
        }
      </div>
    }
    case isDollarAmt(amt):
      return <div className={styles.medicalPlanSpecsCopy}>
        <p style={{ color: isZeroAmt(amt) ? '#29B473' : '#17346F' }} className={styles.dollarFigure}>{moneyString(amt)}<span>/yr</span></p>
        <p style={{ color: isZeroAmt(amt) ? '#29B473' : '#17346F' }} className={styles.inNetwork}>in-network</p>
        {/* Add after we have in/out network information in plans <p>NO out of network contribution</p> */}
      </div>
    default:
      return <div className={styles.medicalPlanSpecsCopy}>
        <p className={styles.dollarFigure}>{amt}</p>
        <p className={styles.inNetwork}>in-network</p>
        {/* Add after we have in/out network information in plans <p>NO out of network contribution</p> */}
      </div>
    }
  }

  function prosperOnlyBox() {
    if (isProsper && individualsMode()) {
      return <div className={styles.isProsperOnlyBox}>
        <span>Upgrade to <em>Right Now</em> plan for additional coverage</span>
      </div>
    }
  }
}

export default MedicalPlanAdditionalSpecs
