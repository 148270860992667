/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import ContributionGraph from './ContributionGraph'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import ContributionAmount, { ContributionSplitType as Type, contributionType as initialType, ContributionSplitType } from 'Components/Stargate/Contribution/ContributionAmount'
import { useAsync } from 'react-use'
import { cleanContributionString } from 'Utilities/Plans/PlanCostUtils'
import Loader from 'Components/Rudimentary/Loader'
import { v3 } from 'Utilities/pharaoh'
import { MedicalPlan, Tier } from 'Utilities/pharaoh.types'
import { moneyString, moneyNumber } from 'Utilities/Plans/PremiumCalculators'
import ReactDOM from 'react-dom'

const ERShopPlansContribution: React.FC<PrivateWizardPageProps> = ({ onwards, stargate }) => {
  const async = useAsync(() => v3.groups(stargate.group?.id).plans.GET())
  const [type, setType] = useState(initialType(stargate.employerContributionIsEquitable, stargate.groupContribution))
  const [contribution, setContribution] = useState(stargate.groupContribution || '50%')
  const [graphHeight, setGraphHeight] = useState(0)

  useEffect(() => {
    window.addEventListener('resize', updateGraphHeight)
    updateGraphHeight()
    return () => {
      window.removeEventListener('resize', updateGraphHeight)
    }
  })

  useEffect(() => {
    const el = document.getElementById('content')
    if (async.loading || !el) return
    el.style.padding = '0'
    el.style.maxWidth = '100%'
    return () => {
      el.style.padding = ''
      el.style.maxWidth = ''
    }
  }, [async.loading])

  if (async.loading || !graphHeight) return <Loader />

  const plans: MedicalPlan[] = async.value

  const defaultPremium: { [key in Tier]: string } = {
    individual: '$350',
    singleParent: '$700',
    couple: '$950',
    family: '$1200'
  } // Default values for the graph

  const premiums = plans.map(plan => plan.premium.employee).reduce((a: { [key in Tier]: string }, b: { [key in Tier]: string }) => {
    return {
      individual: moneyString((moneyNumber(a.individual, 2) + moneyNumber(b.individual, 2)), 2),
      couple: moneyString((moneyNumber(a.couple, 2) + moneyNumber(b.couple, 2)), 2),
      singleParent: moneyString((moneyNumber(a.singleParent, 2) + moneyNumber(b.singleParent, 2)), 2),
      family: moneyString((moneyNumber(a.family, 2) + moneyNumber(b.family, 2)), 2)
    }
  }, { individual: '$0', couple: '$0', singleParent: '$0', family: '$0' }) // Default values need to be '$0', or the avg won't be correct

  const avgPremiums: { [key in Tier]: number } | undefined = plans.length ? {
    individual: moneyNumber(premiums.individual) / (plans.length, 2),
    couple: moneyNumber(premiums.couple) / (plans.length, 2),
    singleParent: moneyNumber(premiums.singleParent) / (plans.length, 2),
    family: moneyNumber(premiums.family) / (plans.length, 2)
  } : undefined

  return <div className={styles.mainContainer}>
    <h1>Select your Contribution!</h1>
    <div className={styles.middleContainer}>
      <div className={styles.middleLeft}>
        <div className={styles.amt}>
          <h2 className={styles.description}>How much do you want to contribute to your employees’ <b>Medical Plans?</b></h2>
          <p className={styles.amountDescription}>{copy(type)}</p>
          <div className={styles.amountContainer}>
            <ContributionAmount
              onChange={(amount: string, type?: ContributionSplitType) => {
                ReactDOM.unstable_batchedUpdates(() => {
                  setContribution(amount)
                  setType(type || ContributionSplitType.perEmployee)
                })
              }}
              value={contribution}
              splitType={type} />
          </div>
        </div>
        <div>
          <button className={styles.nextButton} onClick={() => onwards(go())}>Next Step</button>
          <p className={styles.amountInst}>Most carriers require a 50% contribution from the employer to the individual tier. If you contribute less than 50%, your plan selections may be limited.</p>
        </div>
      </div>
      <div className={styles.graph}>
        <ContributionGraph
          height={graphHeight}
          displayMode={type}
          contributionValue={cleanContributionString(contribution)}
          premiums={avgPremiums || defaultPremium}
        />
      </div>
    </div>
  </div>

  function updateGraphHeight() {
    const graphHeight = window.innerHeight - 515 > 300 ? window.innerHeight - 515 : 300
    setGraphHeight(graphHeight)
  }

  async function go() {
    let employerContributionIsEquitable: boolean
    switch (type) {
    case Type.allTiers:
    case Type.flatContribution:
      employerContributionIsEquitable = true
      break
    case Type.perEmployee:
      employerContributionIsEquitable = false
    }
    const data = {
      contributions: {
        medical: {
          value: contribution,
          isEquitable: employerContributionIsEquitable
        }
      }
    }
    await v3.groups(stargate.group?.id).PUT(data)
  }
}

function copy(type: Type) {
  switch (type) {
  case Type.perEmployee:
    return '% Per Employee, a percentage based on each employee.'
  case Type.flatContribution:
    return '$ Flat Contribution is a fixed amount that applies across each tier.'
  case Type.allTiers:
    return '% Across All Tiers is the percentage that will contribute across each tier equally.'
  }
}

export default ERShopPlansContribution
