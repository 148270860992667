import React, { useState } from 'react'
import ReactModal from 'Components/Rudimentary/CandorModal'
import styles from './AskAQuestion.module.scss'
import * as api from 'Utilities/pharaoh'
import { useForm } from 'react-hook-form'
import useUser from 'Utilities/Hooks/useUser'
import useToast from 'Utilities/Hooks/useToast'
import Loader from 'Components/Rudimentary/Loader'

const AskAQuestion: React.FC<ReactModal.Props> = props => {
  const user = useUser()
  const { register, handleSubmit } = useForm()
  const [disabled, setDisabled] = useState(false)
  const addToast = useToast()

  return <ReactModal {...props} style={style as any}>
    {child()}
  </ReactModal>

  function child() {
    if (user.loading) return <Loader />
    return <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={disabled}>
        <div className={styles.ask}>
          <h2>Ask a Question</h2> <p>We’ll get back with an answer ASAP.</p><br />
          <input
            className={styles.subject}
            type="text"
            placeholder="Subject"
            name="subject"
            ref={register}
          />
          <Email isHidden={!!(user.value?.email)} register={register} />
          <textarea
            className={styles.body}
            placeholder="Body"
            name="body"
            ref={register}
            required
          />
        </div>
        <input type="submit" value="Send" className={styles.sendButton} />
      </fieldset>
    </form>
  }

  async function onSubmit(data: any) {
    try {
      setDisabled(true)

      const { subject, body } = data
      const email = user.value?.email || data.email
      const referrerURL = window.location.href

      await api.v1.support.mail({ subject, body, referrerURL, email })
      addToast('Email sent! You should receive a response shortly.')
      if (props.onRequestClose) props.onRequestClose(null as any)
    } catch (error) {
      addToast(error)
    } finally {
      setDisabled(false)
    }
  }
}

function Email(props: any) {
  if (props.isHidden) {
    return <></>
  } else {
    return <input
      className={styles.subject}
      type="text"
      placeholder="Contact Email"
      name="email"
      ref={props.register}
      required
    />
  }
}

const style = {
  content: {
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '7px',
    outline: 'none',
    padding: '20px',
    width: '850px',
    height: '700px',
    boxShadow: '0 0 4px hsla(0, 0%, 0%, 0.35)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}

export default AskAQuestion
