import React from 'react'
import styles from './ContributionAmount.module.css'
import { $enum } from 'ts-enum-util'
import { classNames } from 'Utilities/etc'
import { moneyNumber } from 'Utilities/Plans/PremiumCalculators'
import CandorSelect from 'Components/Rudimentary/CandorSelect'

export enum ContributionSplitType {
  perEmployee = 'perEmployee',
  flatContribution = 'flatContribution',
  allTiers = 'allTiers'
}

enum ContributionType {
  percent = '%', fixed = '$'
}

interface Props {
  value?: string
  onChange: (value: string, splitType?: ContributionSplitType) => void
  className?: string
  splitType?: ContributionSplitType
}

const ContributionAmount: React.FC<Props> = ({ value, splitType, className, onChange }) => {
  const [amount, type] = extractContribution(value || '0%')

  return <div className={styles.contributionContainer}>
    <ContributionAmountInput
      type={type}
      onChange={onChangeAmount}
      value={amount}
      className={className}
    />
    {!splitType
      ? <ContributionTypeSelect
        onChange={type => onChange(type === ContributionType.percent ? `${amount}%` : `$${amount}`)}
        value={type}
        className={className}
      />
      : <ContributionSplitTypeSelect
        value={splitType || ContributionSplitType.perEmployee}
        onChange={type => {
          onChangeAmount(amount, type)
        }}
        className={className}
      />
    }
  </div>

  function onChangeAmount(amount: number, _splitType?: ContributionSplitType) {
    const type = _splitType || splitType
    switch (type) {
    case ContributionSplitType.allTiers:
    case ContributionSplitType.perEmployee:
      onChange(`${amount}%`, type)
      break
    case ContributionSplitType.flatContribution:
      onChange(`$${amount}`, type)
      break
    case undefined:
      onChange(type === ContributionType.percent ? `${amount}%` : `$${amount}`)
    }
  }
}

function extractContribution(value: string): [number, ContributionType] {
  const type = value.startsWith('$') ? ContributionType.fixed : ContributionType.percent
  switch (type) {
  case ContributionType.fixed:
    return [moneyNumber(value), type]
  case ContributionType.percent:
    return [parseInt(value), type]
  }
}

export default ContributionAmount

interface CAIProps {
  value: number
  onChange: (value: number) => void
  className?: string
  type: ContributionType
}

const ContributionAmountInput: React.FC<CAIProps> = ({ className, type, value, ...props }) => {
  return <input
    className={classNames(styles.amount, className)}
    name="amount"
    type="number"
    placeholder="0"
    onChange={onChange}
    value={value || ''} // Needs to be '' otherwise the older value is used when switching between ancillary types
  />

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    let newValue: number = parseInt(event.currentTarget.value)
    if (isNaN(newValue)) newValue = 0
    switch (type) {
    case ContributionType.percent:
      if (newValue > 100) {
        newValue = 100
      } else if (newValue < 0) {
        newValue = 0
      }
      props.onChange(newValue)
      break
    case ContributionType.fixed:
      if (newValue < 0) {
        newValue = 0
      }
      props.onChange(newValue)
    }
  }
}

export function ContributionSplitTypeText(value: ContributionSplitType): string {
  switch (value) {
  case ContributionSplitType.perEmployee:
    return '% Per Employee'
  case ContributionSplitType.flatContribution:
    return '$ Flat Contribution'
  case ContributionSplitType.allTiers:
    return '% Across All Tiers'
  }
}

interface CASProps {
  className?: string
  value: ContributionSplitType
  onChange: (value: ContributionSplitType) => void
}

const ContributionSplitTypeSelect: React.FC<CASProps> = ({ className, onChange, ...props }) => {
  const options = $enum(ContributionSplitType).map(value => ({ value, label: ContributionSplitTypeText(value) }))

  return <div className={classNames(styles.contType, className)}>
    <CandorSelect
      {...props}
      options={options}
      color='#1F2E4D'
      onChange={value => onChange(value as ContributionSplitType)}
    />
  </div>
}

interface CATProps {
  value: ContributionType
  className?: string
  onChange: (value: ContributionType) => void
}

const ContributionTypeSelect: React.FC<CATProps> = ({ className, value, ...props }) => {
  return <select
    className={classNames(styles.contributionButton, className)}
    onChange={onChange}
    name="type"
    value={value}
    color='#16346F'
  >
    <option value={ContributionType.percent}>%</option>
    <option value={ContributionType.fixed}>$</option>
  </select>

  function onChange(event: React.ChangeEvent<HTMLSelectElement>) {
    props.onChange(event.currentTarget.value as ContributionType)
  }
}

export function contributionType(isEquitable: boolean, contribution?: string): ContributionSplitType {
  if (!contribution) { return ContributionSplitType.perEmployee }
  if (contribution.startsWith('$')) return ContributionSplitType.flatContribution
  if (isEquitable) return ContributionSplitType.allTiers
  return ContributionSplitType.perEmployee
}
