import React, { useState } from 'react'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import { useSet, useMap } from 'react-use'
import styles from './index.module.scss'
import EmployeeInfoWaiveModal from 'Components/Stargate/Waiving/EmployeeInfoWaiveModal'
import { sortBy, compact } from 'lodash'
import { useHistory } from 'react-router-dom'
import { Route } from 'Utilities/Route'
import { put } from 'Utilities/fetch++'
import EmployeeSelectorItem from 'Components/Stargate/EmployeeSelectorItem'
import useFullContentArea from 'Utilities/Hooks/useFullContentArea'

const ERShopCensusWaive: React.FC<PrivateWizardPageProps> = ({ onwards, stargate }) => {
  const [values, { toggle }] = useSet(initialWaived())
  const [selected, setSelected] = useState<string | undefined>(undefined)
  const [reasons, { set: setReason }] = useMap()
  const members = sortBy(stargate.members, 'name')
  const history = useHistory()
  const groupID = stargate.group?.id
  useFullContentArea()

  return <div className={styles.censusWaivingContainer}>
    <header>
      <h1 className='shop-h1-periwinkle '>Employees Covered</h1>
      <h2 className='shop-h2-navy'>Everyone is covered until deselected. If you aren’t sure, keep selected.</h2>
    </header>
    <div className={styles.listContainer}>
      {members.map(member =>
        <EmployeeSelectorItem
          id={member.id}
          key={member.id}
          variant='checkmark'
          groupGroupId={groupID}
          callback={onToggle}
          name={member.name}
          selected={!values.has(member.id)}
        />)
      }
    </div>
    <div className={styles.buttonsContainer}>
      <button onClick={back} className={styles.backButton}>
        <i className={'material-icons'}>arrow_back</i> Back to Census
      </button>
      <button className='shop-next-button' onClick={() => onwards(go())}>Next</button>
    </div>
    <p className={styles.note}>Everyone is selected for coverage by default, please click on the employees who wish to waive<br/>coverage to deselect them. If you do not know, please leave them selected.</p>
    <EmployeeInfoWaiveModal
      waivingEmployee={members.find(m => m.id === selected)}
      isOpen={selected !== undefined}
      onCancel={cancelModal}
      callback={waive} />
  </div>

  function onToggle(id: string) {
    toggle(id)
    if (!values.has(id)) {
      setSelected(id)
    }
  }
  function cancelModal() {
    toggle(selected!)
    setSelected(undefined)
  }
  async function waive(reason?: string) {
    if (!reason) {
      toggle(selected!)
    } else {
      setReason(selected!, reason)
    }
    setSelected(undefined)
  }
  function initialWaived(): Set<string> {
    const waived = stargate.members.filter(ee => ee.is_waived)
    const ids = waived.map(ee => ee.id)
    return new Set(ids)
  }
  function back() {
    history.push(Route.erStargateCensus)
  }
  async function go() {
    if (values.size === members.length) throw new Error('At least one member must have coverage')

    const payload = compact(members.map(member => {
      const waiveReason = reasons[member.id]
      if (waiveReason) {
        return {
          email: member.email,
          isWaiving: true,
          waiveReason
        }
      } else if (!values.has(member.id)) {
        return { email: member.email, isWaiving: false }
      } else {
        // we don’t know their potentially already set waive reason
        // thus we cannot risk losing it, so don’t submit
        return null
      }
    }))

    await put(`/groups/${groupID}/users/waive`, payload)
  }
}

export default ERShopCensusWaive
