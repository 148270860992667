/* eslint-disable camelcase */
import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { $enum } from 'ts-enum-util'
import componentForRoute from 'Utilities/componentForRoute()'
import { Route as RaRoute } from 'Utilities/Route'
import ErrorBoundary from 'Components/Primitives/ErrorBoundary'
import Header from './Header'
import Menu from 'Components/Stargate/TableOfContents'
import Tabs from 'Components/Anubis/Tabs'
import FourOhFour from 'Components/Rudimentary/404'
import Tracking from 'Components/Tracking'
import Titler from 'Components/Titler'
import useContentMode from 'Utilities/Hooks/useContentMode'

const App: React.SFC = () => {
  useContentMode()

  return <ErrorBoundary>
    <Header>
      <Tabs />
      <Menu />
    </Header>
    <div id="content">
      <Switch>
        {/* standard routes */}
        {$enum(RaRoute).map(componentForRoute)}

        {/* deprecated redirects so old emailed links work */}
        <Route exact path={[
          '/sign-up/token/:token',
          '/dashboard/sign-up/token/:token'
        ]} render={props => <Redirect to={`${RaRoute.signUp}/${props.match.params.token}`} /> }/>
        <Route exact path='/sign-in' render={() => <Redirect to={RaRoute.signIn} />} />
        <Route exact path='/sign-out' render={() => <Redirect to={RaRoute.signOut} />} />
        <Route path={['/employee', '/employer']} render={props => <Redirect to={`/shop${props.location.pathname}`} />} />
        <Route exact path='/get-started' render={() => {
          window.location.assign('/shop/agency')
          return null
        }} />

        {/* catch all 404 */}
        <Route component={FourOhFour} />
      </Switch>
    </div>
    <Tracking />
    <Titler />
  </ErrorBoundary>
}

export default App
