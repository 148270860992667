import React from 'react'
import MedicalPlanAdditionalSpecs from './plan-subcomponents/MedicalPlanAdditionalSpecs'
import { MedicalPlanEmployeeCalculator, moneyNumber } from 'Utilities/Plans/PremiumCalculators'
import Plan, { PlanBody } from './plan-subcomponents/Plan'
import PlanTitle from './plan-subcomponents/PlanTitle'
import EmployeePremiumBreakdown from './plan-subcomponents/EEPremiumBreakdown'
import { MedicalPlan, Tier } from 'Utilities/pharaoh.types'
import { Contribution, PremiumSplits } from './plan-subcomponents/PlanHelpers'

interface Props {
  plan: MedicalPlan
  tier: Tier
  // Prefer to recieve PharaohSplits else provide contribution, splits, groupMemberID so we can calculate ourselves
  premiumSplits?: PremiumSplits
  contribution?: Contribution
  splits?: any // TODO Make into a type
  groupMemberID?: string
  selected?: boolean
  selectHandler?: (plan: MedicalPlan) => void
}

const EEMedicalPlan: React.FC<Props> = ({ plan, contribution, tier, selected, selectHandler, premiumSplits, ...props }) => {
  let ee, er
  if (premiumSplits) {
    ee = moneyNumber(premiumSplits.ee)
    er = moneyNumber(premiumSplits.er)
  } else {
    if (!contribution || !props.splits || !props.groupMemberID) { console.warn('Insufficient props to calculate Premium') }
    const calculator = new MedicalPlanEmployeeCalculator(plan, contribution?.value, contribution?.isEquitable, tier, props.splits, props.groupMemberID!)
    ee = calculator.ee()
    er = calculator.er()
  }

  return <Plan selected={selected || false}>
    <PlanTitle
      planName={plan.name}
      carrier={plan.carrier}
      includesProsperBenefits
      selectHandler={selectHandler ? () => selectHandler(plan) : undefined}
      selected={selected}
      sbc={plan.sbc}
    />
    <PlanBody>
      <EmployeePremiumBreakdown erPremium={er} eePremium={ee} tier={tier}/>
      <MedicalPlanAdditionalSpecs plan={plan} isCollapsed={true}/>
    </PlanBody>
  </Plan>
}

export default EEMedicalPlan
