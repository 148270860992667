import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Route } from 'Utilities/Route'

enum ContentMode {
  shop = 'shop',
  dashboard = 'dashboard'
}

function useContentMode() {
  const location = useLocation()
  useEffect(go, [location])

  function go() {
    const el = document.getElementsByTagName('body')
    if (!el.length) return
    const isStargate = location.pathname.startsWith(Route.stargate)
    const classForMode = isStargate ? ContentMode.shop : ContentMode.dashboard
    el[0].className = classForMode

    return () => {
      el[0].className = ''
    }
  }
}

export default useContentMode
