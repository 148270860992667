/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react'
import styles from './index.module.css'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import { useForm } from 'react-hook-form'
import CandorInput from 'Components/Rudimentary/CandorInput'
import CandorSelect from 'Components/Rudimentary/CandorSelect'
import { CandorDatePicker } from 'Components/Anubis/CandorForm'
import api from 'Utilities/Deprecated/api'
import { localMidnightToPharaohFormat } from 'Utilities/pharaoh'
import { showHireDate, showTaxWitholdingStatus } from 'Utilities/config'

const EEShopWorkStatus: React.FC<PrivateWizardPageProps> = ({ stargate, onwards }) => {
  const defaultValues = stargate?.userMetadata as any
  if (defaultValues?.taxWithholdingStatus) {
    defaultValues.taxWithholdingStatus = defaultValues.taxWithholdingStatus.code?.toString()
  }
  const { register, handleSubmit, control } = useForm({ defaultValues })
  const hiddenStyle = { display: 'none' }

  return <div className={styles.mainContainer}>
    <h1 style={{ marginBottom: 4 }} className='shop-h1-periwinkle'>Let’s verify your work status</h1>
    <h2 className='shop-h2-navy'>We make getting healthcare coverage super simple.</h2>
    <div className={styles.formContainer}>
      <form autoComplete="on" onSubmit={handleSubmit(save)}>
        <div className={styles.left}>
          <CandorInput name="jobTitle" ref={register} autoComplete="organization-title" placeholder="Job Title" required />
        </div>
        <div className={styles.right}>
          <CandorInput name="hoursPerWeek" ref={register} type="number" placeholder="Hours Worked Per Week" required={true} />
        </div>
        <div className={styles.left} style={showHireDate() ? undefined : hiddenStyle}>
          <CandorDatePicker
            name='hireDate'
            id="hireDatePickerId"
            placeholder='Hire Date'
            control={control}
            required={showHireDate()}
            showYearDropdown
            autoComplete="off"
            maxDate={new Date()}
            dropdownMode="select"
          />
        </div>
        <div className={styles.right} style={showTaxWitholdingStatus() ? undefined : hiddenStyle}>
          <CandorSelect
            name="taxWithholdingStatus"
            control={control}
            className={styles.select}
            options={taxOptions}
            placeholder="Tax Withholding Status"
            required={showTaxWitholdingStatus()}
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <input type='submit' value='Next' />
        </div>
      </form>
    </div>
  </div>

  async function save(data: any) {
    data.taxWithholdingStatus = parseInt(data.taxWithholdingStatus)
    data.hoursPerWeek = parseInt(data.hoursPerWeek)
    data.hireDate = localMidnightToPharaohFormat(data.hireDate)
    onwards(api.updateUser(data))
  }
}

const taxOptions = [
  {
    value: '0',
    label: 'W2'
  },
  {
    value: '1',
    label: '1099'
  },
  {
    value: '2',
    label: 'Owner/Partner'
  }
]

export default EEShopWorkStatus
