import React from 'react'
import { useHistory } from 'react-router-dom'
import styles from './BackTo.module.scss'

const BackTo: React.FC<{route: string}> = ({ children, route }) => {
  const history = useHistory()
  return <button className={styles.goBack} onClick={() => history.replace(route)}>{children}</button>
}

export default BackTo
