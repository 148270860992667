import React, { useState, useCallback } from 'react'
import styles from './StargateModal.module.scss'
import CandorModal from 'Components/Rudimentary/CandorModal'
import Loader from 'Components/Rudimentary/Loader'
import ReactModal from 'react-modal'
import { merge } from 'lodash'

declare const InstallTrigger: any

export interface SMProps extends ReactModal.Props {
  isOpen: boolean
  loading?: boolean
  styles?: React.CSSProperties
  closeButtonStyles?: React.CSSProperties
  forceCloseButtonType?: CloseButtonType
}

export enum CloseButtonType {
  none = 'none',
  inner = 'inner',
  outer = 'outer'
}

const StargateModal: React.SFC<SMProps> = props => {
  const [closeButtonType, setCloseButtonType] = useState(props.forceCloseButtonType || CloseButtonType.none)

  const measuredRef = useCallback(node => {
    if (node !== null) {
      const width = node.getBoundingClientRect().width
      if (width <= 500) {
        setCloseButtonType(CloseButtonType.outer)
      } else {
        setCloseButtonType(CloseButtonType.inner)
      }
    }
  }, [])

  function closeButton() {
    switch (closeButtonType) {
    case CloseButtonType.none:
      return
    case CloseButtonType.inner:
      return <button style={props.closeButtonStyles} className={styles.closeInner} onClick={props.onRequestClose}/>
    case CloseButtonType.outer:
      return <button style={props.closeButtonStyles} className={styles.closeOuter} onClick={props.onRequestClose}/>
    }
  }

  // we disabled `shouldCloseOnOverlayClick` to prevent inadvertent data loss
  return <CandorModal
    {...props}
    style={modalStyle(closeButtonType, props.styles)}
    shouldCloseOnOverlayClick={!props.onRequestClose}
  >
    <div ref={props.forceCloseButtonType ? undefined : measuredRef} className={styles.container}>
      { props.loading && <div className={styles.loadingContainer}><Loader center/></div> }
      { closeButton() }
      <div className={styles.content}>
        { props.children }
      </div>
    </div>
  </CandorModal>
}

const modalStyle = (closeButtonType: CloseButtonType, customerStyles?: React.CSSProperties): ReactModal.Styles => {
  const styles: ReactModal.Styles = {
    content: {
      border: '1px solid #ccc',
      background: 'white',
      overflow: closeButtonType === CloseButtonType.outer ? 'visible' : 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: 7,
      outline: 'none',
      width: 820,
      height: 'fit-content',
      padding: 0,
      boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.16)',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  }

  if (customerStyles) {
    merge(styles.content, customerStyles)
  }

  /*
    Need to do this madness because display: table fucks up chromium
    browsers, and height: fit-content fucks up firefox and safari.

    If you can find a better solution please fix this
  */
  if (customerStyles?.height === 'fit-content' && (isFirefox() || isSafari())) {
    merge(styles.content, { display: 'table' })
  }

  return styles
}

// Duck-typing since it is very easy to spoof User agent string
// https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser

export function isFirefox() { return typeof InstallTrigger !== 'undefined' }

export function isSafari() {
  return /constructor/i.test((window as any).HTMLElement) || ((p): boolean => {
    return p.toString() === '[object SafariRemoteNotification]'
  })(!(window as any).safari || (window as any).safari?.pushNotification)
}

export default StargateModal
