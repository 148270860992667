import React, { ReactElement } from 'react'
import { Link, Redirect } from 'react-router-dom'
import * as api from 'Utilities/pharaoh'
import Loader from 'Components/Rudimentary/Loader'
import Error from 'Components/Primitives/Error'
import { Route } from 'Utilities/Route'
import useUser, { PowerLevel, Response as User } from 'Utilities/Hooks/useUser'
import { useForm } from 'react-hook-form'
import { post } from 'Utilities/fetch++'
import CandorInput from 'Components/Rudimentary/CandorInput'
import { compact, uniq, concat } from 'lodash'
import { obeliskMode, isProduction } from 'Utilities/config'

const Landing: React.SFC = () => {
  const async = useUser<[User, Venues]>(async user => {
    const venues = await api.v3.users().venues() as Venues
    return [user, venues]
  })
  const { register, handleSubmit } = useForm()

  if (async.loading) return <Loader />
  if (async.error) return <Error error={async.error} />

  const [user, venues] = async.value!
  const groups = venues.groups.reduce((rv: any, group: any) => { rv[group.id] = group.name; return rv }, {})

  const redirect = onlyOneDestination(venues)
  if (redirect) {
    return <Redirect to={redirect} />
  }

  function mapper(id: string, route: string) {
    return <Link to={route} onClick={() => { localStorage.overrideGroupID = id }}>{groups[id]}</Link>
  }

  const stargateERs = venues.stargate.er.map((id: string) => mapper(id, `${Route.stargate}/employer`))
  const stargateEEs = venues.stargate.ee.map((id: string) => mapper(id, `${Route.stargate}/employee`))
  const anubisERs = venues.anubis.er.map((id: string) => mapper(id, Route.dashboardEmployer))
  const anubisEEs = venues.anubis.ee.map((id: string) => mapper(id, Route.dashboardEmployee))

  const els: ReactElement[] = []
  let key = 0

  function ul(input: ReactElement[]) {
    els.push(<ul key={key++}>{input.map((el, ii: number) => <li key={ii}>{el}</li>)}</ul>)
  }

  if (user.powerLevel >= PowerLevel.broker) {
    els.push(<p key={key++}>To invite new users to your benefit portal’s shopping experience use the following link:</p>)
    els.push(<p key={key++}>{shopLink()}</p>)
    els.push(<h1 key={key++} style={{ marginTop: '6rem' }}>Your Links</h1>)
  }

  if (venues.anubis.agency) {
    els.push(<h2 key={key++}>Agency Dashboard</h2>)
    els.push(<Link key={key++} to={Route.agencyDashboard}>Agency Dashboard</Link>)
    els.push(<p key={key++}><em>This is your primary portal.</em></p>)
  }

  function extraInfoCheck(array: any[]) {
    return array.length > 1 || user.powerLevel >= PowerLevel.broker
  }

  if (anubisERs.length) {
    els.push(<h2 key={key++}>HR Dashboard</h2>)
    if (extraInfoCheck(anubisERs)) els.push(<p key={key++}>For the following groups, you’re either an employee or you administer the group.</p>)
    ul(anubisERs)
  }
  if (anubisEEs.length) {
    els.push(<h2 key={key++}>Employee Dashboard</h2>)
    if (extraInfoCheck(anubisEEs)) els.push(<p key={key++}>You are an employee in these groups.</p>)
    ul(anubisEEs)
  }

  if (stargateERs.length) {
    els.push(<h2 key={key++}>EmployER Shopping Experience</h2>)
    if (extraInfoCheck(stargateERs)) els.push(<p key={key++}>For the following groups, you’re either an employee or you administer the group.</p>)
    ul(stargateERs)
  }
  if (stargateEEs.length) {
    els.push(<h2 key={key++}>EmployEE Shopping Experience</h2>)
    if (extraInfoCheck(stargateEEs)) els.push(<p key={key++}>You are an employee in these groups.</p>)
    ul(stargateEEs)
  }

  if (superAdmin() || !isProduction()) {
    els.push(<h1 key={key++} style={{ marginTop: '6rem' }}>Superuser Actions</h1>)
    els.push(<form onSubmit={handleSubmit(onSubmit)} key={key++}>
      <CandorInput
        name="email"
        ref={register}
        placeholder='Switch User'
        style={{ width: '30rem', margin: '1rem 0' }} />
      <input type="submit" />
    </form>)
  }

  return <>
    <h1>Welcome, {user.name || user.email}</h1>
    {els}
  </>

  async function onSubmit(data: any) {
    // NOTE not catching because who cares for Candor ees only
    const rsp = await post('/v2/support/user', data)
    api.setToken(rsp.token)
    window.location.reload(false)
  }

  function superAdmin() {
    return user.powerLevel >= PowerLevel.candorEmployee
  }

  function shopLink(): ReactElement {
    const path = !obeliskMode()
      ? '/shop'
      : `/shop/${user.slug}`
    const host = window.location.host
    const prefix = process.env.REACT_APP_BASENAME || ''
    const display = `${window.location.protocol}//${host}${prefix}${path}`
    return <Link to={path}>{display}</Link>
  }
}

function onlyOneDestination({ anubis, stargate }: {anubis: any, stargate: any}): string | null {
  let count = anubis.ee.length
  count += anubis.er.length
  if (anubis.agency) count += 1
  count += stargate.ee.length
  count += stargate.er.length

  if (!anubis.agency) {
    // if user is a groupManager with only one group, take them to their HR dashboard
    // if user is an employee of only one group, take them to their EE Dashboard
    const options = uniq(compact(concat(anubis.ee, anubis.er, stargate.er, stargate.ee)))
    if (options.length === 1 && options[0] !== undefined) {
      if (anubis.er.length) {
        return Route.dashboardEmployer
      } else {
        return Route.dashboardEmployee
      }
    }
  }

  // user has options, render them
  if (count > 1) return null

  if (anubis.ee.length) return Route.dashboardEmployee
  if (anubis.er.length) return Route.dashboardEmployer
  if (stargate.ee.length) return '/shop/employee'
  if (stargate.er.length) return '/shop/employer'
  if (anubis.agency) return Route.agencyDashboard

  // well hi there user who entered the shop but didn’t create a group yet
  if (obeliskMode()) {
    // nothing we can do without a slug
    throw new window.Error('We can’t find a destination for you, please sign out or contact support.')
  } else {
    return Route.stargate
  }
}

interface Venues {
  groups: { id: string, name: string }[]
  anubis: { ee: string[], er: string[], agency: boolean }
  stargate: { ee: string[], er: string[] }
}

export default Landing
