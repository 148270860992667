import React from 'react'
import { Tier, MedicalPlan } from 'Utilities/pharaoh.types'
import styles from './RNCartSummary.module.scss'
import { IndividualModeProsperName } from 'Components/Plans/EERightNowPlan'
import { moneyString, MedicalPlanEmployeeCalculator } from 'Utilities/Plans/PremiumCalculators'

interface Props {
  plan: MedicalPlan
  tier: Tier
  memberID: string
  callback(premium: string): void
}

enum CostType {
  health = 'health',
  prosper = 'prosper',
  total = 'total'
}

const uhOneValueSelectPlanName = 'Value Select'

const RNCartSummary: React.FC<Props> = ({ plan, tier, memberID, callback }) => {
  const isUhOnePlan = plan.name === uhOneValueSelectPlanName && plan.carrier === 'UnitedHealthcare'
  const isProsperPlan = plan.carrier === IndividualModeProsperName
  const copy = `
    These are your final costs. You will be charged for ${isUhOnePlan ? 'two months' : 'one month'}
    at the time of payment, ${isUhOnePlan ? 'and four additional' : 'with recurring'}
    monthly payments thereafter.
    `

  // Don't need for Individual Mode, but calculator requires it
  const contribution = '0%'
  const isEquitable = true
  const splits: any[] = []

  const calculator = new MedicalPlanEmployeeCalculator(plan, contribution, isEquitable, tier, splits, memberID)

  const cost = calculator.ee()
  const multiplier = isUhOnePlan ? 2 : 1
  const total = cost * multiplier
  const callbackAmount = moneyString(cost, 2) // Forcing precision instead of using conf.moneyDecimals() to avoid charging customers inaccurately

  return <div className={styles.container}>
    <div className={styles.summary}>
      <h2>Cart Summary</h2>
      <em>{ copy }</em>
    </div>
    <div className={styles.costs}>
      <h1 className='shop-h1-periwinkle'>Final Costs</h1>
      { !isProsperPlan && <PlanCost type={CostType.health} cost={cost} /> }
      <PlanCost type={CostType.prosper} cost={isProsperPlan ? cost : 0} />
      <hr />
      <PlanCost className={styles.total} type={CostType.total} cost={total} />
      <button onClick={() => callback(callbackAmount)}>Finalize and Pay</button>
    </div>
  </div>
}

interface PCProps {
  cost: number
  type: CostType
  className?: string
}

const PlanCost: React.FC<PCProps> = ({ cost, type, className }) => {
  function copy() {
    switch (type) {
    case CostType.health:
      return 'Cost of plan per month:'
    case CostType.prosper:
      return 'Immediate Solution:'
    case CostType.total:
      return 'Total Due Today:'
    }
  }

  function costString() {
    switch (type) {
    case CostType.health:
      return <><span>{moneyString(cost)}</span>/mo</>
    case CostType.prosper:
      return cost
        ? <><span>{moneyString(cost)}</span>/mo</>
        : <span>Included</span>
    case CostType.total:
      return <span>{moneyString(cost)}</span>
    }
  }

  return <div className={className || ''}>
    <h3>{copy()}</h3>
    <div>{costString()}</div>
  </div>
}

export default RNCartSummary
