import React from 'react'
import baseStyles from './Plan.module.scss'
import styles from './PremiumBreakdown.module.scss'
import { Tier } from 'Utilities/pharaoh.types'
import { tierMarketingCopy, Contribution } from './PlanHelpers'
import { AncillaryPlanGroupCalculator, moneyNumber, moneyString } from 'Utilities/Plans/PremiumCalculators'
import { Member } from 'Utilities/Hooks/useStargate'

/* eslint-disable camelcase */
interface Props {
  contribution: Contribution
  rates: { [key in Tier]: string }
  members: Member[]
}
/* eslint-enable camelcase */

const PremiumBreakdown: React.FC<Props> = ({ contribution, rates, members }) => {
  const classes = [baseStyles.section, styles.pbcontainer]
  const calc = new AncillaryPlanGroupCalculator({ rates, plan: {} as any }, contribution?.value, members)

  return <div className={classes.join(' ')}>
    <div className={baseStyles.box}>
      <div className={styles.sectionTitle}>Your Cost</div>
      <div className={styles.erCost}><span>$</span>{moneyNumber(calc.avgEr())}<span>/mo</span></div>
      <div className={styles.perEmployee}>Per Employee</div>
    </div>
    <div className={baseStyles.box}>
      <div className={styles.sectionTitle}>Employee Cost</div>
      <div className={styles.premiums}>
        { Object.keys(Tier).map(tier =>
          <div key={tier}>
            <span>{tierMarketingCopy(tier as Tier)}</span>
            <div>{moneyString(calc.eeForTier(tier as Tier))}</div>
          </div>
        )}
      </div>
    </div>
  </div>
}

export default PremiumBreakdown
