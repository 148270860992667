import React, { useState } from 'react'
import styles from './index.module.scss'
import { useAsync } from 'react-use'
import * as api from 'Utilities/pharaoh'
import { Question, QuestionAnswer } from 'Utilities/pharaoh.types'
import Loader from 'Components/Rudimentary/Loader'
import Error from 'Components/Primitives/Error'
import { useForm } from 'react-hook-form'
import CandorSwitch from 'Components/Rudimentary/CandorSwitch'
import { individualsMode } from 'Utilities/config'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'

const EEShopMedical: React.FC<PrivateWizardPageProps> = ({ stargate, onwards }) => {
  const { group, groupMember } = stargate
  const { handleSubmit, control } = useForm()
  const [questions, setQuestions] = useState<Question[]>([])
  const [answers, setAnswers] = useState<QuestionAnswer[]>([])
  const [formID, setFormID] = useState('ec59836c-db1a-489b-b87f-1bc783feb788') // FIXME default to NatGen form ID
  const async = useAsync(async() => {
    const rsp = await api.v2.groups(group?.id).users.redflags.GET()
    setQuestions(rsp.questions)
    setAnswers(rsp.answers)
    setFormID(rsp.questions[0].formID)
  })

  if (!group || !groupMember) {
    return <Error error='Bad Request' />
  }

  if (async.loading) return <Loader />
  if (async.error) return <Error error={async.error} />

  const onsubmit = handleSubmit((data: Record<string, any>) => {
    const payload: QuestionAnswer[] = []
    for (const id in data) {
      payload.push({
        value: data[id],
        formID,
        questionID: id,
        groupID: group.id,
        memberID: groupMember.id
      })
    }
    onwards(api.v2.groups(group?.id).users.redflags.POST({ answers: payload }))
  })

  function pretty(question: Question) {
    if (question.text.includes('i.e.')) {
      const foo = question.text.split('i.e.')
      return <>{foo[0]}<br/><span className={styles.explainer}>{`i.e.${foo[1]}`}</span></>
    }
    return <>{question.text}</>
  }

  return <div className={styles.mainContainer}>
    <header className={styles.header}>
      { individualsMode()
        ? <h1 className='shop-h1-periwinkle'>Do you or any of your dependents<br/>have any of the following?</h1>
        : <>
          <h1 className='shop-h1-periwinkle'>Medical History</h1>
          <h2 className='shop-h2-navy'>Have you or any of your dependents received treatment, testing, consulted with or received a diagnosis from a physician or provider for any of the following within the past 5 years:?</h2>
        </>
      }
    </header>
    <form onSubmit={onsubmit} className={styles.form}>
      {
        questions?.map((q: Question) => {
          if (!q.id) { return }
          const answer = answers.find(a => a.questionID === q.id)
          return <label key={q.id}>{pretty(q)}
            <CandorSwitch control={control} name={q.id} defaultValue={answer?.value} className={styles.switch}/>
            <span className={styles.radio}/>
          </label>
        })
      }
      <input type='submit' value='Next'/>
    </form>
  </div>
}

export default EEShopMedical
