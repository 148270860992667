import React from 'react'
import styles from './ReviewPlansCart.module.scss'
import { MedicalPlanGroupCalculator, AncillaryPlanGroupCalculator, moneyString, moneyNumber, AncillaryPlanUnion } from 'Utilities/Plans/PremiumCalculators'
import { Member, ContributionSplit } from 'Utilities/Hooks/useStargate'
import { MedicalPlan } from 'Utilities/pharaoh.types'

interface Props {
  handleContinue: () => void
  nonWaivedMemberCount: number
  isProsperOnly: boolean
  medicalPlans: MedicalPlan[]
  dentalPlans: AncillaryPlanUnion[]
  visionPlans: AncillaryPlanUnion[]
  members: Member[]
  dentalContribution: string
  visionContribution: string
  splits: ContributionSplit[]
  medicalContribution: string
  isEquitable: boolean
}

type PlanType = 'Medical' | 'Prosper' | 'Ancillary'

const ReviewPlansCart: React.FC<Props> = ({ medicalContribution, splits, isEquitable, dentalContribution, visionContribution, medicalPlans, handleContinue, nonWaivedMemberCount, isProsperOnly, dentalPlans, visionPlans, members }) => {
  return <div className={styles.container}>
    <div className={styles.cartHeader}>
      <h1>Review Estimated Costs</h1>
      <h2>Review your plans below, make sure your contributions are correct, and then click <i>finalize plan selection</i> to continue.</h2>
      <h2>You can then invite your employees to begin selecting their coverage and we <i>will contact you with next steps.</i></h2>
      <button onClick={handleContinue}>
          Finalize Plan Selections
      </button>
    </div>
    <div className={styles.cartContainer}>
      {renderCart()}
    </div>
  </div>

  function estimatedCost(planType: PlanType, cost: number) {
    if (planType === 'Medical' && isProsperOnly) {
      return <p className={styles.figure}>$0<span>/mo</span></p>
    }

    if (planType === 'Prosper' && !isProsperOnly) {
      return <p className={styles.figure}>Included</p>
    } else {
      return <p className={styles.figure}>{moneyString(cost)}<span>/mo</span></p>
    }
  }

  function renderCart() {
    return <>
      <p className={styles.memberCount}>{`(for ${nonWaivedMemberCount} ${nonWaivedMemberCount === 1 ? 'employee' : 'employees'})`}</p>
      <div>
        <div>
          <p className={styles.planLabel}>Estimated Medical Plans</p>
          {estimatedCost('Medical', medicalPremium())}
        </div>
        <div>
          <p className={styles.planLabel}>Estimated Ancillary Plans</p>
          {estimatedCost('Ancillary', ancillaryPremium())}
        </div>
        <div>
          <p className={styles.planLabel}>Prosper Benefits+</p>
          {estimatedCost('Prosper', prosperPremium())}
        </div>
        <hr></hr>
        <div className={styles.total}>
          <p className={styles.planLabel}><b>Estimated Total<br />Employer Costs:</b></p>
          <p className={styles.figure}>{totalPremiumString()}<span>/mo</span></p>
        </div>
      </div>
    </>
  }

  // slight hack. have to pre-round because we display rounded individual estimates.
  // without this, math fails to add up.
  function totalPremiumString() {
    return moneyString(moneyNumber(medicalPremium()) + moneyNumber(prosperPremium()) + moneyNumber(ancillaryPremium()))
  }

  function medicalPremium() {
    const baseContribution = { value: medicalContribution, isEquitable }
    if (medicalPlans.length === 0) {
      // something wrong
      return 0
    }
    const calcs = medicalPlans.map(plan => new MedicalPlanGroupCalculator(plan, baseContribution, splits, members))
    const ers = calcs.map(calc => calc.er())
    const sum = ers.reduce((a, b) => a + b, 0)
    return sum / medicalPlans.length
  }

  function prosperPremium() {
    if (isProsperOnly) {
      return 34.95 * nonWaivedMemberCount
    } else {
      return 0
    }
  }

  function ancillaryPremium() {
    const dentalCalculators = dentalPlans.map(plan =>
      new AncillaryPlanGroupCalculator(plan, dentalContribution, members)
    )
    const visionCalculators = visionPlans.map(plan =>
      new AncillaryPlanGroupCalculator(plan, visionContribution, members)
    )

    const dentalAmount = dentalCalculators.map(calc => calc.er()).reduce((a, b) => a + b, 0)
    const visionAmount = visionCalculators.map(calc => calc.er()).reduce((a, b) => a + b, 0)
    const dentalAverage = dentalAmount ? dentalAmount / dentalCalculators.length : 0
    const visionAverage = visionAmount ? visionAmount / visionCalculators.length : 0
    const total = dentalAverage + visionAverage

    return total
  }
}

export default ReviewPlansCart
