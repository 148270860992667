/* eslint-disable camelcase */
import React, { ReactElement } from 'react'
import styles from './TableOfContents.module.scss'
import { Section, Chapter, State } from './Section'
import { Route } from 'Utilities/Route'
import { Link, useLocation } from 'react-router-dom'
import useStargate, { Response as Stargate } from 'Utilities/Hooks/useStargate'
import { isAuthenticated } from 'Utilities/pharaoh'
import { showCarrierApplication, individualsMode, obeliskMode, showExistingCoverageChapter } from 'Utilities/config'
import * as config from 'Utilities/config'
import { Logo } from 'Header'

export enum AppMode {
  employee = 'employee', employer = 'employer'
}

const ToC: React.FC = () => {
  const location = useLocation()
  const appMode = useAppMode()
  if ((config.showMJMFlair() && !appMode) || !location.pathname.startsWith(Route.stargate)) { return <></> }

  let sections
  switch (appMode) {
  case AppMode.employer:
    sections = <EmployerSections />
    break
  case AppMode.employee:
    sections = <EmployeeSections />
  }
  //

  return <div className={styles.bgContainer}>
    <div className={styles.container}>
      { !config.showMJMFlair() && <div className={styles.logo}>
        <Link to='/'><Logo/></Link>
      </div> }
      <div className={styles.sections}>
        {sections && sections}
      </div>
    </div>
    <SignOutLink />
  </div>
}

const EmployerSections: React.FC = () => {
  const stargate = useStargate()

  const rv = <>
    <Section number={1} title='About Your Company'>
      <Chapter
        title='Coverage Status'
        description='Your current situation'
        destination={Route.erStargateGetStarted}
      />
      <Chapter
        title='Census'
        description='Your employee information'
        destination={Route.erStargateCensus}
      />
      <Chapter
        title='Waiving Coverage'
        description='Employees forgoing coverage'
        destination={Route.erStargateCensusWaive}
      />
    </Section>
    <Section number={2} title='Your Coverage'>
      <Chapter
        title='Contribution'
        description='Your contribution amounts'
        destination={Route.erStargateContribution} />
      <Chapter
        title='Special Coverage Groups'
        description='Carve out contribution groupings'
        destination={Route.erStargateCarvedContributions} />
      <Chapter
        title='Plan Selection'
        description='Choose your coverage'
        destination={Route.erStargatePlans} />
      <Chapter
        title='Enhance Your Offer'
        description='Add dental and vision contributions'
        destination={Route.erStargateAncillaryPlans} />
      <Chapter
        title='Carrier Application'
        description='A little more for the carrier'
        destination={Route.erStargateApplication}
        state={!showCarrierApplication() ? State.hidden : undefined} />
    </Section>
    <Section number={3} title='Complete Sign‑up'>
      <Chapter
        title='Review Selections'
        description='Review your plan selections'
        destination={Route.erStargateReview} />
      <Chapter
        title='Confirmation'
        destination={Route.erStargateFinalize} />
    </Section>
  </>

  return determineChapterStates(rv, stargate.value)
}

const EmployeeSections: React.FC = () => {
  const stargate = useStargate()
  const isIndividualsMode = individualsMode()
  const individualsModeHiddenState = isIndividualsMode ? State.hidden : undefined
  const individualsModeShownState = !isIndividualsMode ? State.hidden : undefined
  const hideUnderwritingPage = stargate.value?.groupMember?.is_redflagged || stargate.value?.showUnderwritingPage !== true
  const existingCoverageState = showExistingCoverageChapter() ? undefined : State.hidden

  const rv = <>
    <Section number={1} title='About You'>
      <Chapter
        title='Your Basic Info'
        description='Enter your basic information'
        destination={Route.eeStargate_info} />
      <Chapter
        title='Work Status'
        description='Enter current occupation details'
        destination={Route.eeStargate_work}
        state={individualsModeHiddenState}
      />
      <Chapter
        title='Family'
        description='Add dependent information'
        destination={Route.eeStargate_family} />
      <Chapter
        title='Medical Information'
        description='Enter your basic medical information'
        destination={Route.eeStargate_redflags}
        state={stargate.value?.showRedflagsPage !== true ? State.hidden : undefined} />
    </Section>
    <Section number={2} title='Your Coverage'>
      <Chapter
        title='Existing Coverage'
        description='Enter current plan details'
        destination={Route.eeStargate_have_plan}
        state={existingCoverageState}
      />
      <Chapter
        title='Healthcare Plans'
        description='Choose your plan'
        destination={Route.eeStargate_select} />
      <Chapter
        title='Ancillary Plans'
        description='Select supplementary coverage'
        destination={Route.eeStargate_ancillary}
        state={individualsModeHiddenState}
      />
    </Section>
    <Section number={3} title='Complete Sign‑up'>
      <Chapter
        title='Finalize Enrollment'
        description='Review your selections'
        destination={Route.eeStargate_enroll} />
      <Chapter
        title='Application'
        description='Sign and finalize'
        destination={Route.eeStargate_underwriting}
        state={hideUnderwritingPage || isIndividualsMode ? State.hidden : undefined} />
      <Chapter
        title='Checkout'
        destination={Route.eeStargate_pay}
        state={individualsModeShownState} />
      <Chapter
        title='Confirmation'
        destination={Route.eeStargate_confirm} />
    </Section>
  </>

  return determineChapterStates(rv, stargate.value)
}

// HACK this is a HACK, but whatever
let furthestChapter: Route | undefined

function determineChapterStates(node: ReactElement, stargate?: Stargate): any {
  let assignedFurthest = false

  return React.Children.map(node.props.children, (section: ReactElement) => {
    const children = React.Children.map(section.props.children, (chapter: ReactElement) => {
      if (chapter.props.state) {
        // if state already assigned, leave it be
        return chapter
      } else {
        let state: State
        if (assignedFurthest) {
          state = State.inaccessible
        } else if (isChapterComplete(chapter.props.destination, stargate)) {
          state = State.complete
        } else {
          assignedFurthest = true
          furthestChapter = chapter.props.destination
          state = State.furthest
        }
        return React.cloneElement(chapter, { state })
      }
    })
    return React.cloneElement(section, { children })
  })
}

export function useAppMode(): AppMode | undefined {
  const active = useLocation().pathname
  if (obeliskMode()) {
    if (active.match(/\/shop\/[^/]+\/get-started/)) return AppMode.employer
    if (individualsMode()) {
      if (active.match(/\/shop\/[^/]+\/census/)) return AppMode.employee
    }
  }
  if (active.startsWith('/shop/employer')) return AppMode.employer
  if (active.startsWith('/shop/employee')) return AppMode.employee
  return undefined
}

export function useTableOfContents(): { furthestChapter?: Route } {
  // FIXME broken since we don’t always render the chapters lol
  // WHICH especially is a problem for /shop
  // HOWEVER works fine if you go direct to /shop/employer for example
  return { furthestChapter }
}

function isChapterComplete(route: Route, stargate?: Stargate): boolean {
  if (!stargate) return false

  const { user, group, members, groupContribution, splits, planIds, consumerProfile, userMetadata, dependents, groupMember, eeSouvenirSent, erSouvenirSent } = stargate

  switch (route) {
  case Route.erStargateGetStarted:
    return !!user?.id && !!group?.effectiveDate && !!group?.industrySICCode && !!group?.ein && !!user.acceptedTermsAndConditions
  case Route.erStargateCensus:
    return members.length > 0
  case Route.erStargateCensusWaive:
    return members.some(o => o.is_waived) ||
      localStorage.waiveScreenSeen === 'true' ||
      groupContribution !== undefined // often no members are unwaived
  case Route.erStargateContribution:
    return groupContribution !== undefined || localStorage.contributionScreenSeen === 'true'
  case Route.erStargateCarvedContributions:
    return splits.length > 0 ||
      localStorage.specialContributionScreenSeen === 'true' ||
      planIds.length > 0 // often no splits
  case Route.erStargatePlans:
    return planIds.length > 0
  case Route.erStargateAncillaryPlans:
    return !!group?.dental_contribution ||
           !!group?.vision_contribution ||
           localStorage.ancillaryScreenSeen === 'true'
  case Route.erStargateApplication:
    return localStorage.getItem(`group/${stargate.group?.id}/carrier-application`) !== undefined
  case Route.erStargateReview:
    return erSouvenirSent || localStorage.reviewScreenSeen === 'true'
  case Route.erStargateFinalize:
    return false

  case Route.eeStargate_info:
    return !!consumerProfile?.ssn
  case Route.eeStargate_work:
    return userMetadata?.jobTitle !== undefined
  case Route.eeStargate_family:
    return !!(dependents?.length) || localStorage.eeFamilySeen === 'true'
  case Route.eeStargate_redflags:
    return localStorage.eeRedFlagsSeen
  case Route.eeStargate_have_plan:
    return userMetadata?.alreadyHasPlan !== undefined
  case Route.eeStargate_select:
    return !!user?.enrolled_dental_plan_id || !!user?.enrolled_vision_plan_id || !!user?.enrolled_life_plan_id || localStorage.employeeAncillaryScreenSeen === 'true'
  case Route.eeStargate_waive_confirm:
    return !!groupMember?.is_waived
  case Route.eeStargate_ancillary:
    return localStorage.employeeAncillaryScreenSeen === 'true'
  case Route.eeStargate_underwriting:
    return localStorage.eeUnderwritingScreenSeen === 'true'
  case Route.eeStargate_enroll:
    return eeSouvenirSent || localStorage.employeeEnrollScreenSeen
  case Route.eeStargate_pay:
    return stargate.eePaymentInfoReceived
  case Route.eeStargate_confirm:
    return false

  default:
    throw new Error(`Unexpected/unhandled route in ToC: ${route}`)
  }
}

const SignOutLink: React.FC = () => {
  const slug = useStargate().value?.obelisk.slug
  if (!isAuthenticated()) return <React.Fragment />

  const destination = obeliskMode()
    ? `${Route.stargate}/${slug}`
    : Route.stargate

  return <Link to={{ pathname: Route.signOut, state: { redirect: destination } }}>
    <button className={styles.signout}>Sign out</button>
  </Link>
}

export default ToC
