import React from 'react'
import baseStyles from './plan-subcomponents/Plan.module.scss'
import styles from './VisionPlan.module.scss'
import Plan, { PlanBody } from './plan-subcomponents/Plan'
import { Tier, VisionPlan } from 'Utilities/pharaoh.types'
import { PremiumSplits, Contribution } from './plan-subcomponents/PlanHelpers'
import PlanTitle from './plan-subcomponents/PlanTitle'
import PremiumBreakdown from './plan-subcomponents/PremiumBreakdown'
import { AncillaryPlanEmployeeCalculator, moneyNumber } from 'Utilities/Plans/PremiumCalculators'
import EEPremiumBreakdown from './plan-subcomponents/EEPremiumBreakdown'
import { startCase } from 'lodash'
import { Member } from 'Utilities/Hooks/useStargate'

/* eslint-disable camelcase */
type EmployerProps = Props & {
  members: Member[]
}
/* eslint-enable camelcase */

type EmployeeProps = Props & {
  employeeTier: Tier
  premium?: PremiumSplits
}

interface Props {
  plan: VisionPlan
  rates: { [key in Tier]: string }
  contribution: Contribution
  selectHandler?(plan: VisionPlan): void
  selected?: boolean
}

enum VisionDataKeys {
  visionExam = 'visionExam',
  materials = 'materials',
  standardLenses = 'standardLenses',
  frameAllowance = 'frameAllowance',
  contactFittingAndEvaluation = 'contactFittingAndEvaluation',
}

const VisionPlanComponent: React.FC<EmployerProps | EmployeeProps> = props => {
  const { plan, rates, contribution, selectHandler, selected } = props
  const data = massagedData()

  return <Plan selected={!!props.selected}>
    <PlanTitle
      planName={plan.name}
      carrier={plan.carrier}
      selectHandler={selectHandler && (() => selectHandler(plan))}
      selected={selected}
      sbc={`https://s3.amazonaws.com/documents-pub.candor.insurance/sbc/${plan.id.toLowerCase()}.pdf`}
      includesProsperBenefits={false}
    />
    <PlanBody>
      { breakdown() }
      <div className={`${baseStyles.section} ${styles.specs}`}>
        <table className={baseStyles.box}>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Reimbursement</th>
              <th style={{ width: '42%' }}>In-Network</th>
              <th style={{ width: '23%' }}>Out-of-Network</th>
              <th style={{ width: '15%' }}>Frequency</th>
            </tr>
          </thead>
          <tbody>
            { Object.keys(data).map(key => {
              const { inNetwork, outOfNetwork, frequency } = data[key as VisionDataKeys]
              if (!inNetwork && !outOfNetwork && !frequency) { return <React.Fragment key={key}/> }
              return <tr key={key}>
                <td>
                  { key === VisionDataKeys.contactFittingAndEvaluation
                    ? <>Contact Fitting<br/>and Evaluation</>
                    : startCase(key)
                  }
                </td>
                <td>{inNetwork || '—'}</td>
                <td>{outOfNetwork || '—'}</td>
                <td>{frequency ? `${frequency} months` : '—'}</td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
    </PlanBody>
  </Plan>

  function breakdown() {
    if ((props as EmployerProps).members !== undefined) {
      const _props = props as EmployerProps
      return <PremiumBreakdown contribution={contribution} rates={rates} members={_props.members}/>
    }

    const _props = props as EmployeeProps
    let er, ee
    if (_props.premium) {
      er = moneyNumber(_props.premium.er)
      ee = moneyNumber(_props.premium.ee)
    } else {
      const calc = new AncillaryPlanEmployeeCalculator({ rates, plan }, contribution.value, _props.employeeTier)
      er = calc.er()
      ee = calc.ee()
    }
    return <EEPremiumBreakdown erPremium={er} eePremium={ee} tier={_props.employeeTier}/>
  }

  function massagedData(): {[key in VisionDataKeys]: { inNetwork?: string, outOfNetwork?: string, frequency?: string }} {
    const { data } = plan
    return {
      visionExam: {
        frequency: data.comprehensiveVisionExamFrequencyInMonths,
        inNetwork: data.comprehensiveVisionExamInNetwork,
        outOfNetwork: data.comprehensiveVisionExamOutOfNetwork
      },
      materials: {
        frequency: data.materialsLensesFramesAndContactsFrequencyInMonths,
        inNetwork: data.materialsLensesFramesAndContactsInNetwork,
        outOfNetwork: data.materialsLensesFramesAndContactsOutOfNetwork
      },
      standardLenses: {
        frequency: data.standardCorrectiveLensesFrequencyInMonths,
        inNetwork: data.standardCorrectiveLensesInNetwork,
        outOfNetwork: data.standardCorrectiveLensesOutOfNetwork
      },
      frameAllowance: {
        frequency: data.framesAllowanceFrequencyInMonths,
        inNetwork: data.framesAllowanceInNetwork,
        outOfNetwork: data.framesAllowanceOutOfNetwork
      },
      contactFittingAndEvaluation: {
        frequency: data.contactLensesFrequencyInMonths,
        inNetwork: data.contactLensesInNetwork,
        outOfNetwork: data.contactLensesOutOfNetwork
      }
    }
  }
}

export default VisionPlanComponent
