export enum Label {
  dohc = 'app.dentalofficehealthcare.com',
  dhc = 'app.designershealthcare.com',
  fbhc = 'app.farmbureauhealthcare.com',
  gmhc = 'app.gamemanufacturershealthcare.com',
  jhc = 'app.jewelershealthcare.com',
  mhc = 'app.manufacturershealthcare.com',
  myhealthily = 'app.myhealthily.com',
  njchc = 'app.chambersofnjhealthcare.com',
  pmhc = 'app.propertymanagershealthcare.com',
  rhc = 'app.realtyhealthcare.com',
  rns = 'app.rightnowsolution.care',
  yahc = 'app.yourassociationhealthcare.com',
  blacksmith = 'blacksmith.candor.insurance',
  brokers = 'brokers.candor.insurance',
  get = 'get.candor.insurance',
  simulacra = 'simulacra.candor.insurance',
  mphc = 'app.meatprocessorshealthcare.com',
  comahc = 'app.chambersofmahealthcare.com',
  frhc = 'app.firstrespondershealthcare.com',
  nprahc = 'app.nprahealthcare.com'
}

export enum Host {
  localhost, cd, develop, staging, production
}

export function host(): Host {
  try {
    switch (window.location.hostname) {
    case 'localhost':
    case '0.0.0.0':
    case '127.0.0.1':
      return Host.localhost
    case 'cd.candor.insurance':
      return Host.cd
    case 'develop.candor.insurance':
      return Host.develop
    case 'staging.candor.insurance':
      return Host.staging
    default:
      if (process.env.NODE_ENV === 'development') {
        // for Ayman doing network testing
        return Host.localhost
      } else {
        return Host.production
      }
    }
  } catch {
    // we're a node script
    return Host.production
  }
}

export enum Mode {
  standard = 'standard',
  demo = 'demo'
}

export function labelShortCode(input: string = rawLabel()): keyof typeof Label {
  for (const key in Label) {
    const shortcode = key as keyof typeof Label
    // allow use of short codes
    if (key === input) { return shortcode }
    // or fully qualified
    if (Label[shortcode] === input) { return shortcode }
  }
  throw new Error('Invalid white label')
}

function rawMode(): Mode {
  switch (get()) {
  case Mode.standard:
    return Mode.standard
  case Mode.demo:
    return Mode.demo
  default:
    throw new Error('Invalid `REACT_APP_MODE`')
  }
  function get() {
    switch (host()) {
    case Host.localhost:
      return localStorage.mode || Mode.standard
    case Host.develop:
    case Host.staging:
      return localStorage.getItem(`mode/${labelShortCode()}`) || Mode.standard
    case Host.cd:
      return localStorage.getItem(`mode${process.env.REACT_APP_BASENAME}`) || Mode.standard
    case Host.production:
      return process.env.REACT_APP_MODE
    }
  }
}

export function mode(): [Mode.demo] | [Mode.standard, {individualsMode: boolean, obeliskMode: boolean}] {
  switch (rawMode()) {
  case Mode.standard:
    return [Mode.standard, { individualsMode: individualsMode(), obeliskMode: obeliskMode() }]
  case Mode.demo:
    return [Mode.demo]
  }
}

enum Pharaoh {
  production = 'https://pharaoh.candor-usa.com',
  develop = 'https://develop.pharaoh.candor-usa.com',
  staging = 'https://pharaoh.staging.candor.insurance', // UHC cannot connect to candor-usa.com
  demo = 'https://demo.pharaoh.candor-usa.com'
}

export function isProduction() {
  return host() === Host.production
}

/// looks like any old Candor branded site
export function isNonDifferentiableCandor() {
  switch (rawLabel()) {
  case Label.dohc:
  case Label.dhc:
  case Label.fbhc:
  case Label.gmhc:
  case Label.jhc:
  case Label.mhc:
  case Label.myhealthily:
  case Label.njchc:
  case Label.pmhc:
  case Label.rhc:
  case Label.rns:
  case Label.yahc:
  case Label.mphc:
  case Label.blacksmith:
    return false
  case Label.brokers:
  case Label.get:
  case Label.simulacra:
    return true
  }
}

export function pharaoh(): string {
  switch (mode()[0]) {
  case Mode.demo:
    switch (host()) {
    case Host.localhost:
      return process.env.REACT_APP_PHARAOH || Pharaoh.demo
    case Host.develop:
    case Host.cd:
      return localStorage.pharaoh || Pharaoh.demo
    case Host.staging:
    case Host.production:
      return Pharaoh.demo
    }
    break // linter requires this
  case Mode.standard:
    switch (host()) {
    case Host.production:
      return Pharaoh.production
    case Host.localhost:
      return process.env.REACT_APP_PHARAOH || Pharaoh.develop
    case Host.cd:
    case Host.develop:
      return localStorage.pharaoh || Pharaoh.develop
    case Host.staging:
      return Pharaoh.staging
    }
  }
}

function rawLabel(): Label {
  switch (host()) {
  case Host.localhost:
  case Host.develop:
  case Host.staging:
    return translate(localStorage.label, Label.simulacra)
  case Host.cd:
    return translate(localStorage.getItem(`label${process.env.REACT_APP_BASENAME}`), Label.simulacra)
  case Host.production:
    switch (rawMode()) {
    case Mode.demo:
      return translate(process.env.REACT_APP_WHITE_LABEL, (() => {
        try { return window.location.hostname } catch { return undefined }
      })())
    case Mode.standard:
      return translate(window.location.hostname)
    }
  }

  function translate(...attempts: (string | undefined | null)[]): Label {
    for (const attempt of attempts) {
      if (!attempt) continue
      for (const key in Label) {
        const shortcode = key as keyof typeof Label
        if (Label[shortcode] === attempt) return Label[shortcode]
      }
    }
    throw new Error('No White-label for this host')
  }
}

export function label(): Label {
  return Label[labelShortCode()]
}

export function obeliskMode() {
  switch (label()) {
  case Label.rns:
  case Label.brokers:
  case Label.myhealthily:
  case Label.comahc:
    return true
  default:
    return false
  }
}

export function associationsMode(): boolean {
  switch (label()) {
  case Label.dhc:
  case Label.dohc:
  case Label.fbhc:
  case Label.gmhc:
  case Label.jhc:
  case Label.mhc:
  case Label.njchc:
  case Label.pmhc:
  case Label.rhc:
  case Label.yahc:
  case Label.mphc:
  case Label.comahc:
  case Label.frhc:
  case Label.nprahc:
    return true
  case Label.myhealthily:
  case Label.brokers:
  case Label.simulacra:
  case Label.get:
  case Label.rns:
  case Label.blacksmith:
    return false
  }
}

export function individualsMode() {
  switch (label()) {
  case Label.rns:
    return true
  default:
    return false
  }
}

export function moneyDecimals() {
  switch (label()) {
  case Label.rns:
  case Label.blacksmith:
    return 2
  default:
    return 0
  }
}

export function title(key: Label = label()): string {
  const host_ = host()
  const title = rawTitle(key, host_)
  switch (mode()[0]) {
  case Mode.demo:
    switch (host_) {
    case Host.production:
      return `${title} Demo`
    default:
      return `${title}·Demo`
    }
  case Mode.standard:
    return title
  }
}

export function rawTitle(key: Label = label(), host_: Host = host()): string {
  switch (host_) {
  case Host.localhost:
    return `Ra·${shortTitle()}`
  case Host.staging:
    return `Staging·${shortTitle()}`
  case Host.cd:
    return `CD·${shortTitle()}`
  case Host.develop:
    return `Develop·${shortTitle()}`
  case Host.production:
    return longTitle()
  }

  function longTitle(): string {
    switch (key) {
    case Label.dohc:
      return 'DentalOfficeHealthCare.com'
    case Label.dhc:
      return 'DesignersHealthCare.com'
    case Label.fbhc:
      return 'FarmBureauHealthCare.com'
    case Label.gmhc:
      return 'GameManufacturersHealthCare.com'
    case Label.jhc:
      return 'JewelersHealthCare.com'
    case Label.mhc:
      return 'ManufacturersHealthCare.com'
    case Label.myhealthily:
      return 'MyHealthily.com'
    case Label.njchc:
      return 'ChambersOfNJHealthCare.com'
    case Label.pmhc:
      return 'PropertyManagersHealthCare.com'
    case Label.rhc:
      return 'RealtyHealthCare.com'
    case Label.rns:
      return 'Right Now Solution'
    case Label.yahc:
      return 'YourAssociationHealthCare.com'
    case Label.blacksmith:
      return 'Blacksmith Automotive Management Benefits Portal'
    case Label.brokers:
    case Label.get:
      return 'Candor USA'
    case Label.simulacra:
      return 'Simulacra'
    case Label.mphc:
      return 'MeatProcessorsHealthCare.com'
    case Label.comahc:
      return 'ChambersOfMAHealthCare.com'
    case Label.frhc:
      return 'FirstRespondersHealthCare.com'
    case Label.nprahc:
      return 'NPRAHealthCare.com'
    }
  }

  function shortTitle() {
    return labelShortCode(key)
  }
}

export function supportPhone() {
  switch (label()) {
  case Label.rns:
    return '+1 (706) 252-8077'
  case Label.jhc:
    return '+1 (888) 613‑5397'
  default:
    return '+1 (888) 272‑1513'
  }
}

export function analytics() {
  switch (label()) {
  case Label.rns:
    return 'UA-139146979-5'
  default:
    return 'UA-139146979-1'
  }
}

export function supportEmail() {
  switch (label()) {
  case Label.rns:
    return 'rightnowsolution@candor-usa.com'
  default:
    return 'support@candor-usa.com'
  }
}

export function copyright(): string {
  const year = new Date().getFullYear()

  switch (label()) {
  case Label.get:
  case Label.simulacra:
  case Label.brokers:
    return `© ${year} Candor USA`
  case Label.myhealthily:
  case Label.dhc:
  case Label.dohc:
  case Label.fbhc:
  case Label.gmhc:
  case Label.jhc:
  case Label.mhc:
  case Label.njchc:
  case Label.pmhc:
  case Label.rhc:
  case Label.yahc:
  case Label.mphc:
  case Label.blacksmith:
  case Label.comahc:
  case Label.frhc:
  case Label.nprahc:
    return `© ${year} MJM Global`
  case Label.rns:
    return `© ${year} Right Now Solution`
  }
}

export function showMJMFlair(): boolean {
  switch (label()) {
  case Label.dhc:
  case Label.dohc:
  case Label.fbhc:
  case Label.gmhc:
  case Label.jhc:
  case Label.mhc:
  case Label.njchc:
  case Label.pmhc:
  case Label.rhc:
  case Label.yahc:
  case Label.mphc:
  case Label.comahc:
  case Label.frhc:
  case Label.nprahc:
    return true
  case Label.blacksmith:
  case Label.myhealthily:
  case Label.get:
  case Label.simulacra:
  case Label.brokers:
  case Label.rns:
    return false
  }
}

export function showLifePlans() {
  return label() !== Label.fbhc
}

export function showCarrierApplication(): boolean {
  switch (label()) {
  case Label.myhealthily:
  case Label.dhc:
  case Label.dohc:
  case Label.fbhc:
  case Label.gmhc:
  case Label.jhc:
  case Label.mhc:
  case Label.njchc:
  case Label.pmhc:
  case Label.rhc:
  case Label.yahc:
  case Label.mphc:
  case Label.comahc:
  case Label.frhc:
  case Label.nprahc:
    return true
  case Label.blacksmith:
  case Label.get:
  case Label.simulacra:
  case Label.brokers:
  case Label.rns:
    return false
  }
}

export enum PlaidKey {
  candor, prosper
}

export function plaidKey() {
  return label() === Label.rns ? PlaidKey.prosper : PlaidKey.candor
}

export enum StripeKey {
  live, test
}

export function stripeKey() {
  return host() === Host.production ? StripeKey.live : StripeKey.test
}

export function plaidName() {
  return label() === Label.rns ? 'RightNowSolution' : 'Candor USA'
}

export function showPlaid() {
  return label() === Label.rns || host() !== Host.production
}

export enum Census {
  standard, super
}

export function censusFilename(type: Census = Census.standard): string {
  let suffix = 'census.xlsx'
  switch (type) {
  case Census.standard:
    break
  case Census.super:
    suffix = `super-${suffix}`
  }
  switch (host()) {
  case Host.localhost:
  case Host.develop:
  case Host.staging:
  case Host.cd:
    return suffix
  case Host.production:
    return `${title()}-${suffix}`
  }
}

export function censusURL(type: Census = Census.standard) {
  return `${process.env.PUBLIC_URL}/${censusFilename(type)}`
}

export function showHeightWeight() {
  return label() === Label.rns
}

export function showTaxWitholdingStatus() {
  return label() !== Label.blacksmith
}

export {
  showTaxWitholdingStatus as showHireDate
}

export function showExistingCoverageChapter() {
  return !individualsMode() && label() !== Label.blacksmith
}

export enum PremiumPeriod {
  weekly,
  monthly
}

export function premiumPeriod(): PremiumPeriod {
  return label() === Label.blacksmith ? PremiumPeriod.weekly : PremiumPeriod.monthly
}

export function cairo() {
  switch (host()) {
  case Host.localhost:
  case Host.develop:
    return 'https://develop.candor.insurance/cairo/'
  case Host.production:
    switch (mode()[0]) {
    case Mode.demo:
      return 'https://demo.candor.insurance/cairo/'
    case Mode.standard:
      return 'https://cairo.candor.insurance'
    }
    break // required for linter
  case Host.staging:
    return 'https://staging.candor.insurance/cairo/'
  }
}

export function switchLabel(label: Label, mode = Mode.standard) {
  switch (mode) {
  case Mode.standard:
    localStorage.removeItem(modeLocalStorageKey()) // remove mode for current label
    localStorage.setItem(labelLocalStorageKey(), label)
    localStorage.removeItem(modeLocalStorageKey()) // remove mode for new label
    break
  case Mode.demo:
    localStorage.setItem(labelLocalStorageKey(), label)
    localStorage.setItem(modeLocalStorageKey(), 'demo') // NOTE order matters!
  }
}

function modeLocalStorageKey(): string {
  switch (host()) {
  case Host.localhost:
    return 'mode'
  case Host.develop:
  case Host.staging:
    return `mode/${labelShortCode()}`
  case Host.cd:
    return `mode${process.env.REACT_APP_BASENAME}`
  case Host.production:
    throw new Error('How did you get here?')
  }
}

function labelLocalStorageKey(): string {
  switch (host()) {
  case Host.localhost:
  case Host.develop:
  case Host.staging:
    return 'label'
  case Host.cd:
    return `label${process.env.REACT_APP_BASENAME}`
  case Host.production:
    throw new Error('How did you get here?')
  }
}
