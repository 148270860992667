import React, { PropsWithChildren } from 'react'
import styles from './AddButton.module.scss'

interface Props {
  onClick: (event: any) => void
  style?: React.CSSProperties
  className?: string
}

const AddButton: React.FC<PropsWithChildren<Props>> = ({ children, onClick, style, className }) => {
  return <button style={style} className={`${styles.addBtn} ${className}`} onClick={onClick}>
    <i className='material-icons'>add</i>
    <span>{ children }</span>
  </button>
}

export default AddButton
