import React from 'react'
import AnubisModal, { Props as AnubisModalProps } from 'Components/Anubis/AnubisModal'
import styles from './AddEmployeeModal.module.scss'
import CountyPicker from 'Components/Rudimentary/CountyPicker'
import CandorInput from 'Components/Rudimentary/CandorInput'
import { v4 as uuid } from 'uuid'
import { post } from 'Utilities/fetch++'
import { useToggle } from 'react-use'
import useToast from 'Utilities/Hooks/useToast'
import usePersistableForm from 'Utilities/Hooks/usePersistableForm'
import CandorSelect from 'Components/Rudimentary/CandorSelect'
import { CandorDatePicker } from './CandorForm'
import { localMidnightToPharaohFormat } from 'Utilities/pharaoh'

interface Props extends AnubisModalProps {
  groupID: string
  callback: (data: { id: string }) => void
  splitID: string | undefined
}

const genderOptions = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' }
]

const AddEmployeeModal: React.FC<Props> = ({ groupID, callback, splitID, ...props }) => {
  const { unpersist, register, handleSubmit, watch, control } = usePersistableForm(`add-employee-${groupID}`)
  const zip = watch('address.zip')
  const [disabled, setDisabled] = useToggle(false)
  const addToast = useToast()

  return <AnubisModal {...props} styles={{ width: 800, overflow: 'visible' }}>
    <h1>Add New Employee</h1>
    <form onSubmit={handleSubmit(onSubmit)} className={styles.addEmpForm}>
      <fieldset disabled={disabled}>
        <CandorInput name='contact.name' ref={register} placeholder='Name' />
        <CandorInput name='contact.email' ref={register} placeholder='Email' required />
        <CandorDatePicker
          className={styles.whiteBackground}
          placeholder='Date of Birth'
          name='dob'
          control={control}
          maxDate={new Date()}
        />
        <CandorSelect
          placeholder='Sex'
          control={control}
          options={genderOptions}
          backgroundColor='#fff'
          name='gender'
        />
      </fieldset>
      <fieldset>
        <CandorInput name='address.street1' ref={register} placeholder='Address 1'/>
        <CandorInput name='address.street2' ref={register} placeholder='Address 2'/>
        <CandorInput name='address.city' ref={register} placeholder='City'/>
        <CandorInput name='address.zip' ref={register} placeholder='ZIP Code' maxLength={5} minLength={5} required />
        <CountyPicker name='address.county.id' control={control} zip={zip} backgroundColor='#fff'/>
        <div className={styles.submit}>
          <p>You can add dependents after you submit</p>
          <input type='submit' value='Submit'/>
        </div>
      </fieldset>
    </form>
  </AnubisModal>

  async function onSubmit(data: any) {
    try {
      setDisabled(true)
      data.id = uuid()
      data.dob = localMidnightToPharaohFormat(data.dob)

      // ignored but required 😕 (mxcl sucks)
      data.tier = 'individual'
      data.group = { id: data.id, name: 'a' }
      data.privilege = 'standard'
      data.status = 'notStarted'
      data.isWaived = false
      data.isMedicallyUnderwritten = false
      data.splitID = splitID

      await post(`/v3/groups/${groupID}/users`, data)

      unpersist()
      callback(data)
    } catch (error) {
      addToast(error)
    } finally {
      setDisabled(false)
    }
  }
}

export default AddEmployeeModal
