/* eslint-disable camelcase */
import React, { useState } from 'react'
import { Route } from 'Utilities/Route'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import { useHistory } from 'react-router-dom'
import useFullContentArea from 'Utilities/Hooks/useFullContentArea'
import { useAsync, useToggle, useSet } from 'react-use'
import { get, post, delete_ } from 'Utilities/fetch++'
import { MedicalPlan } from 'Utilities/pharaoh.types'
import Loader from 'Components/Rudimentary/Loader'
import Error from 'Components/Primitives/Error'
import RemovePlansModal from './components/RemovePlansModal'
import SendPlansModal from './components/SendPlansModal'
import ProsperBenefitsHeader from './components/prosper-benefits-header/ProsperBenefitsHeader'
import RequestReview from './components/RequestReview'
import ReviewPlansList from './components/ReviewPlansList'
import ReviewPlansCart from './components/ReviewPlansCart'
import styles from './ReviewPlanSelections.module.css'
import * as api from 'Utilities/pharaoh'
import useToast from 'Utilities/Hooks/useToast'
import { AncillaryPlanUnion } from 'Utilities/Plans/PremiumCalculators'

enum PlanTypes {
  DENTAL = 'dental',
  VISION = 'vision',
  LIFE = 'life',
  LTD = 'disability'
}

const ERShopPlansReview: React.FC<PrivateWizardPageProps> = ({ stargate, ...props }) => {
  const { group, members, splits } = stargate
  const nonWaivedMemberCount = members.filter(member => !member.is_waived).length
  const [isRemovePlansModalOpen, toggleRemovePlansModal] = useToggle(false)
  const [isRequestReviewModalOpen, toggleRequestReviewModal] = useToggle(false)
  const [medicalContribution, setMedicalContribution] = useState(group?.medical_contribution || '0%')
  const [dentalContribution, setDentalContribution] = useState(group?.dental_contribution || '0%')
  const [visionContribution, setVisionContribution] = useState(group?.vision_contribution || '0%')
  const [planIDsToRemove, { toggle: togglePlanIDToRemove }] = useSet<string>()
  const history = useHistory()
  const addToast = useToast()
  useFullContentArea()

  const { value, error, loading } = useAsync(async() => {
    const medical = await get(`/v3/groups/${group?.id}/plans`) as MedicalPlan[]
    const ancillary = await get(`/v3/groups/${group?.id}/plans/options/ancillary`) as AncillaryPlanUnion[]
    return await Promise.all([medical, ancillary])
  })

  if (!group) return <Error error='Please complete earlier steps'/>
  if (loading) return <Loader />
  if (error) return <Error error={error} />

  const plans = extractPlans()

  return <>
    <RemovePlansModal
      isOpen={isRemovePlansModalOpen}
      plans={plans.medical.filter(({ id }) => planIDsToRemove.has(id))}
      isRemovingAllPlans={plans.medical.length === planIDsToRemove.size}
      handleRemoveContinue={handleRemoveContinue}
      onClose={toggleRemovePlansModal}
    />
    <SendPlansModal
      isOpen={isRequestReviewModalOpen}
      onClose={toggleRequestReviewModal}
      members={members}
      callback={handleSendPlans}
    />
    <ProsperBenefitsHeader/>
    <div className={styles.cartContainer}>
      <ReviewPlansCart
        nonWaivedMemberCount={nonWaivedMemberCount}
        handleContinue={onwards}
        medicalPlans={plans.medical}
        dentalPlans={plans.dental}
        visionPlans={plans.vision}
        medicalContribution={medicalContribution}
        dentalContribution={dentalContribution}
        visionContribution={visionContribution}
        splits={splits}
        members={members}
        isProsperOnly={false}
        isEquitable={group.medical_contribution_equitable}
      />
    </div>
    <ReviewPlansList
      group={group}
      nonWaivedMemberCount={nonWaivedMemberCount}
      splits={splits}
      members={members}
      medicalPlans={plans.medical}
      dentalPlans={plans.dental}
      visionPlans={plans.vision}
      medicalContribution={medicalContribution}
      dentalContribution={dentalContribution}
      visionContribution={visionContribution}
      handleRemove={togglePlanIDToRemove}
      handleContribution={onContributionChange}
      planIDsToRemove={planIDsToRemove}
    />
    <RequestReview requestClose={toggleRequestReviewModal}/>
  </>

  async function handleRemoveContinue(planIDs: string[]) {
    const promises = Promise.all(planIDs.map(id => delete_(`/v2/groups/${group?.id}/plans/${id}`)))
    if (planIDsToRemove.size === plans.medical.length) {
      try {
        await promises
        goBack()
      } catch (error) {
        addToast(error)
      }
    } else {
      props.onwards(promises)
    }
  }

  function handleSendPlans(email: string) {
    return post(`/v2/groups/${group?.id}/invite/manager`, { email })
  }

  function onwards() {
    if (planIDsToRemove.size < 1) {
      props.onwards(api.v3.groups(group?.id).PUT({
        contributions: {
          medical: {
            value: medicalContribution,
            isEquitable: group?.medical_contribution_equitable || false
          },
          vision: {
            value: visionContribution,
            isEquitable: false
          },
          dental: {
            value: dentalContribution,
            isEquitable: false
          }
        }
      }))
    } else {
      toggleRemovePlansModal()
    }
  }

  function onContributionChange(type: 'medical' | 'vision' | 'dental', value: string) {
    (func())(value)

    function func() {
      switch (type) {
      case 'medical': return setMedicalContribution
      case 'vision': return setVisionContribution
      case 'dental': return setDentalContribution
      }
    }
  }

  function goBack() {
    history.push({
      pathname: Route.erStargatePlans,
      state: { levelFundedModalConfirmed: true }
    })
  }

  function extractPlans() {
    return {
      medical: value![0],
      dental: value![1].filter(({ plan }) => plan.type === PlanTypes.DENTAL) as AncillaryPlanUnion[],
      vision: value![1].filter(({ plan }) => plan.type === PlanTypes.VISION) as AncillaryPlanUnion[]
    }
  }
}

export default ERShopPlansReview
