import React from 'react'
import styles from './ProsperBenefitsHeader.module.scss'
import { ReactComponent as ProsperLogo } from './prosper-benefits-logo-white.svg'
import ProsperBenefitsModal from './ProsperBenefitsModal'
import { useToggle } from 'react-use'

const ProsperBenefitsHeader: React.FC = () => {
  const [showProsperModal, toggleProsperModal] = useToggle(false)
  return <>
    <ProsperBenefitsModal isOpen={showProsperModal} onRequestClose={toggleProsperModal}/>
    <div className={styles.prosperContainer}>
      <div className={styles.oval}/>
      <h1>Included with your Healthcare</h1>
      <div className={styles.prosperContent}>
        <div>
          <ProsperLogo className={styles.prosperLogo}/>
          <ul className={styles.prosperList}>
            <li>Telemedicine /</li>
            <li>Medical Bill Saver /</li>
            <li>Confidential Counseling /</li>
            <li>Health Advocacy</li>
          </ul>
        </div>
        <div className={styles.prosperSavings}>
          The average hospital stay in the U.S. costs $97,000 —
          with <span>Prosper Benefits+</span> telemedicine you could
          <span> spend $0.</span> We’re here to give you the best care,
          so we include <span>Prosper Benefits+</span> with every plan.
        </div>
        <div className={styles.floatingProsperHeart}/>
      </div>
      <button className={styles.prosperDetailsButton} onClick={toggleProsperModal}>
        <div>
          <p>Included</p>
          <p>click to learn more</p>
        </div>
      </button>
    </div>
  </>
}

export default ProsperBenefitsHeader
