/* eslint-disable camelcase */
import React, { useState } from 'react'
import styles from './ShowPlans.module.scss'
import useFullContentArea from 'Utilities/Hooks/useFullContentArea'
import { useAsync, useToggle, useSet } from 'react-use'
import { get, delete_, post } from 'Utilities/fetch++'
import Error from 'Components/Primitives/Error'
import { MedicalPlan } from 'Utilities/pharaoh.types'
import PlansFilter, { FilterType } from 'Components/Stargate/ShowPlans/Filters/PlansFilter'
import PlanCart from '../../../../Components/Stargate/ShowPlans/Components/PlanCart'
import Loader from 'Components/Rudimentary/Loader'
import { sortBy, uniq, pick, compact } from 'lodash'
import useToast from 'Utilities/Hooks/useToast'
import * as api from 'Utilities/pharaoh'
import ContributionAmount, { contributionType as extractContributionType, ContributionSplitType } from 'Components/Stargate/Contribution/ContributionAmount'
import LevelFundedModal, { Decision } from 'Components/Modals/LevelFundedModal/LevelFundedModal'
import { useHistory, useLocation } from 'react-router-dom'
import { moneyNumber } from 'Utilities/Plans/PremiumCalculators'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import CompareModal from 'Components/Stargate/ShowPlans/Components/CompareModal'
import MedicalPlanComponent from 'Components/Plans/MedicalPlan'
import headerStyles from 'Components/Stargate/ShowPlans/Components/ShowPlansHeader.module.scss'
import LazyLoad from 'react-lazyload'
import { PlanPlaceholder } from 'Components/Plans/plan-subcomponents/Plan'
import SavingsCalculator from './SavingsCalculator'
import ReactDOM from 'react-dom'

interface Recs {
  lowestTCoC: MedicalPlan
  lowestPremium: MedicalPlan
  bestValue: MedicalPlan
  meta: PlansMeta
}

export interface PlansMeta {
  totalNumberOfPlans: number
  carriers: string[]
  largestDeductible: string
  largestPremium: string
  largestOOPMax: string
  individualPlansCount: number
  couplePlansCount: number
  singleParentPlansCount: number
  familyPlansCount: number
  nonWaivedMemberCount: number
  memberCount: number
}

export enum SortingMethod {
  premium = 'premium', deductible = 'deductible', oopMax = 'oop≥'
}

const ERShopPlans: React.FC<PrivateWizardPageProps> = ({ stargate, ...props }) => {
  const { group, members, splits } = stargate
  const [selectedPlanIDs, { toggle: toggleSelectedPlan }] = useSet<string>(new Set(stargate.planIds))
  const [isCompareModalOpen, toggleCompare] = useToggle(false)
  const [showingLevelFundedQuiz, setShowingLevelFundedQuiz] = useToggle(false)
  const [disabled, setDisabled] = useToggle(false)
  const [contribution, setContribution] = useState(group?.medical_contribution || '0%')
  const [contributionType, setContributionType] = useState<ContributionSplitType>(extractContributionType(group?.medical_contribution_equitable || false, group?.medical_contribution))
  const location = useLocation()
  const history = useHistory()
  const addToast = useToast()
  const { search } = useLocation()
  useFullContentArea()

  const { value: recs, loading, error: error1 } = useAsync(async() =>
    await get(`/v2/groups/${group?.id}/plans/options/recommended`) as Recs
  )
  const { value: unsortedPlans, loading: loadingPlans, error: error2 } = useAsync(async() =>
    await get(`/v2/groups/${group?.id}/plans/options${search}`) as MedicalPlan[]
  , [search])

  if (!group || !members) return <Error error='Please complete earlier steps'/>
  if (error1 || error2) return <Error error={error1 || error2} />
  if (loading) return <Loader />

  const plans = sortedPlans()
  const selectedPlans = plans?.filter(plan => selectedPlanIDs.has(plan.id)) || []

  const isNextButtonDisabled = selectedPlans.length <= 0 || disabled

  return <>
    <header className={headerStyles.header}>
      <div>
        <SavingsCalculator/>
        <h1>Select Plans</h1>
        <button className={headerStyles.nextButton} onClick={onwards} disabled={isNextButtonDisabled}>
          {isNextButtonDisabled ? 'Select a plan to continue' : 'Next Step'}
        </button>
      </div>
    </header>
    <div className={styles.mainContainer}>
      <div className={styles.plansAndFilter} style={{ display: 'grid' }}>
        <div className={styles.filterBarContainer}>
          <PlansFilter
            activeFilters={activeFilters()}
            callback={applyFilter}
            meta={recs!.meta}
            plansCount={plans?.length}
          />
        </div>
        <div className={styles.plansListContainer}>
          <section className={styles.erSection}>
            <h2>
              <b>Selected plans will be added into your shopping cart to compare.</b>
              Once selected you may alter the contribution amount.
            </h2>
            <div className={styles.contributionContainer}>
              <ContributionAmount
                onChange={handleContribution}
                value={contribution}
                splitType={contributionType}
              />
            </div>
          </section>
          {renderPlans()}
        </div>
      </div>
      <PlanCart
        selectedPlans={selectedPlans}
        removePlan={onPlanSelectToggled}
        compare={toggleCompare}
        editingCart={disabled}
      />
      <LevelFundedModal
        show={showingLevelFundedQuiz}
        onClose={setShowingLevelFundedQuiz}
        setDecision={goAhead => {
          if (goAhead === Decision.continue) {
            props.onwards(Promise.resolve())
          } else {
            setShowingLevelFundedQuiz(false)
          }
        }}
        group={group}
      />
      <CompareModal
        isOpen={isCompareModalOpen}
        onRequestClose={toggleCompare}
        plans={selectedPlans}
        contribution={{ value: contribution, isEquitable: group.medical_contribution_equitable }}
        splits={splits}
        members={members}
        removePlanHandler={toggleSelectedPlan}
        groupID={group.id}
      />
    </div>
  </>

  function renderPlans() {
    if (loadingPlans) return <Loader />
    return plans!.map(plan =>
      <LazyLoad
        offset={100}
        key={plan.id}
        placeholder={<PlanPlaceholder/>}
      >
        <MedicalPlanComponent
          plan={plan}
          rates={plan.premium.employee}
          contribution={contribution}
          selected={selectedPlans.map(plan => plan.id).indexOf(plan.id) > -1}
          selectHandler={onPlanSelectToggled}
          isEquitable={contributionType === ContributionSplitType.allTiers}
          splits={splits}
          members={members}
          key={plan.id}
        />
      </LazyLoad>
    )
  }

  function activeFilters(): [FilterType, any][] {
    const pp = new URLSearchParams(search)
    // TODO should verify it is actually a FilterType or we may screw up
    const rv: [FilterType, any][] = compact(Array.from(pp.entries()).map(transform))
    if (!rv.find(([type]) => type === FilterType.sort)) {
      rv.push([FilterType.sort, SortingMethod.premium])
    }
    return rv

    function transform(input: [string, any]): [FilterType, any] | undefined {
      let [key, value] = input
      if (key.endsWith('[]')) key = key.slice(0, -2)
      for (const typeKey in FilterType) {
        if (FilterType[typeKey as keyof typeof FilterType] === key) return [key, value]
      }
    }
  }

  function sortingMethod(): SortingMethod {
    const pp = new URLSearchParams(search)
    const query = pp.get(FilterType.sort)
    for (const key in SortingMethod) {
      const type = SortingMethod[key as keyof typeof SortingMethod]
      if (type === query) return type
    }
    return SortingMethod.premium
  }

  function applyFilter(type: FilterType, value: any) {
    const pp = new URLSearchParams(search)
    if (type === FilterType.sort) {
      pp.set(type, value)
    } else {
      const key = `${type}[]`
      if (activeFilters().find(([a, b]) => type === a && value === b)) {
        const values = pp.getAll(key).filter(vv => vv !== value)
        pp.delete(key)
        values.forEach(value => pp.append(key, value))
      } else {
        pp.append(key, value)
      }
    }
    // decode or [] becomes percent encoded and looks gross
    history.push({ search: decodeURIComponent(pp.toString()) })
  }

  async function onPlanSelectToggled(plan: MedicalPlan) {
    if (disabled) return

    try {
      setDisabled(true)
      const has = selectedPlanIDs.has(plan.id)
      toggleSelectedPlan(plan.id)

      if (has) {
        await delete_(`/v2/groups/${group?.id}/plans/${plan.id}`)
      } else {
        await post(`/v2/groups/${group?.id}/plans/${plan.id}`)
      }
    } catch (error) {
      addToast(error)
      toggleSelectedPlan(plan.id)
    } finally {
      setDisabled(false)
    }
  }

  function onwards() {
    try {
      if (selectedPlanIDs.size > 3) throw window.Error('You can select at most three plans')
      if (uniq(pick(selectedPlans, 'carrier')).length > 1) throw new window.Error('Plans must be from the same carrier')
      if (selectedPlans.some(plan => plan.isLevelFunded) && !levelFundedQuizSeen()) return setShowingLevelFundedQuiz(true)
      props.onwards(Promise.resolve())
    } catch (error) {
      addToast(error)
    }

    function levelFundedQuizSeen() {
      return (location.state as any)?.levelFundedModalConfirmed || false
    }
  }

  async function handleContribution(value: string, type: ContributionSplitType = ContributionSplitType.perEmployee) {
    try {
      ReactDOM.unstable_batchedUpdates(() => {
        setContribution(value)
        setContributionType(type)
      })
      api.v3.groups(group?.id).PUT({
        contributions: {
          medical: {
            value,
            isEquitable: type === ContributionSplitType.allTiers
          }
        }
      })
    } catch (error) {
      addToast(error)
    }
  }

  function sortedPlans(): MedicalPlan[] | undefined {
    if (unsortedPlans && !loadingPlans) {
      return sortBy(unsortedPlans, func())
    }

    function func(): ((plan: MedicalPlan) => number)[] {
      const premium = (plan: MedicalPlan) => moneyNumber(plan.premium.employee.individual)
      const deductible = (plan: MedicalPlan) => moneyNumber(plan.deductible)
      const oopMax = (plan: MedicalPlan) => moneyNumber(plan.oopMax)
      switch (sortingMethod()) {
      case SortingMethod.premium:
        return [premium, deductible, oopMax]
      case SortingMethod.deductible:
        return [deductible, premium, oopMax]
      case SortingMethod.oopMax:
        return [oopMax, premium, deductible]
      }
    }
  }
}

export default ERShopPlans
