import React from 'react'
import useStargate from 'Utilities/Hooks/useStargate'
import Loader from 'Components/Rudimentary/Loader'
import Error from 'Components/Primitives/Error'
import { useToggle } from 'react-use'
import { useLocation } from 'react-router-dom'
import { pharaoh, cairo, label, labelShortCode, host, Host } from 'Utilities/config'
import useToast from 'Utilities/Hooks/useToast'
import AnubisModal from 'Components/Anubis/AnubisModal'
import styles from 'Components/Modals/Anubis/InviteEmployeesModal.module.scss'
import { getToken } from 'Utilities/pharaoh'

export default function DebugModal() {
  const { loading, error, value } = useStargate()
  const [open, toggle] = useToggle(true)
  const location = useLocation()
  const addToast = useToast()

  return <AnubisModal isOpen={open} onRequestClose={toggle}>
    {content()}
  </AnubisModal>

  function content() {
    const pharaoh = getPharaoh()
    const basename = process.env.REACT_APP_BASENAME || ''
    const urlPrefix = `${window.location.protocol}//${window.location.host}${basename}`

    return <>
      <h2>How To Report Bugs</h2>
      <ul>
        <li><a href='https://testlio.com/blog/the-ideal-bug-report/'>How to write a bug report that will make your engineers love you</a></li>
        <li><a href='https://www.chiark.greenend.org.uk/~sgtatham/bugs.html'>How to Report Bugs Effectively</a></li>
        <li><a href='https://developer.mozilla.org/en-US/docs/Mozilla/QA/Bug_writing_guidelines'>Mozzila’s bug report writing guidelines</a></li>
      </ul>
      <p><button className={styles.button} onClick={linear}>Copy Linear Issue Body</button></p>
      <p><button>{jira()} DEPRECATED USE LINEAR</button></p>
      <h2>Tools</h2>
      <button className={styles.button} onClick={copy}>Copy Instant Sign‐in Link</button>
      <h2>Environment</h2>
      <Env />
      <details>
        <summary>/stargate dump</summary>
        <pre style={{ maxHeight: '400px' }}>{stargate()}</pre>
      </details>
    </>

    function instantSignInLink() {
      let rv = `${urlPrefix}/account/sign-in?token=${encodeURIComponent(getToken())}&redirect=${encodeURIComponent(location.pathname)}`
      if (host() !== Host.production) rv += `&label=${labelShortCode()}`
      return rv
    }

    function copy() {
      navigator.clipboard.writeText(instantSignInLink())
      addToast('Sign‐in link copied to clipboard')
    }

    function stargate() {
      if (loading) return <Loader />
      if (error) return <Error error={error} />
      return JSON.stringify(value!, null, 2)
    }

    function jira() {
      if (loading) return <p>Loading…</p>
      if (error) return <Error error={error} />
      const data = value!
      const lines = new Array<string>()

      lines.push('h2. Incorrect Behavior')
      lines.push('Succinctly describe what didn’t work, *don’t* list the replication steps.')
      lines.push('')
      lines.push('h2. Expected Behavior')
      lines.push('What *should* have happened, but didn’t?')
      lines.push('')
      lines.push('h2. Replication Steps')
      lines.push(`1. %5BInstant Sign-in Here|${encodeURIComponent(instantSignInLink())}%5D`)
      lines.push('')

      lines.push('h2. Properties')
      lines.push(`|Location|${urlPrefix}${window.location.pathname}|`)

      if (data.group) {
        const groupName = `%5B${data.group.name}|${cairo()}?id=${data.group.id}%5D`
        lines.push(`|Group Name|${groupName}|`)
        lines.push(`|Group ID|{{${data.group.id}}}|`)
      }

      lines.push(`|User ID|{{${data.user.id}}}|`)
      lines.push(`|User Email|{{${data.user.email}}}|`)
      lines.push(`|Pharaoh|${pharaoh}|`)
      lines.push(`|Host|${window.location.host}|`)
      lines.push(`|White-label|${label()}|`)
      lines.push(`|Token|{{${getToken()}}}|`)

      const sha = getMeta('commit')
      if (sha) {
        lines.push(`|SHA|%5B${sha}|https://github.com/candor/ra/commit/${sha}%5D|`)
      }

      const description = lines.join('%0A')
      const link = `https://candor-usa.atlassian.net/secure/CreateIssueDetails!init.jspa?pid=10002&description=${description}&issuetype=10004&priority=3&summary=%5B${summary()}%5D`

      return <a href={link}>Create Jira Ticket</a>
    }

    function linear() {
      if (loading) return <p>Loading…</p>
      if (error) return <Error error={error} />
      const data = value!

      let text = `
# Incorrect Behavior
Succinctly describe what didn’t work, *don’t* list the replication steps.

# Expected Behavior
What *should* have happened, but didn’t?

# Replication Steps
1. [Instant Sign-in Here](${instantSignInLink()})

# Properties

|Key|Value|
|-|-|
|Location|${location.pathname}|`
      if (data.group) {
        const groupName = `[${data.group.name}](${cairo()}?id=${data.group.id})`
        text += `
|Group Name|${groupName}|
|Group ID|\`${data.group.id}\`|`
      }

      text += `
|User ID|\`${data.user.id}\`|
|User Email|\`${data.user.email}\`|
|Pharaoh|${pharaoh}|
|Host|${window.location.host}|
|White-label|${label()}|
|Token|\`${getToken()}\`|
`
      const sha = getMeta('commit')
      if (sha) {
        text += `|SHA|[${sha}](https://github.com/candor/ra/commit/${sha})|\n`
      }

      navigator.clipboard.writeText(text)
      addToast('<b>Body</b> copied. Paste into Linear’s create ticket. Do <b>not</b> paste it into the title field!')
    }
  }

  function summary() {
    return location.pathname
  }
}

function getPharaoh() {
  const pharaohHostname = new URL(pharaoh()).hostname
  const pharaohPart = pharaohHostname.split('.')[1]

  switch (pharaohPart) {
  case 'candor':
    return 'production'
  case 'staging':
  case 'demo':
    return pharaohPart
  default:
    return pharaohHostname
  }
}

function getMeta(metaName: string) {
  const metas = document.getElementsByTagName('meta')
  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === metaName) { return metas[i].getAttribute('content') }
  }
}

const Env: React.FC = () => {
  return <table style={{ border: '1px solid black' }}>
    {Object.keys(process.env).sort().map(row)}
  </table>

  function row(key: string) {
    const value = process.env[key]
    if (value) {
      return <tr key={key}><td>{key}</td><td>{process.env[key]}</td></tr>
    } else {
      return null
    }
  }
}
