/* eslint-disable camelcase */
import React from 'react'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import styles from 'Components/Stargate/Enroll/EnrollSelectedPlans.module.scss'
import Loader from 'Components/Rudimentary/Loader'
import { individualsMode, label, Label } from 'Utilities/config'
import EERightNowPlan, { IndividualModeProsperName } from 'Components/Plans/EERightNowPlan'
import EEMedicalPlan from 'Components/Plans/EEMedicalPlan'
import ProsperPlan from 'Components/Plans/ProsperPlan'
import RNCartSummary from 'Components/Stargate/Enroll/components/RNCartSummary'
import CartSummary from 'Components/Stargate/Enroll/components/CartSummary'
import useFullContentArea from 'Utilities/Hooks/useFullContentArea'
import { useAsync } from 'react-use'
import { get } from 'Utilities/fetch++'
import { MedicalPlan, DentalPlan, VisionPlan, LifePlan } from 'Utilities/pharaoh.types'
import Error from 'Components/Primitives/Error'
import DentalPlanComponent from 'Components/Plans/DentalPlan'
import VisionPlanComponent from 'Components/Plans/VisionPlan'
import LifePlanComponent from 'Components/Plans/LifePlan'
import { Route } from 'Utilities/Route'
import { useHistory } from 'react-router-dom'
import { AncillaryPlanUnion, ratesFor } from 'Utilities/Plans/PremiumCalculators'
import { v3 } from 'Utilities/pharaoh'
import { PremiumSplits } from 'Components/Plans/plan-subcomponents/PlanHelpers'

interface Response {
  healthPlan?: MedicalPlan
  dentalPlan?: AncillaryPlanUnion
  visionPlan?: AncillaryPlanUnion
  lifePlan?: AncillaryPlanUnion
  disabilityPlan?: AncillaryPlanUnion
  premiums: {
    medical: Premium
    dental: Premium
    vision: Premium
    life: Premium
    disability: Premium
  }
}

interface Premium {
  premium: string
  contribution: string
  split: PremiumSplits
}

const EEShopPlansEnroll: React.FC<PrivateWizardPageProps> = ({ stargate, ...props }) => {
  const { nonWaivedMemberCount, group, groupMember, userTier } = stargate
  const { loading, error, value } = useAsync(async() => await get('/v2/users/plans') as Response)
  const history = useHistory()
  useFullContentArea()

  if (!group || !groupMember || !userTier) return <Error error='Please complete earlier steps'/>
  if (loading) return <Loader />
  if (error) return <Error error={error} />

  const { healthPlan, dentalPlan, visionPlan, lifePlan, premiums } = value!

  return <div className={styles.mainContainer}>
    <div className={styles.headerContainer}>
      <div className={styles.titleContainer}>
        <h1>Review your plans</h1>
      </div>
      <div className={styles.cartContainer}>
        <div>
          <ol className={styles.orderedList}>
            <li className={styles.enrollInfo}>Review your selected plans</li>
            <li className={styles.enrollInfo}>Change any plans you do not want</li>
            <li className={styles.enrollInfo}>{`Click "${individualsMode() ? 'Finalize and Pay' : 'Finalize Selections'}" when you are ready`}</li>
            {label() === Label.blacksmith &&
              <p><a href="https://public.candor.insurance/blacksmith/model-general-notice.doc">Click here to view your employee Cobra rights</a></p>
            }
          </ol>
          {cart()}
        </div>
      </div>
    </div>
    <div className={styles.plansContainer}>
      <div className={styles.selectionReview}>
        <div className={styles.planContainer}>
          <div>
            <h2>
              Medical Plan &nbsp; <span className={styles.backToPlans} onClick={backToSelect}>Change Plan</span>
            </h2>
          </div>
          {medical()}
          {prosper()}
        </div>
        {ancillary()}
      </div>
    </div>
  </div>

  function medical() {
    if (!healthPlan) return
    if (individualsMode()) {
      return <EERightNowPlan
        groupMemberID={groupMember!.id}
        plan={healthPlan}
        tier={userTier!}
      />
    } else {
      return <EEMedicalPlan
        plan={healthPlan}
        tier={userTier!}
        premiumSplits={premiums.medical.split}
      />
    }
  }

  function prosper() {
    if (healthPlan?.carrier === IndividualModeProsperName) return
    const name = individualsMode() ? IndividualModeProsperName : 'Prosper Benefits'
    return <>
      <h2 className={styles.medicalPlanHeader}>{name}</h2>
      <ProsperPlan
        isIncluded={healthPlan !== undefined}
        nonWaivedMemberCount={nonWaivedMemberCount || 0}
      />
    </>
  }

  function ancillary() {
    return <div>
      <h2>Ancillary Plans</h2>
      {dentalPlan && <DentalPlanComponent
        plan={dentalPlan.plan as DentalPlan}
        rates={ratesFor(dentalPlan)!}
        contribution={{ value: group!.dental_contribution || '0%' || '0%', isEquitable: false }}
        premium={premiums.dental.split}
        employeeTier={userTier!}
        key={dentalPlan.plan.id}
      />}
      {visionPlan && <VisionPlanComponent
        plan={visionPlan.plan as VisionPlan}
        rates={ratesFor(visionPlan)!}
        contribution={{ value: group!.vision_contribution || '0%', isEquitable: false }}
        employeeTier={userTier!}
        premium={premiums.vision.split}
        key={visionPlan.plan.id}
      />}
      {lifePlan && <LifePlanComponent
        plan={lifePlan.plan as LifePlan}
        rates={(lifePlan as any).rate}
        contribution={{ value: group!.life_contribution || '0%', isEquitable: false }}
        premium={premiums.life.split}
        key={lifePlan.plan.id}
      />}
    </div>
  }

  function cart() {
    if (!individualsMode()) {
      return <CartSummary
        callback={onwards}
        premiums={premiums}
      />
    } else if (healthPlan) {
      return <RNCartSummary
        plan={healthPlan}
        memberID={groupMember!.id}
        tier={userTier!}
        callback={onwards}
      />
    }
  }

  function onwards(premium?: string) {
    const next = (individualsMode() ? v3.users().payments.premium({ amount: premium }) : Promise.resolve())
      .then(() => stargate.showUnderwritingPage)
    props.onwards(next)
  }

  function backToSelect() {
    history.push(Route.eeStargate_select)
  }
}

export default EEShopPlansEnroll
