import React from 'react'
import styles from './EERightNowPlan.module.scss'
import MedicalPlanAdditionalSpecs from './plan-subcomponents/MedicalPlanAdditionalSpecs'
import PlanSnapshotSection from './plan-subcomponents/PlanSnapshotSection'
import PlanBox from './plan-subcomponents/PlanBox'
import { MedicalPlanEmployeeCalculator, moneyString } from 'Utilities/Plans/PremiumCalculators'
import { Tier, MedicalPlan } from 'Utilities/pharaoh.types'
import Plan, { PlanBody } from './plan-subcomponents/Plan'
import PlanTitle from './plan-subcomponents/PlanTitle'

interface Props {
  groupMemberID: string
  plan: MedicalPlan
  selected?: boolean
  selectHandler?(id: string): void
  isHandlingCheckmark?: boolean
  tier: Tier
}

export const IndividualModeProsperName = 'Immediate Solution'
export function isPlanProsper(carrier: string) { return carrier === IndividualModeProsperName }

const EERightNowPlan: React.FC<Props> = ({ groupMemberID, plan, tier, selected, selectHandler, isHandlingCheckmark }) => {
  // Need these for calculator, RightNow plans don't have splits, contribution related props
  const splits: any = []
  const contribution = '0%'
  const isEquitable = false
  const calculator = new MedicalPlanEmployeeCalculator(plan, contribution, isEquitable, tier, splits, groupMemberID)

  const isProsper = isPlanProsper(plan.carrier)

  // TODO Rewrite plans components as typescript and better composition
  return <>
    <Plan selected={!!selected}>
      <PlanTitle
        planName={plan.name}
        carrier={plan.carrier}
        includesProsperBenefits={!isProsper}
        selectHandler={selectHandler ? onChange : undefined}
        selected={selected}
        sbc={plan.sbc}
      />
      <PlanBody>
        <Body
          plan={plan}
          rate={calculator.ee()}
          isProsper={isProsper}
        />
      </PlanBody>
    </Plan>
  </>

  function onChange() {
    if (isHandlingCheckmark || !selectHandler) { return }
    selectHandler(plan.id)
  }
}

interface BodyProps {
  plan: MedicalPlan
  rate: number
  isProsper: boolean
}

const Body: React.FC<BodyProps> = ({ plan, rate, isProsper }) => {
  return <>
    <EERightNowPremium plan={plan} rate={rate} isProsper={isProsper} preexistingConditionsCovered={plan.preexistingConditionsCovered}/>
    <MedicalPlanAdditionalSpecs plan={plan} isCollapsed={true} isProsper={isProsper}/>
  </>
}

interface PremiumProps {
  rate: number
  isProsper?: boolean
  preexistingConditionsCovered: boolean
  plan: MedicalPlan
}

const uhOneValueSelectPlanName = 'Value Select'
const EERightNowPremium: React.FC<PremiumProps> = ({ plan, rate, isProsper, preexistingConditionsCovered }) => {
  const isUhOnePlan = plan.name === uhOneValueSelectPlanName && plan.carrier === 'UnitedHealthcare'
  const header =
   isProsper
     ? 'Immediate Plan'
     : 'Right Now Plan'
  const copy =
   isProsper
     ? 'A quick, convenient option for when you need assistance right away'
     : 'Full coverage, including ER and Hospital, in case you contract coronavirus (COVID-19)'
  const disclaimer = 'Pre-existing conditions not covered'

  return <PlanSnapshotSection sectionWidth={0.4}>
    <PlanBox className={styles.planPremiumBox}>
      <div className={styles.header}>{header}</div>
      <div className={styles.content}>
        <div className={styles.youPay}>You Pay</div>
        <div className={styles.largeFigure}><span>{moneyString(rate)}</span>/mo</div>
        { isUhOnePlan && <div className={styles.duration}>Two months due at enrollment</div> }
        <div className={styles.copy}>{copy}</div>
        { !preexistingConditionsCovered && <div className={styles.disclaimer}>{disclaimer}</div> }
      </div>
    </PlanBox>
  </PlanSnapshotSection>
}

export default EERightNowPlan
