import React, { ReactElement } from 'react'
import { kebabCase, camelCase } from 'lodash'
import PrivateRoute from 'Components/Primitives/PrivateRoute'
import { WizardRoute, PrivateWizardRoute } from 'Components/Stargate/Wizard/WizardRoute'
import { Route as RaRoute } from 'Utilities/Route'

import Landing from 'Routes'
import SignIn from 'Routes/account/sign-in'
import SignUp from 'Routes/account/sign-up'
import SignOut from 'Routes/account/sign-out'
import ForgotPassword from 'Routes/account/forgot-password'
import ResetPassword from 'Routes/account/reset-password'
import DashboardAgencyReports from 'Routes/dashboard/agency/reports'
import DashboardAgencyPipeline from 'Routes/dashboard/agency/pipeline'
import DashboardAgencyAssociations from 'Routes/dashboard/agency/associations'
import DashboardAgencyAssociation from 'Routes/dashboard/agency/associations/ID'
import DashboardAgencyGroups from 'Routes/dashboard/agency/clients'
import DashboardAgencyGroup from 'Routes/dashboard/agency/clients/ID'
import DashboardAgencyProducers from 'Routes/dashboard/agency/producers'
import DashboardAgencyProducer from 'Routes/dashboard/agency/producers/ID'
import DashboardAgencyEmployees from 'Routes/dashboard/agency/employees/ID'
import DashboardEmployer from 'Routes/dashboard/employer'
import DashboardEmployerEmployees, { Stages as EEStages } from 'Routes/dashboard/employer/employees'
import DashboardEmployee from 'Routes/dashboard/employee'
import DashboardEmployeeProfile from 'Routes/dashboard/employee/profile'
import ERShopGetStarted from 'Routes/shop/employer/get-started'
import ERShopCensus from 'Routes/shop/employer/census'
import ERShopCensusWaive from 'Routes/shop/employer/census/waive'
import ERShopPlansContribution from 'Routes/shop/employer/plans/contribution'
import ShopHome from 'Routes/shop'
import DashboardEmployerProfile from 'Routes/dashboard/employer/profile'
import DashboardAgencyAgencies from 'Routes/dashboard/agency/agencies'
import EmployeeInfo from 'Routes/shop/employee/census'
import EEShopRightNow from 'Routes/shop/employee/plans/health/RightNowShowPlans'
import EEShopConfirm from 'Routes/shop/employee/confirm'
import Payment from 'Routes/shop/employee/checkout'
import { Redirect, Route } from 'react-router-dom'
import { $enum } from 'ts-enum-util'
import Underwriting from 'Components/Stargate/Underwriting/Underwriting'
import ERStargateCarrierApplication from 'Routes/shop/employer/plans/application'
import ERShopCongratulations from 'Routes/shop/employer/confirm'
import { individualsMode, obeliskMode, showCarrierApplication, showExistingCoverageChapter } from 'Utilities/config'
import HavePlan from 'Routes/shop/employee/already-covered'
import ERShopPlansAncillary from 'Routes/shop/employer/plans/ancillary'
import EEShopPlansAncillary from 'Routes/shop/employee/plans/ancillary'
import EEShopWorkStatus from 'Routes/shop/employee/work'
import ERShopContributionsCarved from 'Routes/shop/employer/plans/contribution/carved'
import ERShopPlans from 'Routes/shop/employer/plans'
import ERShopPlansReview from 'Routes/shop/employer/plans/review'
import WaiveConfirmation from 'Routes/shop/employee/plans/health/waive'
import EEShopFamily from 'Routes/shop/employee/census/family'
import EEShopMedical from 'Routes/shop/employee/medical'
import EEShopPlansHealth from 'Routes/shop/employee/plans/health'
import EEShopPlansEnroll from 'Routes/shop/employee/plans/enroll'

function componentForRoute(route: RaRoute): ReactElement | null {
  // For some of the following indexes the following behavior occurs:
  // 1. If logged in query Pharaoh and if one option, redirect
  // 2. If logged in query Pharaoh and if multiple options, ask user where to go
  // 3. If not logged in, show Sign-in screen with help text for lost unregistered ees

  const additionalProps = { key: route, path: route, exact: true }
  const element = raw()

  // skipping for now until Stargate rewrite
  if (element === null) return null

  if (element.props.path) {
    // don’t overwrite any explicit choice
    delete additionalProps.path
  }

  return React.cloneElement(element, additionalProps)

  function raw(): ReactElement | null {
    const isIndividualMode = individualsMode()

    switch (route) {
    case RaRoute.landing:
      return <PrivateRoute component={Landing} />

    case RaRoute.signUp:
      return <Route path={`${route}/:token`} component={SignUp} />
    case RaRoute.signIn:
      return <Route component={SignIn} />
    case RaRoute.signOut:
      return <Route component={SignOut} />
    case RaRoute.resetPassword:
      return <Route path={`${route}/:token`} component={ResetPassword} />
    case RaRoute.forgotPassword:
      return <Route component={ForgotPassword} />
    case RaRoute.agencyDashboard:
      return <Route render={() =>
        <Redirect to={RaRoute.agencyDashboardReports} />
      }/>
    case RaRoute.agencyDashboardReports:
      return <PrivateRoute component={DashboardAgencyReports} />
    case RaRoute.agencyDashboardPipeline:
      // we add `:filter?` here so when we use the raw enum in eg.
      // Link it doesn’t add our parameter to the resulting destination URL
      return <PrivateRoute path={`${route}/:filter?`} component={DashboardAgencyPipeline} />
    case RaRoute.agencyDashboardClients:
      return <PrivateRoute path={`${route}/:id?`} render={props => {
        const id = props.match.params.id
        if (id && id !== 'flagged') {
          return <DashboardAgencyGroup id={id} {...props.location.state} />
        } else {
          return <DashboardAgencyGroups flagged={id === 'flagged'} />
        }
      }}/>
    case RaRoute.agencyDashboardAssociations:
      return <PrivateRoute path={`${route}/:id?`} render={(props) => {
        const id = props.match.params.id
        if (id) {
          return <DashboardAgencyAssociation id={id} {...props.location.state} />
        } else {
          return <DashboardAgencyAssociations />
        }
      }}/>
    case RaRoute.agencyDashboardProducers:
      return <PrivateRoute path={`${route}/:id?`} render={(props) => {
        const id = props.match.params.id
        if (id) {
          return <DashboardAgencyProducer id={id} {...props.location.state} />
        } else {
          return <DashboardAgencyProducers />
        }
      }}/>
    case RaRoute.agencyDashboardEmployees:
      return <PrivateRoute path={`${route}/:id`} render={(props) => {
        const id = props.match.params.id
        return <DashboardAgencyEmployees id={id} />
      }}/>
    case RaRoute.agencyDashboardAgencies:
      return <PrivateRoute component={DashboardAgencyAgencies} />

    case RaRoute.dashboardEmployer:
      return <PrivateRoute component={DashboardEmployer} />
    case RaRoute.dashboardEmployerEmployees:
      return <PrivateRoute path={`${route}/:id?`} render={(props) => {
        const id = props.match.params.id
        if (!$enum(EEStages).getValues().map(kebabCase).includes(id) && id) {
          return <DashboardAgencyEmployees id={id} {...props.location.state} />
        } else {
          return <DashboardEmployerEmployees filter={camelCase(id) as EEStages}/>
        }
      }} />
    case RaRoute.dashboardEmployerProfile:
      return <PrivateRoute component={DashboardEmployerProfile} />
    case RaRoute.dashboardEmployerBilling:
      return <PrivateRoute />

    case RaRoute.dashboardEmployeeProfile:
      return <PrivateRoute component={DashboardEmployeeProfile} />
    case RaRoute.dashboardEmployee:
      return <PrivateRoute component={DashboardEmployee} />

    case RaRoute.dashboard:
      return <Route render={() => <Redirect to='/'/>} />

    case RaRoute.stargate:
      return <Route path={`${RaRoute.stargate}/:slug?`} component={ShopHome} />

    case RaRoute.erStargateGetStarted: {
      const path = obeliskMode()
        ? route.replace(/employer/, ':slug')
        : undefined
      return <WizardRoute path={path} onwards={RaRoute.erStargateCensus} render={props => {
        if (path) {
          const slug = props.stargate.value?.obelisk.slug
          const rx = path.replace(/:slug/, '([^/]+)')
          const match = props.location?.pathname.match(rx) || []
          if (match[1] === 'employer' || match[1] === ':slug') {
            if (!slug) return <Redirect to={RaRoute.stargate} />
            const to = path.replace(/:slug/, slug)
            return <Redirect to={to} />
          }
        }
        return <ERShopGetStarted {...props} />
      }} entry={<>
        <h1>Hello there :)</h1>
        <p>We are going to start with a few questions to make sure we find you <b>Plans that fit your needs.</b></p>
      </>} />
    }
    case RaRoute.erStargateCensus:
      return <PrivateWizardRoute component={ERShopCensus} onwards={RaRoute.erStargateCensusWaive} entry={<>
        <h1>Getting you a Precise Quote</h1>
        <p>In order to provide you with the most <b>precise quote</b>, please provide us with your census. This information helps to determine all your costs.</p>
      </>}/>
    case RaRoute.erStargateCensusWaive:
      return <PrivateWizardRoute component={ERShopCensusWaive} onwards={RaRoute.erStargateContribution} localStorageKey='waiveScreenSeen' entry={<>
        <h1>Waiving Coverage</h1>
        <p>Some employees will not want coverage, this is called <b>waiving coverage.</b> Waiving Coverage may alter your <b>estimated costs.</b></p>
      </>}/>
    case RaRoute.erStargateContribution:
      return <PrivateWizardRoute component={ERShopPlansContribution} onwards={RaRoute.erStargateCarvedContributions} localStorageKey='contributionScreenSeen' entry={<>
        <h1>Set Your Contribution</h1>
        <p>The average employer covers 62–82% of the <b>healthcare premium</b> per employee. Most healthcare companies require a 50% contribution.</p>
      </>} />
    case RaRoute.erStargateCarvedContributions:
      return <PrivateWizardRoute component={ERShopContributionsCarved} onwards={RaRoute.erStargatePlans} localStorageKey='specialContributionScreenSeen' />
    case RaRoute.erStargatePlans:
      return <PrivateWizardRoute component={ERShopPlans} onwards={RaRoute.erStargateAncillaryPlans} entry={<>
        <h1>Prosper Benefits+</h1>
        <p>Included with every plan you will receive <b>Prosper Benefits+</b>, which includes Telemedicine, Confidential Counseling, Bill Saver, and Health Advocacy.</p>
      </>} />
    case RaRoute.erStargateAncillaryPlans:
      return <PrivateWizardRoute component={ERShopPlansAncillary} onwards={() =>
        showCarrierApplication()
          ? RaRoute.erStargateApplication
          : RaRoute.erStargateReview
      } localStorageKey='ancillaryScreenSeen' />
    case RaRoute.erStargateApplication:
      return <PrivateWizardRoute component={ERStargateCarrierApplication} onwards={RaRoute.erStargateReview} entry={<>
        <h1>Signing Up for Your Care</h1>
        <p>For enrollment purposes, please answer these questions about your company. approx. 5 min</p>
      </>} />
    case RaRoute.erStargateReview:
      return <PrivateWizardRoute component={ERShopPlansReview} onwards={RaRoute.erStargateFinalize} localStorageKey='reviewScreenSeen' />
    case RaRoute.erStargateFinalize:
      return <PrivateWizardRoute component={ERShopCongratulations} onwards={RaRoute.dashboardEmployer} />

    case RaRoute.eeStargate_info:
      if (individualsMode()) {
        let path = route.toString()
        if (obeliskMode()) {
          path = route.replace(/employee/, ':slug')
        }
        return <WizardRoute path={path} render={props => <EmployeeInfo {...props} />} onwards={RaRoute.eeStargate_family} />
      } else {
        return <PrivateWizardRoute render={props => <EmployeeInfo {...props} />} onwards={user =>
          user?.waived
            ? RaRoute.eeStargate_waive_confirm
            : RaRoute.eeStargate_work
        } />
      }
    case RaRoute.eeStargate_waive_confirm:
      return <PrivateRoute component={WaiveConfirmation} />
    case RaRoute.eeStargate_work:
      return <PrivateWizardRoute component={EEShopWorkStatus} onwards={RaRoute.eeStargate_family} />
    case RaRoute.eeStargate_family:
      return <PrivateWizardRoute component={EEShopFamily} onwards={showRedFlagsPage =>
        showRedFlagsPage
          ? RaRoute.eeStargate_redflags
          : isIndividualMode
            ? RaRoute.eeStargate_select
            : showExistingCoverageChapter()
              ? RaRoute.eeStargate_have_plan
              : RaRoute.eeStargate_select
      } localStorageKey='eeFamilySeen' />
    case RaRoute.eeStargate_redflags:
      return <PrivateWizardRoute component={EEShopMedical} onwards={() =>
        isIndividualMode
          ? RaRoute.eeStargate_select
          : showExistingCoverageChapter()
            ? RaRoute.eeStargate_have_plan
            : RaRoute.eeStargate_select
      } localStorageKey='eeRedFlagsSeen'/>
    case RaRoute.eeStargate_have_plan:
      return <PrivateWizardRoute component={HavePlan} onwards={RaRoute.eeStargate_select} />
    case RaRoute.eeStargate_select:
      if (isIndividualMode) {
        return <PrivateWizardRoute component={EEShopRightNow} onwards={RaRoute.eeStargate_enroll}/>
      } else {
        return <PrivateWizardRoute component={EEShopPlansHealth} onwards={user => {
          if (user?.waived) {
            return RaRoute.eeStargate_waive_confirm
          } else {
            return RaRoute.eeStargate_ancillary
          }
        }} />
      }
    case RaRoute.eeStargate_ancillary:
      return <PrivateWizardRoute component={EEShopPlansAncillary} onwards={RaRoute.eeStargate_enroll} localStorageKey='employeeAncillaryScreenSeen' />
    case RaRoute.eeStargate_enroll:
      return <PrivateWizardRoute component={EEShopPlansEnroll} onwards={showUnderwritingPage =>
        isIndividualMode
          ? RaRoute.eeStargate_pay
          : showUnderwritingPage
            ? RaRoute.eeStargate_underwriting
            : RaRoute.eeStargate_confirm
      } localStorageKey='employeeEnrollScreenSeen' />
    case RaRoute.eeStargate_underwriting:
      return <PrivateWizardRoute component={Underwriting} onwards={() =>
        isIndividualMode
          ? RaRoute.eeStargate_pay
          : RaRoute.eeStargate_confirm
      } localStorageKey='eeUnderwritingScreenSeen' />
    case RaRoute.eeStargate_pay:
      return <PrivateWizardRoute component={Payment} onwards={RaRoute.eeStargate_confirm} />
    case RaRoute.eeStargate_confirm:
      return <PrivateWizardRoute component={EEShopConfirm} onwards={RaRoute.dashboardEmployee} />
    }
  }
}

export default componentForRoute
