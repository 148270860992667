import React, { useState, useEffect, CSSProperties } from 'react'
import CandorSelect from './CandorSelect'
import { post } from 'Utilities/fetch++'
import { usePrevious } from 'react-use'
import { Styles } from 'react-select'
import { Control, useFormContext, Controller } from 'react-hook-form'

interface Props {
  zip?: string
  value?: string
  onChange?: (fips?: string) => void
  hidePlaceholder?: boolean
  isDisabled?: boolean
  backgroundColor?: string
  width?: string
  styles?: Styles
  className?: string
  style?: CSSProperties
  required?: boolean
  name?: string
  control?: Control<any>

  stop?: boolean // internal use only!
}

/// Either pass control, useFormContext() or manage value/onChange yourself, if you do none of these it won’t work.
/// Pass `zipCode` or there won’t be *any* options.
const CountyPicker: React.FC<Props> = ({ value, zip: zipCode, onChange, hidePlaceholder, isDisabled, ...props }) => {
  const [options, setOptions] = useState<{ value: string, label: string}[]>([])
  const prevZip = usePrevious(zipCode)
  const control = useFormContext()?.control || props.control

  useEffect(() => {
    if (control && !props.stop) return
    if (prevZip === zipCode) return

    const zip = zipCode

    if ((!zip || zip.length < 5)) {
      if (options.length !== 0) {
        setOptions([])
        if (onChange) onChange(undefined)
      }
      return
    }

    fetcher.fetch(zip, counties => {
      if (zip !== zipCode) return
      // ^^ zip changed while we were fetching the data
      if (counties.length === 0) throw new Error('Invalid ZIP Code')

      setOptions(counties.map(({ name, id }) => ({ label: name, value: id })))

      if (onChange) {
        if (counties.length === 1) {
          onChange(counties[0].id)
        } else if (counties.length === 0 || !counties.find(({ id }) => id === value)) {
          onChange(undefined)
        }
      }
    })
  }, [zipCode, value, prevZip, onChange, options.length, control, props.stop])

  if (control && props.name && !props.stop) {
    return <Controller as={CountyPicker} {...props} name={props.name} zip={zipCode} hidePlaceholder={hidePlaceholder} isDisabled={isDisabled} stop />
    // NOTE stop will be forwarded to the CandorSelect preventing doubling the Controller
  }

  return <CandorSelect
    {...props}
    placeholder={hidePlaceholder ? undefined : 'County'}
    isDisabled={isDisabled || options.length < 1}
    options={options}
    value={value}
    onChange={onChange}
  />
}

// because there may be 100+ of us on the same page and we want to make *one* API call
class Fetcher {
  zips: { [key: string]: ((obj: { name: string, id: string }[]) => void)[] } = {}
  active = false

  fetch = (zip: string, callback: (objs: {name: string, id: string}[]) => void) => {
    if (this.zips[zip]) {
      this.zips[zip].push(callback)
    } else {
      this.zips[zip] = [callback]
    }
    if (!this.active) {
      this.active = true
      setTimeout(this.go, 0)
    }
  }

  go = () => {
    const zips = this.zips
    this.zips = {}
    this.active = false

    post('/v2/fips/4/zips', Object.keys(zips)).then(rsp => {
      if (Object.keys(rsp).length === 0) {
        throw new Error('Invalid ZIP Code')
      }
      Object.keys(rsp).forEach(zip =>
        zips[zip].forEach(callback => callback(rsp[zip]))
      )
    }).catch(console.error) // FIXME
  }
}

const fetcher = new Fetcher()

export default CountyPicker
