import React, { useState } from 'react'
import ProfileSection, { ShowAll } from 'Components/Anubis/ProfileSection'
import styles from './AssociationProfileGroupsSection.module.css'
import { useAsync } from 'react-use'
import * as api from 'Utilities/pharaoh'
import { useHistory } from 'react-router-dom'
import { Route } from 'Utilities/Route'
import { AsyncTable, SortDirection } from 'Components/Rudimentary/Table'
import _ from 'lodash'
import SearchBar from 'Components/Anubis/SearchBar'

interface Props {
  id: string
}

interface Group {
  id: string
  name: string
  contact?: {name?: string, email: string}
  eeCount: number
  renewal: Date
  premium: string
  applicationStatus: string
}

const AssociationProfileGroupsSection: React.FC<Props> = ({ id }) => {
  const async = useAsync(async() => (await api.v2.brokers.associations(id).groups()).groups as [Group], [id])
  const [query, setQuery] = useState('')
  const history = useHistory()

  return <ProfileSection name="Groups" expanded={true}>
    <ShowAll onChange={() => setQuery('')} checked={!query}/>
    <SearchBar
      placeholder='Search Groups'
      query={query}
      setQuery={setQuery}
      className={styles.positionSearchBar}
    />
    <AsyncTable
      async={async}
      mangle={filter}
      selectAction={(row) => history.push(`${Route.agencyDashboardClients}/${row.id}`)}
      selectable={(row) => !!row.id}
      scrollable
      style={{ height: 300, overflowY: 'scroll' }}
      content={(key, value) => {
        switch (key) {
        case 'renewal':
          return value ? new Date(value) : '—'
        case 'applicationStatus':
          return _.startCase(value)
        case 'premium':
          return value === '$0.00' ? null : value
        case 'eeCount':
          return value || null
        }
      }}
      width={key => {
        switch (key) {
        case 'contact':
        case 'name':
          return '22%'
        case 'eeCount':
          return '15%'
        case 'renewal':
          return '10%'
        case 'premium':
          return '17%'
        case 'applicationStatus':
          return '14%'
        }
      }}
      heading={key => {
        switch (key) {
        case 'contact': return 'Client/Lead Name'
        case 'name': return 'Group'
        case 'eeCount': return '# Employees'
        case 'renewal': return key
        case 'premium': return 'Total Premium'
        case 'applicationStatus': return 'AP Status'
        }
      }}
      order={['contact', 'name', 'eeCount', 'renewal', 'premium', 'applicationStatus']}
      defaultSort='name'
      defaultSortDirection={key => {
        switch (key) {
        case 'id':
        case 'name':
        case 'contact':
        case 'applicationStatus':
          return SortDirection.ascending
        case 'eeCount':
        case 'renewal':
        case 'premium':
          return SortDirection.descending
        }
      }}
      headerToolTip={key => key === 'eeCount' ? 'Employee Count' : null}
    />
  </ProfileSection>

  /// /////////////////////////////////////////////////////////////////// functions
  function filter(input: Group[]) {
    if (!query) return input
    return input.filter(group => group.name.toLowerCase().includes(query.toLowerCase()))
  }
}

export default AssociationProfileGroupsSection
