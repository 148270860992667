import React, { useState, ChangeEvent } from 'react'
import styles from './TodoList.module.css'
import { useKey, useAsyncRetry } from 'react-use'
import * as api from 'Utilities/pharaoh'
import Error from 'Components/Primitives/Error'
import Loader from 'Components/Rudimentary/Loader'
import { v4 as uuid } from 'uuid'
import useToast from 'Utilities/Hooks/useToast'
import CarrierApplication from 'Components/Anubis/CarrierApplication'
import CandorModal from 'Components/Rudimentary/CandorModal'
import UploadFileModal from './UploadFileModal'
import { startCase } from 'lodash'
import { useHistory } from 'react-router-dom'
import { Route } from 'Utilities/Route'
import InviteEmployeesModal from 'Components/Modals/Anubis/InviteEmployeesModal'

interface Props {
  id: string
  className?: string
}

enum Action {
  plans = 'plans',
  census = 'census',
  invites = 'invites',
  manager = 'manager',
  carrierApplication = 'carrierApplication',
  businessLicense = 'businessLicense',
  priorCarrierBill = 'priorCarrierBill',
  wageAndTaxReport = 'wageAndTaxReport',
  articlesOfIncorporation = 'articlesOfIncorporation',
  unclassified = 'unclassified'
}

interface ToDo {
  id: string
  content: string
  done: boolean
  action: Action
}

const TodoList: React.FC<Props> = ({ id: targetID, className }) => {
  const [disabled, setDisabled] = useState(false)
  const [todos, setTodos] = useState<ToDo[]>([])
  const addToast = useToast()
  const [activeToDo, setActiveToDo] = useState<ToDo | undefined>()
  const history = useHistory()

  // handle enter press to blur and thus trigger save
  useKey('Enter', () => (document.activeElement as HTMLInputElement)?.blur())

  const async = useAsyncRetry(async() => {
    setTodos(await api.v2.todos(targetID).GET())
  }, [targetID])

  if (async.loading) return <Loader/>
  if (async.error) return <Error error={async.error}/>

  const elements = todos.map((todo, index) => {
    const content = todo.action === Action.unclassified
      ? <input
        name={todo.id}
        value={todo.content}
        placeholder="What needs doing?"
        className={styles.text}
        onBlur={save}
        onChange={onChangeText}
        autoFocus={!todo.content}
      />
      : <button onClick={onActionableToDoClick(todo)} className={styles.text}>{todo.content}</button>
    const deleteButton = todo.action === Action.unclassified || todo.done
      ? <button onClick={() => onClickDelete(todo.id)} className={styles.delete}>
        <i className={`material-icons ${styles.arrow}`}>remove_circle_outline</i>
      </button>
      : undefined

    return <div key={index} className={styles.todo}>
      <input
        onChange={onChangeCheck}
        checked={todo.done}
        name={todo.id}
        type="checkbox"
        className={styles.check}
      />
      {content}
      {deleteButton}
    </div>
  })

  let uploadModalOpen
  switch (activeToDo?.action) {
  case Action.priorCarrierBill:
  case Action.articlesOfIncorporation:
  case Action.businessLicense:
  case Action.wageAndTaxReport:
    uploadModalOpen = true
    break
  default:
    uploadModalOpen = false
  }

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.header}>
        <h1>Outstanding Items</h1>
        <button onClick={onAddClick} className={styles.addTodo}>Add Item<i className='material-icons'>add_circle_outline</i></button>
      </div>
      <fieldset disabled={disabled} className={styles.todos}>
        {elements}
      </fieldset>
      <CarrierApplicationModal
        isOpen={activeToDo?.action === Action.carrierApplication}
        groupID={targetID}
        callback={onCarrierApplicationClose}
      />
      <UploadFileModal
        isOpen={uploadModalOpen}
        title={startCase(activeToDo?.action)}
        copy={uploadModalCopy()}
        onSubmit={upload}
        onRequestClose={close}
      />
      <InviteEmployeesModal
        groupID={targetID}
        isOpen={activeToDo?.action === Action.invites}
        onRequestClose={close}
      />
    </div>
  )

  async function onChangeCheck(event: ChangeEvent<HTMLInputElement>) {
    const { name: id, checked: done } = event.currentTarget
    setDisabled(true)
    try {
      await api.v2.todos(id).PUT({ done })
      setTodos(todos =>
        todos.map(todo => {
          if (todo.id === id) todo.done = done
          return todo
        }))
    } catch (error) {
      addToast(error)
    } finally {
      setDisabled(false)
    }
  }

  async function onChangeText(event: ChangeEvent<HTMLInputElement>) {
    const { name: id, value: content } = event.currentTarget
    setTodos(todos => todos.map(todo => {
      if (todo.id === id) todo.content = content
      return todo
    }))
  }

  async function onClickDelete(id: string) {
    setDisabled(true)

    try {
      await api.v2.todos(id).DELETE()
      setTodos(todos => todos.filter(todo => todo.id !== id))
    } catch (error) {
      addToast(error)
    } finally {
      setDisabled(false)
    }
  }

  async function save(event: React.FocusEvent<HTMLInputElement>) {
    const { name: id, value: content } = event.currentTarget

    // remove the ToDo if it is empty
    if (!content) {
      return setTodos(todos => todos.filter(todo => todo.id !== id))
    }

    setDisabled(true)

    try {
      await api.v2.todos(targetID).POST({ content, id })
    } catch (error) {
      addToast(error)
    } finally {
      setDisabled(false)
    }
  }

  function onAddClick() {
    setTodos(todos =>
      todos.concat({ id: uuid(), content: '', done: false, action: Action.unclassified })
    )
  }

  async function onCarrierApplicationClose(success: boolean) {
    if (!success) return setActiveToDo(undefined)
    try {
      if (!activeToDo) return
      await api.v2.todos(activeToDo.id).PUT({ done: true })
      close()
      async.retry() // easiest way to update the checks
    } catch (error) {
      addToast(error)
    }
  }

  function close() {
    setActiveToDo(undefined)
  }

  function uploadModalCopy(): string | undefined {
    switch (activeToDo?.action) {
    case Action.articlesOfIncorporation:
      return 'Your articles of incorporation'
    case Action.businessLicense:
      return 'Your business license'
    case Action.priorCarrierBill:
      return 'A bill from your current or prior carrier'
    case Action.wageAndTaxReport:
      return 'A current wage or tax report'
    }
  }

  async function upload(data: any) {
    if (!activeToDo) return

    const formData = new FormData()
    formData.append('file', data.file[0])
    formData.append('type', activeToDo.action)
    formData.append('note', startCase(activeToDo.action))

    // NOTE catches in `UploadFileModal`
    await api.v2.brokers.groups(targetID).documents().POST(formData)
    await api.v2.todos(activeToDo.id).PUT({ done: true })

    close()

    // async.retry()
    // we need to reload the documents section and this is easier than
    // making a custom hook at this point :/
    window.location.reload()
  }

  function onActionableToDoClick(todo: ToDo): () => void {
    return () => {
      if (todo.done) return
      switch (todo.action) {
      case Action.plans:
        // TODO makes no sense to take them here if we still need the census
        localStorage.overrideGroupID = targetID
        history.push(Route.erStargatePlans)
        break
      case Action.census:
        localStorage.overrideGroupID = targetID
        history.push(Route.erStargateCensus)
        break
      case Action.manager:
        break // TODO
      case Action.invites:
      case Action.carrierApplication:
      case Action.businessLicense:
      case Action.priorCarrierBill:
      case Action.wageAndTaxReport:
      case Action.articlesOfIncorporation:
        setActiveToDo(todo)
        break
      case Action.unclassified:
        break
      }
    }
  }
}

const CarrierApplicationModal: React.FC<{
  groupID: string
  callback: (success: boolean) => void
  isOpen: boolean
}> = ({ groupID, callback: onComplete, isOpen }) => {
  // NOTE wanted to use AnubisModal for close button but that has height problems
  return <CandorModal isOpen={isOpen}>
    <CarrierApplication groupID={groupID} callback={() => onComplete(true)} cancelCallback={() => onComplete(false)} />
  </CandorModal>
}

export default TodoList
