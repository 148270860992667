import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Route } from 'Utilities/Route'
import { Table, Alignment } from 'Components/Rudimentary/Table'
import { utcMidnightToLocalMidnight } from 'Utilities/pharaoh'
import ProfileSection, { ShowAll } from 'Components/Anubis/ProfileSection'
import { startCase } from 'lodash'
import { stages } from './PipelineSettings'
import { PipelineStatus } from 'Utilities/pharaoh.types'
import { associationsMode } from 'Utilities/config'
import { Filter } from './index'

export interface Pipe {
  name: string
  group: any
  pipelineStatus: PipelineStatus
  applicationStatus: string
  renewal: Date | null
  contacted: Date | null
  associations: any[]
  flagged: boolean
}

interface Props {
  pipes: Pipe[]
  filter?: Filter
}

const PipelineDetails: React.SFC<Props> = props => {
  const pipes = props.pipes.map(pipe => ({ id: pipe.group.id, ...pipe }))
  const filtering = props.filter !== undefined
  const filterLabel = stages.find(stage => stage.key === props.filter)?.label
  const history = useHistory()
  const name = filterLabel ? `${filterLabel} Details` : 'Pipeline Details'

  return <ProfileSection expanded name={name}>
    <ShowAll onChange={() => { history.push(Route.agencyDashboardPipeline) }} checked={!filtering}/>
    <Table
      data={pipes}
      content={(key, value) => {
        switch (key) {
        case 'renewal':
          return utcMidnightToLocalMidnight(value)
        case 'group':
          return value.name
        case 'associations':
          return value.map((ass: any) => <li key={ass.id}><Link to={`${Route.agencyDashboardAssociations}/${ass.id}`}>{ass.name}</Link></li>)
        case 'applicationStatus':
        case 'pipelineStatus':
          return startCase(value)
        }
      }}
      alignment={key => {
        switch (key) {
        case 'applicationStatus':
        case 'pipelineStatus':
        case 'renewal':
          return Alignment.center
        default:
          return Alignment.left
        }
      }}
      heading={key => {
        switch (key) {
        case 'name':
          return 'Contact Name'
        case 'pipelineStatus':
          return 'Status'
        case 'applicationStatus':
          return 'App. Status'
        case 'flagged':
          return 'Flag'
        case 'group':
          return 'Client'
        }
      }}
      width={key => {
        switch (key) {
        case 'name':
          return '20%'
        case 'group':
          return '20%'
        case 'pipelineStatus':
          return '10%'
        case 'renewal':
          return '10%'
        case 'applicationStatus':
          return '13%'
        case 'associations':
          return '20%'
        case 'flagged':
          return '7%'
        }
      }}
      defaultSort='group'
      selectAction={row =>
        history.push(`${Route.agencyDashboardClients}/${row.id}`)
      }
      selectable={(row) => !!row.id}
      sortable={['name', 'group', 'pipelineStatus', 'applicationStatus', 'renewal', 'flagged']}
      order={(() => {
        const keys: Array<keyof Pipe> = ['name', 'group', 'pipelineStatus', 'applicationStatus', 'renewal']
        if (associationsMode()) keys.push('associations')
        keys.push('flagged')
        return keys
      })()}
      sortValue={(key, value) => {
        switch (key) {
        case 'group':
          return value.name
        }
      }}
      reportFileBasename='pipeline'
      reportContent={(key, value) => {
        switch (key) {
        case 'associations':
          return value.map((ass: any) => ass.codename || ass.name).join(', ')
        case 'flagged':
          return value
        }
      }}
    />
  </ProfileSection>
}

export default PipelineDetails
