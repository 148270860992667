import React, { ReactElement, useRef, useState, useEffect } from 'react'
import PlanTitle from './plan-subcomponents/PlanTitle'
import PlanSnapshotSection from './plan-subcomponents/PlanSnapshotSection'
import PlanBox from './plan-subcomponents/PlanBox'
import MedicalPlanAdditionalSpecs from './plan-subcomponents/MedicalPlanAdditionalSpecs'
import styles from './MedicalPlan.module.css'
import { MedicalPlanGroupCalculator, moneyString } from 'Utilities/Plans/PremiumCalculators'
import Plan, { PlanBody } from './plan-subcomponents/Plan'
import { fundingTypeFor } from './plan-subcomponents/PlanHelpers'
import { MedicalPlan, Tier } from 'Utilities/pharaoh.types'
import { useToggle } from 'react-use'
import { Member, ContributionSplit } from 'Utilities/Hooks/useStargate'
import ReactDOM from 'react-dom'

interface Props {
  plan: MedicalPlan
  selected?: boolean
  selectedForRemoval?: boolean
  selectHandler?: (plan: MedicalPlan) => void
  deleteHandler?: (planID: string) => void
  rates: any
  members: Member[]
  splits: ContributionSplit[]
  contribution: string
  isEquitable: boolean
}

const MedicalPlanComponent: React.FC<Props> = ({ members, splits, contribution, isEquitable, rates, selected, plan, selectedForRemoval, selectHandler, deleteHandler }) => {
  const [isCollapsed, toggleCollapsed] = useToggle(true)
  const [medicalPremiumTypeContainerWidth, setMedicalPremiumTypeContainerWidth] = useState(0)
  const [medicalPremiumBreakdownContainerWidth, setMedicalPremiumBreakdownContainerWidth] = useState(0)
  const [medicalTierContainerWidth, setMedicalTierContainerWidth] = useState(0)
  const ref = useRef<HTMLElement>()

  // FIXME inefficient to do this *every* render
  useEffect(() => {
    updateTableColumnWidth.bind(window)(false)
    window.addEventListener('resize', updateTableColumnWidth)
    return () => window.removeEventListener('resize', updateTableColumnWidth)
  })

  const wantCoverage = members.filter(m => !m.is_waived)
  const individualPlansCount = wantCoverage.filter(m => m.tier === 'individual').length
  const singleParentPlansCount = wantCoverage.filter(m => m.tier === 'singleParent').length
  const couplePlansCount = wantCoverage.filter(m => m.tier === 'couple').length
  const familyPlansCount = wantCoverage.filter(m => m.tier === 'family').length

  return <Plan selected={selected}>
    <PlanTitle
      planName={plan.name}
      carrier={plan.carrier}
      includesProsperBenefits
      fundingType={fundingTypeFor(plan)}
      selectHandler={selectHandler && (() => selectHandler(plan))}
      selected={selected}
      removeHandler={deleteHandler && onRemoveChange}
      selectedForRemoval={selectedForRemoval}
      sbc={plan.sbc}
    />
    <PlanBody selectedForRemoval={selectedForRemoval}>{medicalSnapshot()}</PlanBody>
  </Plan>

  function collapse() {
    ReactDOM.unstable_batchedUpdates(() => {
      toggleCollapsed()
      updateTableColumnWidth.bind(window)(true)
    })
  }

  function updateTableColumnWidth(this: Window, onChange: any) {
    if (!ref.current) return

    const width = ref.current.getBoundingClientRect().width

    // Padded to avoid white space due to rounding. It is actually 24px
    const paddedExpandButtonWidth = 25
    const planContainerPadding = 20
    const collapsedTablePercentage = 0.4
    // First column percentage when table is collapsed
    const premiumTypeColumnPercentage = 0.5
    // Second column percentage when table is collapsed
    const premiumBreakdownColumnPercentage = 0.5

    const snapshotContainerWidth = width + planContainerPadding
    const planSnapshotSectionWidth = snapshotContainerWidth * collapsedTablePercentage - planContainerPadding
    const premiumTableWithoutButtonWidth = planSnapshotSectionWidth - paddedExpandButtonWidth

    if (onChange) {
      /* This is to avoid the one pixel stutter when toggling the collapse
        because pixels can't be .2423424 and is also why we do Math.round() */
      if (!isCollapsed) {
        const tierWidth = (width - medicalPremiumTypeContainerWidth - medicalPremiumBreakdownContainerWidth - paddedExpandButtonWidth) / 3
        setMedicalTierContainerWidth(tierWidth)
      }
    } else if (isCollapsed) {
      // Width - expand button width * column width percentage

      ReactDOM.unstable_batchedUpdates(() => {
        setMedicalPremiumTypeContainerWidth(Math.round((width - paddedExpandButtonWidth) * premiumTypeColumnPercentage))
        setMedicalPremiumBreakdownContainerWidth(Math.round((width - paddedExpandButtonWidth) * premiumBreakdownColumnPercentage))
        setMedicalTierContainerWidth(0)
      })
    } else {
      const premiumTypeWidth = Math.round(premiumTableWithoutButtonWidth * premiumTypeColumnPercentage)
      const premiumBreakdownWidth = Math.round(premiumTableWithoutButtonWidth * premiumBreakdownColumnPercentage)
      const medicalTierContainerWidth = (width - premiumTypeWidth - premiumBreakdownWidth - paddedExpandButtonWidth) / 3

      ReactDOM.unstable_batchedUpdates(() => {
        setMedicalPremiumTypeContainerWidth(premiumTypeWidth)
        setMedicalPremiumBreakdownContainerWidth(premiumBreakdownWidth)
        setMedicalTierContainerWidth(medicalTierContainerWidth)
      })
    }
  }

  function medicalSnapshot() {
    const premiums = getPremiums()

    return <>
      <PlanSnapshotSection sectionWidth={isCollapsed ? 0.4 : 1}>
        <PlanBox height={ref.current?.getBoundingClientRect().height || 0} className={styles.medicalTableBox} refCallback={(rof: HTMLElement) => { ref.current = rof }}>
          <div className={styles.medicalPremiumTypeContainer} style={{ width: medicalPremiumTypeContainerWidth }}>
            <div>
                Individual{individualPlansCount > 1 ? 's' : ''}<br/>
              <span>cost / mo</span>
            </div>
            <div>
                Couples<br/>
              <span>cost / mo</span>
            </div>
            <div>
                Employee/Child<br/>
              <span>cost / mo</span>
            </div>
            <div>Family<br/>
              <span>cost / mo</span>
            </div>
          </div>
          <div className={styles.medicalPremiumBreakdownContainer} style={{ width: medicalPremiumBreakdownContainerWidth }}>
            <div>
              {/* Extra div level to do the mint overlay */}
              <div className={styles.premiumLabel}>
                {planCostString(rates.individual)}
              </div>
            </div>
            <div>
              <div className={styles.premiumLabel}>
                {planCostString(rates.couple)}
              </div>
            </div>
            <div>
              <div className={styles.premiumLabel}>
                {planCostString(rates.singleParent)}
              </div>
            </div>
            <div>
              <div className={styles.premiumLabel}>
                {planCostString(rates.family)}
              </div>
            </div>
          </div>
          {tray()}
          <div className={styles.medicalExpandContainer}>
            <button className={isCollapsed ? styles.medicalExpandCollapsedCarrot : styles.medicalExpandShownCarrot} onClick={collapse}>
              <div>Click {isCollapsed ? 'for' : 'to hide'} more information</div>
            </button>
          </div>
        </PlanBox>
      </PlanSnapshotSection>
      <MedicalPlanAdditionalSpecs sectionWidth={isCollapsed ? 0.6 : 1} isCollapsed={isCollapsed} plan={plan}/>
    </>

    function tray() {
      if (isCollapsed) return null
      return <>
        <div style={{ width: medicalTierContainerWidth, opacity: isCollapsed ? 0 : 1, transition: 'all .25s linear' }}>
          <div className={styles.premiumTypeHeader}>
            <p>Employer Cost</p>
            <div>{moneyString(premiums.individual.er)}</div>
          </div>
          <div>{moneyString(premiums.couple.er)}</div>
          <div>{moneyString(premiums.singleParent.er)}</div>
          <div>{moneyString(premiums.family.er)}</div>
        </div>
        <div style={{ width: medicalTierContainerWidth, opacity: isCollapsed ? 0 : 1, transition: 'all .25s linear' }}>
          <div className={styles.premiumTypeHeader}>
            <p>Employee Cost</p>
            <div>{moneyString(premiums.individual.ee)}</div>
          </div>
          <div>{moneyString(premiums.couple.ee)}</div>
          <div>{moneyString(premiums.singleParent.ee)}</div>
          <div>{moneyString(premiums.family.ee)}</div>
        </div>
        <div className={styles.premiumTotalColumn} style={{ width: medicalTierContainerWidth, opacity: isCollapsed ? 0 : 1, transition: 'all .25s linear' }}>
          <div>
            <p>Total</p>
            <div>
              <p>{`( for ${individualPlansCount} ${individualPlansCount === 1 ? 'plan' : 'plans'} )`}</p>
              <div>{moneyify(premiums.individual.sum)}</div>
            </div>
          </div>
          <div>
            <p>{`( for ${couplePlansCount} ${couplePlansCount === 1 ? 'plan' : 'plans'} )`}</p>
            <div>{moneyify(premiums.couple.sum)}</div>
          </div>
          <div>
            <p>{`( for ${singleParentPlansCount} ${singleParentPlansCount === 1 ? 'plan' : 'plans'} )`}</p>
            <div>{moneyify(premiums.singleParent.sum)}</div>
          </div>
          <div>
            <p>{`( for ${familyPlansCount} ${familyPlansCount === 1 ? 'plan' : 'plans'} )`}</p>
            <div>{moneyify(premiums.family.sum)}</div>
          </div>
        </div>
      </>
    }

    function getPremiums() {
      const calc = new MedicalPlanGroupCalculator(
        plan,
        { value: contribution, isEquitable },
        splits,
        members
      )

      return {
        individual: {
          sum: calc.erForTier(Tier.individual),
          er: calc.erCostForTier(Tier.individual),
          ee: calc.eeCostForTier(Tier.individual)
        },
        singleParent: {
          sum: calc.erForTier(Tier.singleParent),
          er: calc.erCostForTier(Tier.singleParent),
          ee: calc.eeCostForTier(Tier.singleParent)
        },
        couple: {
          sum: calc.erForTier(Tier.couple),
          er: calc.erCostForTier(Tier.couple),
          ee: calc.eeCostForTier(Tier.couple)
        },
        family: {
          sum: calc.erForTier(Tier.family),
          er: calc.erCostForTier(Tier.family),
          ee: calc.eeCostForTier(Tier.family)
        }
      }
    }

    function moneyify(input: number | string): string {
      if (input === 0) {
        return '—'
      } else {
        return moneyString(input)
      }
    }

    function planCostString(input: number | string): string | ReactElement {
      if (input === 0) {
        return '–'
      } else {
        // Remove '$' from string
        const amt = moneyString(input).split('$')
        return <p className={styles.planCost}><span>$</span>{amt}<span>/mo</span></p>
      }
    }
  }

  function onRemoveChange() {
    if (deleteHandler) {
      deleteHandler(plan.id)
    }
  }
}

export default MedicalPlanComponent
