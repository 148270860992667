/* eslint-disable @typescript-eslint/no-explicit-any */
import * as config from 'Utilities/config'
import { getToken } from './pharaoh'

export class FetchError extends Error {
  constructor(msg: string, rsp: Response, json: any) {
    super(msg)
    this.response = rsp
    this.json = json
    Object.setPrototypeOf(this, FetchError.prototype) // https://stackoverflow.com/a/41429145/6444
  }

  response: Response
  json: any
}

async function go(url: RequestInfo, obj: any) {
  // cache setting for IE11
  const rsp = await fetch(url, { ...obj, cache: 'no-cache' })
  if (rsp.ok) {
    const contentType = ((rsp.headers.get('Content-Type') || '').split(';')[0] || '').trim()
    if (contentType === 'application/json') {
      return rsp.json()
    } else {
      return rsp
    }
  } else {
    let json = null
    try {
      json = await rsp.json()
    } catch {
      json = { error: true, reason: 'Unknown Error (no JSON response)' }
    }
    throw new FetchError(rsp.statusText, rsp, json)
  }
}

const makeHeaders = () => {
  const headers = new Headers()
  headers.set('Accept', 'application/json')
  headers.set('Content-Type', 'application/json')
  if (process.env.REACT_APP_BASENAME) {
    headers.set('X-Ra-Basename', process.env.REACT_APP_BASENAME)
  }
  if (window.location.pathname.startsWith('/shop')) {
    // ^^ strictly check is not sufficient due to REACT_APP_BASENAME
    // but this is a quick hack for COMAHC launch, prod sites never have BASENAME
    headers.set('X-Ra-Slug', window.location.pathname.split('/')[2])
  }
  const token = getToken()
  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
  }
  const label = config.label()
  if (window.location.host !== label) {
    headers.set('X-Ra-White-Label', label)
  }
  return headers
}

async function get(path: string) {
  const headers = makeHeaders()
  return go(`${config.pharaoh()}${path}`, { headers: headers })
}

async function delete_(path: string) {
  const headers = makeHeaders()
  return go(`${config.pharaoh()}${path}`, { headers: headers, method: 'DELETE' })
}

async function put(path: string, data: any = null) {
  const headers = makeHeaders()
  return go(`${config.pharaoh()}${path}`, {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(data)
  })
}

async function upload(path: string, file: File, auth = true) {
  const formData = new FormData()
  formData.append('file', file)
  return post(path, formData, auth)
}

async function post(path: string, data: any = null, auth = true) {
  const headers = makeHeaders()
  if (!auth) {
    headers.delete('Authorization')
  }

  if (data instanceof FormData) {
    // pharaoh seems to default to formdata
    headers.delete('Content-Type')
  } else if (data) {
    data = JSON.stringify(data)
  }

  return go(`${config.pharaoh()}${path}`, {
    method: 'POST',
    cache: 'no-cache',
    headers: headers,
    body: data
  })
}

export { get, put, post, delete_, upload }
