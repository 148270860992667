import React, { useState } from 'react'
import { v1, clearLocalStorage, setToken } from 'Utilities/pharaoh'
import { useHistory, RouteComponentProps, Link, Redirect } from 'react-router-dom'
import useToast from 'Utilities/Hooks/useToast'
import { useForm } from 'react-hook-form'
import { Route } from 'Utilities/Route'
import styles from './account.module.scss'
import CandorInput from 'Components/Rudimentary/CandorInput'
import { mode, Mode, individualsMode, obeliskMode, rawTitle, Label, labelShortCode, switchLabel, host, Host } from 'Utilities/config'

type Props = RouteComponentProps

/// props via a <Redirect />’s location.state
interface ReceivedProps {
  email: string | undefined
  name: string | undefined
  terminus: string | undefined
}

const SignIn: React.FC<Props> = (props) => {
  const { email, name, terminus } = (props.location?.state || {}) as ReceivedProps
  const { register, handleSubmit } = useForm()
  const history = useHistory()
  const addToast = useToast()
  const [disabled, setDisabled] = useState(false)
  const isDemo = mode()[0] === Mode.demo
  const demoHiddenStyle = { display: isDemo ? 'none' : 'block' }

  const [token, redirect, label] = extractParameters()
  if (token) {
    if (label && host() !== Host.production) switchLabel(label)
    clearLocalStorage() // mostly for the overrideGroupID type stuff
    setToken(token)
    return <Redirect to={redirect || '/'} />
  }

  return <form className={styles.accountForm} onSubmit={handleSubmit(onSubmit)}>
    {welcome()}
    <fieldset disabled={disabled}>
      <CandorInput
        name="email"
        ref={register}
        type="email"
        placeholder="Email"
        defaultValue={email}
        required
      />
      <CandorInput
        name="password"
        ref={register}
        type="password"
        placeholder="Password"
        style={demoHiddenStyle}
        required={!isDemo}
      />
      <span style={demoHiddenStyle}>Email or password trouble? <Link to={Route.forgotPassword}>Click here!</Link></span>
      <input type="submit" value={disabled ? 'Signing In…' : 'Sign In'} />
    </fieldset>
    <h2 style={{ margin: '4rem 0 0 0' }}>Looking for the Shop?</h2>
    {shopCopy()}
  </form>

  async function onSubmit(data: any) {
    try {
      setDisabled(true)
      await v1.users.sessions.POST(data.email, data.password)
      if (label) switchLabel(label)
      history.replace(terminus || Route.landing)
    } catch (error) {
      addToast(error)
      setDisabled(false)
    }
  }

  function welcome() {
    if (mode()[0] === Mode.demo) {
      return <>
        <h1>Welcome to the {rawTitle()} Demo</h1>
        <h3>This portal demonstrates the capabilities of {rawTitle()}</h3>
        <p>Explore away, we delete everything when you sign out.</p>
        <h3>We need your email</h3>
        <p>We send a lot of emails (so many) but this is a demo so we email <b>you</b>, just you and nobody else. Go wild! Test <i>everything</i>.</p>
        <h3>The demo is public</h3>
        <p>Don’t enter anything real or personally identifiable. For serious.</p>
        <br/>
        <h1>Let’s Get Started</h1>
      </>
    } else if (name) {
      return <h1>Welcome Back {name}!</h1>
    } else {
      return <h1>Welcome Back!</h1>
    }
  }

  function extractParameters(): [string | null, string | null, Label | undefined] {
    const pp = new URLSearchParams(window.location.search)
    return [pp.get('token'), pp.get('redirect'), label()]

    function label() {
      const param = pp.get('label')
      if (param) {
        return Label[labelShortCode(param)]
      }
    }
  }

  function shopCopy() {
    if (individualsMode()) {
      return <p style={{ textAlign: 'center' }}><Link to='/shop/employee'>Click here</Link></p>
    } else if (obeliskMode()) {
      return <p>Your broker will have provided you a unique link, you need that.</p>
    } else {
      return <p style={{ textAlign: 'center' }}><Link to='/shop'>Click here</Link></p>
    }
  }
}

export default SignIn
