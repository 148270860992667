import React from 'react'
import styles from './ReviewPlansList.module.scss'
import DentalPlanComponent from 'Components/Plans/DentalPlan'
import MedicalPlanComponent from 'Components/Plans/MedicalPlan'
import VisionPlanComponent from 'Components/Plans/VisionPlan'
import { Group, ContributionSplit, Member } from 'Utilities/Hooks/useStargate'
import { MedicalPlan, VisionPlan, DentalPlan } from 'Utilities/pharaoh.types'
import { AncillaryPlanUnion, ratesFor } from 'Utilities/Plans/PremiumCalculators'
import ContributionAmount, { contributionType } from 'Components/Stargate/Contribution/ContributionAmount'

type PlanType = 'medical' | 'vision' | 'dental'

interface Props {
  medicalContribution: string
  dentalContribution: string
  visionContribution: string

  medicalPlans: MedicalPlan[]
  dentalPlans: AncillaryPlanUnion[]
  visionPlans: AncillaryPlanUnion[]

  group: Group
  nonWaivedMemberCount: number
  planIDsToRemove: Set<string>

  plansMeta?: {
    individualPlansCount: number
    singleParentPlansCount: number
    couplePlansCount: number
    familyPlansCount: number
  }

  splits: ContributionSplit[]
  members: Member[]

  handleRemove: (id: string) => void
  handleContribution: (type: PlanType, value: string) => void
}

const ReviewPlansList: React.FC<Props> = ({ medicalContribution, dentalContribution, visionContribution, medicalPlans, dentalPlans, visionPlans, group, splits, members, handleRemove, planIDsToRemove, handleContribution }) => {
  return <div className={styles.plansContainer}>
    {renderMedicalPlans()}
    {renderDentalPlans()}
    {renderVisionPlans()}
  </div>

  function getContribution(planType: PlanType) {
    switch (planType) {
    case 'medical': return medicalContribution
    case 'dental': return dentalContribution
    case 'vision': return visionContribution
    }
  }

  function renderContributionFor(planType: PlanType) {
    const isEquitable = planType === 'medical' ? group.medical_contribution_equitable : false
    const contribution = getContribution(planType)
    const splitType = contributionType(isEquitable, contribution)
    return <div className={styles.contributionContainer}>
      <h2>{planType}</h2>
      <ContributionAmount
        value={contribution}
        splitType={splitType}
        onChange={value => handleContribution(planType, value)}
      />
    </div>
  }

  function renderMedicalPlans() {
    const isEquitable = group.medical_contribution_equitable
    if (medicalPlans.length < 1) { return }

    return <>
      {renderContributionFor('medical')}
      {medicalPlans.map(plan =>
        <MedicalPlanComponent
          plan={plan}
          rates={plan.premium.employee}
          contribution={getContribution('medical')}
          isEquitable={isEquitable}
          splits={splits}
          members={members}
          selectedForRemoval={planIDsToRemove.has(plan.id)}
          deleteHandler={handleRemove}
          key={plan.id}
        />
      )}
    </>
  }

  function renderDentalPlans() {
    if (dentalPlans.length < 1) { return }
    return <>
      {renderContributionFor('dental')}
      {dentalPlans.map(plan =>
        <DentalPlanComponent
          plan={plan.plan as DentalPlan}
          rates={ratesFor(plan)!}
          contribution={{ value: getContribution('dental'), isEquitable: false }}
          members={members}
          key={plan.plan.id}
        />
      )}
    </>
  }

  function renderVisionPlans() {
    if (visionPlans.length < 1) { return }
    return <>
      {renderContributionFor('vision')}
      {visionPlans.map(plan =>
        <VisionPlanComponent
          plan={plan.plan as VisionPlan}
          rates={ratesFor(plan)!}
          contribution={{ value: getContribution('vision'), isEquitable: false }}
          members={members}
          key={plan.plan.id}
        />
      )}
    </>
  }
}

export default ReviewPlansList
