import React, { useState } from 'react'
import { put, get, post } from 'Utilities/fetch++'
import { useAsyncRetry, useToggle } from 'react-use'
import Loader from 'Components/Rudimentary/Loader'
import Error from 'Components/Primitives/Error'
import ProfileSection from 'Components/Anubis/ProfileSection'
import { Table, SortDirection } from 'Components/Rudimentary/Table'
import DependentModal from 'Components/Anubis/DependentModal'
import { startCase } from 'lodash'
import { Member, Dependent, Privilege, EnrollmentStatus, Venue, Tier } from 'Utilities/pharaoh.types'
import CandorForm, { CandorFormName, CandorFormAddress, CandorFormDatePicker, CandorFormCheckbox, CandorFormSelect } from 'Components/Anubis/CandorForm'
import { useForm, Controller } from 'react-hook-form'
import * as api from 'Utilities/pharaoh'
import { Route } from 'Utilities/Route'
import useToast from 'Utilities/Hooks/useToast'
import { localMidnightToPharaohFormat } from 'Utilities/pharaoh'
import ContactSnapshot, { Content } from 'Components/Anubis/ContactSnapshot'
import TodoList from 'Components/Anubis/TodoList'
import styles from './index.module.scss'
import { healthcareDetailsTitle } from '../../clients/ID/GroupsProfileMedicalPlansSection'
import EEMedicalPlan from 'Components/Plans/EEMedicalPlan'
import DentalPlan from 'Components/Plans/DentalPlan'
import VisionPlan from 'Components/Plans/VisionPlan'
import LifePlan from 'Components/Plans/LifePlan'
import useUser, { PowerLevel } from 'Utilities/Hooks/useUser'
import BackTo from 'Components/Anubis/BackTo'
import moment from 'moment'
import AddButton from 'Components/Anubis/AddButton'
import AddQualifyingEventModal from 'Components/Anubis/AddQualifyingEventModal'
import EERightNowPlan from 'Components/Plans/EERightNowPlan'
import { PremiumSplits, massagedPlanName } from 'Components/Plans/plan-subcomponents/PlanHelpers'
import { individualsMode, mode, Mode } from 'Utilities/config'
import { ssnNormalizer } from 'Utilities/etc'

interface Props {
  id: string
}

const DashboardAgencyEmployee: React.SFC<Props> = ({ id }) => {
  const [open, toggle] = useToggle(false)
  const [isQleModalOpen, toggleQleModalOpen] = useToggle(false)
  const async = useAsyncRetry<[Member, any | null, any[]]>(async() => {
    const [user, plans] = await Promise.all([
      api.v3.users(id).GET(),
      get(`/v2/users/${id}/plans`).catch(() => null)
    ])
    const splits = await api.v1.groups(user.group.id).splits()
    return [user, plans, splits]
  })
  const addToast = useToast()
  const user = useUser()

  if (async.loading) return <Loader/>
  if (async.error) return <Error error={async.error}/>

  // TODO the AddDependentModal returns the correctly typed new object, we should add to our list
  //   though to do that we probs want our own Async“List” custom hook

  const ee = async.value![0]
  const deps = ee.dependents || []
  const plans = async.value![1]
  const splits = async.value![2]

  const contactSnapshotContent: Content[] = [{
    before: <h2>{deps.length || '0'}</h2>,
    after: <span>TOTAL # Dependents</span>
  }]

  contactSnapshotContent.push({ after: <span>Status: {startCase(ee.status.replace('MedicalUnderwriting', 'Application'))}</span> })

  if (user.value && user.value.powerLevel >= PowerLevel.groupManager) {
    if (ee.signUpLink) {
      contactSnapshotContent.push({ after: <span><a href={ee.signUpLink}>User Sign Up Link</a></span> })
    }
    contactSnapshotContent.push({
      after: (() => {
        switch (ee.privilege) {
        case Privilege.standard:
          if (!ee.signUpLink) {
            return <span>
              <button onClick={onInviteStandard}>Invite to Group</button>
              <button onClick={onInvite}>Invite to Administrate&nbsp;Group</button>
            </span>
          } else {
            return <span><button onClick={onInvite}>Invite to Administrate&nbsp;Group</button></span>
          }
        case Privilege.manager:
          return <span>Group Administrator</span>
        case Privilege.invitedToManage:
          return <span>Group Administrator invitation&nbsp;sent</span>
        }
      })()
    })
  }

  const effectiveDate = ee.dates.effective

  if (effectiveDate) {
    contactSnapshotContent.push({
      after: <span>Effective Date<br/> {moment(effectiveDate).format('L') }</span>
    })
  }

  const button = user.value && user.value!.powerLevel >= PowerLevel.broker
    ? <BackTo route={`${Route.agencyDashboardClients}/${ee.group.id}`}>Back to <em>{ee.group.name}</em></BackTo>
    : undefined

  return <>
    <header className={styles.header}>
      {button}
      <AddQualifyingEventModal isOpen={isQleModalOpen} onRequestClose={toggleQleModalOpen} name={ee.contact.name || ''} id={id} callback={toggleQleModalOpen} />
      <AddButton className={styles.qualifyingLifeEvent} onClick={toggleQleModalOpen}>Add Qualifying Event</AddButton>
    </header>
    <div className={styles.profileContainer}>
      <ContactSnapshot name={ee.contact.name || ''} content={contactSnapshotContent}/>
      <Form user={ee} />
      <TodoList id={id} />
    </div>
    <div className={styles.profileSectionContainer}>
      <ProfileSection name='Benefits Breakdown' expanded={true}>
        <BenefitsBreakdown plans={plans} user={ee} />
      </ProfileSection>
      <ProfileSection name={healthcareDetailsTitle} expanded={false}>
        <MedicalPlan user={ee} plan={plans?.healthPlan} splits={splits} premiumSplits={plans?.premiums.medical?.split} />
      </ProfileSection>
      <ProfileSection name='Ancillary Plans' expanded={false}>
        <AncillaryPlans user={ee} plans={plans} />
      </ProfileSection>
      <ProfileSection name='Dependents' expanded={false} addButtonName="Add Dependent" onAddButton={toggle}>
        <Dependents id={id} deps={deps} reload={async.retry} open={open} close={() => toggle(false)} username={user.value?.name || 'loading'} />
      </ProfileSection>
    </div>
  </>

  async function onInvite() {
    try {
      const rsp = await post(`/v3/groups/${ee.group.id}/users/${ee.id}/promote`)
      addToast((() => {
        switch (rsp.outcome) {
        case 'invited':
          return 'User invited'
        case 'alreadyInvited':
          return 'User will be promoted when they sign‑up'
        case 'promoted':
          return 'User promoted'
        default:
          return 'Unexpected success'
        }
      })())
      async.retry()
    } catch (error) {
      addToast(error)
    }
  }

  async function onInviteStandard() {
    try {
      await post('/v3/tickets', { ...ee.contact, venue: Venue.employee, venueID: ee.group.id })
      const email = mode()[0] === Mode.demo ? user.value!.email : ee.contact.email
      addToast(`Email invitation sent to ${email}`)
      async.retry()
    } catch (error) {
      addToast(error)
    }
  }
}

interface FormProps {
  user: Member
}

const genderOptions = [{ value: 'male', label: 'Male' }, { value: 'female', label: 'Female' }]

const Form: React.FC<FormProps> = ({ user }) => {
  const form = useForm({ defaultValues: user })

  return <CandorForm save={save} {...form}>
    <CandorFormName name='contact.name' />
    <label>Email
      <input name='contact.email' ref={form.register} />
    </label>
    <CandorFormAddress showCountyPicker />
    <CandorFormDatePicker placeholder='Birth Date' name="dob" >
      Birth Date
    </CandorFormDatePicker>
    <CandorFormSelect name='gender' options={genderOptions}>
      Sex
    </CandorFormSelect>
    <label>SSN
      <Controller
        render={props => <input
          {...props}
          onChange={event => props.onChange(ssnNormalizer(event.currentTarget.value))}
          minLength={11} maxLength={11}
          pattern='[0-9-]{11}'
          className='glance_masked'
          placeholder='Social Security Number'
        />}
        name="ssn"
      />
    </label>
    {!individualsMode() && <>
      <CandorFormCheckbox name='isWaived' checked={user.isWaived}>Waived</CandorFormCheckbox>
      <CandorFormCheckbox name='isMedicallyUnderwritten' checked={user.isMedicallyUnderwritten}>Application<br/>Complete</CandorFormCheckbox>
      <CandorFormDatePicker placeholder='Hire Date' name='dates.hire'>
        Hire Date
      </CandorFormDatePicker>
      <label>Salary
        <input name='salary' placeholder='Salary' ref={form.register} />
      </label>
    </>}
  </CandorForm>

  async function save(data: any) {
    if (data.address.state === '') delete data.address.state
    data.id = user.id
    data.dob = localMidnightToPharaohFormat(data.dob)
    data.salary = data.salary || undefined

    if (data.dates?.hire) {
      data.dates.hire = localMidnightToPharaohFormat(data.dates.hire)
    }

    if (typeof data.isWaived !== 'boolean') {
      data.isWaived = user.isWaived
    }

    if (typeof data.isMedicallyUnderwritten !== 'boolean') {
      data.isMedicallyUnderwritten = user.isMedicallyUnderwritten
    }

    // ignored, but required (FIXME)
    data.status = user.status
    data.tier = user.tier
    data.group = user.group
    data.privilege = user.privilege

    await put('/v3/users', data)
  }
}

interface MPProps {
  user: Member
  splits: any[]
  plan?: any
  premiumSplits?: PremiumSplits
}

const MedicalPlan: React.FC<MPProps> = ({ user, plan, premiumSplits }) => {
  if (!plan || !premiumSplits) return <p>User has not completed their profile.</p>
  return individualsMode()
    ? <EERightNowPlan
      plan={plan}
      tier={user.tier}
      groupMemberID={user.id}
    />
    : <EEMedicalPlan
      plan={plan}
      tier={user.tier}
      premiumSplits={premiumSplits}
      key={plan.id}
    />
}

interface APProps {
  plans: any | null
  user: Member
}

const AncillaryPlans: React.FC<APProps> = ({ plans, user }) => {
  const rv: any[] = []
  if (plans?.dentalPlan) {
    rv.push(<DentalPlan
      plan={plans.dentalPlan.plan}
      rates={plans.dentalPlan.rates}
      contribution={{
        value: plans.premiums.dental.contribution,
        isEquitable: !!plans.premiums.dental.contributionIsEquitable
      }}
      employeeTier={user.tier}
      premium={plans.premiums.dental.split}
      key={plans.dentalPlan.plan.id}
    />)
  }
  if (plans?.visionPlan) {
    rv.push(<VisionPlan
      plan={plans.visionPlan.plan}
      rates={plans.visionPlan.rates}
      contribution={{
        value: plans.premiums.vision.contribution,
        isEquitable: !!plans.premiums.vision.contributionIsEquitable
      }}
      employeeTier={user.tier}
      premium={plans.premiums.vision.split}
      key={plans.visionPlan.plan.id}
    />)
  }
  if (plans?.lifePlan) {
    rv.push(<LifePlan
      plan={plans.lifePlan.plan}
      rates={plans.lifePlan.rate}
      contribution={{
        value: plans.premiums.life.contribution,
        isEquitable: !!plans.premiums.life.contributionIsEquitable
      }}
      premium={plans.premiums.life.split}
      key={plans.lifePlan.plan.id}
    />)
  }
  if (rv.length === 0) {
    return <p>User has not chosen any ancillary plans.</p>
  } else {
    return <>{rv}</>
  }
}

interface BenefitsBreakdown {
  id: string
  plan: string
  tier: Tier
  premium: string
  dependents: number
  waiting: number
  effective: Date
  renewal: Date
  status: EnrollmentStatus
}

interface BBProps {
  plans: any
  user: Member
}

const BenefitsBreakdown: React.FC<BBProps> = ({ user, plans: rawPlans }) => {
  const planTypes = [
    {
      plan: rawPlans?.healthPlan,
      premium: rawPlans?.premiums?.medical?.split.ee
    },
    {
      plan: rawPlans?.lifePlan,
      premium: rawPlans?.premiums?.life?.split.ee
    },
    {
      plan: rawPlans?.dentalPlan,
      premium: rawPlans?.premiums?.dental?.split.ee
    },
    {
      plan: rawPlans?.visionPlan,
      premium: rawPlans?.premiums?.vision?.split.ee
    },
    {
      plan: rawPlans?.disabilityPlan,
      premium: rawPlans?.premiums?.disability?.split.ee
    }
  ].filter(a => !!a.plan)

  const data: BenefitsBreakdown[] = planTypes.map(type => ({
    id: user.id,
    plan: type.plan.name ? massagedPlanName(type.plan.name, type.plan.carrier) : type.plan.plan?.name,
    tier: user.tier,
    premium: type.premium,
    dependents: user.dependents?.length ?? 0,
    waiting: 0,
    effective: new Date(),
    renewal: new Date(),
    status: user.status
  }))

  return <Table
    data={data}
    order={['plan', 'tier', 'premium', 'dependents', 'status']}
    content={(key, value) => {
      switch (key) {
      case 'tier':
      case 'status':
        return startCase(value.replace('MedicalUnderwriting', 'Application'))
      }
    }}
    sortable={['plan', 'tier', 'premium', 'waiting', 'effective', 'renewal', 'status']}
    width={key => {
      switch (key) {
      case 'tier':
        return '10%'
      case 'premium':
        return '8%'
      case 'dependents':
        return '10%'
      case 'waiting':
      case 'effective':
      case 'renewal':
      case 'status':
        return '10%'
      }
    }}
    reportFileBasename={`${user.contact.name}-benefits-breakdown`}
  />
}

interface DependentsProps {
  deps: Dependent[]
  id: string
  reload: () => void
  close: () => void
  open: boolean
  username: string
}

const Dependents: React.FC<DependentsProps> = ({ deps, id, reload, open, close, username }) => {
  const [selected, setSelected] = useState<Dependent | undefined>()
  return <>
    <Table
      data={deps}
      content={(key, value) => {
        switch (key) {
        case 'gender':
        case 'relationship':
          return startCase(value)
        }
      }}
      width={key => {
        switch (key) {
        case 'dob':
        case 'relationship':
          return '18%'
        case 'gender':
        case 'ssn':
          return '13%'
        }
      }}
      order={['name', 'dob', 'relationship', 'gender', 'ssn']}
      heading={key => {
        switch (key) {
        case 'dob':
          return 'Birth Date'
        }
      }}
      sortable={['name', 'dob', 'gender', 'relationship']}
      selectAction={setSelected}
      selectable={() => true}
      defaultSort='relationship'
      defaultSortDirection={key => key === 'relationship' ? SortDirection.descending : SortDirection.ascending}
      secondarySort={() => ['dob', 'name']}
      reportFileBasename={`${username}-dependents`}
    />
    <DependentModal
      key={selected?.id || id /* key must vary or defaultValues don’t work */}
      id={id}
      dependent={selected}
      isOpen={open || selected !== undefined}
      onRequestClose={onCancel}
      onSuccess={onSuccess}
      onDelete={onSuccess} />
  </>

  function onSuccess() {
    setSelected(undefined)
    close()
    reload()
  }

  function onCancel() {
    setSelected(undefined)
    close()
  }
}

export default DashboardAgencyEmployee
