import React from 'react'
import { useToggle } from 'react-use'
import StargateModal, { CloseButtonType } from 'Components/Stargate/StargateModal'
import styles from './PlanTitle.module.scss'
import { RemoveCheckbox, SelectCheckbox } from './Checkboxes'
import { IndividualModeProsperName } from '../EERightNowPlan'
import { logoFor, FundingType, massagedPlanName } from './PlanHelpers'
import { individualsMode } from 'Utilities/config'

interface Props {
  className?: string
  planName: string
  carrier: string
  includesProsperBenefits: boolean
  fundingType?: FundingType
  selectHandler?: () => void // Select checkbox won't show up unless you pass the handler
  selected?: boolean
  removeHandler?: () => void // Remove checkbox won't show up unless you pass the handler
  selectedForRemoval?: boolean
  sbc?: string // View details won't show unless your pass this handler
}

const PlanTitle: React.FC<Props> = ({ planName, carrier, includesProsperBenefits, fundingType, selectHandler, selected, selectedForRemoval, removeHandler, sbc }) => {
  const prosperName = individualsMode() ? IndividualModeProsperName : 'Prosper Benefits'

  return <>
    { fundingType && <FundingTypeTab fundingType={fundingType}/> }
    <div className={styles.planTitleContainer}>
      { selectHandler &&
      <SelectCheckbox
        name='planSelect'
        value={selected}
        onChange={selectHandler}
      />
      }
      { logoFor(carrier, planName) || <div>{ carrier }</div> }
      <div className={styles.title}>
        { planName && <div>{ massagedPlanName(planName, carrier) }</div> }
        { includesProsperBenefits && <div className={styles.prosper}>Includes <b>{prosperName}</b></div> }
      </div>
      { removeHandler && <RemoveCheckbox name='planSelect' value={selectedForRemoval} onChange={removeHandler} /> }
      { sbc && <PlanDetailsButton sbc={sbc} /> }
      { selectedForRemoval && <div className={styles.remove}/>}
    </div>
  </>
}

interface SBCProps {
  sbc: string
}

const PlanDetailsButton: React.FC<SBCProps> = ({ sbc }) => {
  const [showSBC, toggleShowSBC] = useToggle(false)

  return <>
    <PlanDetailsModal sbc={sbc} showSBC={showSBC} toggleShowSBC={toggleShowSBC}/>
    <button className={styles.viewDetails} onClick={handleClick}>View Details</button>
  </>

  function handleClick() {
    const glance = (window as any).GLANCE
    if (glance?.Cobrowse?.Visitor.inSession()) {
      glance.Cobrowse.Visitor.viewPDF({ url: sbc })
    } else {
      toggleShowSBC()
    }
  }
}

interface SBCPModalProps extends SBCProps {
  showSBC: boolean
  toggleShowSBC(): void
}

export const PlanDetailsModal: React.FC<SBCPModalProps> = ({ sbc, showSBC, toggleShowSBC }) => {
  return <StargateModal
    isOpen={showSBC}
    styles={{ width: '80vw', height: '80vh', overflow: 'visible', padding: 30 }}
    forceCloseButtonType={CloseButtonType.outer}
    onRequestClose={toggleShowSBC}
  >
    <object title="sbc" data={sbc} type="application/pdf" width="100%" height="100%">
      <a href={sbc}>SBC document</a>
    </object>
  </StargateModal>
}

const FundingTypeTab: React.FC<{ fundingType: FundingType }> = ({ fundingType }) => {
  const [showExplanation, toggleShowExplanation] = useToggle(false)
  const planTypeExplanation = explanationFor(fundingType)

  return <div style={{ backgroundColor: colorFor(fundingType) }} className={styles.fundingTypeContainer}>
    { planTypeExplanation }
    { fundingType }
    { planTypeExplanation &&
      <button
        className={styles.whatAreThose}
        onClick={toggleShowExplanation}
      >
        What are these?
      </button>
    }
  </div>

  function explanationFor(fundingType: FundingType) {
    switch (fundingType) {
    case FundingType.mec:
      return <MECExplanationModal isOpen={showExplanation} onRequestClose={toggleShowExplanation}/>
    case FundingType.levelFunded:
      return <LevelFundedExplanationModal isOpen={showExplanation} onRequestClose={toggleShowExplanation}/>
    case FundingType.fullyFunded:
    }
  }
}

function colorFor(fundingType: FundingType) {
  switch (fundingType) {
  case FundingType.fullyFunded:
    return '#D9E8FF'
  case FundingType.levelFunded:
    return '#DDF7FF'
  case FundingType.mec:
    return '#DBE1FF'
  }
}

interface ExplainationModalProps {
  isOpen: boolean
  onRequestClose(): void
}

export const LevelFundedExplanationModal: React.FC<ExplainationModalProps> = ({ isOpen, onRequestClose }) =>
  <StargateModal
    isOpen={isOpen}
    styles={{ width: 900, height: 325, padding: 30, backgroundColor: colorFor(FundingType.levelFunded) }}
    closeButtonStyles={{ background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24.03 24.03'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23${'16346f'};%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eanubis-close%3C/title%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cpolygon class='cls-1' points='24.03 19.56 16.49 12.02 24.03 4.47 19.56 0 12.02 7.54 4.47 0 0 4.47 7.55 12.02 0 19.56 4.47 24.03 12.02 16.49 19.56 24.03 24.03 19.56'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat` }}
    onRequestClose={onRequestClose}
  >
    <div className={styles.explanationContainer}>
      <h1>{ FundingType.levelFunded } Plans</h1>
      <p>Our software was created to match each employee to their personalized plan. Providing each person with the optimal level of coverage, resulting in major savings for all. This gives employees real choices for their very real and unique situations.</p>
      <h2>Advantages</h2>
      <ul>
        <li>Savings in Healthcare costs</li>
        <li>Protection against extraordinary costs</li>
        <li>Employees are offered plans that best fit their needs</li>
      </ul>
    </div>

  </StargateModal>

const MECExplanationModal: React.FC<ExplainationModalProps> = ({ isOpen, onRequestClose }) =>
  <StargateModal
    isOpen={isOpen}
    styles={{ width: 900, height: 765, overflow: 'scroll', padding: 30, backgroundColor: colorFor(FundingType.mec) }}
    closeButtonStyles={{ background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24.03 24.03'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23${'16346f'};%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eanubis-close%3C/title%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cpolygon class='cls-1' points='24.03 19.56 16.49 12.02 24.03 4.47 19.56 0 12.02 7.54 4.47 0 0 4.47 7.55 12.02 0 19.56 4.47 24.03 12.02 16.49 19.56 24.03 24.03 19.56'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat` }}
    onRequestClose={onRequestClose}
  >
    <div className={styles.explanationContainer}>
      <h1>{ FundingType.mec } Plans</h1>
      <p>{ FundingType.mec } Plans provide simple to understand and affordable options. { FundingType.mec } Plans exceed the requirements of the Affordable Care Act satisfying Employer Penalty A and Individual Mandate Penalty. These plans are not insurance products. These plans do not meet the standards for the MA individual coverage mandate.</p>
      <h2>What it is</h2>
      <p>Everyday Healthcare Needs. Laboratory Services. Imaging or X-ray Services. Prescription Drugs. By frequency, Premier Plan accounts for 90+% of healthcare service and addresses those everyday healthcare needs.</p>
      <h2>What these plans cover</h2>
      <p>Preventive Care, Primary Care, Office Visits, Specialists Office Visits, Urgent Care Visits, Laboratory Services, Imaging or X-ray Services, Prescription Drugs, and Telemedicine</p>
      <h2>What these plans don’t cover</h2>
      <p>Big ticket items such as hospital stays, chronic diseases, specialty drugs. The Premier Plan includes a hospital component for Inpatient, Outpatient, Emergency Room, and Ambulance Services 50/50 Benefit to $5,000 / Max payout $2,500.</p>
      <h2>Q: Is there a network of doctors or physicians?</h2>
      <p>Yes, the plan uses Preferred Healthcare Services (PHCS), one of the largest physician networks with over 900,000 physicians nationwide. There is no coverage for out‐of‐network services </p>
      <h2>Q: Will I receive an ID Card?</h2>
      <p>Yes, An ID card and welcome kit will be sent to your home address. This usually takes several days after your requested effective date. For questions on benefits, claims, ID cards etc.: <a style={{ color: '#16346f' }} href='tel:+1-888-272-1513'>1-888-272-1513</a></p>
      <h2>Q: Does the plan cover Preventive Care Services?</h2>
      <p>Yes, the plan provides 100% benefits, no copays, no deductibles (in-network) Preventive Care services include: Screenings for blood pressure, cancer, cholesterol, depression, obesity, and Type 2 diabetes. Pediatric screenings for hearing, vision, autism, and developmental disorders, depression, and obesity.</p>
    </div>
  </StargateModal>

export default PlanTitle
