import React from 'react'
import styles from './CartError.module.css'

interface Props {
  message: string
}

const CartError: React.FC<Props> = ({ message }) =>
  <div className={styles.container}>
    <div className={styles.left}>
      <i className={`${styles.errorIcon} material-icons`}>error_outline</i>
    </div>
    <div className={styles.right}>
      <p className={styles.errorMessage}>{message}</p>
    </div>
  </div>

export default CartError
