import React from 'react'
import styles from './CompareModal.module.scss'
import { MedicalPlan } from 'Utilities/pharaoh.types'
import StargateModal from 'Components/Stargate/StargateModal'
import { Controller, FormProvider } from 'react-hook-form'
import { logoFor, massagedPlanName, Contribution } from 'Components/Plans/plan-subcomponents/PlanHelpers'
import { MedicalPlanGroupCalculator, moneyNumber, moneyString } from 'Utilities/Plans/PremiumCalculators'
import { Member, ContributionSplit } from 'Utilities/Hooks/useStargate'
import { startCase } from 'lodash'
import { TrueCostOfCareCalculator } from 'Utilities/Plans/TrueCostOfCareCalculator'
import numeral from 'numeral'
import usePersistableForm from 'Utilities/Hooks/usePersistableForm'

interface Props {
  plans: MedicalPlan[]
  contribution: Contribution
  splits: ContributionSplit[]
  members: Member[]
  removePlanHandler(event: any): void
  isOpen: boolean
  onRequestClose(): void
  groupID: string
}

type CompareData = {
  avgMonthlyPremium?: string
  estCost?: string
  deductible?: string
  oopMax?: string
  primaryCarePhysician?: string
  urgentCare?: string
  generic?: string
  medicalBillAdvocacy?: boolean
  healthCareAdvisor?: boolean
  telemedicine?: boolean
  telemental?: boolean
  trueCostOfCare?: { competitior: number, candor?: number }
  savingsWithUs?: number
}

const CompareModal: React.SFC<Props> = ({ plans, contribution, splits, members, removePlanHandler, isOpen, onRequestClose, groupID }) => {
  const form = usePersistableForm(`compare-current-plan-${groupID}`)
  const currentPlan = form.watch()
  const data = planDataToDisplay()
  const keys = data.length ? (Object.keys(data[0]) as Array<keyof CompareData>) : []

  // Auto close when no plans
  if (!plans.length && isOpen) { onRequestClose() }

  return <StargateModal
    isOpen={isOpen}
    styles={modalStyle}
    onRequestClose={onRequestClose}
  >
    <form className={styles.container}>
      <FormProvider {...form}>
        <table className={styles.compareTable}>
          <thead>
            <tr>
              <th>Compare<br/>Plans</th>
              <th className={styles.currentPlan}>Your Current Plan<span>Click into a cell to update</span></th>
              { plans.map(plan =>
                <th key={plan.id}>
                  <div className={styles.selected}>
                    { logoFor(plan.carrier, plan.name) }
                    { massagedPlanName(plan.name, plan.carrier) }
                    <button type='button' className='material-icons' onClick={() => removePlanHandler(plan)}>close</button>
                  </div>
                </th>)
              }
            </tr>
          </thead>
          <tbody>
            { keys.map(key => {
              const rowCopy = rowCopyForKey(key)
              return <tr key={key}>
                <td>
                  { rowCopy.header }
                  { rowCopy.sub && <span>{rowCopy.sub}</span> }
                </td>
                <DataRow
                  data={[massagedCurrentPlanData()[key], ...data.map(data => data[key])]}
                  dataKey={key}
                  setValue={form.setValue}
                />
              </tr>
            })}
          </tbody>
        </table>
      </FormProvider>
    </form>
  </StargateModal>

  function massagedCurrentPlanData() {
    if (currentPlan.estCost && currentPlan.estCost !== '—') {
      currentPlan.avgMonthlyPremium = moneyString(numeral(currentPlan.estCost).value() / members.filter(member => !member.is_waived).length)
      if (currentPlan.oopMax && currentPlan.oopMax !== '—') {
        const tcoc = new TrueCostOfCareCalculator(numeral(currentPlan.avgMonthlyPremium).value(), numeral(currentPlan.oopMax).value())
        const hasProsper = currentPlan.billSaver && currentPlan.healthAdvocacy && currentPlan.remoteMentalHealth && currentPlan.telemedicine
        currentPlan.trueCostOfCare = { competitior: hasProsper ? tcoc.candorCostOfCare() : tcoc.competitorCostOfCare() }
      }
    }
    return currentPlan
  }

  function planDataToDisplay() {
    const compareData: CompareData[] = []
    plans.forEach(plan => {
      const calc = new MedicalPlanGroupCalculator(plan, contribution, splits, members)
      const avgMonthlyPremium = moneyString(calc.er() / members.filter(member => !member.is_waived).length, 2)
      const tcoc = new TrueCostOfCareCalculator(moneyNumber(avgMonthlyPremium, 2), moneyNumber(plan.oopMax, 2))

      compareData.push({
        estCost: moneyString(calc.er(), 2),
        avgMonthlyPremium,
        deductible: moneyString(plan.deductible, 2),
        oopMax: moneyString(plan.oopMax, 2),
        primaryCarePhysician: plan.copay.primaryCarePhysician,
        urgentCare: plan.copay.urgentCare,
        generic: plan.prescription.generic,
        medicalBillAdvocacy: true,
        healthCareAdvisor: true,
        telemedicine: true,
        telemental: true,
        trueCostOfCare: {
          competitior: tcoc.competitorCostOfCare(),
          candor: tcoc.candorCostOfCare()
        },
        savingsWithUs: tcoc.competitorCostOfCare() - tcoc.candorCostOfCare()
      })
    })
    return compareData
  }

  function rowCopyForKey(key: keyof CompareData): { header: string, sub?: string } {
    switch (key) {
    case 'estCost': {
      const employees = members.filter(member => !member.is_waived).length
      return { header: 'Your Est. Cost', sub: `(For ${employees} ${employees === 1 ? 'employee' : 'employees'})` }
    }
    case 'avgMonthlyPremium': return { header: 'Avg. Monthly Premium' }
    case 'deductible': return { header: 'Yearly Deductible', sub: '(In-Network)' }
    case 'oopMax': return { header: 'Out of Pocket Max', sub: '(In-Network)' }
    case 'primaryCarePhysician': return { header: 'Primary Care Copay' }
    case 'urgentCare': return { header: 'Urgent Care Copay' }
    case 'generic': return { header: 'Prescription Copay' }
    case 'healthCareAdvisor': return { header: 'HealthCare Advisor', sub: 'Included with Prosper Benefits' }
    case 'medicalBillAdvocacy':
    case 'telemedicine':
    case 'telemental':
      return { header: startCase(key), sub: 'Included with Prosper Benefits' }
    case 'trueCostOfCare':
      return { header: startCase(key), sub: 'This metric that captures an employee’s healthcare spending over a year. This total includes Premiums and Out-of-pocket costs.' }
    default: return { header: startCase(key) }
    }
  }
}

interface DataRowProps {
  data: any[]
  dataKey: keyof CompareData
  setValue(key: keyof CompareData, value: any): void
}

const DataRow: React.FC<DataRowProps> = ({ data, dataKey, setValue }) => {
  const values: any[] = massagedValues(data)
  const best = bestValue()

  return <>
    {values.map((value, index) => {
      const classes = []
      const isBest = typeof data[index] === 'boolean' ? data[index] === best : numeral(data[index]).value() === best
      const isCovered = !['not covered', 'n/a', 'not included', 'no', 'nope'].some(foo => foo === value.toString().toLowerCase())
      const isLongSentence = value.toString().length > 20

      if (isBest) { classes.push(styles.best) }
      if (!isCovered) { classes.push(styles.notIncluded) }
      if (isLongSentence) { classes.push(styles.small) }

      return <td className={classes.join(' ')} key={index}>
        {index === 0
          ? <InputForDataRow dataKey={dataKey} value={data[index]} massagedValue={value} setValue={setValue}/>
          : value
        }
      </td>
    })}
  </>

  function massagedValues(array: any[]) {
    return array.map((value, index) => {
      if (value === undefined || value === null) { return '—' }
      switch (dataKey) {
      case 'estCost':
      case 'avgMonthlyPremium':
        return numeral(value).value() ? `${moneyString((numeral(value).value()))}/mo` : '—'
      case 'deductible':
      case 'oopMax':
      case 'savingsWithUs':
        return `${moneyString((numeral(value).value()))}/yr`
      case 'medicalBillAdvocacy':
      case 'healthCareAdvisor':
      case 'telemedicine':
      case 'telemental':
        return value ? 'Included' : <span className={styles.notIncluded}>Not Included</span>
      case 'primaryCarePhysician':
      case 'urgentCare':
      case 'generic': {
        const isDollarAmount = value.toString().substring(0, 1) === '$' && value.length > 1 && !isNaN(Number(value.slice(1)))
        return isDollarAmount ? moneyString(value) : value
      }
      case 'trueCostOfCare':
        return <div className={styles.tcoc}>
          <div>
            { index !== 0 && <span>Competitor</span> /* Doing this since we don't know if the current plan is from Candor */}
            { value.competitior ? `${moneyString(value.competitior)}/yr` : '—'}
          </div>
          { value.candor && <div><span>With us</span>{moneyString(value.candor)}/yr</div> }
        </div>
      default:
        return value
      }
    })
  }

  function bestValue() {
    switch (dataKey) {
    case 'estCost':
    case 'avgMonthlyPremium':
    case 'deductible':
    case 'oopMax': {
      const filtered = data.filter(value => value !== undefined && value !== '' && value !== '—')
      return Math.min(...filtered.map(value => numeral(value).value()))
    }
    case 'medicalBillAdvocacy':
    case 'healthCareAdvisor':
    case 'telemedicine':
    case 'telemental':
      return true
    }
  }
}

interface InputForDataRowProps {
  dataKey: keyof CompareData
  value: any
  massagedValue: string
  setValue(key: keyof CompareData, value: any): void
}

const InputForDataRow: React.FC<InputForDataRowProps> = ({ dataKey, value, massagedValue, setValue }) => {
  switch (dataKey) {
  case 'estCost':
  case 'deductible':
  case 'oopMax':
    return <Controller
      name={dataKey}
      render={props => <input
        onFocus={strip}
        {...props}
        onBlur={event => normalize(event.target.value)}
        maxLength={10}
        type='text'
        pattern='[0-9]+'
      />}
      defaultValue={massagedValue}
    />
  case 'primaryCarePhysician':
  case 'urgentCare':
  case 'generic':
    return <Controller
      name={dataKey}
      render={props => <input
        onFocus={strip}
        {...props}
        onBlur={event => normalize(event.target.value)}
        maxLength={30}
      />}
      defaultValue={massagedValue}
    />
  case 'medicalBillAdvocacy':
  case 'healthCareAdvisor':
  case 'telemedicine':
  case 'telemental':
    return <Controller
      name={dataKey}
      as={<button className={styles.prosperBenefitToggle} type='button' onClick={() => setValue(dataKey, !value)}>
        {massagedValue}
      </button>}
    />
  default: return <>{massagedValue}</>
  }

  // Need to setValue since we are normalizing the value on blur, but the value in the form is still a number which gets reset for some reason when changing another value
  function normalize(value: any) {
    if (!value) {
      setValue(dataKey, '—')
      return
    }
    // Incase user enters in random characters, use the previous value
    if (isNaN(value)) {
      setValue(dataKey, massagedValue)
      return
    }
    switch (dataKey) {
    case 'estCost':
    case 'avgMonthlyPremium':
      value = `${moneyString(value)}/mo`
      break
    case 'deductible':
    case 'oopMax':
      value = `${moneyString(value)}/yr`
      break
    default:
      break
    }
    setValue(dataKey, value)
  }

  function strip(event: any) {
    const value = event.target.value
    if (!value || value === '—') {
      event.target.value = ''
      return
    }
    switch (dataKey) {
    case 'estCost':
    case 'avgMonthlyPremium':
    case 'deductible':
    case 'oopMax':
      event.target.value = numeral(value).value()
      return
    default:
      return value
    }
  }
}

const modalStyle = {
  display: 'table',
  minHeight: '80vh',
  padding: '50px 70px',
  overflow: 'none'
}

export default CompareModal
