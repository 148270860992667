import React from 'react'
import ReactModal from 'react-modal'
import styles from './index.module.scss'
import { ReactComponent as GreenClose } from './close.svg'
import StargateModal, { CloseButtonType } from 'Components/Stargate/StargateModal'

const InformationalModal: React.SFC<ReactModal.Props> = props =>
  <StargateModal
    {...props}
    styles={modalStyle}
    forceCloseButtonType={CloseButtonType.none}
  >
    <GreenClose className={styles.closeButton} onClick={props.onRequestClose}/>
    <div className={styles.container}>
      { props.children }
    </div>
  </StargateModal>

const modalStyle = {
  border: 'none',
  backgroundImage: 'linear-gradient(30deg, #29b5aa 20%, #29b473 100%)',
  width: '30rem',
  height: 'fit-content',
  padding: '2rem',
  overflow: 'visible'
}

export default InformationalModal
