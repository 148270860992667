import { flatten } from 'lodash'

// NOTE prefer to `firstName`
export function firstNames(input: string | undefined): string | undefined {
  return input?.split(' ').slice(0, -1).join(' ')
}

export function firstName(input: string | undefined): string | undefined {
  return input?.split(' ')[0]
}

export function lastName(input: string | undefined): string | undefined {
  return input?.split(' ').pop()
}

export const classNames = (...objs: any) => flatten(objs).filter(Boolean).join(' ')

export function cleanPhoneNumber(input: string | undefined | null): string {
  if (!input) return ''
  const cleaned = input.replace(/[^+\d]/g, '')
  const match = cleaned.match(/^(\+\d*)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = (match[1] ? `${match[1]} ` : '')
    return `${intlCode}(${match[2]}) ${match[3]}‑${match[4]}`
  }
  return input
}

// Add comma to thousands place
export const thousandsComma = (x: number | string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const ssnNormalizer = (value: string) => {
  if (!value) {
    return value
  } else {
    value = value.replace(/[^\d]/g, '')
  }
  return value.length > 3 ? value.length > 5 ? `${value.substring(0, 3)}-${value.substring(3, 5)}-${value.substring(5)}` : `${value.substring(0, 3)}-${value.substring(3)}` : value
}
