import React from 'react'
import { Control, Controller } from 'react-hook-form'
import styles from './CandorSwitch.module.scss'

interface CandorSwitchProps {
  name: string
  control?: Control<Record<string, any>>
  defaultValue?: string
  placeholder?: string
  className?: string
}

interface SwitchProps {
  value?: string
  onChange(value: string): void
  className?: string
}

const Switch: React.FC<SwitchProps> = ({ onChange, value, className }) => {
  function classFor(button: boolean) {
    return value === button.toString() ? styles.selected : styles.unselected
  }

  function handleChange(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: boolean) {
    event.preventDefault()
    onChange(value.toString())
  }

  return <div className={`${styles.switch} ${className}`}>
    <button onClick={(e) => handleChange(e, true)} className={classFor(true)}>Y</button>
    <button onClick={(e) => handleChange(e, false)} className={classFor(false)}>N</button>
  </div>
}

const CandorSwitch: React.FC<CandorSwitchProps> = ({ ...props }) => {
  return <Controller
    {...props}
    render={props => <Switch {...props} />}
  />
}

export default CandorSwitch
