import React from 'react'
import styles from './RequestReview.module.scss'

type Props = {
  requestClose: () => void
}

const RequestReview: React.FC<Props> = ({ requestClose }) => {
  return <div className={styles.requestReviewContainer}>
    <div className={styles.oval}/>
    <div>
      <p>Need to submit your choices to the business owner or your HR representative to evaluate and approve?</p>
      <button onClick={requestClose}>Request a Review</button>
    </div>
  </div>
}

export default RequestReview
