import React from 'react'
import styles from './ContributionBar.module.css'

interface Props {
  barHeight: number
  fillHeight: number
  fillColor: string
  backgroundColor: string
  premium: number
  label: string
}

const ContributionBar: React.FC<Props> = ({ barHeight, fillHeight, fillColor, backgroundColor, children, premium, label }) => {
  const backgroundHeight = barHeight - fillHeight

  const wtf0 = children && (children as any).props.children.length > 0 && (children as any).props.children[0]
  const wtf1 = children && (children as any).props.children.length > 1 && (children as any).props.children[1]

  return (
    <div className={styles.contributionBar}>
      <Bar color={'transparent'} height={60}>
        <div className={styles.premiumContainer}>
          <span>Average Cost</span>
          <span className={styles.premium}>${premium}</span>
        </div>
      </Bar>
      <Bar color={backgroundColor} height={backgroundHeight}>
        <div className={styles.eeBarLabel}>
          {wtf0}
        </div>
      </Bar>
      <Bar color={fillColor} height={fillHeight}>
        <div className={styles.erBarLabel}>
          {wtf1}
        </div>
      </Bar>
      <Bar color={'#e3eeff'} height={50}>
        <div className={styles.labelContainer}>
          <span className={styles.label}>
            {label}
          </span>
        </div>
      </Bar>
    </div>
  )
}

interface BarProps {
  color: string
  height: number
}

const Bar: React.FC<BarProps> = ({ color: backgroundColor, height, children }) =>
  <div className={styles.bar} style={{ backgroundColor, height }}>
    {children}
  </div>

export default ContributionBar
