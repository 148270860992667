import React from 'react'
import styles from 'Routes/dashboard/pipeline.module.scss'
import moment from 'moment'
import PipelineDetails, { Pipe } from './PipelineDetails'
import Loader from 'Components/Rudimentary/Loader'
import * as api from 'Utilities/pharaoh'
import { useAsync } from 'react-use'
import Error from 'Components/Primitives/Error'
import Stage from 'Components/Anubis/Stage'
import { stages } from './PipelineSettings'
import { Link, useParams } from 'react-router-dom'
import { Route } from 'Utilities/Route'
import { PipelineStatus } from 'Utilities/pharaoh.types'
import { $enum } from 'ts-enum-util'

export enum ComboStatus {
  inProgress = 'in-progress',
  active = 'active'
}

export type Filter = PipelineStatus | ComboStatus

const AgencyDashboardPipeline: React.FC = () => {
  const { loading, error, value: data } = useAsync(api.v2.brokers.pipeline)
  const { filter } = useParams() as { filter: Filter | undefined }

  if (loading) return <Loader />
  if (error) return <Error error={error} />

  const counts = data.counts as StagesProps
  const pipes = data.pipes as Pipe[]

  const countInProgress = counts.suspect + counts.prospect + counts.lead
  const countActive = counts.proposed + counts.processing + counts.enrolled

  return <>
    <div>
      <h1 className={styles.title}>Sales Pipeline</h1>
      <span className={styles.today}>Today: {moment().format('L')}</span>
    </div>
    <div className={styles.stages}>
      <Stages {...counts} filter={filter} />
    </div>
    <div className={styles.completion}>
      <Link to={`${Route.agencyDashboardPipeline}/${ComboStatus.inProgress}`} className={styles.inProgress}>
        <span className={styles.inProgressLabel}><b>#{countInProgress}</b> In-progress Clients</span>
      </Link>
      <Link to={`${Route.agencyDashboardPipeline}/${ComboStatus.active}`} className={styles.activeGroups}>
        <span className={styles.activeLabel}><b>#{countActive}</b> Active Clients</span>
      </Link>
    </div>
    <PipelineDetails pipes={filteredPipes()} filter={filter} />
  </>

  function filteredPipes() {
    const set = statusesForFilter(filter)
    return pipes.filter(({ pipelineStatus }) => set.has(pipelineStatus))
  }
}

function statusesForFilter(filter: Filter | undefined): Set<PipelineStatus> {
  switch (filter) {
  case PipelineStatus.suspect:
  case PipelineStatus.prospect:
  case PipelineStatus.lead:
  case PipelineStatus.proposed:
  case PipelineStatus.processing:
  case PipelineStatus.enrolled:
  case PipelineStatus.archived:
    return new Set([filter])
  case undefined: {
    const set = new Set($enum(PipelineStatus).getValues())
    set.delete(PipelineStatus.archived)
    return set
  }
  case ComboStatus.inProgress:
    return new Set([PipelineStatus.suspect, PipelineStatus.prospect, PipelineStatus.lead])
  case ComboStatus.active:
    return new Set([PipelineStatus.proposed, PipelineStatus.processing, PipelineStatus.enrolled])
  }
}

type StagesProps = {
  suspect: number
  prospect: number
  lead: number
  proposed: number
  processing: number
  enrolled: number
  archived: number

  filter: Filter | undefined
}

const Stages: React.FC<StagesProps> = ({ filter, ...props }) => {
  return <>{stages.map((stage, index) => {
    let to = `${Route.agencyDashboardPipeline}/${stage.key}`
    let active = false
    if (filter && statusesForFilter(filter).has(stage.key)) {
      if (PipelineStatus[filter as keyof typeof PipelineStatus]) {
        // act like a toggle but only if we’re not a combo-filter
        to = Route.agencyDashboardPipeline
      }
      active = true
    }
    return <Link key={stage.key} to={to}>
      <Stage
        key={stage.key}
        activeStage={active}
        stage={{ quantity: (props as any)[stage.key], ...stage, index: stages.length - index }}
      />
    </Link>
  })}</>
}

export default AgencyDashboardPipeline
