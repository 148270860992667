import React from 'react'
import { useAsync } from 'react-use'
import styles from './index.module.scss'
import Loader from 'Components/Rudimentary/Loader'
import Error from 'Components/Primitives/Error'

interface BlogPost {
  link: string
  title: { rendered: string }
  excerpt: { rendered: string, protected: boolean}
  featuredMedia: number
}

const HealthCareNews: React.FC = () => {
  const posts = useAsync(async() => {
    const response = await fetch('https://candor.insurance/wp-json/wp/v2/posts?&per_page=4&_fields=title,excerpt,link,featured_media')
    const result = await response.json()
    return result
  }, [])

  return <div className={styles.container}>
    <h3>Healthcare News</h3>
    <p>Everything from healthcare coverage to small business success.</p>
    {/* Podcast */}
    <iframe
      src='https://webplayer.whooshkaa.com/show/8345?theme=light&button-color=%2348A3DB&background-color=%23F8F8F8A&waveform-progress-color=%233F8FC1&scrolling-title=true&hide-download=true&hide-rss-feed=true&enable-volume=true'
      className={styles.podcast}
      scrolling='no'
      frameBorder='0'
      allow='autoplay'
      title='Podcast'
    >
    </iframe>
    {/* Blog Feed */}
    { posts.loading
      ? <Loader/>
      : posts.error
        ? <Error error={posts.error} />
        : Object.values(posts.value).map((post: any, index) => {
          //  If the get request for the featured_media returns a 401, it is possibly due to the image being used in a previously deleted post. Reuploading the image fixes it: https://github.com/WP-API/WP-API/issues/2596. If this issue crops up repeatedly, we should get an auth token before fetching.
          return <NewsItem post={{ ...post, featuredMedia: post.featured_media }} key={index}/>
        })}
  </div>
}

const NewsItem: React.FC<{ post: BlogPost }> = ({ post }) => {
  const image = useAsync(async() => {
    const response = await fetch(`https://candor.insurance/wp-json/wp/v2/media/${post.featuredMedia}`)
    const result = await response.json()
    return result
  }, [])

  function parsedExcerpt(): string {
    const excerpt = post.excerpt.rendered.replace(/\[.*?\]/g, '')
    const html = new DOMParser().parseFromString(excerpt, 'text/html')
    if (Array.from(html.body.childNodes).some(node => node.nodeType === 1)) {
      return html.documentElement.textContent || ''
    }
    return excerpt
  }

  function parsedTitle(): string {
    const html = new DOMParser().parseFromString(post.title.rendered, 'text/html')
    return html.documentElement.textContent || ''
  }

  return <div className={styles.newsItemContainer}>
    {/* eslint-disable-next-line */}
    <a
      href={post.link}
      target='_blank'
      rel="noopener noreferrer"
      className={styles.image}
      style={{ backgroundImage: image.value && `url(${image.value.source_url})` }}/>
    <div>
      <h4>{parsedTitle()}</h4>
      <p>{parsedExcerpt()}</p>
      <a href={post.link} target='_blank' rel="noopener noreferrer">Read more...</a>
    </div>
  </div>
}

export default HealthCareNews
