import React from 'react'
import styles from './Loader.module.css'

interface Props {
  center?: boolean
}

const Loader: React.FC<Props> = ({ center }) => {
  let style: React.CSSProperties
  if (center) {
    style = {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  } else {
    style = {
      textAlign: 'center',
      height: '100%'
    }
  }

  return (
    <div style={style}>
      <div className={styles.ldsDefault}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  )
}

export default Loader
