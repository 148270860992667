/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react'
import Select from 'Components/Rudimentary/CandorSelect'
import styles from './CarrierApplication.module.scss'
import api from 'Utilities/Deprecated/api'
import Input from 'Components/Rudimentary/CandorInput'
import { FormProvider } from 'react-hook-form'
import { host, Host } from 'Utilities/config'
import usePersistableForm from 'Utilities/Hooks/usePersistableForm'
import useToast from 'Utilities/Hooks/useToast'
import moment from 'moment'
import useContentAdjustment from 'Utilities/Hooks/useContentAdjustment'

const business_types = [
  { value: 'Proprietorship', label: 'Proprietorship' },
  { value: 'Partnership', label: 'Partnership' },
  { value: 'C-Corp', label: 'C-Corp' },
  { value: 'S-Corp', label: 'S-Corp' },
  { value: 'Non-Profit', label: 'Non-Profit' }
]

interface Props {
  cancelCallback?: () => void
  groupID: string
  callback?: () => void
  asyncCallback?: (api: Promise<void>) => void
}

const CarrierApplication: React.FC<Props> = props => {
  const form = usePersistableForm(`group/${props.groupID}/carrier-application`)
  const { handleSubmit, watch, register } = form
  const multipleLocations = watch('multiple_locations')
  const addToast = useToast()
  useContentAdjustment({ paddingTop: 0 })

  return <FormProvider {...form}>
    <div className={styles.applicationComponent}>
      <h1>Information for the carrier</h1>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} className={styles.form2col}>

        <h3>Company</h3>
        <Select
          options={business_types}
          name="business_type"
          placeholder="Business Type"
          required={required()} />

        <p>How long has the company been<br />in business?</p>
        <div className={styles.threeCol}>
          <div className={styles.colItem}>
            <Input type="number" placeholder='Years' name="business_age" />
          </div>
        </div>

        <div className={styles.threeCol}>
          <h3>Billing Contact</h3>
          <div className={styles.colItem}>
            <Input autoComplete="name" name="billing_name" placeholder="Name" required={required()} />
          </div>
          <div className={styles.colItem}>
            <Input autoComplete="tel-national" minLength={10} maxLength={10} name="business_phone" placeholder="Phone Number" required={required()} />
          </div>
          <div className={styles.colItem}>
            <Input autoComplete="email" name="business_email" placeholder="Email" required={required()} />
          </div>
        </div>

        <div className={styles.threeCol}>
          <h3>HR Contact</h3>
          <div className={styles.colItem}>
            <Input autoComplete="name" name="hr_name" placeholder="Name" />
          </div>
          <div className={styles.colItem}>
            <Input autoComplete="tel-national" minLength={10} maxLength={10} name="hr_phone" placeholder="Phone Number" />
          </div>
          <div className={styles.colItem}>
            <Input autoComplete="email" name="hr_email" placeholder="Email" />
          </div>
        </div>

        <h3>Waiting Period</h3>
        <p className={styles.noTopMargin}>The length of time future employees must be employed before becoming eligible for coverage):</p>
        <p className={styles.noTopMargin} style={{ fontSize: 13 }}>Note: the effective date will be on the first day of the billing cycle following the date the employee satisfied<br />their waiting period and they enrolled for coverage within 31 days of becoming eligible for coverage.</p>
        <Select name="waiting_period" options={[
          { value: '0 days', label: '0 days' },
          { value: '30 days', label: '30 days' },
          { value: '60 days', label: '60 days' },
          { value: '90 days', label: '90 days' }
        ]} />

        <h3>General</h3>
        <p className={styles.noTopMargin}>Did you employ 20 or more full-time equivalent employees for at least 50% of the previous calendar year?</p>
        <Select name="employed_20" options={[
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' }

        ]} />
        <p>Is your company subject to the requirements of COBRA?</p>
        <Select name="cobra_select" options={[
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' }
        ]} />
        <p>Do you want to offer COBRA if your current or future group size does not require this?</p>
        <Select name="offer_optional_cobra" options={[
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' }
        ]} />
        <p>Does your company have other business organizations under common ownership or more than one Federal Tax ID?</p>
        <Select name="other_business_organizations" options={[
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' }
        ]} />
        <p>Does your business have more than one physical location?</p>
        <Select name="multiple_locations" required={required()} options={[
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' }
        ]} />
        {multipleLocations === 'true' && <>
          <p>If yes what are the addresses of your other businesses?</p>
          <textarea autoComplete="street-address" className={styles.candorInput} name="other_business_addresses" ref={register} />
        </>}
        <p>Will you be or are you offering another group medical plan in addition to those you selected?</p>
        <Select name="offering_another_group_medical" options={[
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' }
        ]} />

        <div className={styles.twoCol} style={{ marginTop: '3em' }}>
          <div className={styles.colItem}>
            <p className={styles.noTopMargin}>Full-time employees</p>
            <Input type="number" name="full_time_percent" placeholder="Number of employees" required={required()} />
          </div>
          <div className={styles.colItem}>
            <p className={styles.noTopMargin}>Part-time employees</p>
            <Input type="number" name="part_time_percent" placeholder="Number of Employees" required={required()} />
          </div>
          <input className={styles.nextButton} type='submit' />
          {props.cancelCallback &&
          <button className={styles.nextButton} onClick={e => { e.preventDefault(); props.cancelCallback!() }}>Cancel</button>
          }
        </div>
      </form>
    </div>
  </FormProvider>

  async function onSubmit(data: any) {
    if (props.cancelCallback) addToast('Submitting…')

    const subject = `Carrier Application (${props.groupID})`
    const body = formatApplicationBody(data)
    const referrer = window.location.href

    const promise = api.sendSupportEmail(subject, body, referrer, null, true)

    if (props.asyncCallback) {
      props.asyncCallback(promise)
    } else {
      try {
        await promise
        props.callback!()
      } catch (error) {
        addToast(error)
      }
    }
  }

  function required() {
    switch (host()) {
    case Host.localhost:
    case Host.cd:
    case Host.develop:
      return false
    case Host.staging:
    case Host.production:
      return true
    }
  }

  function formatApplicationBody(data: any) {
    const { group_id, business_type, business_age, billing_name, business_phone, business_email, hr_name, hr_phone, hr_email, waiting_period, employed_20, cobra_select, offer_optional_cobra, other_business_organizations, multiple_locations, other_business_addresses, offering_another_group_medical, full_time_percent, part_time_percent } = data
    const today = moment().format('L')

    return `Group ID: ${group_id} \n
            Business Type: ${business_type} \n
            Business Age: ${business_age} \n
            Billing Name: ${billing_name} \n
            Business Phone: ${business_phone} \n
            Business Email: ${business_email} \n
            HR Name: ${hr_name} \n
            HR Phone: ${hr_phone} \n
            HR Email: ${hr_email} \n
            Waiting Period: ${waiting_period} \n
            Employed over 20?: ${employed_20} \n
            COBRA Select?: ${cobra_select} \n
            Offer Optional COBRA?: ${offer_optional_cobra} \n
            Other Business Organizations: ${other_business_organizations} \n
            Multiple Physical Locations?: ${multiple_locations} \n
            Other Business Addresses: ${other_business_addresses} \n
            Offering Another Group Medical?: ${offering_another_group_medical} \n
            № Full Time EEs: ${full_time_percent} \n
            № Part Time EEs: ${part_time_percent} \n
            Date: ${today}
            `
  }
}

export default CarrierApplication
