import * as api from 'Utilities/fetch++'
import { useAsync } from 'react-use'
import { Tier, Relationship } from 'Utilities/pharaoh.types'
import { utcMidnightToLocalMidnight, getToken } from 'Utilities/pharaoh'
import { PowerLevel } from './useUser'
import { AsyncState } from 'react-use/lib/useAsync'

/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/camelcase */

// globally promise this immutable data for /dashboard/employer routes

export interface Response {
  group?: Group
  members: Member[]
  associationIds: string[]
  planIds: string[]
  groupContribution?: string
  nonWaivedMemberCount?: number
  isProsperOnlyMode?: boolean
  user: User
  dependents?: Dependent[]
  groupMember?: GroupMember
  userMetadata?: UserMetadata
  consumerProfile?: ConsumerProfile
  mode: Mode
  userTier?: Tier
  employerContributionIsEquitable: boolean
  splits: ContributionSplit[]
  obelisk: {
   logo: string
   slug: string
   name: string
  }
  showUnderwritingPage: boolean
  showRedflagsPage: boolean
  showCarrierApplication: boolean
  eePaymentInfoReceived: boolean
  payment: Payment
  eeSouvenirSent: boolean
  erSouvenirSent: boolean
}

export enum MaritalStatus {
  single = 'single',
  married = 'married',
  domesticPartner = 'domesticPartner',
  divorced = 'divorced',
  widowed = 'widowed',
  separated = 'separated'
}

export interface GroupMember {
  id: string
  date_of_birth?: Date
  name?: string
  gender?: number // 0 is male
  zip_code?: string
  countyId?: string
  height?: number
  weight?: number
  is_redflagged?: boolean
  is_waived: boolean
  medical_underwriting_complete: boolean
}

interface ConsumerProfile {
  ssn?: string
  state_code?: string
  phone_number?: string
  address1?: string
  address2?: string
  city?: string
}

interface UserMetadata {
  jobTitle?: string
  city?: string
  alreadyHasPlan?: boolean
  taxWithholdingStatus?: number
  householdIncome?: number
  hoursPerWeek?: number
  hireDate?: Date
  mailingAddress1?: string
  mailingAddress2?: string
  mailingCity?: string
  mailingState?: string
  mailingZIP?: string
}

export interface ContributionSplit {
  id: string
  members: string[]
  contribution: string
  isEquitable?: boolean
  name?: string
}

export interface Group {
  id: string
  name: string
  effectiveDate?: Date
  zipCode?: string
  industrySICCode?: string
  countyID?: string
  medical_contribution?: string
  dental_contribution?: string
  vision_contribution?: string
  life_contribution?: string
  disability_contribution?: string
  ein?: string
  existingCoverageCarrier?: string
  existingCoverageRenewalDate?: Date
  medical_contribution_equitable: boolean
  carrierApplicationComplete: boolean
}

export interface User {
  first_name?: string
  last_name?: string
  id: string
  email: string
  power_level: PowerLevel
  acceptedTermsAndConditions: boolean
  enrolled_group_plan_id?: string
  enrolled_dental_plan_id?: string
  enrolled_vision_plan_id?: string
  enrolled_life_plan_id?: string
  enrolled_individual_plan_id?: string
  enrolled_disability_plan_id?: string
  county_id?: string
  marital_status?: MaritalStatus
}

export interface Member {
  id: string
  name: string
  email: string
  gender?: Gender
  zip?: string
  countyId?: string
  lastTobaccoDate?: Date
  dateOfBirth?: Date
  is_waived: boolean
  dependents: MemberDependent[]
  tier: Tier
}

export interface Dependent {
  id: string
  firstName: string
  lastName: string
  gender: Gender
  zipCode?: string
  lastUsedTobacco?: Date
  dateOfBirth: Date
  relationship: Relationship
  ssn?: string
  height?: number
  weight?: number
}

interface MemberDependent {
  id: string
  firstName: string
  lastName: string
  gender: Gender
  zip?: string
  countyId?: string
  lastTobaccoDate?: Date
  dateOfBirth: Date
  relationship: Relationship
  ssn?: string
}

export enum Mode {
  employee = 'employee',
  employer = 'employer',
  both = 'both'
}

export enum Gender {
  male = 'M',
  female = 'F'
}

export interface Payment {
  paymentInfoReceived: boolean
  appliedPromoCode?: string
  status?: PaymentStatus
  lastPaymentDate?: Date
  monthlyPremium?: string
  paymentAmount?: string
  nextPaymentDate?: Date
  nextPaymentAmount?: string
}

enum PaymentStatus {
  unpaid,
  pending,
  paid,
  error
}

const store: {[key: string]: Promise<Response>} = {}

export default function useStargate(): AsyncState<Response> {
  const groupID = localStorage.overrideGroupID || ''

  return useAsync(async() => {
    if (store[groupID]) return store[groupID]

    store[groupID] = (async() => {
      const rsp = await api.get(`/stargate/${groupID}`) as Response
      if (rsp.group) {
        rsp.group.effectiveDate = utcMidnightToLocalMidnight(rsp.group.effectiveDate)
        rsp.group.existingCoverageRenewalDate = utcMidnightToLocalMidnight(rsp.group.existingCoverageRenewalDate)
      }
      if (rsp.groupMember) {
        rsp.groupMember.date_of_birth = utcMidnightToLocalMidnight(rsp.groupMember.date_of_birth)
      }
      rsp.members = rsp.members.map(member => {
        member.dateOfBirth = utcMidnightToLocalMidnight(member.dateOfBirth)
        member.dependents = member.dependents.map(dep => {
          dep.dateOfBirth = utcMidnightToLocalMidnight(dep.dateOfBirth)! // bang because it is not optional on server
          return dep
        })
        return member
      })
      rsp.dependents = rsp.dependents?.map(dep => {
        dep.dateOfBirth = utcMidnightToLocalMidnight(dep.dateOfBirth)! // bang because not optional on server
        return dep
      })
      if (rsp.userMetadata) {
        rsp.userMetadata.hireDate = utcMidnightToLocalMidnight(rsp.userMetadata?.hireDate)
      }
      return rsp
    })()

    try {
      return await store[groupID]
    } finally {
      // we only cache during simultaneous executions
      // thus ensuring subsequent requests get fresh data for the new Wizard pages
      delete store[groupID]
    }
  }, [groupID, getToken()])
}
