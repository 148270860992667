import React, { useEffect } from 'react'
import styles from './PlanCart.module.scss'
import FooterStyles from 'Components/Stargate/RefactorMe.module.css'
import PlanCartItem from './PlanCartItem'
import CartError from './CartError'
import Loader from 'Components/Rudimentary/Loader'
import { useEffectOnce } from 'react-use'
import { MedicalPlan } from 'Utilities/pharaoh.types'

interface Props {
  selectedPlans: MedicalPlan[]
  removePlan: (plan: MedicalPlan) => void
  compare: () => void
  editingCart: boolean
}

const PlanCart: React.FC<Props> = ({ selectedPlans, removePlan, compare, editingCart }) => {
  useEffectOnce(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })
  useEffect(handleScroll)

  const visible = selectedPlans.length > 0
  const checkoutDisabled = checkSingleCarrier()

  if (!visible) return null

  return <div className={styles.container}>
    { editingCart && renderLoading() }
    <div className={styles.content}>
      { checkoutDisabled && <CartError message={'You can only purchase plans from one carrier.'}/> }
      <div className={styles.planContainer}>
        {selectedPlans.slice(0).reverse().map(plan => <PlanCartItem key={plan.id} plan={plan} remove={removePlan}/>)}
      </div>
      <button onClick={compare}>Compare</button>
    </div>
  </div>

  function handleScroll() {
    const cart = document.getElementsByClassName(styles.container)[0] as HTMLElement
    const footer = document.getElementsByClassName(FooterStyles.footer)[0]

    // When an employee goes to the next page,
    // the element with the classname "container" will no longer exist,
    // so don't do anything with this function.
    if (!cart || !footer) return

    // https://stackoverflow.com/a/45514790
    const footerBounds = footer.getBoundingClientRect()
    const isOverlapping = (footerBounds.top + footerBounds.height) >= 0 && (footerBounds.top - footerBounds.height) <= window.innerHeight
    const offset = window.innerHeight - footerBounds.top
    if (isOverlapping && offset > 0) {
      cart.style.bottom = offset + 'px'
    } else {
      cart.style.bottom = '0'
    }
  }

  function checkSingleCarrier() {
    const selected = selectedPlans
    const tracker: string[] = []
    selected.forEach(plan => {
      if (!tracker.includes(plan.carrier)) {
        tracker.push(plan.carrier)
      }
    })
    return tracker.length > 1
  }

  function renderLoading() {
    return <div className={styles.loading}>
      <Loader/>
    </div>
  }
}

export default PlanCart
