import React from 'react'
import { PrivateWizardPageProps } from 'Components/Stargate/Wizard/WizardRoute'
import useFullContentArea from 'Utilities/Hooks/useFullContentArea'
import styles from './SpecialContribution.module.scss'
import { useToggle } from 'react-use'
import { sortBy } from 'lodash'
import CandorInput from 'Components/Rudimentary/CandorInput'
import ContributionAmount, { contributionType, ContributionSplitType } from 'Components/Stargate/Contribution/ContributionAmount'
import EmployeeSelector from 'Components/Stargate/EmployeeSelector'
import { ContributionSplit } from 'Utilities/Hooks/useStargate'
import ReactDOM from 'react-dom'
import useSplits from './useSplits()'

const ERShopContributionsCarved: React.FC<PrivateWizardPageProps> = ({ stargate, ...props }) => {
  const [asking, stopAsking] = useToggle(stargate.splits.length <= 0)
  const [splits, unsplitMembers, { create, delete: remove, toggleMember, setName, setContribution, async }] = useSplits(stargate.group, stargate.members, stargate.splits)
  useFullContentArea()

  return <div className={styles.container}>
    <header>
      <h1>Special Coverage Groups</h1>
      {renderHeader()}
    </header>
    <div className={styles.body}>
      {renderBody()}
    </div>
  </div>

  function renderBody() {
    if (asking) {
      return <>
        <h2>
          Would you like to create special coverage groups for different employee types?
          &nbsp;<span>(eg. executives, managers, hourly, salary)</span>
        </h2>
        <div className={styles.boolContainer}>
          <BoolButton callback={() => {
            ReactDOM.unstable_batchedUpdates(() => {
              stopAsking()
              create()
            })
          }} color='#D4F8EF' value={true}>
            I want a special coverage group!
          </BoolButton>
          <BoolButton callback={onwards} color='#EBEBEB' value={false}>
            Show me the plans!
          </BoolButton>
        </div>
      </>
    } else {
      return <>
        {splits.map(render)}
        <button className={styles.nextButton} onClick={onwards}>Next: Medical Plans</button>
      </>
    }

    function render(split: ContributionSplit) {
      const options = new Set([...unsplitMembers, ...split.members])
      const members = stargate.members.filter(member => options.has(member.id))
      return <div className={styles.employeeSelector} key={split.id}>
        <div className={styles.coveredBy}>
          <label>Employees Covered by</label>
          <input placeholder="Group Name" value={split.name} onChange={event => setName(split.id, event.currentTarget.value)} />
        </div>
        <p>
          Please <b>select</b> all employees in the special coverage group. by default, no employees are covered in a special group until selected.
        </p>
        <EmployeeSelector
          id={split.id}
          selectCallback={toggleMember}
          selected={new Set(split.members)}
          groupMembers={members}
          variant='checkmark'
        />
      </div>
    }
  }

  function onwards() {
    props.onwards(async)
  }

  function renderHeader() {
    if (asking) return null
    return <div>
      <p>To create a Special Coverage Group, please complete the following:</p>
      <ol>
        <li>Add a group name</li>
        <li>Designate a coverage amount and contribution type</li>
        <li>
        Create up to 6 Special Coverage Groups
        </li>
      </ol>
      {renderSplits()}
      <button className={styles.addGroup} onClick={create}>Add Group</button>
    </div>

    function renderSplits() {
      return sortBy(splits, 'index').map(split => {
        const type = contributionType(split.isEquitable || false, split.contribution)
        return <div key={split.id} className={styles.contContainer}>
          <RemoveBox callback={() => remove(split.id)} />
          <CandorInput
            className={styles.headerNameInput}
            onChange={event => setName(split.id, event.currentTarget.value)}
            value={split.name}
            placeholder="Group Name"
            autoFocus
          />
          <div className={styles.amountContainer}>
            <ContributionAmount
              className={styles.contributionAmount}
              onChange={(value: string, type?: ContributionSplitType) =>
                setContribution(split.id, value, type || ContributionSplitType.perEmployee)
              }
              value={split.contribution}
              splitType={type}
            />
          </div>
        </div>
      })
    }
  }
}

const RemoveBox = (props: { callback: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void }) =>
  <button onClick={props.callback} className={styles.addBox}>
    <i className='material-icons'>{'remove'}</i>
  </button>

const BoolButton = (props: { children: any, value: boolean, color: string, callback: () => void }) => {
  const { value, color: backgroundColor, callback } = props
  return <button style={{ backgroundColor }} className={styles.boolButton} onClick={callback}>
    {value ? 'YES' : 'NO'}
    <p>{props.children}</p>
  </button>
}

export default ERShopContributionsCarved
