import React, { useEffect, useState } from 'react'
import CandorSelect from 'Components/Rudimentary/CandorSelect'
import api from 'Utilities/Deprecated/api'
import useToast from 'Utilities/Hooks/useToast'
import { uniq } from 'lodash'
import { usePrevious } from 'react-use'
import { Styles } from 'react-select'
import { useFormContext, Control, Controller } from 'react-hook-form'

interface Props {
  value?: string
  zip?: string
  onChange?: (state?: string) => void
  className?: string
  name?: string
  required?: boolean
  control?: Control<any>
  styles?: Styles
  isDisabled?: boolean

  stop?: boolean // internal use only
}

/// Either pass control, useFormContext() or manage value/onChange yourself, if you do none of these it won’t work.
/// Pass `zipCode` or there won’t be *any* options.
const StatePicker: React.FC<Props> = ({ zip, value, onChange, ...props }) => {
  const [options, setOptions] = useState(states)
  const addToast = useToast()
  const prevZip = usePrevious(zip)
  const choice = value ? { value: value, label: value } : undefined
  const control = useFormContext()?.control || props.control

  useEffect(() => {
    if (control && !props.stop) return
    if (prevZip === zip) return // because value probably change (via us even), but we don't want to fetch in a loop

    if (!zip || zip.length < 5) {
      setOptions(states)
      if (onChange) onChange(value)
    } else {
      fetch().catch(addToast)
    }

    async function fetch() {
      const activeZip = zip
      const rsp = await api.getStateOfZip(zip) as {stateId: string}[]
      if (zip !== activeZip) /* zip changed while we were fetching the data */ return
      if (rsp.length === 0) throw new Error('StatePicker Invalid ZIP Code')

      const states = uniq(rsp.map(({ stateId }) => stateId))

      setOptions(states)

      if (onChange) {
        if (states.length === 1) {
          onChange(states[0])
        } else if (states.length === 0 || !states.includes(value || '')) {
          onChange(undefined)
        }
      }
    }
  }, [zip, prevZip, onChange, addToast, value, control, props.stop])

  if (control && props.name && !props.stop) {
    return <Controller as={StatePicker} {...props} name={props.name} zip={zip} stop />
    // NOTE stop will be forwarded to the CandorSelect preventing doubling the Controller
  }

  const maybeOnChange = onChange ? (obj: any) => onChange(obj) : undefined

  return <CandorSelect
    {...props}
    placeholder="State"
    options={options.map(value => ({ value, label: value }))}
    value={choice?.value}
    onChange={maybeOnChange}
    isDisabled={props.isDisabled || options.length < 1}
  />
}

StatePicker.displayName = 'StatePicker'

const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
]

export default StatePicker
