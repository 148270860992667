import React, { useState, useEffect } from 'react'
import styles from './SignInModal.module.scss'
import api from 'Utilities/Deprecated/api'
import StargateModal from 'Components/Stargate/StargateModal'
import NextButton, { ButtonStates as NextButtonState } from 'Components/Stargate/NextButton/NextButton'
import ReactModal from 'react-modal'
import { useForm } from 'react-hook-form'
import useToast from 'Utilities/Hooks/useToast'
import { v1 } from 'Utilities/pharaoh'

interface Props extends ReactModal.Props {
  email: string | undefined
  onRequestClose?: () => void
  callback?: () => void
}

enum Mode {
  forgot = 'forgot', signIn = 'signIn', password = 'password'
}

const SignInModal: React.FC<Props> = ({ email, ...props }) => {
  const [mode, setMode] = useState(Mode.signIn)
  const { register, handleSubmit, reset } = useForm({ defaultValues: { email } })
  const [buttonState, setButtonState] = useState<NextButtonState>('')
  const addToast = useToast()

  // defaultValues passed to useForm only work on first render, and we exist even though we aren’t visible, so…
  useEffect(() => reset({ email }), [email, reset])

  return <StargateModal
    {...props}
    styles={{ width: 'fit-content', padding: 30, height: 'fit-content' }}
  >
    <h1 className={styles.title}>Welcome!</h1>
    { mode === 'password' &&
    <p className={styles.desc}>Please type in your email</p>
    }
    <form
      onSubmit={handleSubmit(go)}
      className={styles.modalContainer}
    >
      {(mode === 'signIn' || mode === 'password') && (
        <input
          className={styles.input}
          type="email"
          name="email"
          placeholder="Email"
          ref={register}
          required
          autoFocus={!email}
        />
      )}
      {mode === 'signIn' && (
        <input
          className={styles.input}
          type="password"
          name="password"
          placeholder="Password"
          ref={register}
          required
          autoFocus={!!email}
        />
      )}
      {mode === 'signIn' && (
        <button
          onClick={() => setMode(Mode.password)}
          className={styles.link}
          type='button'
        >
        Forgot password or didn’t set one up?
        </button>
      )}

      {mode !== 'signIn' && (
        <button
          className={`${styles.link} ${styles.center}`}
          type='button'
          onClick={() => setMode(Mode.signIn)}
        >
        Back to Sign‑In
        </button>
      )}

      {(mode === 'signIn' || mode === 'password') && (
        <NextButton
          state={buttonState}
          type="submit"
          controlled={true}
          className={styles.nextButton}
          value="Enter"
        />
      )}
    </form>
  </StargateModal>

  async function go(data: any) {
    const { email, password } = data
    setButtonState('loading')
    try {
      switch (mode) {
      case 'password':
        await api.resetPassword({ email })
        addToast('Reset password email has been sent.', 'success')
        setButtonState('success')
        if (props.onRequestClose) props.onRequestClose()
        break
      case 'signIn':
        await v1.users.sessions.POST(email, password)
        addToast('Welcome back!', 'info')
        setButtonState('success')
        if (props.callback) await props.callback()
      }
    } catch (error) {
      addToast(error)
      setButtonState('error')
    }
  }
}

export default SignInModal
