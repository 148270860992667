/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable camelcase */
import React from 'react'
import api from 'Utilities/Deprecated/api.js'
import { useAsync } from 'react-use'
import { sortBy } from 'lodash'
import Select from 'Components/Rudimentary/CandorSelect'

interface Industry {
  title: string
  sic_code: string
}

interface Props {
  onChange: (sic_code: string) => void
  value?: string
}

const IndustryPicker: React.FC<Props> = ({ onChange, value }) => {
  const async = useAsync(async() => {
    const rsp = await api.getIndustriesFor('jewelershealthcare.com') as Industry[]
    return sortBy(rsp.map(({ title, sic_code }) => ({
      label: `${title} (SIC: ${sic_code})`,
      value: sic_code
    }), 'label'))
  })

  return <Select
    placeholder="Industry"
    options={options()}
    value={value}
    required={true}
    onChange={onChange} />

  function options(): { label: string, value: string }[] {
    if (async.loading) {
      return []
    } else if (async.error) {
      return [{ label: 'Error', value: value || '' }]
    } else {
      return async.value!
    }
  }
}

export default IndustryPicker
