import React, { useState } from 'react'
import { useAsync } from 'react-use'
import * as api from 'Utilities/pharaoh'
import Loader from 'Components/Rudimentary/Loader'
import Error from 'Components/Primitives/Error'
import queryString from 'query-string'
import { GroupMember, Group, User } from 'Utilities/Hooks/useStargate'
import { PrivateWizardPageProps } from '../Wizard/WizardRoute'
import styles from './Underwriting.module.scss'
import DocuSignForm, { ReturnEvent } from 'Utilities/DocuSignForm'

type Props = {
  group: Group
  groupMember: GroupMember
  user: User
}

const UnderwritingForm: React.FC<Props> = ({ group, groupMember, user }) => {
  const [envelope, setEnvelope] = useState<any>()
  const async = useAsync(async() => {
    setEnvelope(await api.v3.groups(group.id).users(user.id).underwriting.GET())
  }, [group.id])

  if (async.error) { return <Error error={async.error} /> }
  if (async.loading) { return <Loader /> }

  return <div className={styles.mainContainer}>
    <h1 className='shop-h1-periwinkle'>Application</h1>
    <h2 className='shop-h2-navy'>We need a little more information to complete your application</h2>
    <DocuSignForm
      id={groupMember.id}
      envelope={envelope}
      onSubmit={(envelope) =>
        api.v3.groups(group.id).users(user.id).underwriting.POST({ envelope })
      }
    />
  </div>
}

const Underwriting: React.FC<PrivateWizardPageProps> = ({ stargate, onwards }) => {
  const { groupMember, group, user } = stargate
  if (!groupMember || !group) return <p>You must complete the earlier steps before you can continue.</p>

  const parsedQuery = queryString.parse(window.location.search)

  if (parsedQuery.event === ReturnEvent.SIGNING_COMPLETE || groupMember.medical_underwriting_complete) {
    return <header>
      <h1 className='shop-h1-periwinkle'>Application</h1>
      <h2 style={{ marginBottom: '4rem' }} className='shop-h2-navy'>Thank you for completing your application.</h2>
      <button className='shop-next-button' onClick={next}>Next Step</button>
    </header>
  } else {
    return <UnderwritingForm
      group={group}
      groupMember={groupMember}
      user={user} />
  }

  function next() {
    onwards(Promise.resolve())
  }
}

export default Underwriting
