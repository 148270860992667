import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'

const Tracking: React.FC = () => {
  const location = useLocation()

  useEffect(() => {
    ReactGA.pageview(location.pathname)
  }, [location.pathname])

  return <React.Fragment />
}

export default Tracking
