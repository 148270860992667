import React from 'react'
import styles from 'Components/Plans/plan-subcomponents/Checkboxes.module.scss'
import TermsModal from '.'
import { Checkbox } from 'Components/Plans/plan-subcomponents/Checkboxes'
import { useToggle } from 'react-use'

interface Props {
  value?: boolean
  onChange: (value: boolean) => void
  required?: boolean
}

const TermsAndConditions: React.FC<Props> = props => {
  const [isShowingTermsModal, toggleModal] = useToggle(false)

  return <div className={styles.termsContainer}>
    <TermsModal isOpen={isShowingTermsModal} onClose={toggleModal} />
    <Checkbox
      {...props}
      label='I accept the'
      labelClassName={styles.termsLabel}
      name='accept'
    />
    <button onClick={toggleModal} type="button">Terms and Conditions</button>
  </div>
}

export default TermsAndConditions
