import React from 'react'
import ProfileSection from 'Components/Anubis/ProfileSection'
import { useToggle } from 'react-use'
import { Alignment, Table } from 'Components/Rudimentary/Table'
import _ from 'lodash'
import AddEmployeeModal from 'Components/Anubis/AddEmployeeModal'
import { useHistory, useLocation } from 'react-router-dom'
import { Route } from 'Utilities/Route'
import { AsyncStateRetry } from 'react-use/lib/useAsyncRetry'
import { Tier } from 'Utilities/pharaoh.types'
import { carrierForPlan, massagedPlanName } from 'Components/Plans/plan-subcomponents/PlanHelpers'
import { ContributionSplit } from 'Utilities/Hooks/useStargate'
import Loader from 'Components/Rudimentary/Loader'
import Error from 'Components/Primitives/Error'

type Props = {
  ees: AsyncStateRetry<Response>
  id: string
}

export interface Response {
  members: Member[]
  splits: ContributionSplit[]
}

interface Member {
  id: string
  lastName: string
  firstNames: string
  plans: Plan[]
  class: Class
  status: string
  tobacco: boolean
  dependents: Member[]
}

interface Plan {
  id: string
  name: string
  tier: Tier
  type: string
  carrier: string
  premiums: {ee: string, er: string}
}

enum Class {
  employee = 'employee',
  spouse = 'spouse',
  child = 'child',
  lifePartner = 'lifePartner'
}

const GroupsProfileEmployeesSection: React.FC<Props> = ({ ees: async, id }) => {
  if (async.loading || async.error) {
    return <ProfileSection key={0} name='Employees'>
      {async.loading ? <Loader /> : <Error error={async.error} />}
    </ProfileSection>
  }

  const { splits, members } = async.value!
  if (!splits || splits.length < 1) {
    return <EETable ees={members} id={id} />
  } else {
    const all = new Set(members.map(({ id }) => id))
    const rv = splits.map(split => {
      const set = new Set(split.members)
      set.forEach(id => all.delete(id))

      const ees: Member[] = []
      let addedLast = false
      for (const member of members) {
        if (member.class !== Class.employee) {
          if (addedLast) {
            all.delete(member.id)
            ees.push(member)
          }
        } else if (!set.has(member.id)) {
          addedLast = false
        } else {
          all.delete(member.id)
          ees.push(member)
          addedLast = true
        }
      }

      return <EETable
        key={split.id}
        ees={ees}
        name={split.name}
        splitID={split.id}
        id={id}
      />
    })
    if (all.size) {
      rv.push(<EETable
        key='key'
        ees={members.filter(member => all.has(member.id))}
        name='Ungrouped'
        id={id}
      />)
    }
    return <>{rv}</>
  }
}

const EETable: React.FC<{splitID?: string, name?: string, ees: Member[], id: string}> = ({ splitID, name, ees, id }) => {
  const [open, toggle] = useToggle(false)
  const history = useHistory()
  const location = useLocation()

  if (name) {
    name = `Employees: ${name}`
  } else {
    name = 'Employees'
  }
  return <ProfileSection key={splitID} name={name} addButtonName="Add New Employee" expanded={false} onAddButton={toggle}>
    <AddEmployeeModal isOpen={open} onRequestClose={toggle} groupID={id} callback={push} splitID={splitID} />
    <Table
      data={ees}
      sortable={false}
      order={['lastName', 'firstNames', 'plans', 'class', 'status']}
      width={key => {
        switch (key) {
        case 'lastName':
        case 'firstNames':
          return '10%'
        case 'plans':
          return '52%'
        case 'class':
          return '10%'
        case 'status':
          return '12%'
        }
      }}
      content={(key, value, row) => {
        const isEE = row.class === Class.employee
        switch (key) {
        case 'class':
          return prettyValue()
        case 'lastName':
          if (!isEE) {
            return <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{prettyValue()}</>
          } else {
            return prettyValue()
          }
        case 'status':
          return isEE ? prettyValue() : <></>
        case 'plans':
          return isEE ? <Details plans={row.plans} /> : <></>
        }

        function prettyValue() {
          return _.startCase(value?.replace('MedicalUnderwriting', 'Application'))
        }
      }}
      alignment={key => {
        switch (key) {
        case 'tobacco':
          return Alignment.center
        default:
          return Alignment.left
        }
      }}
      selectAction={push}
      selectable={() => true}
      reportFileBasename='employees'
      reportContent={(key, value, row) => {
        const isEE = row.class === Class.employee
        switch (key) {
        case 'lastName':
          return value
        case 'status':
          return isEE ? _.startCase(value) : ''
        case 'plans':
          return isEE ? row.plans.map(plan => plan.name).join(', ') : ''
        }
      }}
    />
  </ProfileSection>

  function push(data: { id: string }) {
    const newEmployeeID = data.id
    const path = location.pathname.startsWith('/dashboard/agency')
      ? `${Route.agencyDashboardEmployees}/${newEmployeeID}`
      : `${Route.dashboardEmployerEmployees}/${newEmployeeID}`
    history.push(path)
  }
}

const Details: React.FC<{ plans: Plan[] }> = ({ plans }) => {
  if (plans.length === 0) return <>—</>
  return <Table
    style={{ pointerEvents: 'none' }}
    data={plans}
    order={['name', 'tier', 'premiums']}
    content={(key, value, row) => {
      switch (key) {
      case 'tier':
        return _.startCase(value)
      case 'premiums':
        return value.ee
      case 'name':
        return <><b>{carrierForPlan(row.carrier, value)}</b> {massagedPlanName(value, row.carrier)}</>
      }
    }}
    showHeader={false}
    width={key => {
      switch (key) {
      case 'name':
        return '65%'
      }
    }}
  />
}

export default GroupsProfileEmployeesSection
