import React from 'react'
import candorAvatar from 'Assets/CandorAvatar.jpg'
import styles from './HeaderUserProfile.module.css'
import { useLocation, Link, useHistory } from 'react-router-dom'
import useUser, { PowerLevel } from 'Utilities/Hooks/useUser'
import { Img } from 'react-image'
import { compact } from 'lodash'
import * as config from 'Utilities/config'
import { Mode } from 'Utilities/config'
import { $enum } from 'ts-enum-util'
import { post } from 'Utilities/fetch++'
import { setToken } from 'Utilities/pharaoh'
import useToast from 'Utilities/Hooks/useToast'

enum DemoUserTypes {
  am = 'Agency Manager',
  pr = 'Producer',
  hr = 'HR Manager',
  ee = 'Employee'
}

const HeaderUserProfile: React.FC = () => {
  const { value: user, loading, error } = useUser()
  const history = useHistory()
  const location = useLocation()
  const addToast = useToast()

  if (error || loading || location.pathname.startsWith('/shop')) {
    if (error) console.error(error)
    return null
  }

  const srcs = compact([user?.avatar, candorAvatar])
  const mode = config.mode()[0]

  const power = (() => {
    return mode !== Mode.demo ? ` (${value()})` : null
    function value() {
      switch (user!.powerLevel) {
      case PowerLevel.individual:
        return 'Employee'
      case PowerLevel.groupManager:
        return 'HR Manager'
      case PowerLevel.associationManager:
        return 'Association Manager'
      case PowerLevel.broker:
        return 'Producer'
      case PowerLevel.candorEmployee:
        return 'Candor Employee'
      case PowerLevel.superBroker:
        if (user!.agencyID === user!.whiteLabelAgencyID) {
          return 'General Agent'
        } else {
          return 'Agency Manager'
        }
      }
    }
  })()

  return <div className={styles.container}>
    <Img className={styles.userProfileImg} src={srcs} width="auto" height="100%" alt="Profile"/>
    <div>
      <Link to='/dashboard/employee' className={styles.userName}>
        {user?.name} {power}
      </Link>
      {bottom()}
    </div>
  </div>

  function bottom() {
    switch (mode) {
    case Mode.demo:
      return <select onChange={onChange} value={user!.email}>
        <optgroup>
          {$enum(DemoUserTypes).map(option =>
            <option key={option} value={DemoUserTypesEmail(option, user!.email)}>{option}</option>
          )}
        </optgroup>
        <optgroup>
          <option>Reset Demo</option>
        </optgroup>
      </select>
    case Mode.standard:
      return <Link to='/account/sign-out' className={styles.profileMenuItem}>
        Sign Out
      </Link>
    }
  }

  async function onChange(data: any) {
    try {
      setToken((await go()).token)
      history.push('/dashboard')
    } catch (error) {
      addToast(error)
    }

    async function go() {
      const email = data.currentTarget.value
      if (email.includes('@')) {
        return await post('/users/sessions', { email, password: '' })
      } else {
        return await post('/users/sessions/reset')
      }
    }
  }
}

function DemoUserTypesEmail(kind: DemoUserTypes, base: string): string {
  const { username, host } = split()
  return `${username}${suffix() || ''}@${host}`

  function suffix() {
    switch (kind) {
    case DemoUserTypes.am:
      return ''
    case DemoUserTypes.pr:
      return '+producer'
    case DemoUserTypes.hr:
      return '+hr1'
    case DemoUserTypes.ee:
      return '+ee11'
    }
  }

  function split() {
    const [prefix, host] = base.split('@')
    const username = prefix.split('+')[0]
    return { username, host }
  }
}

export default HeaderUserProfile
