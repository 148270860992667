import React from 'react'
import styles from './ProsperBenefitsModal.module.css'
import { ReactComponent as ProsperLogo } from './prosper-benefits-logo-white.svg'
import telemedicine from './telemedicine.png'
import counseling from './counseling.svg'
import healthAdvocacy from './health-advocacy.svg'
import medicalBillSaver from './medical-bill-saver.svg'
import StargateModal, { SMProps, CloseButtonType } from 'Components/Stargate/StargateModal'
import { useInterval } from 'react-use'
import { classNames } from 'Utilities/etc'
import { useCycle } from 'Utilities/Hooks/useCycle'

const ProsperBenefitsModal: React.FC<SMProps> = props => {
  const [step, { next, prev, set }] = useCycle(ProsperBenefits.length - 1)
  useInterval(next, props.isOpen ? 5000 : null)
  const modalStyles = { width: 940, height: 480, backgroundColor: '#e3eeff', border: 'none' }

  return <StargateModal {...props} styles={modalStyles} forceCloseButtonType={CloseButtonType.outer}>
    <ProsperLogo className={styles.prosperLogo}/>
    <img className={styles.prosperBenefitsImage} src={ProsperBenefits[step].image} alt={ProsperBenefits[step].title}/>
    <button className={styles.prev} onClick={prev}><i className="material-icons">navigate_before</i></button>
    <button className={styles.next} onClick={next}><i className="material-icons">navigate_next</i></button>
    <div className={styles.prosperIncluded}>
      <h1>What’s Included?</h1>
      <h2>{ProsperBenefits[step].title}</h2>
      <p style={{ width: ProsperBenefits[step].width }}>{ProsperBenefits[step].copy}</p>
    </div>
    <div className={styles.stepNav}>{renderStepNav()}</div>
  </StargateModal>

  function renderStepNav() {
    return ProsperBenefits.map(({ title }, index) =>
      <button
        className={classNames(step === index && styles.stepButtonActive, styles.stepButton)}
        onClick={() => set(index)}
        key={title}
      />)
  }
}

// Width is to make sure the copy doesn't rag poorly or have widows

const ProsperBenefits = [
  {
    title: 'Telemedicine',
    width: 300,
    image: telemedicine,
    copy: `
    Feel better now! Get access to a licensed healthcare provider 24/7,
    right from your desktop or mobile device. Telemedicine doctors can
    offer treatment options, refer you to specialists, and even order
    prescription refills.
    `
  },
  {
    title: 'Counseling',
    width: 285,
    image: counseling,
    copy: `
    True wellness starts from within. Our Confidential Employee Counseling
    program enables you access to a licensed counselor anytime, anywhere.
    Counselors can assist with everything from depression and anxiety to
    struggles with addiction.
    `
  },
  {
    title: 'Health Advocacy',
    width: 288,
    image: healthAdvocacy,
    copy: `
    Prosper’s Health Advocacy program provides a team of expert negotiators
    to support you from start to finish when you visit the hospital or
    emergency room. They’ll educate you on various procedures and assess
    medical bills.
    `
  },
  {
    title: 'Medical Bill Saver',
    width: 288,
    image: medicalBillSaver,
    copy: `
    A recent study showed that 60% of adults who asked their doctor for
    a discount, got it. Bill Saver works to reduce the amount you owe by
    as much as possible. This way, you can spend money on the things that
    matter–and not an overpriced X-ray.
    `
  }
]

export default ProsperBenefitsModal
