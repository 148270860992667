import React, { useState } from 'react'
import styles from './index.module.scss'
import * as api from 'Utilities/pharaoh'
import { useAsync } from 'react-use'
import { AsyncTable, Alignment, SortDirection } from 'Components/Rudimentary/Table'
import { Route } from 'Utilities/Route'
import { useHistory } from 'react-router-dom'
import SearchBar from 'Components/Anubis/SearchBar'
import ProfileSection, { ShowAll } from 'Components/Anubis/ProfileSection'
import { supportEmail } from 'Utilities/config'

interface AssociationsResponse {
  associations: AssociationsResponseSelf[]
  associationCount: number
}

interface AssociationsResponseSelf {
  id: string
  name: string
  contact: {name: string}
  groupCount: number
  billableGroups: number
  premium: string
}

const DashboardAgencyAssociations: React.FC = () => {
  const [query, setQuery] = useState('')
  const async = useAsync(async() => (await api.v2.brokers.associations().GET() as AssociationsResponse).associations)
  const history = useHistory()
  const count = async.value ? `(${filter(async.value).length})` : ''

  function filter(associations: AssociationsResponseSelf[]) {
    return associations.filter(ass => ass.name.toLowerCase().includes(query.toLowerCase()))
  }

  return <>
    <header className={styles.header}>
      <h1>Associations</h1>
      <div>
        <a href={`mailto:${supportEmail()}?Subject=[Request]%20Add%20Association`} className={styles.contact}>Contact support to add associations</a>
        <SearchBar
          placeholder='Search Associations'
          query={query}
          setQuery={setQuery}
        />
      </div>
    </header>
    <ProfileSection expanded={true} name={`Associations ${count}`} style={{ marginTop: 0 }}>
      <ShowAll onChange={() => setQuery('')} checked={!query}/>
      <AsyncTable
        async={async}
        mangle={filter}
        order={['name', 'contact', 'groupCount', 'billableGroups', 'premium']}
        heading={key => {
          switch (key) {
          case 'name':
            return 'Association'
          case 'contact':
            return 'Lead Name'
          case 'groupCount':
            return '# Clients'
          case 'billableGroups':
            return '# Billable Clients'
          case 'premium':
            return 'Total Premium'
          }
        }}
        width={key => {
          switch (key) {
          case 'name':
            return '17%'
          case 'contact':
            return '32%'
          case 'groupCount':
            return '11%'
          case 'billableGroups':
            return '18%'
          case 'premium':
            return '16%'
          default:
            return '0%'
          }
        }}
        content={(key, value) => {
          switch (key) {
          case 'contact':
            return value.name
          case 'billableGroups':
            return value?.toFixed(1)
          }
        }}
        alignment={key => {
          switch (key) {
          case 'groupCount':
          case 'billableGroups':
          case 'premium':
            return Alignment.right
          default:
            return Alignment.left
          }
        }}
        selectAction={push}
        selectable={(row) => !!row.id}
        defaultSort='name'
        defaultSortDirection={key => {
          switch (key) {
          case 'id':
          case 'name':
          case 'contact':
            return SortDirection.ascending
          case 'groupCount':
          case 'billableGroups':
          case 'premium':
            return SortDirection.descending
          }
        }}
        secondarySort={() => ['name', 'contact']}
        reportFileBasename='associations'
      />
    </ProfileSection>
  </>

  function push(row: AssociationsResponseSelf) {
    history.push(`${Route.agencyDashboardAssociations}/${row.id}`)
  }
}

export default DashboardAgencyAssociations
