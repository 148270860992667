import React from 'react'
import styles from './RemovePlansModal.module.css'
import StargateModal from 'Components/Stargate/StargateModal'
import { logoFor, massagedPlanName } from 'Components/Plans/plan-subcomponents/PlanHelpers'
import { MedicalPlan } from 'Utilities/pharaoh.types'
import { classNames } from 'Utilities/etc'
import usePivot, { Side } from 'Utilities/Hooks/usePivot'

interface Props {
  plans: MedicalPlan[]
  isOpen: boolean
  onClose: () => void
  isRemovingAllPlans: boolean
  handleRemoveContinue: (ids: string[]) => void
}

const RemovePlansModal: React.FC<Props> = ({ isRemovingAllPlans, plans, isOpen, onClose, handleRemoveContinue: callback }) => {
  const [[removes], , { side, size, slot }] = usePivot<string>()

  return <StargateModal
    isOpen={isOpen}
    styles={style}
    onRequestClose={onClose}
  >
    <div className={styles.removePlansContainer}>
      {modalTitle()}
      {plans.map((plan) => {
        return <PlanToRemove
          plan={plan}
          toggle={slot}
          side={side(plan.id)}
          isRemovingAllPlans={isRemovingAllPlans}
          key={plan.id}
        />
      })}
      {button()}
    </div>

  </StargateModal>

  function button() {
    let disabled: boolean
    let text: string
    if (isRemovingAllPlans && removes.size === plans.length) {
      disabled = false
      text = 'Back to Plans'
    } else {
      text = 'Continue'
      disabled = size !== plans.length
    }

    return <button className={styles.applicationButton} onClick={onClick} disabled={disabled}>{text}</button>

    function onClick() {
      callback(Array.from(removes))
    }
  }

  function modalTitle() {
    return removes.size === plans.length
      ? <h2 className={styles.planName}>You must choose at least <span>one plan</span> to continue</h2>
      : <h2>{`Are you sure you want to remove ${plans.length > 1 ? 'these plans' : 'this plan'}?`}</h2>
  }
}

interface Props2 {
  plan: MedicalPlan
  side: Side | undefined
  isRemovingAllPlans: boolean
  toggle: (id: string, to: Side) => void
}

const PlanToRemove: React.FC<Props2> = ({ plan, side, isRemovingAllPlans, toggle }) => {
  const activeStyle = isRemovingAllPlans ? styles.removingAllActive : styles.active

  return <div className={styles.planToRemove}>
    { logoFor(plan.carrier, plan.name) || <h3 className={styles.carrierName}>{plan.carrier}</h3> }
    <p className={styles.planName}>{massagedPlanName(plan.name, plan.carrier)}</p>
    <div className={styles.buttonContainer}>
      <button className={classNames(side === Side.left && activeStyle)} onClick={() => toggle(plan.id, Side.left)}>
        Yes<br /><span>Remove</span>
      </button>
      <button className={classNames(side === Side.right && activeStyle)} onClick={() => toggle(plan.id, Side.right)}>
        No<br /><span>Keep</span>
      </button>
    </div>
  </div>
}

const style = {
  width: '540px',
  height: 'fit-content',
  background: '#f8f8f8'
}

export default RemovePlansModal
